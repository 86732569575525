import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { CompanyPhone } from '@generated/types/graphql';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useDeleteCompanyPhone } from '@hooks/workspace/phones/useDeleteCompanyPhone';
import { useAddCompanyPhone } from '@hooks/workspace/phones/useAddCompanyPhone';
import { useUpdateCompanyPhone } from '@hooks/workspace/phones/useUpdateCompanyPhone';
import { Body, Fields, Footer } from './styled';
import { AccessList, MemberSetting, SendAndReceiveOption } from '../../AccessList';

const OPTIONS: SendAndReceiveOption[] = [
  {
    id: 3,
    name: 'Send SMS and receive their notifications',
    description: 'User can send SMS and receive Inbox notifications about their incoming messages',
    canSend: true,
    canReceiveAll: false
  },
  {
    id: 1,
    name: 'Send SMS and receive notifications',
    description: 'User can send SMS and receive Inbox notifications about all incoming messages',
    canSend: true,
    canReceiveAll: true
  },
  {
    id: 2,
    name: 'Can’t send SMS, but receive notifications',
    description: 'User can’t send SMS, but receive Inbox notifications about all incoming messages',
    canSend: false,
    canReceiveAll: true
  }
];

type FormValues = {
  phone: number;
  alias: string;
};

interface Props {
  initialValues?: CompanyPhone;
  onClose: () => void;
}

export const PhoneForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;
  const accessListRef = useRef<MemberSetting[]>([]);

  const { mutateAsync: remove } = useDeleteCompanyPhone();
  const { mutateAsync: add } = useAddCompanyPhone();
  const { mutateAsync: update } = useUpdateCompanyPhone();

  const confirmDelete = useConfirmDeleteModal();

  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const handleDisconnect = useCallback(async () => {
    if (
      await confirmDelete(`You're about to delete this phone number. \n You will no longer be able to send
        and receive SMS in Coperniq using this phone number.`)
    ) {
      setIsDisconnecting(true);
      await remove(initialValues.id);
      onClose();
      setIsDisconnecting(false);
    }
  }, [initialValues, remove, onClose, confirmDelete]);

  const postForm = async (values: FormValues) => {
    if (isNew) {
      await add({
        phone: values.phone,
        alias: values.alias
      });

      onClose();
    } else {
      const users = accessListRef.current.map((member) => ({
        userId: member.user.id,
        canSend: member.sendAndReceive.canSend,
        canReceiveAll: member.sendAndReceive.canReceiveAll
      }));

      await update({ id: initialValues.id, alias: values.alias, users });
      onClose();
    }
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: initialValues
      ? {
          phone: (initialValues.phone ?? '').replace('+', ''),
          alias: initialValues.alias
        }
      : undefined
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      phone: {
        isRequired: true,
        validate: (value) => {
          if (!value || !isNew) {
            return undefined;
          }

          const code = parseInt(value, 10);

          if (Number.isNaN(code) || code < 100 || code > 999) {
            return 'Please enter a valid area code';
          }

          return undefined;
        }
      },
      alias: {
        isRequired: true
      }
    }),
    [isNew]
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <Fields>
          <InputField
            type="number"
            disabled={!isNew}
            label={isNew ? 'Area code' : 'Phone'}
            name="phone"
            control={control}
          />
          <InputField label="Name" placeholder="Sales main..." name="alias" control={control} />
        </Fields>
        {!isNew && (
          <AccessList
            placeholder="No one can use this number yet"
            ref={accessListRef}
            users={initialValues.companyPhoneUsers}
            sendAndReceiveOptions={OPTIONS}
          />
        )}
      </Body>
      <Footer>
        {!isNew && (
          <Button disabled={isSubmitting || isDisconnecting} variant={ButtonVariant.Danger} onClick={handleDisconnect}>
            Delete
          </Button>
        )}
        <Button disabled={isDisconnecting || isSubmitting} variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || isDisconnecting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Save'}
        </Button>
      </Footer>
    </Form>
  );
};
