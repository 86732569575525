import React from 'react';
import { Container } from './styled';
import { Table } from './Table';
import { Header } from './Header';

export const List = () => {
  return (
    <Container>
      <Header />
      <Table />
    </Container>
  );
};
