import React from 'react';
import { IProps } from './type';

import styled from 'styled-components';
import { button } from '../../../../styles/';

const Button = styled.button`
  ${button}

  height: 46px;
`;

const PrimaryButton = (props: IProps) => {
  const { value, disabled = false } = props;
  return (
    <Button type="submit" disabled={disabled} primary>
      {value}
    </Button>
  );
};

export default PrimaryButton;
