import React from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useSelectedWorker } from '@components/Scheduler/Dispatcher/useSelectedWorker';
import { useMapMode } from '@components/Scheduler/components/MapView/useMapMode';
import { QueryParamsEnum, useQueryParam } from '@hooks';
import { Container, Info, Name, Subtitle } from './styled';

export const ResourceCell = (props: any) => {
  const {
    data: { data: user }
  } = props;

  const [selected, setSelected] = useSelectedWorker();
  const [mapMode, setMapMode] = useMapMode();
  const [currentView] = useQueryParam(QueryParamsEnum.CalendarViewType);

  const handleClick = () => {
    setSelected(selected?.id === user.id ? null : user);

    if (mapMode === 'hidden' && currentView === 'day') {
      setMapMode('half');
    }
  };

  return (
    <Container selected={selected?.id === user.id} onClick={handleClick}>
      <UserAvatar user={user} />
      <Info>
        <Name>
          {user.firstName} {user.lastName}
        </Name>
        <Subtitle>{user.email}</Subtitle>
      </Info>
    </Container>
  );
};
