import React, { useMemo } from 'react';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { EnphaseIntegrationDTO } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector, useAuth } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { useCreateIntegration } from '@hooks/workspace/systems/integrations';
import { Info } from 'react-feather';
import { colors } from '@styles';
import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Body, Container, CredsAlert, CredsHeader, CredsSubtitle, CredsTitle } from './styled';

type Props = {
  isMock: boolean;
  onClose: () => void;
};

export const EnphaseForm = ({ isMock, onClose }: Props) => {
  const {
    user: { userId }
  } = useAuth();
  const companyId = useAppSelector(selectWorkspaceId);

  const { mutateAsync: create } = useCreateIntegration(userId, companyId);

  const {
    form: {
      control,
      formState: { isSubmitting, isValid }
    },
    handleSubmit
  } = useForm({
    defaultValues: {
      provider: isMock ? IntegrationProvider.Enfake : IntegrationProvider.Enphase
    },
    onSubmit: async (values: EnphaseIntegrationDTO) => {
      await create(values);

      onClose();
    }
  });

  const rules = useMemo<FormValidationRules<EnphaseIntegrationDTO>>(
    () => ({
      provider: {
        isRequired: true
      },
      apiKey: {
        isRequired: true
      },
      clientId: {
        isRequired: true
      },
      clientSecret: {
        isRequired: true
      },
      email: {
        isRequired: true
      },
      password: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <Container>
          <CredsAlert>
            <CredsHeader>
              <Info size={24} color={colors.blue} />
              <CredsTitle>We do not get access to your Enphase account.</CredsTitle>
            </CredsHeader>
            <CredsSubtitle>
              We do not store your Enphase account credentials. We only send it to Enphase to get app access tokens.
            </CredsSubtitle>
          </CredsAlert>

          <Body>
            <InputField control={control} name="email" label="Enphase account email" />
            <InputField control={control} name="clientId" label="Enphase Client ID" />
            <InputField control={control} name="password" label="Enphase account password" />
            <InputField control={control} name="clientSecret" label="Enphase Client secret" />
            <InputField control={control} name="apiKey" label="Enphase API key" />
          </Body>
        </Container>
      </ModalBody>

      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isValid} variant={ButtonVariant.Primary} type="submit">
          Connect
        </Button>
      </ModalFooter>
    </Form>
  );
};
