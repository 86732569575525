import React from 'react';
import styled from 'styled-components';
import { device, colors } from '@styles';

const Wrapper = styled.div<{ color: string }>`
  padding: 0 0 100px 0;
  overflow-y: auto;
  background-color: ${(props) => props.color || colors.white};
  padding: 16px;

  ${device.sm`
    height: calc(100vh - 124px);
    padding: 25px;
  `};
`;

interface IContainer {
  children?: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
}

const Container: React.FC<IContainer> = (props: IContainer): JSX.Element => {
  const { children, color = colors.white, style } = props;

  return (
    <Wrapper color={color} style={style}>
      {children}
    </Wrapper>
  );
};

export default Container;
