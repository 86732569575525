import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Body = styled.div`
  padding: 16px 24px;
  overflow: auto;
  ${scroll};
  max-height: calc(100vh - 64px - 64px - 64px);
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 24px;
  background-color: #fff;
`;

export const TextButton = styled.div`
  color: ${colors.green};
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
`;

export const CcAndBccButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
  margin-top: -12px;
`;
