import { ReferralSettings } from './types';

export const DEFAULT_SETTINGS: ReferralSettings = {
  enabled: false,
  promptTitle: 'Refer & Earn',
  promptDescription: 'Know someone curious about solar energy? Just share their contacts and earn rewards.',
  shareableMessage: `Hello!
[Company name|~property:companyName] installed great solar panels for me recently. Contact them if you’re interested!


Tap here to contact:\n[Referral link|~property:referralLink]`,
  promptImageUrl: null
};
