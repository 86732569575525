import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { actionTemplateApi } from '@services/api/actionTemplateApi';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteActionTemplate = () => {
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { id: number }>(
    async ({ id }) => {
      try {
        await actionTemplateApi.remove(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(ReactQueryKey.ActionTemplateList);
        await queryClient.resetQueries([ReactQueryKey.WorkspaceBlueprints]);

        showSuccess('Successfully deleted the action template');
      }
    }
  );
};
