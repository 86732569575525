import { Reminder as TReminder } from '@generated/types/graphql';
import { colors } from '@styles';
import moment from 'moment';

import React, { useCallback } from 'react';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useToast } from '@hooks/useToast';
import { useModal } from '@common/PromiseModal';
import { ReminderView } from '@features/Work/ReminderView';
import { Container, Title, Cell, Label, Assignee, AssigneeName } from './styled';

interface Props {
  task: TReminder;
}

const formatDate = (date: string | null) => (date ? moment(date).format('MM/DD/YY') : '...');

export const Reminder = ({ task }: Props) => {
  const { assignee } = task;
  const ReminderIc = REMINDER_TYPE_TO_ICON[task.type];
  const { showError } = useToast();
  const { openModal } = useModal();

  const { isAppointment } = task;

  const handleClick = useCallback(() => {
    if (!task.project) {
      showError(`You don't have access to the project`);

      return;
    }

    openModal<void>(({ onClose }) => <ReminderView initialValues={task} onClose={onClose} />, {
      isHeaderShown: false
    });
  }, [task, showError, openModal]);

  return (
    <Container onClick={handleClick}>
      <Cell width="24%">
        <Title isCompleted={task.isCompleted}>
          {!isAppointment && !!ReminderIc && <ReminderIc size="16px" color={colors.green} />}
          {isAppointment && <CalendarIcon size="16px" color={colors.green} />}
          <div>{task.title}</div>
        </Title>
      </Cell>

      <Cell width="10%" />
      <Cell width="11%">
        {assignee && (
          <Assignee>
            <UserAvatar user={assignee} />
            <AssigneeName>
              {assignee.firstName} {assignee.lastName}
            </AssigneeName>
          </Assignee>
        )}
      </Cell>
      <Cell width="11%" />
      <Cell width="15%">{task.project && <ProjectTitleChip target="panel" project={task.project} />}</Cell>
      <Cell width="16%">
        <div>{moment(task.dueDate).format('MM/DD/YY hh:mm')}</div>
      </Cell>
      <Cell width="10%" align="flex-end">
        <div>
          <Label>{formatDate(task.createdAt)}</Label>
        </div>
      </Cell>
      <Cell width="3%" align="flex-end" />
    </Container>
  );
};
