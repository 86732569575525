import { Checkbox } from '@material-ui/core';
import { colors } from '@styles';
import styled from 'styled-components';
import { WorkOrderStatus as WorkOrderStatusComponent } from '@components/WorkOrder/WorkOrderStatus';
import { Actions } from '@components/Scheduler/components/WorkOrderView/Actions';

export const MoreMenu = styled(Actions)`
  opacity: 0;
`;

export const Container = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0px 4px 4px rgba(26, 3, 64, 0.08);
  padding: 8px 12px;
  margin-top: 8px;
  border-left: 4px solid ${(props) => props.borderColor || '#fff'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  font-size: 12px;
  padding-left: 8px;

  &:first-child {
    margin-top: 16px;
  }

  &:hover {
    ${MoreMenu} {
      opacity: 1;
    }
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${colors.black};
  text-decoration: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
  display: flex;
  align-items: center;
  gap: 8px;

  div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const BulkCheckbox = styled(Checkbox)`
  && {
    margin-left: -4px;
  }
`;

export const Cell = styled.div`
  width: ${(props) => props.width};
  display: flex;
  justify-content: ${(props) => props.align ?? 'flex-start'};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-shrink: 0;
  padding-right: 8px;

  &:last-child {
    padding-right: 0;
  }
  > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const Assignee = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  > div:first-child {
    flex: 0;
  }

  > div > div {
    margin-right: 0px;
  }
`;

export const AssigneeName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Label = styled.span`
  color: #98a9bc;
`;

export const WorkOrderStatus = styled(WorkOrderStatusComponent)`
  max-width: 100%;
`;
