import React from 'react';
import { Control, FieldPath } from 'react-hook-form';
import { FormField, useControllerWithValidation } from '@kit/components/Form';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { Checkbox } from '@kit/ui/Checkbox';
import { IntegrationProvider } from '@generated/types/graphql';
import { Check } from 'react-feather';
import { BadgeSize } from '@kit/ui/Badge/types';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { SYSTEM_METRIC_TITLES_MAP } from '@domains/systems';
import { Chip, EnabledMetric, MetricList, MetricsGroup, MetricsGroupTitle } from './styled';
import { PROVIDER_CONFIGS_MAP } from '../constants';
import { FormValues } from './types';

interface Props {
  control: Control<FormValues>;
  config: Fleet['systemProfileConfigs'][number];
  name: FieldPath<FormValues>;
  label?: string;
  clearOnUnmount?: boolean;
  hint?: string;
}

const METRICS_TREE: { [key: SystemMetric | string]: SystemMetric[] } = {
  Energy: [
    SystemMetric.EnergyProduction,
    SystemMetric.EnergyConsumption,
    SystemMetric.EnergyImport,
    SystemMetric.EnergyExport
  ],
  Power: [
    SystemMetric.PowerProduction,
    SystemMetric.PowerConsumption,
    SystemMetric.PowerImport,
    SystemMetric.PowerExport
  ],
  [SystemMetric.Storage]: [],
  [SystemMetric.Metadata]: [],
  [SystemMetric.Devices]: []
};

export const MetricsField = ({ name, control, config, label = 'Metrics', hint, clearOnUnmount }: Props) => {
  const {
    field: { value, onChange }
  } = useControllerWithValidation(name, control, label, clearOnUnmount);

  const enabledMetrics = (value as SystemMetric[]) ?? [];

  return (
    <FormField label={label} hint={hint}>
      <MetricList>
        {Object.entries(METRICS_TREE).map(([group, metrics]) => {
          if (!metrics.length) {
            return (
              <div>
                <MetricControl
                  isParent
                  provider={config.integration.provider}
                  metric={group as SystemMetric}
                  enabledMetrics={enabledMetrics}
                  onChange={onChange}
                />
              </div>
            );
          }

          const allEnabledAndNotEditable = metrics.every((metric) => {
            const metricConfig = PROVIDER_CONFIGS_MAP[config.integration.provider].metricsConfig[metric];

            return !metricConfig.enablable && metricConfig.defaultEnabled;
          });

          const availableMetrics = metrics.filter((metric) => {
            const metricConfig = PROVIDER_CONFIGS_MAP[config.integration.provider].metricsConfig[metric];

            return metricConfig.available;
          });

          return (
            <div>
              <MetricsGroupTitle>
                {allEnabledAndNotEditable ? (
                  <EnabledMetric>
                    <Check size="20px" color="#009A47" />
                    {group}
                  </EnabledMetric>
                ) : (
                  <Checkbox
                    label={group}
                    intermediate={
                      enabledMetrics.some((metric) => availableMetrics.includes(metric)) &&
                      !availableMetrics.every((metric) => enabledMetrics.includes(metric))
                    }
                    isChecked={availableMetrics.every((metric) => enabledMetrics.includes(metric))}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        onChange([...new Set([...enabledMetrics, ...availableMetrics])]);
                      } else {
                        onChange(enabledMetrics.filter((m) => !availableMetrics.includes(m)));
                      }
                    }}
                  />
                )}
              </MetricsGroupTitle>
              <MetricsGroup>
                {metrics.map((metric) => (
                  <MetricControl
                    key={metric}
                    provider={config.integration.provider}
                    metric={metric}
                    enabledMetrics={enabledMetrics}
                    onChange={onChange}
                  />
                ))}
              </MetricsGroup>
            </div>
          );
        })}
      </MetricList>
    </FormField>
  );
};

export const MetricControl = ({
  isParent = false,
  provider,
  metric,
  enabledMetrics,
  onChange
}: {
  isParent?: boolean;
  provider: IntegrationProvider;
  metric: SystemMetric;
  enabledMetrics: SystemMetric[];
  onChange: (newValue: SystemMetric[]) => void;
}) => {
  const metricConfig = PROVIDER_CONFIGS_MAP[provider].metricsConfig[metric];

  if (!metricConfig.available || (!metricConfig.enablable && !metricConfig.defaultEnabled)) {
    return (
      <Chip isDisabled size={BadgeSize.Large} color="#828D9A" bgColor="#fff">
        {SYSTEM_METRIC_TITLES_MAP[metric]}
      </Chip>
    );
  }

  if (metricConfig.enablable === false && metricConfig.defaultEnabled) {
    if (isParent) {
      return (
        <EnabledMetric>
          <Check size="20px" color="#009A47" />
          {SYSTEM_METRIC_TITLES_MAP[metric]}
        </EnabledMetric>
      );
    }

    return (
      <Chip isDisabled size={BadgeSize.Large} color="#1D1D35" bgColor="#fff">
        <Check size="16px" color="#009A47" />
        {SYSTEM_METRIC_TITLES_MAP[metric]}
      </Chip>
    );
  }

  if (isParent) {
    return (
      <Checkbox
        label={SYSTEM_METRIC_TITLES_MAP[metric]}
        isChecked={enabledMetrics.includes(metric)}
        onChange={(isChecked) =>
          onChange(isChecked ? [...enabledMetrics, metric] : enabledMetrics.filter((m) => m !== metric))
        }
      />
    );
  }

  return (
    <Chip
      size={BadgeSize.Large}
      color="#1D1D35"
      bgColor="#fff"
      isChecked={enabledMetrics.includes(metric)}
      onClick={() =>
        onChange(
          enabledMetrics.includes(metric) ? enabledMetrics.filter((m) => m !== metric) : [...enabledMetrics, metric]
        )
      }
      isDisabled={false}
    >
      {enabledMetrics.includes(metric) && <Check size="16px" color="#009A47" />}
      {SYSTEM_METRIC_TITLES_MAP[metric]}
    </Chip>
  );
};
