import React, { useCallback, useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Edit3, Plus } from 'react-feather';
import { CompanyPhone } from '@generated/types/graphql';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useCompanyPhoneList } from '@hooks/workspace/phones/useCompanyPhoneList';
import { useDeleteCompanyPhone } from '@hooks/workspace/phones/useDeleteCompanyPhone';
import { Form } from './Form';
import { Table, TableContainer, TableHeader, TableTitle } from '../styled';

const TableRow = ({ companyPhone }: { companyPhone: CompanyPhone }) => {
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const { mutateAsync: remove } = useDeleteCompanyPhone();

  const handleRowClick = useCallback(() => {
    openModal<void>(({ onClose }) => <Form initialValues={companyPhone} onClose={onClose} />, { title: 'Edit Phone' });
  }, [companyPhone, openModal]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit3 size="16px" color="#98A9BC" />,
        onClick: handleRowClick
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,
        onClick: async () => {
          if (
            await confirmDelete(`You're about to delete the phone number ${companyPhone?.phone}. \n You will no longer be able to send
                    and receive SMS in Coperniq using this phone number.`)
          ) {
            await remove(companyPhone.id);
          }
        }
      }
    ];
  }, [companyPhone, handleRowClick, confirmDelete, remove]);

  return (
    <tr onClick={handleRowClick}>
      <td>{companyPhone.alias}</td>
      <td>{companyPhone.phone}</td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};

export const Phones = () => {
  const { data: companyPhones = [], isLoading } = useCompanyPhoneList();

  const { openModal } = useModal();

  const handleAddClick = useCallback(() => {
    openModal<void>(({ onClose }) => <Form onClose={onClose} />, { title: 'Add Phone' });
  }, [openModal]);

  return (
    <div>
      <TableHeader>
        <TableTitle>Phones</TableTitle>
        <Button onClick={handleAddClick} variant={ButtonVariant.Primary}>
          <Plus size="16px" />
          Phone
        </Button>
      </TableHeader>

      <TableContainer>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Phone</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {companyPhones.map((companyPhone) => (
              <TableRow key={companyPhone.id} companyPhone={companyPhone} />
            ))}

            {!isLoading && companyPhones.length === 0 && (
              <tr>
                <td colSpan={3} align="center">
                  No phones added yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </TableContainer>
    </div>
  );
};
