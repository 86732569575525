import styled from 'styled-components';

export const FieldsGrid = styled.div<{ fieldsCount: number }>`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - 40px), 1fr));
  column-gap: 40px;
  row-gap: 16px;

  grid-template-rows: auto;

  ${({ fieldsCount }) =>
    fieldsCount === 3 &&
    `
    &:before {
        content: "";
        grid-column: 2 / -1;
        grid-row: 1 / 2;
      }
  `}
`;
