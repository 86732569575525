import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 24px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto auto;

  gap: 24px;
  grid-template-areas:
    'col1 col2'
    'full full';

  > * {
    &:first-child {
      grid-area: col1;
    }

    &:nth-child(2) {
      grid-area: col2;
    }

    &:last-child {
      grid-area: full;
    }
  }
`;

export const Card = styled.div`
  border-radius: 8px;
  border: 1px solid #dfdfe8;
  padding: 16px;
`;

export const CardTitle = styled.div`
  color: #828d9a;

  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const Value = styled.div`
  font-size: 14px;
  font-weight: 400;

  span {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const PieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 140px;

  > *:first-child {
    min-height: 140px;
  }
`;

export const PieLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 150px;
  flex-shrink: 0;
`;

export const LegendLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 400;
  gap: 8px;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;

export const OverbudgetDescription = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;

  span {
    font-weight: 500;
  }
`;
