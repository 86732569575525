import { RecordType } from '@types';
import React, { useCallback, useMemo } from 'react';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Copy, Edit2 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useNavigate } from '@reach/router';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { WorkflowListItem } from '@hooks/workflows/useWorkflowList';
import moment from 'moment';
import { RecordTypeIcon } from '@common/RecordTypeIcon';
import { useDeleteWorkflow } from '@hooks/workflows/useDeleteWorkflow';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Row, Author, Name } from './styled';
import { WorkflowForm } from '../Form';

interface Props {
  workflow: WorkflowListItem;
}

export const WorkflowRow = ({ workflow }: Props) => {
  const { mutateAsync: deleteWorkflow } = useDeleteWorkflow();
  const confirmDelete = useConfirmDeleteModal();

  const navigate = useNavigate();

  const { openModal } = useModal();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          openModal<void>(({ onClose }) => <WorkflowForm initialValues={workflow} onClose={onClose} />, {
            title: 'Edit Workflow'
          });
        }
      },
      {
        icon: <Copy size="16px" color="#9C9CAA" />,
        title: 'Duplicate',
        onClick: () => {
          openModal<void>(
            ({ onClose }) => (
              <WorkflowForm
                sourceId={workflow.id}
                initialValues={{ ...workflow, name: `${workflow.name} (copy)` }}
                onClose={onClose}
              />
            ),
            { title: 'Copy Workflow' }
          );
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this Workflow?')) {
            await deleteWorkflow(workflow.id);
          }
        }
      }
    ];
  }, [workflow, confirmDelete, deleteWorkflow, openModal]);

  const handleRowClick = useCallback(() => {
    navigate(`./${workflow.id}`);
  }, [navigate, workflow]);

  return (
    <Row onClick={handleRowClick}>
      <td>
        <Name>
          <RecordTypeIcon recordType={workflow.type === 'PROJECT' ? RecordType.PROJECT : RecordType.DEAL} />
          {workflow.name}
        </Name>
      </td>
      <td>{workflow.description}</td>
      <td>{moment(workflow.createdAt).format('MM/DD/YY')}</td>
      <td>
        <Author>
          <UserAvatar user={workflow.createdByUser} size={20} />
          <div>
            {workflow.createdByUser.firstName} {workflow.createdByUser.lastName}
          </div>
        </Author>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </Row>
  );
};
