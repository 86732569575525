import React, { useCallback, useState } from 'react';
import { Upload, UploadProps } from 'antd';
import { uploadSingleFile } from '@services/UploadServices/uploadService';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useToast } from '@hooks/useToast';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { Loader } from '@kit/ui/Loader';
import defaultPromptImage from './assets/defaultPromptImage.png';
import { ChangeImageButton, PromptImageContainer, RemoveImageButton } from './styled';

const { Dragger } = Upload;

const MAX_FILE_SIZE_MB = 5;

interface Props {
  imageUrl: string | null;
  onChange: (imageUrl: string | null) => void;
}

export const PromptImage = ({ imageUrl, onChange }: Props) => {
  const [isUploading, setIsUploading] = useState(false);
  const companyId = useAppSelector(selectWorkspaceId);
  const { showError } = useToast();

  const uploadFile = useCallback(
    async (file: File) => {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      const result = await uploadSingleFile(formData, { companyId, by: 'company', id: companyId });

      const newImageUrl = result?.data?.downloadUrl;

      onChange(newImageUrl);

      setIsUploading(false);
    },
    [companyId, onChange]
  );

  const uploadProps: UploadProps = {
    multiple: false,
    showUploadList: false,
    accept: 'image/png, image/jpeg, image/jpg',
    beforeUpload: async (file) => {
      if (file.size / 1024 / 1024 > MAX_FILE_SIZE_MB) {
        // 350mb
        showError(`Maximum file size is ${MAX_FILE_SIZE_MB} MB`);

        return false;
      }

      uploadFile(file);

      return true;
    }
  };

  const handleRemoveImage = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (e) => {
      e.stopPropagation();

      onChange(null);
    },
    [onChange]
  );

  if (isUploading) {
    return (
      <PromptImageContainer>
        <Loader size={64} />
      </PromptImageContainer>
    );
  }

  return (
    <Dragger {...uploadProps}>
      <PromptImageContainer>
        <img src={imageUrl || defaultPromptImage} alt="prompt" />
        <ChangeImageButton>Change image</ChangeImageButton>

        {imageUrl && (
          <RemoveImageButton onClick={handleRemoveImage}>
            <TrashIcon size="16px" color="#D54855" />
          </RemoveImageButton>
        )}
      </PromptImageContainer>
    </Dragger>
  );
};
