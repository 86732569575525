import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import React from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { validateUrl } from '@utils/validations';
import { FormBody, FormDescription, FormFooter, ReviewChannelIcon, ReviewChannelRow } from './styled';
import { ReviewChannelType, ReviewsSettings } from './types';

import googleLogo from './assets/google.png';
import bbbLogo from './assets/bbb.png';
import yelpLogo from './assets/yelp.png';

const CHANNEL_CONFIG: Record<ReviewChannelType, { title: string; logo: string }> = {
  [ReviewChannelType.Google]: {
    title: 'Google',
    logo: googleLogo
  },
  [ReviewChannelType.BBB]: {
    title: 'Better Business Bureau',
    logo: bbbLogo
  },
  [ReviewChannelType.Yelp]: {
    title: 'Yelp',
    logo: yelpLogo
  }
};

type FormValues = Record<ReviewChannelType, string>;

const rules: FormValidationRules<FormValues> = {
  [ReviewChannelType.Google]: {
    validate: validateUrl
  },
  [ReviewChannelType.Yelp]: {
    validate: validateUrl
  },
  [ReviewChannelType.BBB]: {
    validate: validateUrl
  }
};

interface Props {
  onClose: () => void;
  settings: Partial<ReviewsSettings>;
}

const getDefaultValues = (settings: Partial<ReviewsSettings>) => {
  const defaultValues: FormValues = {
    [ReviewChannelType.Google]: '',
    [ReviewChannelType.Yelp]: '',
    [ReviewChannelType.BBB]: ''
  };

  settings?.channels?.forEach((channel) => {
    defaultValues[channel.type] = channel.url;
  });

  return defaultValues;
};

export const EditForm = ({ settings, onClose }: Props) => {
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const postForm = async (values: FormValues) => {
    const channels = Object.entries(values).map(([type, url]) => ({
      type: type as ReviewChannelType,
      url: (url ?? '').trim()
    }));

    const payload = {
      enabled: true,
      channels
    };

    await updateCompany({
      settings: {
        portalReviews: payload
      }
    });

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: getDefaultValues(settings)
  });

  const { control } = form;

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <FormBody>
        <FormDescription>
          Select channel where you want to get reviews from your clients and paste links to your company profiles here.
        </FormDescription>
        {settings.channels.map((channel) => {
          return (
            <ReviewChannelRow key={channel.type}>
              <ReviewChannelIcon>
                <img src={CHANNEL_CONFIG[channel.type].logo} alt={channel.type} />
              </ReviewChannelIcon>
              <InputField
                control={control}
                name={channel.type}
                placeholder={`Paste your ${CHANNEL_CONFIG[channel.type].title} link here`}
              />
            </ReviewChannelRow>
          );
        })}
      </FormBody>

      <FormFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          Update
        </Button>
      </FormFooter>
    </Form>
  );
};
