import React, { useMemo } from 'react';

import { PrivilegedTasksConnection } from '@generated/types/graphql';

import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import { colors } from '@styles';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';

import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { TooltipContent, Heading, List, WorkOrderItem, WorkOrderItemLeft, SeeAll } from './styled';

interface Props {
  children: React.ReactNode;
  workOrders: PrivilegedTasksConnection;
  title: React.ReactNode;
}

const LightTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: colors.white,
    color: colors.black,
    boxShadow: '0 0 20px 0 rgba(0, 0, 74, 0.12)'
  }
}))(Tooltip);

export const WorkOrderListPopover = ({ title, children, workOrders }: Props) => {
  const { openDrawer } = useDrawersContext();

  const ids = useMemo(() => workOrders.nodes.map((node) => node.id), [workOrders.nodes]);

  return (
    <LightTooltip
      interactive
      title={
        <TooltipContent>
          <Heading>{title}</Heading>

          <List>
            {workOrders.totalCount === 0 && <div>No work orders yet</div>}
            {workOrders.nodes.map((node) => (
              <WorkOrderItem
                key={node.id}
                onClick={() => {
                  // first row click opens project and then we add work order id to query params
                  setTimeout(() => {
                    openDrawer(DrawerEntity.WORK_ORDER, node.id, ids);
                  }, 0);
                }}
              >
                <WorkOrderItemLeft>
                  <WorkOrderTypeIcon isField={node.isField} />
                  <div>
                    #{node.uid}
                    {' · '}
                    {node.title}
                  </div>
                </WorkOrderItemLeft>
                <WorkOrderStatus status={node.taskStatus} />
              </WorkOrderItem>
            ))}

            {workOrders.totalCount > 10 && <SeeAll>See more ({workOrders.totalCount - 10})</SeeAll>}
          </List>
        </TooltipContent>
      }
    >
      {children}
    </LightTooltip>
  );
};
