import React, { useCallback, useMemo } from 'react';
import { formatCurrencyWithoutCents } from '@utils/utils';
import { Button, ButtonVariant } from '@kit/ui/Button';

import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';

import { Description } from './styled';

interface Props {
  currentBudget: number;
  totalCurrentExpectedBill: number;
  totalNewExpectedBill: number;
  onClose: (newBudget: number | void) => void;
}

interface FormValues {
  budget: number;
}

export const ConfirmSave = ({ currentBudget, totalCurrentExpectedBill, totalNewExpectedBill, onClose }: Props) => {
  const { handleSubmit, form } = useForm<FormValues>({
    defaultValues: {
      budget: currentBudget
    },
    onSubmit: async ({ budget }) => {
      onClose(budget);
    }
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const { control } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      budget: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="750px">
        <Description>
          <div>
            You are about to save changed Fleets settings. This will lead to changes in expected bills. <br />
            Please review Budget and confirm changes.
          </div>

          <br />

          <div>
            Expected bill before simulation: <b>{formatCurrencyWithoutCents(totalCurrentExpectedBill)}</b>
          </div>
          <div>
            Expected bill after saving: <b>{formatCurrencyWithoutCents(totalNewExpectedBill)}</b>
          </div>
          <br />
        </Description>

        <InputField control={control} name="budget" label="New Budget" type="number" />
      </ModalBody>

      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} type="submit">
          Confirm and save
        </Button>
      </ModalFooter>
    </Form>
  );
};
