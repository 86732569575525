import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const Container = styled(EditableFieldContainer)`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
`;
