import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { Form, FormValidationRules, InputField, RadioGroupField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React, { useMemo } from 'react';
import { formatCurrencyWithoutCents } from '@utils/utils';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { useUpdateSystemsBudget } from '@hooks/workspace/systems/useUpdateSystemsBudget';
import { OverbudgetBehaviour } from '@generated/types/graphql';
import { OVERBUDGET_BEHAVIOUR_OPTIONS } from '../constants';
import {
  OverbudgetSection,
  OverbudgetSectionDescription,
  OverbudgetSectionTitle,
  RadioSelectContainer
} from './styled';

interface FormValues {
  monthlyBudget: number;
  overbudgetBehaviour: OverbudgetBehaviour;
  whitelistProfiles: { id: number; name: string }[];
}

interface Props {
  initialValues: FormValues;
  onClose: () => void;
}

export const EditBudget = ({ initialValues, onClose }: Props) => {
  const { data: fleets = [] } = useFleetList();

  const { mutateAsync: update } = useUpdateSystemsBudget();

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      ...initialValues
    },
    onSubmit: async (values) => {
      await update({
        monthlyBudget: values.monthlyBudget,
        overbudgetBehaviour: values.overbudgetBehaviour,
        whitelistProfiles:
          values.overbudgetBehaviour === OverbudgetBehaviour.StopExceptWhitelist
            ? values.whitelistProfiles.map((profile) => profile.id)
            : []
      });

      onClose();
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      monthlyBudget: {
        isRequired: true,
        min: 0
      },
      whitelistProfiles: {
        isRequired: true
      }
    }),
    []
  );

  const {
    control,
    formState: { isSubmitting },
    watch
  } = form;

  const budget = watch('monthlyBudget') || 0;
  const overbudgetBehaviour = watch('overbudgetBehaviour');

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody width="815px">
        <InputField control={control} name="monthlyBudget" label="Enter your monthly budget" type="number" />

        <OverbudgetSection>
          <OverbudgetSectionTitle>Overbudget behaviour</OverbudgetSectionTitle>
          <OverbudgetSectionDescription>
            If budget usage reaches its limits ({formatCurrencyWithoutCents(budget)}), then:
          </OverbudgetSectionDescription>

          <RadioGroupField name="overbudgetBehaviour" options={OVERBUDGET_BEHAVIOUR_OPTIONS} control={control} />

          {overbudgetBehaviour === OverbudgetBehaviour.StopExceptWhitelist && (
            <RadioSelectContainer>
              <SelectField
                control={control}
                name="whitelistProfiles"
                options={fleets}
                isMulti
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.id}
              />
            </RadioSelectContainer>
          )}
        </OverbudgetSection>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
