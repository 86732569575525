import { IntegrationProvider } from '@generated/types/graphql';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { PROVIDER_CONFIGS_MAP } from './constants';

export const calculateHitsCostPerProvider = (hitsByProvider: { [provider in IntegrationProvider]: number } | null) => {
  if (!hitsByProvider) {
    return {} as { [provider in IntegrationProvider]: number };
  }

  return Object.keys(hitsByProvider).reduce(
    (acc, providerStr) => {
      const provider = providerStr as IntegrationProvider;
      const providerConfig = PROVIDER_CONFIGS_MAP[provider];

      return {
        ...acc,
        [provider]: providerConfig.getHitsCost(hitsByProvider[provider])
      };
    },
    {} as { [provider in IntegrationProvider]: number }
  );
};

export const calculateHitsCost = (hitsByProvider: { [provider in IntegrationProvider]: number }) => {
  return Object.values(calculateHitsCostPerProvider(hitsByProvider)).reduce((acc, cost) => acc + cost, 0);
};

export const calculateExpectedBill = ({
  currentHitsByProvider = {},
  averageMonthlyNewSystems,
  systemsByFleetId,
  systemsByFleetIdByIntegrationId,
  totalSystems,
  fleets,
  days,
  daysInMonth = 30
}: {
  currentHitsByProvider?: { [provider in IntegrationProvider]: number };
  averageMonthlyNewSystems: number;
  systemsByFleetId: { [fleetId: string]: number };
  systemsByFleetIdByIntegrationId: { [fleetId: string]: { [integrationId: string]: number } };
  totalSystems: number;
  fleets: Fleet[];
  days: number;
  daysInMonth?: number;
}) => {
  const avgSystemsPerDay = averageMonthlyNewSystems / daysInMonth;

  const expectedNewSystems = avgSystemsPerDay * days;

  const expectedHitsByProvider = Object.entries(systemsByFleetId).reduce(
    (acc, [fleetId, fleetSystemsCount]) => {
      const fleetRatio = fleetSystemsCount / totalSystems;

      const expectedSystems = fleetSystemsCount + expectedNewSystems * fleetRatio;
      const fleet = fleets.find((fleet) => fleet.id.toString() === fleetId);

      fleet.systemProfileConfigs.forEach((config) => {
        const providerId = config.integration.id;
        const { provider } = config.integration;
        const providerConfig = PROVIDER_CONFIGS_MAP[provider];
        const providerRatio = (systemsByFleetIdByIntegrationId[fleetId]?.[providerId] ?? 0) / fleetSystemsCount;

        const enabledMetrics = Object.keys(config.metrics).filter(
          (metric) => config.metrics[metric as SystemMetric].enabled
        ) as SystemMetric[];

        const hits = expectedSystems * providerRatio * providerConfig.getHitsByEnabledMetrics(enabledMetrics) * days;

        acc[provider] = (acc[provider] || 0) + hits;
      });

      return acc;
    },
    { ...currentHitsByProvider } as Record<IntegrationProvider, number>
  );

  return calculateHitsCostPerProvider(expectedHitsByProvider);
};
