import React, { useCallback, useState } from 'react';
import { SubjectAccess } from '@common/RoleAccess';
import { FilterWithGroups } from '@components/Scheduler/components/Filters/FilterWithGroups';
import { Plus, Sliders } from 'react-feather';
import { PropertyModal } from '@components/Project/ProjectHeader/PropertyModal/PropertyModal';
import { ProjectStatus } from '@generated/types/graphql';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { MoreFilters } from './MoreFilters';
import { SortBy } from './SortBy';
import { Search } from './Search';
import { GroupBy } from './GroupBy/GroupBy';
import { useClientFilterState } from '../useClientFilterState';
import { Views } from './Views';
import { Container, Filters, Right } from './styled';
import { ArchivedToggle } from './ArchivedToggle';
import { Form } from '../Form';

const STATUSES: { id: ProjectStatus; title: string }[] = [
  { id: ProjectStatus.Active, title: 'Active' },
  { id: ProjectStatus.OnHold, title: 'On hold' },
  { id: ProjectStatus.Completed, title: 'Completed' },
  { id: ProjectStatus.Cancelled, title: 'Cancelled' }
];

export const Header = () => {
  const { openModal } = useModal();

  const { clientFilters, updateFilters } = useClientFilterState();
  const [isManageColumnsOpen, setIsManageColumnsOpen] = useState(false);
  const updateFilter = useCallback(
    (filter: any) => {
      updateFilters(filter);
    },
    [updateFilters]
  );

  const handleManageColumnsClick = useCallback(() => {
    setIsManageColumnsOpen(true);
  }, []);

  const handleAddClick = useCallback(() => {
    openModal<void>(({ onClose }) => <Form onClose={onClose} />, { title: 'Create Project' });
  }, [openModal]);

  return (
    <Container>
      <Filters>
        <Search />
        <FilterWithGroups
          label="Status"
          fieldName="statuses"
          onSelect={updateFilter}
          groups={[{ title: 'Status', options: STATUSES }]}
          selected={clientFilters.statuses}
        />

        <ArchivedToggle />

        <GroupBy />
        <SortBy />
        <MoreFilters />
        <Button onClick={handleManageColumnsClick} variant={ButtonVariant.Flat}>
          <Sliders size="16px" />
          COLUMNS
        </Button>
      </Filters>
      <Right>
        <Views />
        <SubjectAccess subject="project" privilege="create">
          <div>
            <Button onClick={handleAddClick} variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Project
            </Button>
          </div>
        </SubjectAccess>
      </Right>

      {isManageColumnsOpen && <PropertyModal onClose={() => setIsManageColumnsOpen(false)} />}
    </Container>
  );
};
