import { useQuery } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { DynamicEntity, ReactQueryKey } from '@enums';
import workflowsApi from '@services/api/workflowsApi';
import { errorHandler } from '@services/api/helpers';
import { DropdownValueFromApi } from '@types';
import { useAppSelector } from '..';

type Params = {
  entity: DynamicEntity;
};

export const useDynamicDropdownValues = (params: Params) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.DynamicDropdownValues, params], async () => {
    try {
      const { data } = await workflowsApi.findDropdownValues({
        companyId,
        keys: [],
        ...params
      });

      return data as DropdownValueFromApi[];
    } catch (error) {
      throw errorHandler(error);
    }
  });
};
