import styled from 'styled-components';
import { colors, getColorWithOpacity, device } from '@styles';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 1010;
  width: 80%;
  left: 20%;
  height: 85px;
  background-color: ${colors.green};
  bottom: 40px;
  box-shadow:
    0px 4px 4px rgba(0, 0, 0, 0.25),
    0px -4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  ${device.xs`
    width: initial;
    left: 50%;
    margin-left: -160px;
  `};

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 10px;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 98px;
  font-size: 14px;
  line-height: 1.25;
  color: ${colors.white};
  text-transform: uppercase;
  cursor: pointer;

  svg {
    margin-bottom: 10px;
  }

  &:first-child {
    border-right: 1px solid ${getColorWithOpacity(colors.white, 40)};
    padding-right: 10px;
  }
`;

export const Count = styled.div`
  font-size: 24px;
  line-height: 1.25;
  height: 24px;
  margin-bottom: 10px;
`;
