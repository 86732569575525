import React from 'react';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Container } from './styled';

interface Props {
  user: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string | null;
  };
}

export const UserAvatarAndName = ({ user }: Props) => {
  return (
    <Container>
      <UserAvatar user={user} size={20} />
      <div>
        {user.firstName} {user.lastName}
      </div>
    </Container>
  );
};
