import React, { useCallback, useEffect, useRef } from 'react';
import { MapPin, Maximize, Minimize, Minus, Plus } from 'react-feather';
import { LngLatBounds, MapboxEvent, MapRef, NavigationControl, ViewStateChangeEvent } from 'react-map-gl';
import { useMapMode } from '@components/Scheduler/components/MapView/useMapMode';
import { Badge } from '@kit/ui/Badge';
import { Map } from '@kit/ui/Map';
import { Loader } from '@kit/ui/Loader';
import { Actions, Container, Header, Inner, LiveCircle, LoaderContainer, MapContainer, Title } from './styled';

type Props = React.PropsWithChildren<{
  live: boolean;
  isLoading?: boolean;
  onBoundsChange?: (bounds: LngLatBounds) => void;
}>;

export const MapView = ({ children, live, onBoundsChange, isLoading }: Props) => {
  const mapRef = useRef<MapRef>();
  const [mode, setMode] = useMapMode();

  useEffect(() => {
    mapRef.current?.resize();
  }, [mode]);

  const handleMapMove = useCallback(
    (e: ViewStateChangeEvent) => {
      onBoundsChange?.(e.target.getBounds());
    },
    [onBoundsChange]
  );

  const handleMapIdle = useCallback(
    (e: MapboxEvent) => {
      onBoundsChange?.(e.target.getBounds());
    },
    [onBoundsChange]
  );

  return (
    <Container>
      <Inner>
        <Header>
          <Actions>
            {mode === 'hidden' && <Plus onClick={() => setMode('half')} size="16px" />}
            {mode !== 'hidden' && <Minus onClick={() => setMode('hidden')} size="16px" />}
            {mode !== 'full' && <Maximize onClick={() => setMode('full')} size="16px" />}
            {mode === 'full' && <Minimize onClick={() => setMode('half')} size="16px" />}
          </Actions>
          <Title onClick={() => setMode((prev) => (prev === 'hidden' ? 'half' : 'hidden'))}>
            <MapPin size="16px" color="#98A9BC" />
            <div>Map</div>
            <LoaderContainer>{isLoading && <Loader size={24} />}</LoaderContainer>
          </Title>
          {(live && (
            <Badge color="#FFFFFF" bgColor="#D54855">
              <LiveCircle size={8} stroke="#FFFFFF" fill="#FFFFFF" />
              Live
            </Badge>
          )) || <div style={{ width: 63 }} />}
        </Header>
        <MapContainer mode={mode}>
          <Map
            ref={mapRef}
            initialViewState={{
              latitude: 39.8283,
              longitude: -98.5795,
              zoom: 4
            }}
            onIdle={handleMapIdle}
            onMove={handleMapMove}
          >
            {children}
            <NavigationControl position="bottom-left" showZoom showCompass={false} />
          </Map>
        </MapContainer>
      </Inner>
    </Container>
  );
};
