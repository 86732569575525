import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { FormNameInput, FormNameView } from './styled';

interface Props {
  initialValue?: string;
  onChange: (value: string) => void;
}

export const FormName = ({ initialValue, onChange }: Props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(initialValue || '');

  const handleClick = useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setValue(e.target.value);
  }, []);

  const handleBlur = useCallback(() => {
    setIsEdit(false);
    onChange(value);
  }, [value, onChange]);

  if (isEdit) {
    return <FormNameInput autoFocus value={value} onChange={handleInputChange} onBlur={handleBlur} />;
  }

  return (
    <FormNameView isPlaceholder={!value} onClick={handleClick}>
      {initialValue || 'Type a template name here...'}
    </FormNameView>
  );
};
