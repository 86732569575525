import React, { useMemo, useState } from 'react';
import { GeoEstimation, TaskStatus } from '@generated/types/graphql';
import { Marker, useMap } from 'react-map-gl';
import { parseLocation } from '@utils';
import { TASK_STATUS_COLORS } from '@styles';
import { DateTime } from 'luxon';
import { Avatar } from '@kit/ui/Avatar';
import { useClickTooltip } from '@kit/ui/Floats';
import { WorkerPopup } from '@components/Scheduler/Dispatcher/Map/WorkerPopup';
import { DayPlans, TaskWithDates } from '@components/Scheduler/Dispatcher/Map/types';
import { TaskPopup } from '@components/Scheduler/Dispatcher/Map/TaskPopup';
import { ActualRoutes, PlannedRoutes } from '@hooks/geolocation';
import { useSelectedWorker } from '@components/Scheduler/Dispatcher/useSelectedWorker';
import { offset } from '@floating-ui/react';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { useFitBounds } from '@kit/ui/Map';
import { TaskArrow, TaskBadge, TaskSubtitle, TaskTitle, WorkerAvatar } from './styled';

type Props = {
  tasks: TaskWithDates[];
  estimate: GeoEstimation | null;
  withWorkersOnSite: TaskWithDates[];
  dayPlans: DayPlans;
  actualRoutes: ActualRoutes;
  plannedRoutes: PlannedRoutes;
};

export const TasksLayer = ({
  tasks = [],
  estimate = null,
  withWorkersOnSite = [],
  dayPlans = {},
  actualRoutes = {},
  plannedRoutes = {}
}: Props) => {
  const mapRef = useMap();

  const tasksBounds = useMemo(
    () => tasks.map((task) => parseLocation(task.project.geoLocation)).filter(Boolean),
    [tasks]
  );
  useFitBounds(tasksBounds, mapRef);

  // sort tasks by start date so of 2 tasks for the same site the recent one gets on top (being later in the DOM)
  const orderedTasks = useMemo(
    () => tasks.toSorted((t1, t2) => new Date(t1.startDate).getTime() - new Date(t2.startDate).getTime()),
    [tasks]
  );

  return (
    <>
      {orderedTasks.map((task) => (
        <TaskMarker
          key={task.id}
          task={task}
          estimate={estimate}
          withWorkersOnSite={withWorkersOnSite}
          dayPlans={dayPlans}
          actualRoutes={actualRoutes}
          plannedRoutes={plannedRoutes}
        />
      ))}
    </>
  );
};

const TaskMarker = ({
  task,
  estimate,
  withWorkersOnSite,
  dayPlans,
  actualRoutes,
  plannedRoutes
}: {
  task: TaskWithDates;
} & Omit<Props, 'tasks'>) => {
  const settings = useAppSelector(selectCompanySettings);
  const isGeoEnabled = !!settings?.features?.geolocation && !!settings?.geolocation?.enabled;

  const parsedLocation = parseLocation(task.project.geoLocation);

  const workerOnSite = withWorkersOnSite.find((candidate) => candidate.id === task.id)?.assignee;

  const taskOrderInAssigneeDayPlan = task.assignee
    ? (dayPlans[task.assignee.id] || []).findIndex((planTask) => planTask.id === task.id)
    : -1;
  const orderLabel = taskOrderInAssigneeDayPlan >= 0 ? `${taskOrderInAssigneeDayPlan + 1} · ` : '';

  const taskEstimate =
    (estimate?.taskId === task.id &&
      estimate?.location.userId === task.assignee?.id &&
      task.taskStatus.id === TaskStatus.OnTheWay &&
      `ETA: ${DateTime.now().plus({ seconds: estimate.duration }).toLocaleString(DateTime.TIME_SIMPLE)}`) ||
    '';

  const [selected, setSelected] = useSelectedWorker();

  const [showTaskPopup, setShowTaskPopup] = useState(false);
  const taskPopup = useClickTooltip({
    open: showTaskPopup,
    onOpenChange: setShowTaskPopup,
    placement: 'right',
    middleware: [offset(24)]
  });

  const [showWorkerPopup, setShowWorkerPopup] = useState(false);
  const workerPopup = useClickTooltip({
    open: showWorkerPopup,
    onOpenChange: setShowWorkerPopup,
    placement: 'right',
    middleware: [offset(24)]
  });

  const handleClickWorker = () => {
    if (showWorkerPopup) {
      setShowWorkerPopup(false);
      setSelected(null);
    } else {
      setShowWorkerPopup(true);
      setSelected(workerOnSite);
    }
  };

  if (!parsedLocation) {
    return null;
  }

  return (
    <>
      <Marker longitude={parsedLocation.lng} latitude={parsedLocation.lat} anchor="top">
        <TaskBadge
          color={TASK_STATUS_COLORS[task.taskStatus.id].color}
          bgColor={TASK_STATUS_COLORS[task.taskStatus.id].background}
          dimmed={selected && selected.id !== task.assignee?.id}
        >
          <TaskArrow
            color={TASK_STATUS_COLORS[task.taskStatus.id].color}
            bgColor={TASK_STATUS_COLORS[task.taskStatus.id].background}
          />

          <div ref={taskPopup.refs.setReference} {...taskPopup.getReferenceProps()}>
            <TaskTitle>
              {isGeoEnabled && orderLabel}
              {task.uid ? `#${task.uid}` : task.title}
            </TaskTitle>

            <TaskSubtitle>{taskEstimate}</TaskSubtitle>
          </div>

          {workerOnSite && (
            <WorkerAvatar onClick={handleClickWorker} ref={workerPopup.refs.setReference}>
              <Avatar user={workerOnSite} />
            </WorkerAvatar>
          )}
        </TaskBadge>
      </Marker>

      {showTaskPopup && (
        <div ref={taskPopup.refs.setFloating} style={taskPopup.floatingStyles} {...taskPopup.getFloatingProps()}>
          <TaskPopup task={task} handleClose={() => setShowTaskPopup(false)} />
        </div>
      )}

      {showWorkerPopup && workerOnSite && (
        <WorkerPopup
          worker={workerOnSite}
          dayPlan={dayPlans[workerOnSite.id]}
          dayRoute={actualRoutes[workerOnSite.id]}
          plannedRoute={plannedRoutes[workerOnSite.id]}
          handleClose={() => setShowWorkerPopup(false)}
          ref={workerPopup.refs.setFloating}
          style={workerPopup.floatingStyles}
          {...workerPopup.getFloatingProps()}
        />
      )}
    </>
  );
};
