import React from 'react';

export const LongArrow = () => (
  <svg width="16" height="41" viewBox="0 0 16 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.29289 40.7071C7.68342 41.0976 8.31658 41.0976 8.7071 40.7071L15.0711 34.3431C15.4616 33.9526 15.4616 33.3195 15.0711 32.9289C14.6805 32.5384 14.0474 32.5384 13.6569 32.9289L8 38.5858L2.34314 32.9289C1.95262 32.5384 1.31946 32.5384 0.928931 32.9289C0.538406 33.3195 0.538406 33.9526 0.928931 34.3431L7.29289 40.7071ZM7 -4.37114e-08L7 40L9 40L9 4.37114e-08L7 -4.37114e-08Z"
      fill="#DFDFE8"
    />
  </svg>
);
