import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { StepApiRequest } from '@services/api/types';
import workflowsApi from '@services/api/workflowsApi';
import { AutomationFromApi, StepFromApi } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { selectWorkspaceId } from '@state/selectors';
import { useToast } from '..';

type Params = {
  automationId: number;
  req: StepApiRequest;
};

export const useCreateAutomationStep = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  const companyId = useSelector(selectWorkspaceId);

  return useMutation<StepFromApi, Error, Params>(
    async ({ automationId, req }) => {
      try {
        const { data: step } = await workflowsApi.createStep(automationId, req, { companyId });

        return step as StepFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (result, { automationId }) => {
        showSuccess('Automation updated successfully');

        queryClient.setQueryData<AutomationFromApi[] | undefined>([ReactQueryKey.ProjectWorkflows], (old) => {
          if (!old) {
            return old;
          }

          return old.map((automation) => {
            if (automation.id === automationId) {
              return {
                ...automation,
                steps: [...automation.steps, result]
              };
            }

            return automation;
          });
        });
      }
    }
  );
};
