import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useRecordsGroups } from '@hooks/useRecords';
import { DealStatus, SearchFrom } from '@components/Project/ProjectView/types';
import { RecordType } from '@types';
import { useMemo } from 'react';
import { ProjectRequestStatus } from '@generated/types/graphql';
import { useClientFilterState } from '../useClientFilterState';

const getIsActiveFilter = (isArchivedShown: boolean): DealStatus => {
  return isArchivedShown ? DealStatus.all : DealStatus.active;
};

export const useTotalsByStatus = () => {
  const { clientFilters } = useClientFilterState();

  const companyId = useAppSelector(selectWorkspaceId);

  const { data, isLoading } = useRecordsGroups(companyId, RecordType.DEAL, {
    groupBy: -48, // RequestStatus property
    status: [],
    type: getIsActiveFilter(clientFilters.isArchivedShown),
    search: clientFilters.query,
    filters: clientFilters.filters,
    // seems to be not used:
    page: 1,
    perPage: 10,
    from: SearchFrom.grid,
    groupFilter: ''
  });

  const totals = useMemo(() => {
    const result: Record<ProjectRequestStatus | 'all', number> = {
      all: 0,
      [ProjectRequestStatus.New]: 0,
      [ProjectRequestStatus.AppointmentScheduled]: 0,
      [ProjectRequestStatus.AppointmentCompleted]: 0,
      [ProjectRequestStatus.Overdue]: 0
    };

    data?.groups.forEach((group) => {
      result[group.label as ProjectRequestStatus] = group.ids.length;
      result.all += group.ids.length;
    });

    return result;
  }, [data]);

  return {
    totals,
    isLoading
  };
};
