import styled from 'styled-components';
import { colors } from '@styles';
import { Button as KitButton } from '@kit/ui/Button';

export const Button = styled(KitButton)<{ first: boolean; placeholder: boolean }>`
  && {
    svg {
      width: 16px;
      color: ${colors.gray};
    }

    ${(props) =>
      props.first
        ? `
      position: absolute;
      left: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      min-width: 40px;
    `
        : `
      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      padding: 0;
      z-index: 100;
    `};

    ${(props) =>
      props.placeholder
        ? `
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 184px;
      max-width: 184px;
      padding: 8px 12px;
      white-space: nowrap;
      height: 40px;
      border-radius: 4px;
      background-color: ${(props) => props.color || colors.white2};
      color: ${(props) => (props.color ? colors.white2 : colors.black)};
      border: 1px dashed ${colors.white2};
      background: none;
      
      svg {
        color: ${colors.white};
      }
        
      &:hover, &:focus {
        background-color: ${colors.white2};
        color: ${colors.gray};
       
        svg {
          color: ${colors.gray};
        }
      }
    `
        : null};
  }
`;

export const Actions = styled.div<{ vertical?: boolean }>`
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.vertical ? 'column' : 'row')};
  justify-content: space-between;
  opacity: 0;

  &:hover,
  &:focus {
    opacity: 1;
  }

  button {
    position: absolute;

    &:nth-child(1) {
      ${(props) =>
        props.vertical
          ? `
      top: 0;
    `
          : `
      left: 0px;
      top: 20px;
    `}
    }

    &:nth-child(2) {
      ${(props) =>
        props.vertical
          ? `
      top: 50%;
      margin-top: -10px;
      left: -25px;
    `
          : `
      bottom: 0px;
      left: 25px;
    `}
    }

    &:nth-child(3) {
      ${(props) =>
        props.vertical
          ? `
      bottom: 0;
    `
          : `
      right: 0px;
      top: 10px;
    `}
    }

    &:nth-child(4) {
      ${(props) =>
        props.vertical
          ? `
      bottom: 0;
    `
          : `
      bottom: -25px;
      left: 25px;
    `}
    }
  }
`;

export const AutocompleteFooter = styled.div`
  margin: 0 14px;
  padding: 12px 0;
  border-top: 1px solid #dfdfe8;
`;

export const NewWorkOrderTemplateButton = styled.div`
  cursor: pointer;
  color: ${colors.green};
  font-size: 12px;
  font-weight: 500;
`;
