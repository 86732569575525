import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  min-height: 21px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  > * {
    margin-bottom: 0;
  }
`;

export const Options = styled.div`
  display: grid;
  grid-template-columns: min-content auto min-content;
  gap: 16px;
  align-items: center;
  margin-bottom: 16px;

  > * {
    margin-bottom: 0;
  }
`;

export const OptionLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
`;

export const RemoveOptionButton = styled.div`
  cursor: pointer;
  color: #9c9caa;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 12px 12px 0;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #000;
  }
`;
