import { OverbudgetBehaviour } from '@generated/types/graphql';
import { put } from '@services/api/base';
import { Urls } from '@services/api/base/axios';

export default {
  update: (companyId: number, dto: Partial<BudgetUpdateDTO>) => put<void>(`${Urls.fleet}/budget`, dto, { companyId })
};

export type BudgetUpdateDTO = {
  monthlyBudget: number;
  overbudgetBehaviour: OverbudgetBehaviour;
  whitelistProfiles: number[];
};
