import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.row ? 'row' : 'column'};
  align-items: ${props => props.row ? 'flex-end' : 'inherit'};
`;

const Item = (props) => <Wrapper row={props.row}>{props.children}</Wrapper>;

export default Item;
