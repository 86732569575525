import React from 'react';
import { useFormikContext } from 'formik';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { TitleField as TitleFieldWrapper, TitleView } from './styled';
import { CommonFieldProps } from '../types';

interface Props extends CommonFieldProps {
  name?: string;
  placeholder?: string;
}

export const TitleField = ({ name = 'title', placeholder = 'Type a template name here...', onUpdated }: Props) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <TitleFieldWrapper
          name={name}
          autoFocus
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  }

  if (!context.values[name]) {
    return (
      <TitleView isPlaceholder onClick={() => setIsEdit(true)}>
        {placeholder}
      </TitleView>
    );
  }

  return <TitleView onClick={() => setIsEdit(true)}>{context.values[name]}</TitleView>;
};
