import React, { useCallback } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { StageListItem } from '@hooks/workspace/stages/useStageList';
import { useModal } from '@common/PromiseModal';
import { RecordType } from '@types';
import { ENTITY_NAME_BY_RECORD_TYPE } from '@features/ProjectPortfolio/Project/History/FeedItem/constants';
import { StageForm } from '../../Form';
import { Header, Title, Table } from './styled';
import { Row } from './Row';

interface Props {
  title: string;
  addButtonText: string;
  recordType: RecordType;
  isDescriptionShown?: boolean;
  stages: StageListItem[];
}

export const StagesTable = ({ title, recordType, isDescriptionShown, addButtonText, stages }: Props) => {
  const { openModal } = useModal();

  const handleAdd = useCallback(() => {
    openModal<void>(
      ({ onClose }) => <StageForm isDescriptionShown={isDescriptionShown} recordType={recordType} onClose={onClose} />,
      { title: `Create ${ENTITY_NAME_BY_RECORD_TYPE[recordType]} Stage` }
    );
  }, [openModal, isDescriptionShown, recordType]);

  return (
    <div>
      <Header>
        <Title>{title}</Title>
        <Button variant={ButtonVariant.Primary} onClick={handleAdd}>
          <Plus size="16px" />
          {addButtonText}
        </Button>
      </Header>

      <Table>
        <thead>
          <th>Name</th>
          <th>{isDescriptionShown && 'Description (visible only in Portal)'}</th>
          <th>
            Yellow
            <br />
            SLA
          </th>
          <th>
            Red
            <br />
            SLA
          </th>
          <th>Type</th>
          <th />
        </thead>

        <tbody>
          {stages.map((stage) => (
            <Row key={stage.id} stage={stage} recordType={recordType} isDescriptionShown={isDescriptionShown} />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
