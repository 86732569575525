import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 32px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #98a9bc;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

export const LoadMore = styled.div`
  text-align: center;
  padding: 16px 8px;
  display: flex;
  justify-content: center;
`;
