import { useCallback } from 'react';
import { useCreateFleet } from '@hooks/workspace/systems/fleets/useCreateFleet';
import { useUpdateFleet } from '@hooks/workspace/systems/fleets/useUpdateFleet';
import { useDeleteFleet } from '@hooks/workspace/systems/fleets/useDeleteFleet';
import { Fleet, useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { ProfileCreateDTO, SystemMetric } from '@services/api/systemProfilesAPI';
import { isEqual } from 'lodash';

const fleetToDto = (fleet: Fleet): ProfileCreateDTO => {
  return {
    name: fleet.name,
    default: fleet.default,
    configs: fleet.systemProfileConfigs.map((config) => ({
      integration: config.integration.id,
      frequency: config.frequency,
      enabledMetrics: Object.entries(config.metrics)
        .filter(([, { enabled }]) => enabled)
        .map(([metric]) => metric as SystemMetric)
    }))
  };
};

export const useUpdateFleets = () => {
  const { mutateAsync: createFleet } = useCreateFleet();
  const { mutateAsync: updateFleet } = useUpdateFleet();
  const { mutateAsync: deleteFleet } = useDeleteFleet();
  const { data: currentFleets } = useFleetList();

  return useCallback(
    async (fleetsToProcess: Fleet[]) => {
      const currentFleetIds = currentFleets.map((fleet) => fleet.id);
      const fleetsToCreate = fleetsToProcess.filter((fleet) => !currentFleetIds.includes(fleet.id));
      const fleetsToUpdate = fleetsToProcess.filter((fleet) => currentFleetIds.includes(fleet.id));
      const fleetsToDelete = currentFleets.filter((fleet) => !fleetsToProcess.some((f) => f.id === fleet.id));

      await Promise.all([
        ...fleetsToCreate.map((fleet) => createFleet(fleetToDto(fleet))),
        ...fleetsToUpdate.map((fleet) => {
          const currentFleet = currentFleets.find((f) => f.id === fleet.id);
          if (!isEqual(currentFleet, fleet)) {
            return updateFleet({ id: fleet.id, dto: fleetToDto(fleet) });
          }

          return Promise.resolve(true);
        }),
        ...fleetsToDelete.map((fleet) => deleteFleet(fleet.id))
      ]);
    },
    [createFleet, updateFleet, deleteFleet, currentFleets]
  );
};
