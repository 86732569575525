import { useLocalStorageValue } from '@react-hookz/web';
import { LocalStorageKey } from '@enums';

export type Mode = 'hidden' | 'half' | 'full';

export const useMapMode = () => {
  const { value, set } = useLocalStorageValue<Mode>(LocalStorageKey.defaultMapViewMode, {
    defaultValue: 'hidden'
  });

  return [value , set] as [typeof value, typeof set];
};
