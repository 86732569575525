import React, { useCallback, useState } from 'react';
import { SubjectAccess } from '@common/RoleAccess';
import { Plus, Sliders } from 'react-feather';
import { PropertyModal } from '@components/Project/ProjectHeader/PropertyModal/PropertyModal';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { MoreFilters } from './MoreFilters';
import { SortBy } from './SortBy';
import { Search } from './Search';
import { GroupBy } from './GroupBy/GroupBy';
import { Views } from './Views';
import { Container, Filters, Right } from './styled';
import { ArchivedToggle } from './ArchivedToggle';
import { Form } from '../Form';

export const Header = () => {
  const { openModal } = useModal();
  const [isManageColumnsOpen, setIsManageColumnsOpen] = useState(false);

  const handleManageColumnsClick = useCallback(() => {
    setIsManageColumnsOpen(true);
  }, []);

  const handleAddClick = useCallback(() => {
    openModal<void>(({ onClose }) => <Form onClose={onClose} />, { title: 'Create Request' });
  }, [openModal]);

  return (
    <Container>
      <Filters>
        <Search />
        <GroupBy />
        <SortBy />
        <ArchivedToggle />
        <MoreFilters />
        <Button onClick={handleManageColumnsClick} variant={ButtonVariant.Flat}>
          <Sliders size="16px" />
          COLUMNS
        </Button>
      </Filters>
      <Right>
        <Views />
        <SubjectAccess subject="project" privilege="create">
          <div>
            <Button onClick={handleAddClick} variant={ButtonVariant.Primary}>
              <Plus size="16px" />
              Request
            </Button>
          </div>
        </SubjectAccess>
      </Right>

      {isManageColumnsOpen && <PropertyModal onClose={() => setIsManageColumnsOpen(false)} />}
    </Container>
  );
};
