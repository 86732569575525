import { SelectField } from '@kit/components/Form';
import { AutomationField } from '@types';
import React from 'react';
import { Control } from 'react-hook-form';
import {
  WorkOrderTemplateListItem,
  useWorkOrderTemplateList
} from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { ANY_OPTION } from './constants';
import { DropdownItemWithIcon } from './styled';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
}

export const WorkOrderTemplateField = ({ field, isDisabled, control }: Props) => {
  const { data: options = [], isFetched } = useWorkOrderTemplateList();

  if (!options || !isFetched) {
    return null;
  }

  return (
    <SelectField
      name={field.key}
      disabled={isDisabled}
      label={field.label}
      control={control}
      options={!field.required ? [ANY_OPTION].concat(options) : options}
      getOptionLabel={(option: WorkOrderTemplateListItem) => option.title}
      getOptionValue={(option: WorkOrderTemplateListItem) => option.id}
      renderOption={(option: WorkOrderTemplateListItem) => (
        <DropdownItemWithIcon>
          <WorkOrderTypeIcon isField={option.isField} />
          {option.title}
        </DropdownItemWithIcon>
      )}
    />
  );
};
