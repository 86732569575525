import React, { useCallback, useMemo, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { Label as TLabel } from '@generated/types/graphql';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Edit2, Plus } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useLabelList } from '@hooks/workspace/labels/useLabelList';
import { useDeleteLabel } from '@hooks/workspace/labels/useDeleteLabel';
import { Label } from '@domains/labels';
import { UserAvatarAndName } from '@domains/users';
import { useLabelsStats } from '@hooks/workspace/labels/useLabelsStats';
import { CoperniqIcon } from '@kit/ui/icons/Coperniq';
import {
  Container,
  Header,
  Title,
  Description,
  HeaderLeft,
  PageHeader,
  Table,
  TableContainer,
  Th,
  CoperniqAuthor
} from './styled';
import { LabelForm } from '../Form';

interface RowProps {
  label: TLabel;
  usageCount?: number;
}

export const TableRow = ({ label, usageCount }: RowProps) => {
  const { openModal } = useModal();
  const { mutateAsync: remove } = useDeleteLabel();
  const confirmDelete = useConfirmDeleteModal();

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <LabelForm initialValues={label} onClose={onClose} />, {
      title: 'Edit Label'
    });
  }, [openModal, label]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        onClick: handleEdit
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this Label?')) {
            await remove(label.id);
          }
        }
      }
    ];
  }, [confirmDelete, handleEdit, remove, label]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  return (
    <tr onClick={handleEdit} onContextMenu={handleContextMenu}>
      <td>
        <Label label={label} />
      </td>
      <td>
        {usageCount > 0 && (
          <span>
            {usageCount} {usageCount === 1 ? 'Work Order' : 'Work Orders'}
          </span>
        )}
      </td>
      <td>
        {label.createdByUser ? (
          <UserAvatarAndName user={label.createdByUser} />
        ) : (
          <CoperniqAuthor>
            <CoperniqIcon size="20px" />
            Coperniq
          </CoperniqAuthor>
        )}
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu} />
      </td>
    </tr>
  );
};

export const List = () => {
  const [search, setSearch] = useState('');

  const { data: labels = [] } = useLabelList();
  const { data: stats = {} } = useLabelsStats();

  const { openModal } = useModal();

  const handleAdd = useCallback(() => {
    openModal<void>(({ onClose }) => <LabelForm onClose={onClose} />, { title: 'Create Label' });
  }, [openModal]);

  const filteredLabels = useMemo(() => {
    if (!search.trim()) {
      return labels;
    }

    const normalizedSearch = search.toLowerCase().trim();

    return labels.filter(({ label }) => label.toLowerCase().includes(normalizedSearch));
  }, [search, labels]);

  return (
    <Container>
      <PageHeader>
        <Title>Labels</Title>
        <Description>
          Create, edit, and delete Labels for various records within a platform. These labels are used for
          categorization, organization, and filtering, enabling users to quickly locate, identify, and group related
          items.
        </Description>
      </PageHeader>

      <Header>
        <HeaderLeft>
          <Button onClick={handleAdd} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Label
          </Button>
          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <Th width="40%">Label</Th>
            <Th width="20%">Used in</Th>
            <Th width="35%">Created by</Th>
            <Th width="5%" />
          </thead>
          <tbody>
            {filteredLabels.map((label) => (
              <TableRow key={label.id} label={label} usageCount={stats[label.label]} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
