import React, { useCallback } from 'react';
import { ProjectAdapted } from '@types';
import { Loader } from '@kit/ui/Loader';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { TableBody } from '../TableBody';
import { useGroupedRecordIds } from '../useGroupedRecordIds';
import { TableHeader } from '../TableHeader';
import { useSelectionWithGroups } from '../useSelection';
import { LoaderContainer } from './styled';
import { useColumns } from '../useColumns';

export const PlainTable = () => {
  const { groupedIds, isLoading } = useGroupedRecordIds();
  const columns = useColumns();

  const { openDrawer } = useDrawersContext();

  const {
    isAllSelected,
    toggleAllSelected,
    isItemSelected,
    toggleItem,
    setDataForGroup,
    selectedIds,
    isAllSelectedItemsArchived,
    clearSelection
  } = useSelectionWithGroups(groupedIds.groups);

  const group = groupedIds.groups[0] ?? { label: '' };

  const checkIsItemSelectedInGroup = useCallback(
    (project: ProjectAdapted) => {
      return isItemSelected(group.label, project);
    },
    [isItemSelected, group.label]
  );

  const toggleItemInGroup = useCallback(
    (project: ProjectAdapted) => {
      toggleItem(group.label, project);
    },
    [group.label, toggleItem]
  );

  const onGroupDataChange = useCallback(
    (projects: ProjectAdapted[]) => {
      setDataForGroup(group.label, projects);
    },
    [group.label, setDataForGroup]
  );

  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.PROJECT, id, groupedIds.groups[0].ids);
    },
    [openDrawer, groupedIds.groups]
  );

  if (isLoading || groupedIds.groups.length === 0 || columns.length === 0) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <TableHeader
        isAllSelected={isAllSelected}
        onToggleAllSelected={toggleAllSelected}
        selectedIds={selectedIds}
        clearSelection={clearSelection}
        isAllSelectedArchived={isAllSelectedItemsArchived}
        columns={columns}
      />

      <TableBody
        ids={groupedIds.groups[0].ids}
        checkIsItemSelected={checkIsItemSelectedInGroup}
        toggleItem={toggleItemInGroup}
        onDataChange={onGroupDataChange}
        onRowClick={handleRowClick}
        columns={columns}
      />
    </>
  );
};
