import React, { useEffect, useState } from 'react';
import { Geolocation, MainUser } from '@generated/types/graphql';
import { useSelectedWorker } from '@components/Scheduler/Dispatcher/useSelectedWorker';
import { LngLatLike, Marker, useMap } from 'react-map-gl';
import { WorkerPopup } from '@components/Scheduler/Dispatcher/Map/WorkerPopup';
import { useClickTooltip } from '@kit/ui/Floats';
import { DayPlans, TaskWithDates } from '@components/Scheduler/Dispatcher/Map/types';
import { Avatar } from '@kit/ui/Avatar';
import { ActualRoutes, PlannedRoutes, Route } from '@hooks/geolocation';
import { offset } from '@floating-ui/react';
import { MarkerContainer } from './styled';

type Props = {
  workers: MainUser[];
  locations: Geolocation[];
  dayPlans: DayPlans;
  actualRoutes: ActualRoutes;
  plannedRoutes: PlannedRoutes;
};

export const WorkersLayer = ({
  workers = [],
  locations = [],
  dayPlans = {},
  actualRoutes = {},
  plannedRoutes = {}
}: Props) => {
  const [selected] = useSelectedWorker();

  const { current: map } = useMap();

  useEffect(() => {
    if (!selected) {
      return;
    }

    const selectedLocation = locations.find((location) => location.userId === selected.id);
    if (!selectedLocation) {
      return;
    }

    const selectedPoint: LngLatLike = { lat: selectedLocation.latitude, lng: selectedLocation.longitude };
    if (!map.getBounds().contains(selectedPoint)) {
      map.panTo(selectedPoint, { animate: true, duration: 500 });
    }
    // do not pan the map when current location changes - only when selected worker changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, map]);

  return (
    <>
      {locations.map((location) => {
        const user = workers.find(({ id }) => id === location.userId);

        if (!user) {
          return null;
        }

        return (
          <WorkerMarker
            key={user.id}
            user={user}
            location={location}
            dayPlan={dayPlans[user.id]}
            route={actualRoutes[user.id]}
            plannedRoute={plannedRoutes[user.id]}
          />
        );
      })}
    </>
  );
};

const WorkerMarker = ({
  user,
  location,
  dayPlan,
  route,
  plannedRoute
}: {
  user: MainUser;
  location: Geolocation;
  dayPlan: TaskWithDates[];
  route: Geolocation[];
  plannedRoute: Route;
}) => {
  const [selected, setSelected] = useSelectedWorker();

  const [showPopup, setShowPopup] = useState(false);
  const popup = useClickTooltip({
    open: showPopup,
    onOpenChange: setShowPopup,
    placement: 'right',
    middleware: [offset(24)]
  });

  const handleClick = () => {
    if (showPopup) {
      setShowPopup(false);
      setSelected(null);
    } else {
      setShowPopup(true);
      setSelected(user);
    }
  };

  return (
    <>
      <Marker longitude={location.longitude} latitude={location.latitude}>
        <MarkerContainer
          selected={selected?.id === user.id}
          dimmed={selected && selected?.id !== user.id}
          onClick={handleClick}
          ref={popup.refs.setReference}
        >
          <Avatar user={user} />
        </MarkerContainer>
      </Marker>

      {showPopup && (
        <WorkerPopup
          worker={user}
          dayPlan={dayPlan}
          dayRoute={route}
          plannedRoute={plannedRoute}
          handleClose={() => {
            setShowPopup(false);
          }}
          ref={popup.refs.setFloating}
          style={popup.floatingStyles}
          {...popup.getFloatingProps()}
        />
      )}
    </>
  );
};
