import { Form, FormValidationRules, InputField, SwitchField, useForm } from '@kit/components/Form';
import React, { useCallback, useEffect } from 'react';
import { RoleDTO } from '@types';
import { useUpdateRole } from '@hooks/workspace/roles/useUpdateRole';
import { debounce, isEqual } from 'lodash';
import { isFixedRole, normalizeRoleName } from '@utils/roles';
import { PermissionsMatrix } from './PermissionsMatrix';
import { Header } from './styled';

interface FormValues {
  name: string;
  isActive: boolean;
  settings: RoleDTO['settings'];
}

const RULES: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  }
};

interface Props {
  initialValues: RoleDTO;
}

export const EditForm = ({ initialValues }: Props) => {
  const isReadOnly = isFixedRole(initialValues);

  const { mutateAsync: update } = useUpdateRole();

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: normalizeRoleName(initialValues.name),
      isActive: initialValues.active,
      settings: { ...initialValues.settings }
    },
    onSubmit: async ({ name, isActive, settings }) => {
      const dto = {} as Partial<RoleDTO>;
      if (name !== initialValues.name) {
        dto.name = name;
      }

      if (isActive !== initialValues.active) {
        dto.active = isActive;
      }

      if (!isEqual(settings, initialValues.settings)) {
        dto.settings = settings;
      }
      update({ id: initialValues.id, dto });
    }
  });

  const { control, watch, setValue } = form;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSubmit = useCallback(
    debounce(() => {
      handleSubmit();
    }, 300),
    [handleSubmit]
  );

  useEffect(() => {
    const subscription = watch(() => {
      debouncedSubmit();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [watch, debouncedSubmit]);

  const permissions = watch('settings');

  const handlePermissionsChange = useCallback(
    (newPermissions: RoleDTO['settings']) => {
      setValue('settings', newPermissions);
    },
    [setValue]
  );

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <Header>
        <InputField disabled={isReadOnly} label="Role name" name="name" control={control} />
        <SwitchField isDisabled={isReadOnly} label="Active" name="isActive" control={control} />
      </Header>

      <PermissionsMatrix isReadOnly={isReadOnly} permissions={permissions} onChange={handlePermissionsChange} />
    </Form>
  );
};
