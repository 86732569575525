import styled from 'styled-components';

export const Header = styled.div`
  padding: 24px 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
