import { Checkbox } from '@material-ui/core';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const HeaderContainer = styled.table`
  border-spacing: 0;
  th {
    height: 40px;
    background-color: #f8fafb;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
  }
  th:first-child {
    background-color: #fff;
    position: sticky;
    left: 0px;
    z-index: 1;
    width: 396px;
    min-width: 396px;
    max-width: 396px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-right: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;
    border-left: 1px solid #dfdfe8;
  }
`;

export const Header = styled.div`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  user-select: none;
`;

export const BulkCheckbox = styled(Checkbox)`
  && {
    margin-left: -2px;

    .MuiSvgIcon-fontSizeSmall {
      font-size: 26px;
    }
  }
`;
