import { IntegrationProvider } from '@generated/types/graphql';
import { SystemMetric } from '@services/api/systemProfilesAPI';

export type ProviderMetricsConfig = {
  [metric in SystemMetric]: {
    /** Whether provider can provide this metric */
    available: boolean;
    /** User can enable or disable receiving this metric */
    enablable: boolean;
    /** Whether it is enabled by default or not */
    defaultEnabled: boolean;
  };
};

export enum ApiHitsLimitType {
  Daily = 'daily',
  Monthly = 'monthly'
}

type ApiHitsLimit = {
  type: ApiLimitType;
  limit: number;
};

export type ProviderConfig = {
  id: IntegrationProvider;
  name: string;
  logo: string;
  description: string;
  isMock?: boolean;
  color: string;
  connectForm: React.FC<{ isMock: boolean; onClose: () => void }>;
  metricsConfig: ProviderMetricsConfig;
  metricsDescription: string;
  apiHitsLimit?: ApiHitsLimit;
  getHitsCost: (hits: number) => number;
  getHitsByEnabledMetrics: (enabledMetrics: SystemMetric[]) => number;
};
