import styled from 'styled-components';
import { Badge } from '@common/Badge';
import { getLighterBgColor } from '@utils';

export const TaskBadge = styled(Badge)<{ dimmed: boolean }>`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid;
  height: auto;
  opacity: ${(props) => (props.dimmed ? '50%' : '100%')};
`;

export const TaskArrow = styled.div<{ color: string; bgColor: string }>`
  position: absolute;
  left: calc(50% - 2px);
  top: calc(100% - 2px);
  width: 4px;
  height: 4px;
  border: 1px solid ${(props) => props.color};
  border-bottom-left-radius: 1px;
  border-top: none;
  border-right: none;
  background-color: ${(props) => props.bgColor || getLighterBgColor(props.color)};
  transform: rotate(-45deg);
`;

export const WorkerAvatar = styled.div`
  margin: -4px -4px -4px 0;
  cursor: pointer;

  .MuiAvatar-rounded {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`;

export const PopoverContainer = styled.div<{ borderColor?: string }>`
  padding: 9px 8px;
  background-color: #fff;
  box-shadow: 0px 4px 8px 0px #1a034014;
  border-radius: 4px;
  font-size: 12px;
  max-width: 320px;
  border-left: 4px solid ${(props) => props.borderColor || '#fff'};
`;

export const PopoverHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;
`;

export const PopoverTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-right: 4px;
  margin-top: 2px;
  display: flex;
  align-items: center;
  gap: 4px;

  div {
    white-space: nowrap;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const PopoverPriortiyIcon = styled.div`
  flex-shrink: 0;
  margin-right: 4px;
  display: flex;
  margin-top: 2px;
`;

export const TaskTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
`;

export const TaskSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
`;
