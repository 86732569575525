import React from 'react';
import { useSystemsPeakPower } from '@hooks/systems/useSystemsPeakPower';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { formatPower } from '@features/SystemPortfolio/utils';
import { Widget, WidgetLeft, WidgetMetric, WidgetTitle } from '../styled';
import { PowerIcon } from './styled';

type Props = {
  allIds: number[];
};

export const PowerWidget = ({ allIds }: Props) => {
  const { data: peakPower } = useSystemsPeakPower(allIds);

  return (
    <Widget>
      <WidgetLeft>
        <WidgetTitle>
          <PowerIcon>
            <ZapIcon size="16px" color="#FFFFFF" />
          </PowerIcon>
          Peak power
        </WidgetTitle>

        <WidgetMetric>{formatPower(peakPower)}</WidgetMetric>
      </WidgetLeft>
    </Widget>
  );
};
