import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import emailAPI from '@services/api/emailAPI';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useMutation, useQueryClient } from 'react-query';

export const useDisconnectEmailAccount = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, number>(
    async (id) => {
      try {
        await emailAPI.disconnectAccount(id, companyId);
      } catch (e) {
        throw apiErrorHandler('Error disconnecting account', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceEmailAccounts, companyId]);
      }
    }
  );
};
