import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const CredsAlert = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  background-color: #dbe5ff;
  border-radius: 6px;
`;

export const CredsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const CredsTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const CredsSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 24px;
`;

export const Footer = styled.div`
  margin-top: -16px;
`;
