import React, { memo, useCallback, useMemo } from 'react';
import { useContactsList } from '@hooks/contacts/useContactList';
import { Loader } from '@kit/ui/Loader';
import { Contact } from '@generated/types/graphql';
import moment from 'moment';
import { useModal } from '@common/PromiseModal';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { PhoneNumber } from '@kit/ui/anchors/PhoneNumber';
import { EmailAddress } from '@kit/ui/anchors/EmailAddress';
import { TableHeader } from './TableHeader';
import { Container, LoadMore, LoadMoreLoaderContainer, LoaderContainer, Scrollable, TableContainer } from './styled';
import { mapClientSortToServerSort, useClientFilterState } from '../useClientFilterState';

interface TableRowProps {
  contact: Contact;
  onClick: (contact: Contact) => void;
}

const TableRow = memo(({ contact, onClick }: TableRowProps) => {
  const handleTitleClick = useCallback(() => {
    onClick(contact);
  }, [onClick, contact]);

  return (
    <tr onClick={handleTitleClick}>
      <td>{contact.name}</td>
      <td>{Boolean(contact.emails[0]) && <EmailAddress value={contact.emails[0]} />}</td>
      <td>{Boolean(contact.phones[0]) && <PhoneNumber value={contact.phones[0]} />}</td>
      <td>{moment(contact.createdAt).format('MM/DD/YYYY')}</td>
    </tr>
  );
});

export const Table = () => {
  const { clientFilters } = useClientFilterState();

  const { openModal } = useModal();

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = useContactsList({
    perPage: clientFilters.perPage.value,
    query: clientFilters.query,
    orderBy: mapClientSortToServerSort(clientFilters.sortBy)
  });

  const totalCount = useMemo(() => {
    if (!data) {
      return 0;
    }

    return data.pages[0].totalCount;
  }, [data]);

  const contacts = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.flatMap((page) => page.contacts);
  }, [data]);

  const handleLoadMoreClick = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const handleRowClick = useCallback(
    (contact: Contact) => {
      openModal<void>(
        ({ onClose }) => (
          <ContactForm isAllowedToDelete isRelatedRecordsShown initialValues={contact} onClose={onClose} />
        ),
        { title: 'Edit Contact' }
      );
    },
    [openModal]
  );

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <Scrollable>
        <TableHeader />

        <TableContainer>
          <tbody>
            {contacts.map((contact) => (
              <TableRow key={contact.id} contact={contact} onClick={handleRowClick} />
            ))}
          </tbody>

          {totalCount > contacts.length && (
            <tfoot>
              <tr>
                <td>
                  {isFetchingNextPage && (
                    <LoadMoreLoaderContainer>
                      <Loader size={24} />
                    </LoadMoreLoaderContainer>
                  )}

                  {!isFetchingNextPage && (
                    <LoadMore onClick={handleLoadMoreClick}>Load more ({totalCount - contacts.length})</LoadMore>
                  )}
                </td>
                <td colSpan={3} />
              </tr>
            </tfoot>
          )}
        </TableContainer>
      </Scrollable>
    </Container>
  );
};
