import React, { useCallback, useMemo } from 'react';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Copy, Edit2 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { QueryParamsEnum } from '@hooks/useQueryParam';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useFormTemplateList, FormTemplateListItem } from '@hooks/templates/forms/useFormTemplateList';
import { useNavigate } from '@reach/router';
import { useFileMutation } from '@hooks/useFiles';
import { Table } from './styled';

const TableRow = ({ item }: { item: FormTemplateListItem }) => {
  const confirmDelete = useConfirmDeleteModal();
  const navigate = useNavigate();
  const { removePublic: fileRemove } = useFileMutation();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          navigate(`./${item.id}`);
        }
      },
      {
        icon: <Copy size="16px" color="#9C9CAA" />,
        title: 'Duplicate',
        onClick: () => {
          navigate(`./new?${QueryParamsEnum.FromId}=${item.id}`);
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this template?')) {
            await fileRemove.mutateAsync({
              fileId: item.fileId
            });
          }
        }
      }
    ];
  }, [navigate, item, confirmDelete, fileRemove]);

  const handleRowClick = useCallback(() => {
    navigate(`./${item.id}`);
  }, [navigate, item]);

  return (
    <tr onClick={handleRowClick}>
      <td>{item.name}</td>

      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};

interface Props {
  search?: string;
}

export const Forms = ({ search = '' }: Props) => {
  const { data = [] } = useFormTemplateList();

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return item.name.toLowerCase().includes(search.toLowerCase());
    });
  }, [data, search]);

  return (
    <Table>
      <thead>
        <th>Name</th>
        <th />
      </thead>
      <tbody>
        {filteredData.map((item) => (
          <TableRow key={item.id} item={item} />
        ))}
      </tbody>
    </Table>
  );
};
