import React from 'react';
import { Control } from 'react-hook-form';
import { InputField, SelectField } from '@kit/components/Form';
import { StepFieldKeys } from '@enums';
import { Container, Text } from './styled';

interface Props {
  control: Control<any>;
  isDisabled?: boolean;
}

/*
     {
        key: 'dueDateNumber',
        required: false,
        type: InputTypeEnum.NUMBER,
        label: 'Due Date'
      },
      {
        key: 'dueDateUnit',
        required: true,
        type: InputTypeEnum.OPTIONS_STATIC,
        options: [
          {
            key: 'days',
            value: 'Days'
          },
          {
            key: 'hours',
            value: 'Hours'
          }
        ]
      }
*/

const UNIT_OPTIONS = [
  {
    key: 'days',
    value: 'Days'
  },
  {
    key: 'hours',
    value: 'Hours'
  }
];

export const ReminderDueDateField = ({ control, isDisabled }: Props) => {
  return (
    <Container>
      <InputField
        name={StepFieldKeys.DUE_DATE_NUMBER}
        label="Due date"
        type="number"
        control={control}
        disabled={isDisabled}
      />
      <SelectField
        name={StepFieldKeys.DUE_DATE_UNIT}
        control={control}
        options={UNIT_OPTIONS}
        disabled={isDisabled}
        getOptionValue={(option) => option?.key}
        getOptionLabel={(option) => option?.value}
        fieldValueIsOptionValue
        isClearable={false}
      />
      <Text>after automation execution</Text>
    </Container>
  );
};
