import { List, ListItem, ListItemSecondaryAction, Popover } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { uniq } from 'lodash';
import { SearchBar } from '@common/SearchBar';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Checkbox } from '@kit/ui/Checkbox';
import {
  FilterButton,
  GroupContainer,
  GroupItemsList,
  GroupSelectedCount,
  Menu,
  Remove,
  SelectedCount,
  Summary
} from './styled';

const Group = ({
  title,
  options,
  icon: Icon,
  selected,
  onSelect,
  search,
  fieldName
}: {
  search: string;
  title: string;
  fieldName: string;
  options: { id: number | string; title: string }[];
  selected: number[];
  onSelect: (fieldName: string, selected: number[]) => void;
  icon?: any;
}) => {
  const isAllSelected = useMemo(
    () => options.length > 0 && options.every(({ id }) => selected.includes(id)),
    [selected, options]
  );

  const selectedCount = useMemo(() => options.filter(({ id }) => selected.includes(id)).length, [selected, options]);

  const handleToggle = (id: number) => () => {
    if (selected.includes(id)) {
      onSelect(
        fieldName,
        selected.filter((value) => value !== id)
      );
    } else {
      onSelect(fieldName, selected.concat([id]));
    }
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      onSelect(
        fieldName,
        selected.filter((id) => !options.some((option) => option.id === id))
      );
    } else {
      onSelect(fieldName, uniq(selected.concat(options.map(({ id }) => id))));
    }
  };

  const handleClear = useCallback(() => {
    onSelect(
      fieldName,
      selected.filter((id) => !options.some((option) => option.id === id))
    );
  }, [fieldName, onSelect, selected, options]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    return options.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase().trim()));
  }, [options, search]);

  return (
    <GroupContainer>
      <List disablePadding>
        <ListItem role={undefined} dense disableGutters>
          <Checkbox
            isChecked={isAllSelected}
            onChange={handleToggleAll}
            label={
              <>
                {Icon && React.isValidElement(Icon) && Icon}
                {Icon && !React.isValidElement(Icon) && <Icon size="16px" color="#C8C8D3" />}
                {title} (
                {filteredOptions.length === options.length
                  ? options.length
                  : `${filteredOptions.length}/${options.length}`}
                )
              </>
            }
          />
          {selectedCount > 0 && (
            <ListItemSecondaryAction>
              <GroupSelectedCount>
                {selectedCount}

                <Remove onClick={handleClear} />
              </GroupSelectedCount>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <GroupItemsList disablePadding>
        {filteredOptions.map((option) => {
          return (
            <ListItem style={{ paddingLeft: 16 }} key={option.id} role={undefined} dense disableGutters>
              <Checkbox
                isChecked={selected.includes(option.id)}
                onChange={handleToggle(option.id)}
                label={
                  <>
                    {option.icon?.component && React.cloneElement(option.icon.component, { size: '16px' })}
                    {option.title}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </GroupItemsList>
    </GroupContainer>
  );
};

interface Props {
  label: string;
  groups: { fieldName: string; title: string; options: { id: number | string; title: string }[] }[];
  selected: { [fieldName: string]: number[] };
  onSelect: (filter: { [key: string]: number[] }) => void;
}

export const EventFilter = ({ label, groups, selected, onSelect }: Props) => {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = useCallback(
    (fieldName: string, selected: number[]) => {
      onSelect({ [fieldName]: selected });
    },
    [onSelect]
  );

  const handleClear = useCallback(() => {
    onSelect(
      groups.reduce(
        (acc, group) => {
          acc[group.fieldName] = [];

          return acc;
        },
        {} as { [key: string]: number[] }
      )
    );
  }, [onSelect, groups]);

  const selectedLength = Object.values(selected).reduce((acc, selectedValues) => acc + selectedValues.length, 0);

  return (
    <>
      <FilterButton aria-describedby="filter-event" variant="outlined" onClick={handleClick}>
        {label}
        {selectedLength > 0 && <SelectedCount>({selectedLength})</SelectedCount>}
        <ChevronDown size="20px" />
      </FilterButton>
      <Popover
        id="filter-event"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          {groups.map((group) => (
            <Group
              search={search}
              icon={group.icon}
              key={group.title}
              title={group.title}
              options={group.options}
              selected={selected[group.fieldName]}
              onSelect={handleSelect}
              fieldName={group.fieldName}
            />
          ))}

          <Summary>
            <div>Selected: {selectedLength}</div>
            <Button
              onClick={handleClear}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              disabled={selectedLength === 0}
            >
              Clear all
            </Button>
          </Summary>
        </Menu>
      </Popover>
    </>
  );
};
