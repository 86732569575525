import { fonts } from '@styles';
import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.div`
  position: absolute;

  left: 2px;
  top: 2px;
  z-index: 1;
`;

export const Td = styled.td<{ isSelected: boolean }>`
  &&& {
    width: 318px;
    min-width: 318px;
    max-width: 318px;
    padding: 0;
    position: sticky;
    left: 0px;
    z-index: 2;
    border-right: 1px solid #dfdfe8;
  }

  &:hover {
    ${CheckboxContainer} {
      display: block;
    }
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      ${CheckboxContainer} {
        display: block;
      }
    `};
`;

export const Container = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ImageAndCheckboContainer = styled.div`
  position: relative;
  flex-shrink: 0;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: space-between;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const FirstLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const SecondLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Title = styled.a`
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #1d1d35;
  display: block;
  text-decoration: none;

  &:hover,
  &:active,
  &:link,
  &:visited,
  &:focus {
    color: #1d1d35;
  }

  span {
    font-weight: 400;
  }
`;

export const StatusLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: ${fonts.primary};
`;

export const SystemName = styled.div`
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SystemNumber = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const AlertName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  min-width: 0;
`;
