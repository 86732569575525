import { ReactQueryKey } from '@enums';
import { TaskReportsConnection } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { postGraphql } from '@services/api/base/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { gql } from 'graphql-request';
import { useQuery } from 'react-query';

export const useLabelsStats = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.LabelList, companyId, 'stats'], async () => {
    try {
      const { taskReportsConnection } = await postGraphql<{ taskReportsConnection: TaskReportsConnection }>(
        gql`
          query LABELS_STATS_QUERY($companyId: Int!) {
            taskReportsConnection(filter: { companyId: { equalTo: $companyId } }, condition: { withLabels: true }) {
              groupedAggregates(groupBy: [LABEL_NAME]) {
                keys
                distinctCount {
                  id
                }
              }
            }
          }
        `,
        { companyId }
      );

      return taskReportsConnection.groupedAggregates.reduce(
        (acc, group) => {
          const label = group.keys[0] as string;

          acc[label] = parseInt(group.distinctCount.id, 10);

          return acc;
        },
        {} as Record<string, number>
      );
    } catch (error) {
      throw apiErrorHandler('Error fetching Labels stats', error);
    }
  });
};
