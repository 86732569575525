import { useAppSelector } from '@hooks/store';
import { getToken } from '@services/api/base/axios';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { useCallback, useEffect, useRef } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { PrivilegedTask } from '@generated/types/graphql';
import { fetchTasks } from './useTasks';
import { config } from '../../config';
import { ClientTrayFilters, useClientTrayFilterState } from './useClientTrayFilterState';

const fetchWorkOrder = async (companyId: number, id: number) => {
  const { tasks } = await fetchTasks({ pageParam: 0, companyId, filters: { id: { equalTo: id } } });

  return tasks[0];
};

const socketInstance: { current: io.Socket | null } = {
  current: null
};

const removeItemFromPaginatedData = (oldData: any, id: number) => {
  if (!oldData?.pages) {
    return oldData;
  }

  return {
    ...oldData,
    pages: oldData.pages.map((page) => {
      return {
        ...page,
        tasks: page.tasks.filter((task) => task.id !== id)
      };
    })
  };
};

const isWorkOrderExistInCache = (oldData: any, id: number) => {
  if (!oldData?.pages) {
    return false;
  }

  return oldData.pages.some((page) => {
    return page.tasks.some((task) => task.id === id);
  });
};

const isFilteredOutByTrayFilters = (trayFilters: ClientTrayFilters, workOrder: PrivilegedTask) => {
  const isFilteredOutByStatus =
    trayFilters.statuses.length > 0 && !trayFilters.statuses.includes(workOrder.taskStatus.id);
  const isFilteredOutByTemplate =
    trayFilters.templates.length > 0 && !trayFilters.templates.includes(workOrder.templateTaskId);

  return isFilteredOutByStatus || isFilteredOutByTemplate;
};

type Options = {
  trayQueryKey: ReactQueryKey;
  calendarQueryKey: ReactQueryKey;
  checkIfWorkOrderIsInCalendar: (workOrder: PrivilegedTask) => boolean;
};

export const useSubscribeToWOUpdates = ({ trayQueryKey, calendarQueryKey, checkIfWorkOrderIsInCalendar }: Options) => {
  const user = useAppSelector(selectUser);
  const token = getToken();
  const companyId = useAppSelector(selectWorkspaceId);
  const [trayFilters] = useClientTrayFilterState();

  const trayFiltersRef = useRef(trayFilters);

  trayFiltersRef.current = trayFilters;
  const queryClient = useQueryClient();

  const handleWorkOrderRemove = useCallback(
    async (payload) => {
      queryClient.removeQueries({ active: false, queryKey: [ReactQueryKey.Tasks] });

      queryClient.setQueriesData([ReactQueryKey.Tasks, calendarQueryKey], (oldData) => {
        return removeItemFromPaginatedData(oldData, payload.id);
      });

      let shouldInvalidateTrayCache = false;

      queryClient.setQueriesData([ReactQueryKey.Tasks, trayQueryKey], (oldData) => {
        if (isWorkOrderExistInCache(oldData, payload.id)) {
          shouldInvalidateTrayCache = true;

          return removeItemFromPaginatedData(oldData, payload.id);
        }

        return oldData;
      });

      // this is required to keep pagination in sync
      if (shouldInvalidateTrayCache) {
        queryClient.invalidateQueries([ReactQueryKey.Tasks, trayQueryKey]);
      }
    },
    [queryClient, trayQueryKey, calendarQueryKey]
  );

  const handleWorkOrderUpdate = useCallback(
    async (payload) => {
      queryClient.removeQueries({ active: false, queryKey: [ReactQueryKey.Tasks] });

      const currentTrayFilters = trayFiltersRef.current;

      const workOrder = await fetchWorkOrder(companyId, payload.id);

      if (!workOrder) {
        // workOrder is archived
        handleWorkOrderRemove(payload);

        return;
      }

      const isDispatched = checkIfWorkOrderIsInCalendar(workOrder);

      let shouldInvalidateTrayCache = false;
      if (isDispatched) {
        // remove from tray if it is there
        queryClient.setQueriesData([ReactQueryKey.Tasks, trayQueryKey], (oldData) => {
          if (isWorkOrderExistInCache(oldData, payload.id)) {
            shouldInvalidateTrayCache = true;

            return removeItemFromPaginatedData(oldData, payload.id);
          }

          return oldData;
        });

        // update in resources area
        queryClient.setQueriesData([ReactQueryKey.Tasks, calendarQueryKey], (oldData) => {
          if (!oldData?.pages) {
            return oldData;
          }

          let isPresented = false;

          const updated = {
            ...oldData,
            pages: oldData.pages.map((page) => {
              return {
                ...page,
                tasks: page.tasks.map((task) => {
                  if (task.id === payload.id) {
                    isPresented = true;

                    return workOrder;
                  }

                  return task;
                })
              };
            })
          };

          // if already in list just updated it
          if (isPresented) {
            return updated;
          } else {
            // otherwise add it to the top
            return {
              ...oldData,
              pages: [
                {
                  ...oldData.pages[0],
                  tasks: [workOrder, ...oldData.pages[0].tasks]
                },
                ...oldData.pages.slice(1)
              ]
            };
          }
        });
      }

      if (!isDispatched) {
        // remove from calendar area
        queryClient.setQueriesData([ReactQueryKey.Tasks, calendarQueryKey], (oldData) => {
          return removeItemFromPaginatedData(oldData, payload.id);
        });

        const isFilteredOutFromTray = isFilteredOutByTrayFilters(currentTrayFilters, workOrder);

        if (isFilteredOutFromTray) {
          // remove from tray
          queryClient.setQueriesData([ReactQueryKey.Tasks, trayQueryKey], (oldData) => {
            if (isWorkOrderExistInCache(oldData, payload.id)) {
              shouldInvalidateTrayCache = true;

              return removeItemFromPaginatedData(oldData, payload.id);
            }

            return oldData;
          });
        } else {
          queryClient.setQueriesData([ReactQueryKey.Tasks, trayQueryKey], (oldData) => {
            if (!oldData?.pages) {
              return oldData;
            }

            let isPresentedInTray = false;

            const updated = {
              ...oldData,
              pages: oldData.pages.map((page) => {
                return {
                  ...page,
                  tasks: page.tasks.map((task) => {
                    if (task.id === payload.id) {
                      isPresentedInTray = true;

                      return workOrder;
                    }

                    return task;
                  })
                };
              })
            };

            // if already in list just updated it
            if (isPresentedInTray) {
              return updated;
            }

            shouldInvalidateTrayCache = true;

            return oldData;
          });
        }
      }
      // this is required to keep pagination in sync
      if (shouldInvalidateTrayCache) {
        queryClient.invalidateQueries([ReactQueryKey.Tasks, trayQueryKey]);
      }
    },
    [companyId, queryClient, trayQueryKey, calendarQueryKey, checkIfWorkOrderIsInCalendar, handleWorkOrderRemove]
  );

  useEffect(() => {
    if (user.userId && user.userId !== 0 && (socketInstance.current === null || !socketInstance.current.connected)) {
      // @ts-ignore
      socketInstance.current = io(`${config.socketIoUrl}`, {
        query: `jwt=${token}`,
        path: '/socket',
        rejectUnauthorized: false
      });

      socketInstance.current?.on('connect', () => {
        socketInstance.current?.emit('join', `WORK-ORDER-CHANGES-${companyId}`);
      });

      socketInstance.current?.on('WORK-ORDER-CREATED', handleWorkOrderUpdate);

      socketInstance.current?.on('WORK-ORDER-UPDATED', handleWorkOrderUpdate);

      socketInstance.current?.on('WORK-ORDER-DELETED', handleWorkOrderRemove);
    }

    return () => {
      if (socketInstance.current != null) {
        socketInstance.current.disconnect();
      }
    };
  }, [user, companyId, handleWorkOrderUpdate, handleWorkOrderRemove, token]);
};
