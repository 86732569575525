import React, { useCallback, useState } from 'react';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { SearchBar } from '@common/SearchBar';
import { useClientFilterState } from '../../useClientFilterState';
import { List, Menu, MenuItem, Trigger, TriggerLabel, TriggerValue } from './styled';
import { GROUP_OPTIONS } from '@features/SystemPortfolio/constants';

export const GroupBy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const {
    clientFilters: { groupBy },
    updateFilters
  } = useClientFilterState();

  return (
    <Popover
      content={
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          <List>
            {!search && <MenuItem onClick={() => updateFilters({ groupBy: null })}>None</MenuItem>}

            {GROUP_OPTIONS.map((option) => (
              <MenuItem key={option.id} onClick={() => updateFilters({ groupBy: option })}>
                {option.name}
              </MenuItem>
            ))}
          </List>
        </Menu>
      }
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Group by:</TriggerLabel>
        <TriggerValue>{groupBy?.name || 'None'}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
