import React, { useEffect, useState } from 'react';
import { Geolocation } from '@generated/types/graphql';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { PriorityIcon } from '@common/PriorityIcon';
import { ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import { MapPin, X } from 'react-feather';
import { Avatar } from '@kit/ui/Avatar';
import { getFullName } from '@utils';
import { useTask } from '@components/Scheduler/useTaskDrawer';
import {
  Assignee,
  Container,
  FieldLabel,
  FirstRow,
  SeventhRow,
  SixthRow,
  TitleShrink,
  Title,
  TitleNoShrink
} from './styled';

type Props = {
  geolocation: Geolocation;
  handleClose: () => void;
};

const GEOLOCATION_FLOAT_PRECISION = 5;

export const ActualPointPopup = ({ handleClose, geolocation }: Props) => {
  const [loadedTask, setLoadedTask] = useState(null);
  const { taskId, longitude, latitude } = geolocation;
  const { task, isLoading, isFetching } = useTask(taskId);

  useEffect(() => {
    if (!isLoading && !isFetching && task) {
      setLoadedTask(task);
    }
  }, [isLoading, isFetching, task]);

  if (isLoading || !loadedTask) {
    return null;
  }

  return (
    <Container>
      <FirstRow>
        <Title>
          <TitleNoShrink>
            <WorkOrderTypeIcon isField={loadedTask.isField} size="16px" />
          </TitleNoShrink>
          <TitleShrink title={loadedTask.title}>
            {loadedTask?.uid ? `#${loadedTask?.uid}` : ''} · {loadedTask?.title}
          </TitleShrink>
          <TitleNoShrink>
            <PriorityIcon priority={task?.priority} />
          </TitleNoShrink>
        </Title>
        <IconButton variant={ButtonVariant.Flat} size={ButtonSize.Small} onClick={handleClose}>
          <X size={16} color="#9C9CAA" />
        </IconButton>
      </FirstRow>
      <SixthRow>
        <FieldLabel>
          <MapPin size={16} />
        </FieldLabel>
        <strong>Lat</strong>: {latitude.toFixed(GEOLOCATION_FLOAT_PRECISION)}, <strong>Long</strong>:{' '}
        {longitude.toFixed(GEOLOCATION_FLOAT_PRECISION)}
      </SixthRow>
      <SeventhRow>
        <Assignee>
          <Avatar user={loadedTask.assignee} />
          {getFullName(loadedTask.assignee)}
        </Assignee>
      </SeventhRow>
    </Container>
  );
};
