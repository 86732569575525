import React, { useEffect, useMemo, useRef } from 'react';
import { slidebarArrowLeft as ArrowLeft, slidebarArrowRight as ArrowRight } from '@assets/svg';
import { useIntersectionObserver } from '@react-hookz/web';
import { useActivity } from '@hooks/useActivity';
import { flatPagesData } from '@utils/reactQuery';
import { StateMessege } from '@common/index';
import { ReactQueryKey } from '@enums';
import { FeedItem } from '@features/ProjectPortfolio/Project/History/FeedItem';
import { Container, Feed, FeedContainer, Header, Toggle, Title } from './styled';
import { useSubscribeToUpdates } from './useSubscribeToUpdates';
import { FEED_TYPES } from './constants';
import { FeedItemToLoad } from './FeedItemToLoad';

interface Props {
  isCollapsed: boolean;
  onToggleIsCollapsed: () => void;
}

export const Activity = ({ isCollapsed, onToggleIsCollapsed }: Props) => {
  const loadingNodeRef = useRef<HTMLDivElement>();
  const loadingRef = useRef<boolean>(false);

  const entry = useIntersectionObserver(loadingNodeRef.current);

  useSubscribeToUpdates();

  const { data, isLoading, fetchNextPage } = useActivity({
    queryKey: ReactQueryKey.DispatcherActivity,
    types: FEED_TYPES
  });

  const flatFeed = useMemo(() => {
    return flatPagesData(data);
  }, [data]);

  useEffect(() => {
    if (entry?.isIntersecting && flatFeed.length < data?.total) {
      if (!loadingRef.current) {
        loadingRef.current = true;
        fetchNextPage().finally(() => {
          loadingRef.current = false;
        });
      }
    }
  }, [entry, flatFeed, data, fetchNextPage]);

  return (
    <Container>
      <Header isCollapsed={isCollapsed}>
        <Title isRotated={isCollapsed}>Feed</Title>
        <Toggle onClick={onToggleIsCollapsed} isCollapsed={isCollapsed}>
          <img src={isCollapsed ? ArrowLeft : ArrowRight} alt="" />
        </Toggle>
      </Header>

      <FeedContainer>
        <Feed>
          {flatFeed.length === 0 && !isLoading ? (
            <StateMessege subTitle="Looks like there's no activity here yet." />
          ) : (
            <>
              {flatFeed.map((item) =>
                item.shouldBeFetched ? (
                  <FeedItemToLoad id={item.id} parentId={item.parentId} key={item.id} />
                ) : (
                  <FeedItem context="dispatch" key={item.id} item={item} />
                )
              )}

              <div ref={loadingNodeRef} />
            </>
          )}
        </Feed>
      </FeedContainer>
    </Container>
  );
};
