import { useMutation, useQueryClient } from 'react-query';
import { useAppSelector, useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import integrationsAPI from '@services/api/integrationsAPI';
import { ReactQueryKey } from '@enums';
import { selectWorkspaceId } from '@state/selectors';
import { Integration } from '@generated/types/graphql';

export const useDeleteIntegration = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);
  const { showSuccess } = useToast();

  return useMutation<void, Error, number>(
    async (id: number) => {
      try {
        await integrationsAPI.deleteIntegration(id);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: (id) => {
        queryClient.cancelQueries([ReactQueryKey.Integration, 'useIntegrations', companyId]);
        queryClient.setQueryData<Integration[] | undefined>(
          [ReactQueryKey.Integration, 'useIntegrations', companyId],
          (old) => {
            if (!old) {
              return old;
            }

            return old.filter((integration) => integration.id !== id);
          }
        );
      },
      onError: () => {
        queryClient.invalidateQueries([ReactQueryKey.Integration]);
      },
      onSuccess: () => {
        showSuccess(`Integration deleted successfully`);
        queryClient.invalidateQueries([ReactQueryKey.Integration]);
      }
    }
  );
};
