import styled from 'styled-components';

export const Container = styled.div<{ isDark?: boolean }>`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ isDark }) => (isDark ? 'rgba(29, 29, 53, 0.75)' : '#F7F7FC')};

  iframe {
    border: none;
  }
`;

export const InfoBlock = styled.div<{ isDark?: boolean; isActive: boolean }>`
  padding: 16px;
  border: 1px solid #dfdfe8;

  position: absolute;
  left: calc(100vw / 2 - 350px - 275px - 30px);
  top: 200px;

  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 4px;
  color: ${({ isDark }) => (isDark ? '#fff' : '#000')};

  [data-type='switch-left'] {
    color: ${({ isActive, isDark }) => {
      if (isDark) {
        return isActive ? '#c8c8d3' : '#fff';
      } else {
        return isActive ? '#828d9a' : '#1D1D35';
      }
    }};
  }

  [data-type='switch-right'] {
    color: ${({ isActive, isDark }) => {
      if (isDark) {
        return isActive ? '#fff' : '#c8c8d3';
      } else {
        return isActive ? '#1D1D35' : '#828D9A';
      }
    }};
  }
`;

export const InfoBlockRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;

export const InternalFormFakeModal = styled.div`
  position: relative;
`;

export const InternalFormFakeModalHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 24px;
  gap: 16px;
  border-bottom: 1px solid #dfdfe8;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const InternalFormFakeModalTitle = styled.h2`
  font-size: 16px;
  font-weight: 500;
`;
