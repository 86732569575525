import { useConfirmDeleteModal } from '@common/PromiseModal';
import { ActionTemplateListItem, useActionTemplateList } from '@hooks/templates/actions/useActionTemplateList';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Loader } from '@kit/ui/Loader';
import { TrashIcon } from '@kit/ui/icons/Trash';
import React, { useCallback, useMemo } from 'react';
import { Copy, Edit3 } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useDeleteActionTemplate } from '@hooks/templates/actions/useDeleteActionTemplate';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useCreateActionTemplate } from '@hooks/templates/actions/useCreateActionTemplate';
import { Author, LoaderWrapper, Table, TitleWithIcon } from './styled';

const TableRow = ({ item, onClick }: { item: ActionTemplateListItem; onClick: (id: number) => void }) => {
  const confirmDelete = useConfirmDeleteModal();

  const { openDrawer } = useDrawersContext();
  const { mutateAsync: create } = useCreateActionTemplate();

  const { mutateAsync: deleteTemplate } = useDeleteActionTemplate();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit3 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          onClick(item.id);
        }
      },
      {
        icon: <Copy size="16px" color="#9C9CAA" />,
        title: 'Duplicate',
        onClick: async () => {
          const createdId = await create({
            title: `${item.title} (copy)`,
            description: item.description,
            assignAllContacts: item.assignAllContacts,
            type: item.type
          });

          openDrawer(DrawerEntity.ACTION_ITEM_TEMPLATE, createdId, []);
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this template?')) {
            await deleteTemplate({ id: item.id });
          }
        }
      }
    ];
  }, [item, confirmDelete, deleteTemplate, onClick, openDrawer, create]);

  const handleRowClick = useCallback(() => {
    onClick(item.id);
  }, [item, onClick]);

  return (
    <tr onClick={handleRowClick}>
      <td width="50%">
        <TitleWithIcon>{item.title}</TitleWithIcon>
      </td>
      <td>
        <Author>
          <UserAvatar user={item.createdByUser} size={20} />
          <div>
            {item.createdByUser.firstName} {item.createdByUser.lastName}
          </div>
        </Author>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};

interface Props {
  search?: string;
}

export const ActionItems = ({ search }: Props) => {
  const { data = [], isLoading } = useActionTemplateList();
  const { openDrawer } = useDrawersContext();

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    });
  }, [data, search]);

  const ids = useMemo(() => filteredData.map((item) => item.id), [filteredData]);

  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.ACTION_ITEM_TEMPLATE, id, ids);
    },
    [openDrawer, ids]
  );

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Table>
      <thead>
        <th>Name</th>
        <th>Created by</th>
        <th />
      </thead>
      <tbody>
        {filteredData.map((item) => (
          <TableRow key={item.id} item={item} onClick={handleRowClick} />
        ))}

        {!isLoading && data.length === 0 && (
          <tr>
            <td colSpan={3} align="center">
              No Action Item templates added yet
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};
