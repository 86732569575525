import React from 'react';
import { Search } from './Search';
import { Container, Filters } from './styled';
import { GroupBy } from './GroupBy';
import { SortBy } from './SortBy';
import { ShowMonitored } from '@features/SystemPortfolio/List/Header/ShowMonitored';

export const Header = () => {
  return (
    <Container>
      <Filters>
        <Search />
        <GroupBy />
        <SortBy />
        <ShowMonitored />
      </Filters>
    </Container>
  );
};
