import React, { useMemo } from 'react';
import { CheckboxGroupField, Form, useForm } from '@kit/components/Form';
import { GeofencingSettings } from '@features/Platform/GeolocationSettings/types';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { Slider } from '@kit/ui/Slider';
import { useController } from 'react-hook-form';
import { Switch } from '@kit/ui/Switch';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RadiusMap } from '@features/Platform/GeolocationSettings/RadiusMap';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { merge } from 'lodash';
import { defaultGeofencing } from '@features/Platform/GeolocationSettings';
import { RadioGroup } from '@kit/ui/RadioGroup';
import { normalizeRoleName } from '@utils';
import { useRolesWithGeoSettings, useTeamsWithGeoSettings, useUpdateUsersGeolocation } from '@hooks/geolocation';
import {
  AutoManualHeader,
  AutoManualValueContainer,
  AutoManualValueDescription,
  AutoManualValueTitle,
  BlockContainer,
  BlockDescription,
  BlockTitle,
  Body,
  Description,
  Footer,
  GeofencingContainer,
  GeofencingDescription,
  GeofencingSubtitle,
  GeofencingTitle,
  MapContainer,
  RadiusLabel,
  RequiredStar,
  Separator,
  UsersChecks,
  UsersContainer,
  UsersDescription,
  UsersLabel,
  UsersSection,
  UsersSections
} from './styled';

type FormValues = {
  geofencing: GeofencingSettings;
  rolesWithGeolocation: string[]; // stringified ids
  teamsWithGeolocation: string[]; // stringified ids
};

type Props = {
  onClose: () => void;
};

export const GeolocationSettingsForm = ({ onClose }: Props) => {
  const companySettings = useAppSelector(selectCompanySettings);

  const { mutateAsync: updateCompany } = useUpdateCompany();
  const { mutateAsync: updateUsersGeolocation } = useUpdateUsersGeolocation();

  const postForm = async (values: FormValues) => {
    await updateCompany({
      settings: {
        geolocation: {
          geofencing: values.geofencing
        }
      }
    });

    await updateUsersGeolocation({
      roles: values.rolesWithGeolocation.map((value) => +value),
      teams: values.teamsWithGeolocation.map((value) => +value)
    });

    onClose();
  };

  const { data: roles } = useRolesWithGeoSettings();
  const rolesOptions = useMemo(
    () => roles.map((role) => ({ value: '' + role.id, label: normalizeRoleName(role.name) })),
    [roles]
  );

  const { data: teams } = useTeamsWithGeoSettings();
  const teamsOptions = useMemo(() => teams.map((team) => ({ value: '' + team.id, label: team.name })), [teams]);

  const {
    form: { control, watch },
    handleSubmit
  } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {
      geofencing: merge(defaultGeofencing, companySettings?.geolocation?.geofencing),
      rolesWithGeolocation: roles
        .filter((role) => role.geolocationUserSettings[0]?.enabled)
        .map((role) => '' + role.id),
      teamsWithGeolocation: teams
        .filter((team) => team.geolocationUserSettingsByTeamId[0]?.enabled)
        .map((team) => '' + team.id)
    }
  });

  const {
    field: { value: enabled, onChange: onChangeEnabled }
  } = useController<FormValues, 'geofencing.enabled'>({
    name: 'geofencing.enabled',
    control
  });

  const {
    field: { value: radius, onChange: onChangeRadius }
  } = useController<FormValues, 'geofencing.radius'>({
    name: 'geofencing.radius',
    control
  });

  const {
    field: { value: auto, onChange: onChangeAuto }
  } = useController<FormValues, 'geofencing.auto'>({
    name: 'geofencing.auto',
    control
  });

  const {
    field: { value: prevent, onChange: onChangePrevent }
  } = useController<FormValues, 'geofencing.preventClockInWithoutGeofence'>({
    name: 'geofencing.preventClockInWithoutGeofence',
    control
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Body>
        <Description>
          Geolocation is the identification of the real-world geographic location of users. By using this feature you
          are responsible for ensuring compliance with local laws and regulations.
        </Description>

        <UsersContainer>
          <UsersDescription>
            Select roles and teams who will be geolocated <RequiredStar />:
          </UsersDescription>

          <UsersSections>
            <UsersSection>
              <UsersLabel>
                Roles ({watch('rolesWithGeolocation')?.length} of {roles.length} selected):
              </UsersLabel>
              <UsersChecks>
                <CheckboxGroupField label="" options={rolesOptions} name="rolesWithGeolocation" control={control} />
              </UsersChecks>
            </UsersSection>

            <UsersSection>
              <UsersLabel>
                Teams ({watch('teamsWithGeolocation')?.length} of {teams.length} selected):
              </UsersLabel>
              <UsersChecks>
                <CheckboxGroupField label="" options={teamsOptions} name="teamsWithGeolocation" control={control} />
              </UsersChecks>
            </UsersSection>
          </UsersSections>
        </UsersContainer>

        <Separator />

        <GeofencingContainer>
          <Switch isActive={enabled} onChange={onChangeEnabled} />

          <GeofencingDescription>
            <GeofencingTitle disabled={!enabled}>Geofencing</GeofencingTitle>
            <GeofencingSubtitle disabled={!enabled}>
              Geofencing allows you to set up virtual boundaries or perimeters around a specific geographic location.
              When assignees enter or exit these virtual boundaries, actions are triggered.
            </GeofencingSubtitle>
          </GeofencingDescription>
        </GeofencingContainer>

        {enabled && (
          <>
            <div>
              <RadiusLabel>
                Geofencing radius to clock in, feet
                <RequiredStar />
              </RadiusLabel>
              <Slider
                value={radius}
                onChange={(_, value) => onChangeRadius(value)}
                min={300}
                max={3000}
                step={100}
                valueLabelDisplay="on"
                marks={[
                  { value: 300, label: 300 },
                  { value: 3000, label: 3000 }
                ]}
              />
            </div>

            <MapContainer>
              <RadiusMap radius={radius} />
            </MapContainer>

            <div>
              <AutoManualHeader>
                Clock in and out when near location
                <RequiredStar />
              </AutoManualHeader>
              <RadioGroup<'auto' | 'manual'>
                value={auto ? 'auto' : 'manual'}
                name="geofencing.auto"
                onChange={(_, value: string) => onChangeAuto(value === 'auto')}
                options={[
                  { value: 'auto', label: '' },
                  { value: 'manual', label: '' }
                ]}
                renderOptionLabel={({ value }) => (
                  <AutoManualValueContainer>
                    <AutoManualValueTitle>{value === 'auto' ? 'Automatically' : 'Manually'}</AutoManualValueTitle>
                    <AutoManualValueDescription>
                      {value === 'auto'
                        ? 'Users will be clocked in and out automatically when they are at or leave the scheduled geofenced location.'
                        : 'Users will receive a reminder to clock in and out when they are at or leave the scheduled geofenced location.'}
                    </AutoManualValueDescription>
                  </AutoManualValueContainer>
                )}
              />
            </div>

            <BlockContainer>
              <Switch isActive={prevent} onChange={onChangePrevent} />

              <div>
                <BlockTitle>Only allow users to clock in when inside geofence</BlockTitle>
                <BlockDescription>
                  It limits where users are allowed to clock in based on where they’re physically located. So you can
                  make sure users are clocking in from where they’re scheduled to work.
                </BlockDescription>
              </div>
            </BlockContainer>
          </>
        )}
      </Body>

      <Footer>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </Footer>
    </Form>
  );
};
