import styled from 'styled-components';

export const ErrorText = styled.span`
  color: #d54855;
  font-size: 14px;
  font-weight: 400;
`;

export const SecondaryText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const TextBlock = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;

  > *:first-child {
    width: 120px;
  }

  > *:last-child {
    flex: 1;
  }
`;

export const FormDescription = styled.div`
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 400;
`;

export const FormBody = styled.div`
  padding: 24px;
`;

export const FormFooter = styled.div`
  background-color: #fff;
  padding: 16px 24px;

  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

export const ReviewChannelRow = styled.div`
  display: flex;
  gap: 16px;

  > *:last-child {
    flex: 1;
  }
`;

export const ReviewChannelIcon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dfdfe8;
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
  }
`;
