import { CheckboxField, InputField, TextareaField } from '@kit/components/Form';
import React from 'react';
import { Globe } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { FormProps } from './types';
import { Header, HeaderRight, Title } from './styled';

interface Props extends FormProps {
  title?: string;
}

export const Text = ({ title = 'Text', control, isProjectProperty, isReadOnlyProperty }: Props) => {
  return (
    <div>
      <Header>
        <Title>
          {title}{' '}
          {isProjectProperty && (
            <Tooltip title="Project property">
              <Globe size="16px" />
            </Tooltip>
          )}
        </Title>
        <HeaderRight>
          {isProjectProperty && (
            <CheckboxField isDisabled={isReadOnlyProperty} name="isEditable" control={control} label="Editable" />
          )}
          <CheckboxField name="isRequired" control={control} label="Required" />
        </HeaderRight>
      </Header>
      <InputField control={control} label="Title" name="name" />

      <TextareaField control={control} label="Add notes and instructions" name="notes" />
    </div>
  );
};
