import { OverbudgetBehaviour } from '@generated/types/graphql';

export const OVERBUDGET_BEHAVIOUR_TITLES_MAP: Record<OverbudgetBehaviour, string> = {
  [OverbudgetBehaviour.None]: 'continue monitoring',
  [OverbudgetBehaviour.StopAll]: 'stop monitoring all Fleets',
  [OverbudgetBehaviour.StopExceptWhitelist]: 'stop monitoring all Fleets except:'
};

export const OVERBUDGET_BEHAVIOUR_OPTIONS = Object.entries(OVERBUDGET_BEHAVIOUR_TITLES_MAP).map(
  ([value, label]) =>
    ({
      value,
      label: label[0].toUpperCase() + label.slice(1)
    }) as { value: OverbudgetBehaviour; label: string }
);
