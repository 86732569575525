import { autoUpdate, flip, hide, shift, size, useFloating, UseFloatingOptions } from '@floating-ui/react';

/**
 * https://floating-ui.com/docs/react
 */
export const useTooltip = (opts: UseFloatingOptions) =>
  useFloating({
    whileElementsMounted: autoUpdate,
    ...opts,
    middleware: [
      ...(opts.middleware || []),
      shift(),
      flip(),
      hide(),
      size({
        apply({ availableHeight, elements }) {
          // eslint-disable-next-line no-param-reassign
          elements.floating.style.maxHeight = `${availableHeight}px`;
        }
      })
    ]
  });
