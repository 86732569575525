import React from 'react';
import { Skeleton } from '@material-ui/lab';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  height: 64px;

  padding: 9px 8px;
  background-color: #fff;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0px 4px 8px 0px #1a034014;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;

  > * {
    border-radius: 4px;
  }
`;

export const TaskSkeleton = () => (
  <Wrapper>
    <Row>
      <Skeleton animation="wave" width={200} height={18} />
      <Skeleton variant="rect" animation="wave" width={50} height={24} />
    </Row>
    <Skeleton animation="wave" width={200} height={14} />
  </Wrapper>
);
