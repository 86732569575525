import React, { useEffect } from 'react';
import { useForm } from '@kit/components/Form';
import { cloneDeep } from 'lodash';
import { Group, Text, Select, File } from './forms';
import { FormBuilderElement, FormFieldType, FormLayoutType } from '../helpers';

interface Props {
  element: FormBuilderElement;
  onChange: (id: FormBuilderElement['id'], config: FormBuilderElement['config']) => void;
}

type FormValues = {
  name: string;
  layoutElementId?: number;
  columnId?: number;
  multiple?: boolean;
  isReadOnlyProperty?: boolean;
  isReadOnly?: boolean;
  isRequired?: boolean;
  additional?: any; // TODO
  projectColumnId?: number;
};

export const Form = ({ element, onChange }: Props) => {
  const {
    form: { control, watch }
  } = useForm<FormValues>({
    onSubmit: () => {
      return Promise.resolve();
    },
    defaultValues: cloneDeep(element.config)
  });

  const isReadOnlyProperty = watch('isReadOnlyProperty');

  useEffect(() => {
    const subscription = watch((values) => {
      // without cloneDeep, the values are mutated and select options removed
      onChange(element.id, cloneDeep(values));
    });

    return () => subscription.unsubscribe();
  }, [watch, element.id, onChange]);

  const isProjectProperty = Boolean(element.config?.projectColumnId);

  switch (element.type) {
    case FormLayoutType.GROUP:
      return <Group control={control} />;
    case FormFieldType.Text:
      return <Text isReadOnlyProperty={isReadOnlyProperty} isProjectProperty={isProjectProperty} control={control} />;
    case FormFieldType.Numeric:
      return (
        <Text
          isReadOnlyProperty={isReadOnlyProperty}
          isProjectProperty={isProjectProperty}
          title="Numeric"
          control={control}
        />
      );
    case FormFieldType.SingleSelect:
      return <Select isReadOnlyProperty={isReadOnlyProperty} isProjectProperty={isProjectProperty} control={control} />;
    case FormFieldType.MultiSelect:
      return (
        <Select
          isReadOnlyProperty={isReadOnlyProperty}
          isProjectProperty={isProjectProperty}
          title="Multiple select"
          control={control}
        />
      );
    case FormFieldType.File:
      return <File isReadOnlyProperty={isReadOnlyProperty} isProjectProperty={isProjectProperty} control={control} />;
    case FormFieldType.Date:
      return <Text isReadOnlyProperty={isReadOnlyProperty} isProjectProperty title="Date" control={control} />;
    case FormFieldType.Person:
      return <Text isReadOnlyProperty={isReadOnlyProperty} isProjectProperty title="Person" control={control} />;
    default:
      return null;
  }
};
