import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Switch, SwitchVariant } from '@kit/ui/Switch';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { DateTime, Duration } from 'luxon';
import { ButtonVariant, IconButton } from '@kit/ui/Button';
import { ChevronLeftIcon } from '@kit/ui/icons/ChevronLeft';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { Circle, Square } from 'react-feather';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { formatCurrency, formatCurrencyWithoutCents, formatNumber } from '@utils/utils';
import { Point, useApiUsage } from './useApiUsage';
import { DateRangeType } from './types';
import { FLEET_COLORS } from '../constants';
import {
  DateRangeTypeTrigger,
  Header,
  HeaderLeft,
  Legend,
  LegendItem,
  PeriodSwitch,
  PeriodValue,
  PointTooltip,
  TooltipLabel,
  TooltipValue
} from './styled';
import { Panel, PanelDescription, PanelHeader, PanelTitle } from '../styled';

const DURATION_BY_DATE_RANGE_TYPE: Record<DateRangeType, Duration> = {
  [DateRangeType.Daily]: Duration.fromObject({ month: 1 }),
  [DateRangeType.Monthly]: Duration.fromObject({ year: 1 })
};

interface Props {
  fleets: Fleet[];
  stats: {
    total: number;
    averageMonthlyNew: number;
    byFleetId: Record<string, number>;
    byFleetIdByIntegrationId: { [fleetId: string]: { [integrationId: string]: number } };
  };
}

export const SystemsBreakdown = ({ fleets, stats }: Props) => {
  const [isHitsBreakdown, setIsHitsBreakdown] = useState(false);
  const [dateRangeType, setDateRangeType] = useState(DateRangeType.Monthly);

  const [anchorTime, setAnchorTime] = useState<DateTime<true>>(DateTime.now());

  const period = useMemo(() => {
    const duration = DURATION_BY_DATE_RANGE_TYPE[dateRangeType];

    const start = anchorTime.startOf(dateRangeType === DateRangeType.Daily ? 'month' : 'year');
    const end = start.plus(duration).minus(1);

    return start.until(end);
  }, [anchorTime, dateRangeType]);

  const periodText = useMemo(() => {
    return dateRangeType === DateRangeType.Daily ? period.start.toFormat('MMMM yyyy') : period.start.toFormat('yyyy');
  }, [dateRangeType, period]);

  const dateRangeOptions = useMemo<MenuItem[]>(() => {
    return Object.values(DateRangeType).map((value) => ({
      title: value,
      onClick: () => setDateRangeType(value as DateRangeType)
    }));
  }, []);

  const goPrevPeriod = useCallback(() => {
    const duration = DURATION_BY_DATE_RANGE_TYPE[dateRangeType];

    setAnchorTime((prev) => prev.minus(duration));
  }, [dateRangeType]);

  const goNextPeriod = useCallback(() => {
    const duration = DURATION_BY_DATE_RANGE_TYPE[dateRangeType];

    setAnchorTime((prev) => prev.plus(duration));
  }, [dateRangeType]);

  const points = useApiUsage({
    period,
    dateRangeType,
    fleets,
    stats
  });

  useEffect(() => {
    if (dateRangeType === DateRangeType.Daily) {
      setIsHitsBreakdown(true);
    }
  }, [dateRangeType]);

  return (
    <Panel>
      <PanelHeader>
        <div>
          <PanelTitle>Systems breakdown</PanelTitle>
          <PanelDescription>This chart provides detailed data on the overall system performance.</PanelDescription>
        </div>
      </PanelHeader>

      <Header>
        <HeaderLeft>
          <ContextMenu items={dateRangeOptions}>
            <DateRangeTypeTrigger>
              {dateRangeType}
              <ChevronDownIcon size="16px" />
            </DateRangeTypeTrigger>
          </ContextMenu>

          <PeriodSwitch>
            <IconButton onClick={goPrevPeriod} variant={ButtonVariant.Flat}>
              <ChevronLeftIcon size="16px" />
            </IconButton>
            <PeriodValue isFixedWidth={dateRangeType === DateRangeType.Daily}>{periodText}</PeriodValue>

            <IconButton onClick={goNextPeriod} variant={ButtonVariant.Flat}>
              <ChevronRightIcon size="16px" />
            </IconButton>
          </PeriodSwitch>
        </HeaderLeft>

        {dateRangeType === DateRangeType.Monthly && (
          <Switch
            variant={SwitchVariant.TwoWay}
            label="hits"
            secondLabel="$$"
            isActive={isHitsBreakdown}
            onChange={setIsHitsBreakdown}
          />
        )}
      </Header>
      <div>
        <ResponsiveContainer width="100%" height="100%" minWidth="200px" minHeight="200px">
          <BarChart data={points}>
            <CartesianGrid vertical={false} stroke="#DFDFE8" strokeDasharray="2 2" />

            <YAxis
              tickFormatter={isHitsBreakdown ? formatNumber : formatCurrencyWithoutCents}
              tickLine={false}
              axisLine={false}
              fontSize={12}
              stroke="#828D9A"
            />

            <XAxis
              type="category"
              dataKey={({ date }: Point) => date.toSeconds()}
              name="Month"
              tickLine={false}
              tickFormatter={(seconds: number) =>
                DateTime.fromSeconds(seconds).toLocaleString(
                  dateRangeType === DateRangeType.Monthly ? { month: 'short' } : { day: 'numeric', month: 'short' }
                )
              }
              interval="preserveStartEnd"
              minTickGap={8}
              tick={{ fill: '#828D9A', fontSize: 10, fontWeight: 400 }}
              tickMargin={4}
              axisLine={{ stroke: '#DFDFE8' }}
            />

            <Bar
              dataKey={(point: Point) => {
                const isFuture =
                  dateRangeType === DateRangeType.Monthly
                    ? point.date > DateTime.now()
                    : point.date.startOf('day') >= DateTime.now().startOf('day');

                if (isFuture) {
                  return 0;
                }

                return isHitsBreakdown ? point.totalHits : point.totalCost;
              }}
              stackId="main"
              name="Historical data"
              fillOpacity={1}
              isAnimationActive={false}
              barSize={24}
              fill="#828d9a"
            >
              {points.map((_point, index) => (
                <Cell key={`cell-${index}`} fill="#828d9a" />
              ))}
            </Bar>

            {fleets.map((fleet, fleetIndex) => (
              <Bar
                key={fleet.id}
                dataKey={(point: Point) => {
                  const isFuture =
                    dateRangeType === DateRangeType.Monthly
                      ? point.date > DateTime.now()
                      : point.date.startOf('day') >= DateTime.now().startOf('day');

                  if (!isFuture) {
                    return 0;
                  }

                  const statsForFleet = point.statsByFleetId?.[fleet.id] ?? { cost: 0, hits: 0 };

                  return isHitsBreakdown ? statsForFleet.hits : statsForFleet.cost;
                }}
                stackId="main"
                name={fleet.name}
                fillOpacity={1}
                isAnimationActive={false}
                barSize={24}
                fill={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]}
              >
                {points.map((_point, index) => (
                  <Cell key={`cell-${index}`} fill={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]} />
                ))}
              </Bar>
            ))}

            <Tooltip<number, 'historical' | string>
              cursor={{ stroke: '#DFDFE8', fill: 'transparent' }}
              wrapperStyle={{ maxWidth: 400 }}
              // eslint-disable-next-line
              content={({ payload, label: seconds }) => {
                const date = DateTime.fromSeconds(Number(seconds));
                const isPredicted =
                  dateRangeType === DateRangeType.Monthly
                    ? date > DateTime.now()
                    : date.startOf('day') >= DateTime.now().startOf('day');

                return (
                  <PointTooltip>
                    <TooltipLabel>
                      {typeof seconds === 'number' &&
                        DateTime.fromSeconds(seconds).toLocaleString(
                          dateRangeType === DateRangeType.Monthly
                            ? { month: 'long', year: 'numeric' }
                            : { day: 'numeric', month: 'long' }
                        )}
                      <br />
                      {isPredicted ? 'Projected data' : 'Historical data'}
                    </TooltipLabel>

                    {payload.map((item) => {
                      const isHistoricalValue = item.name === 'Historical data';

                      if ((isHistoricalValue && isPredicted) || (!isHistoricalValue && !isPredicted)) {
                        return null;
                      }

                      if (isHistoricalValue) {
                        return (
                          <TooltipValue key={item.name}>
                            {isHitsBreakdown
                              ? `${formatNumber(Math.round(item.value))} hits`
                              : `${formatCurrency(Math.round(item.value))}`}
                          </TooltipValue>
                        );
                      }

                      const fleetName = item.name;
                      const fleetIndex = fleets.findIndex((fleet) => fleet.name === fleetName);

                      return (
                        <TooltipValue key={item.name}>
                          <Circle
                            size={8}
                            color={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]}
                            fill={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]}
                          />

                          {isHitsBreakdown
                            ? `${fleetName}: ${formatNumber(Math.round(item.value))} hits`
                            : `${fleetName}: ${formatCurrency(Math.round(item.value))}`}
                        </TooltipValue>
                      );
                    })}
                  </PointTooltip>
                );
              }}
            />
          </BarChart>
        </ResponsiveContainer>

        <Legend>
          <LegendItem>
            <Square size={12} color="#828D9A" fill="#828D9A" />
            Historical data
          </LegendItem>
          {fleets.map((fleet, fleetIndex) => (
            <LegendItem key={fleet.id}>
              <Square
                size={12}
                color={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]}
                fill={FLEET_COLORS[fleetIndex % FLEET_COLORS.length]}
              />

              {fleet.name}
            </LegendItem>
          ))}
        </Legend>
      </div>
    </Panel>
  );
};
