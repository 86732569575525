import { PriorityIcon } from '@common/PriorityIcon';
import { Chip } from '@components/Scheduler/components/Chip';
import { ProjectTitleChip } from '@components/Scheduler/components/ProjectTitleChip';
import { PRIORITY_OPTIONS } from '@components/Scheduler/constants';
import { PrivilegedTask } from '@generated/types/graphql';
import { makeStyles } from '@material-ui/core';
import { checkboxMui, colors } from '@styles';
import moment from 'moment';
import React, { useCallback, useMemo, useRef } from 'react';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { UserAvatar } from '@kit/components/UserAvatar';
import {
  Container,
  Title,
  BulkCheckbox,
  Cell,
  Label,
  Assignee,
  AssigneeName,
  WorkOrderStatus,
  MoreMenu
} from './styled';

interface Props {
  task: PrivilegedTask;
  isSelected: boolean;
  onToggleSelect: (id: number) => void;
  onTaskClick: (id: number) => void;
}

const useStylesCheckbox = makeStyles(checkboxMui({ size: '24px' }));

const formatDate = (date: string | null) => (date ? moment(date).format('MM/DD/YY') : '...');

export const Task = ({ task, isSelected, onToggleSelect, onTaskClick }: Props) => {
  const containerNodeRef = useRef<HTMLDivElement>();
  const classesCheckbox = useStylesCheckbox();

  const handleCheckClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      onToggleSelect(task.id);
    },
    [task.id, onToggleSelect]
  );

  const priorityOption = useMemo(
    () => PRIORITY_OPTIONS.find(({ id }) => task.priority === id) ?? PRIORITY_OPTIONS[1],
    [task]
  );

  const { assignee } = task;

  const handleClick = useCallback<React.MouseEventHandler<HTMLDivElement>>(
    (event) => {
      const targetNode = event.target as HTMLElement;

      // for some reason clicking on mui popover backdrop calls this click handler too
      if (!containerNodeRef.current?.contains(targetNode)) {
        return;
      }

      onTaskClick(task.id);
    },
    [onTaskClick, task]
  );

  return (
    <Container ref={containerNodeRef} borderColor={task.firstLabel?.color} onClick={handleClick}>
      <Cell width="24%">
        <Title isCompleted={task.isCompleted}>
          <BulkCheckbox
            classes={classesCheckbox}
            checked={isSelected}
            onClick={handleCheckClick}
            style={{ color: colors.gray }}
            size="small"
          />
          <WorkOrderTypeIcon isField={task.isField} />
          <div>
            #{task.uid}
            {' · '}
            {task.title}
          </div>
        </Title>
      </Cell>

      <Cell width="10%">
        <Chip title={priorityOption.title} icon={<PriorityIcon priority={task.priority} size="12px" />} />
      </Cell>
      <Cell width="11%">
        {assignee && (
          <Assignee>
            <UserAvatar user={assignee} />
            <AssigneeName>
              {assignee.firstName} {assignee.lastName}
            </AssigneeName>
          </Assignee>
        )}
      </Cell>
      <Cell width="11%">
        <WorkOrderStatus status={task.taskStatus} isArchived={task.isArchived} />
      </Cell>
      <Cell width="15%">{task.project && <ProjectTitleChip target="panel" project={task.project} />}</Cell>
      <Cell width="16%">
        <div>
          {formatDate(task.startDate)} — {formatDate(task.endDate)}
        </div>
      </Cell>
      <Cell width="10%" align="flex-end">
        <div>
          <Label>{formatDate(task.createdAt)}</Label>
        </div>
      </Cell>
      <Cell align="flex-end" width="3%">
        <MoreMenu task={task} />
      </Cell>
    </Container>
  );
};
