import React, { useCallback, useState } from 'react';
// import { DrawerWrapper } from '@components/Project/ProjectView/styled';
import { Filter } from 'react-feather';
import { ButtonVariant } from '@kit/ui/Button';
import { AnalyticsFilters, countSelectedFilters } from '@features/Analytics';
import { AnalyticsModule, WidgetFilters } from '@features/Analytics/types';
import { XIcon } from '@kit/ui/icons/X';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useUpdateEffect } from '@react-hookz/web';
import { Body, CloseButton, FiltersButton, SlidePanel, Title } from './styled';
import { useClientFilterState } from '../../useClientFilterState';

export const MoreFilters = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [smartViewId = 'noSmartView'] = useQueryParam(QueryParamsEnum.SmartViewId);

  const {
    clientFilters: { filters },
    updateFilters
  } = useClientFilterState();

  useUpdateEffect(() => {
    setIsOpen(false);
  }, [smartViewId]);

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleFiltersChange = useCallback(
    (newFilters: WidgetFilters | null) => {
      updateFilters({ filters: newFilters ?? undefined });
    },
    [updateFilters]
  );

  const totalSelected = countSelectedFilters([filters]);

  return (
    <div>
      <FiltersButton aria-describedby="filter-more" variant={ButtonVariant.Flat} onClick={toggle} isUpperCase={false}>
        <Filter size="16px" />
        FILTERS
        {totalSelected > 0 && ` (${totalSelected})`}
      </FiltersButton>
      {isOpen && (
        <SlidePanel>
          <CloseButton onClick={toggle}>
            <XIcon size="24px" />
          </CloseButton>
          <Body>
            <Title>Filters</Title>
            <AnalyticsFilters
              initialFilters={filters}
              module={AnalyticsModule.PROJECTS}
              onChange={handleFiltersChange}
            />
          </Body>
        </SlidePanel>
      )}
    </div>
  );
};
