import styled from 'styled-components';

export const Body = styled.div`
  padding: 16px 24px;
`;
export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  padding: 16px 24px;
  background-color: #fff;
`;
