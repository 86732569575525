import { Popover } from '@kit/ui/Popover';
import React, { useCallback, useState } from 'react';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Select } from '@kit/ui/Select';
import { XIcon } from '@kit/ui/icons/X';

import { sortAsc as SortAsc, sortDesc as SortDesc } from '@assets/svg';
import { useUpdateEffect } from '@react-hookz/web';
import { SORT_OPTIONS, SortOption } from '@features/Contacts/constants';
import { OrderBy, useClientFilterState } from '../../useClientFilterState';
import { Trigger, TriggerLabel, TriggerValue, Menu, SortByRow, AddButton, IconButton, OrderButton } from './styled';

const MAX_SORT_BY = 1;

export const SortBy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    clientFilters: { sortBy },
    updateFilters
  } = useClientFilterState();
  const [items, setItems] = useState<OrderBy[]>(sortBy);

  const handleAdd = useCallback(() => {
    setItems((prev) => [...prev, { property: null, isDesc: true }]);
  }, []);

  const handleChangeProperty = useCallback(
    (index: number) => (option: SortOption) => {
      setItems((prev) => {
        const newItems = [...prev];
        newItems[index] = { ...newItems[index], option };

        return newItems;
      });
    },
    []
  );

  useUpdateEffect(() => {
    updateFilters({ sortBy: items });
  }, [items]);

  const handleRemoveProperty = useCallback(
    (index: number) => () => {
      setItems((prev) => {
        const newItems = [...prev];
        newItems.splice(index, 1);

        return newItems;
      });
    },
    []
  );

  const togglePropertyOrder = useCallback(
    (index: number) => () => {
      setItems((prev) => {
        const newItems = [...prev];
        newItems[index] = { ...newItems[index], isDesc: !newItems[index].isDesc };

        return newItems;
      });
    },
    []
  );

  return (
    <Popover
      content={
        <Menu>
          {items?.map((item, index) => (
            <SortByRow key={item.option?.id || `new_${index}`}>
              <Select
                value={item.option}
                options={SORT_OPTIONS}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(op, val) => op.id === val.id}
                isClearable={false}
                onChange={(_e, value) => handleChangeProperty(index)(value)}
              />
              <OrderButton onClick={togglePropertyOrder(index)}>
                <img src={item.isDesc ? SortDesc : SortAsc} alt="" />
              </OrderButton>
              {items.length > 1 && (
                <IconButton isHidden={index === 0} onClick={index === 0 ? undefined : handleRemoveProperty(index)}>
                  <XIcon size="16px" />
                </IconButton>
              )}
            </SortByRow>
          ))}

          {items?.length < MAX_SORT_BY && <AddButton onClick={handleAdd}>+ Add sort</AddButton>}
        </Menu>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Sort by:</TriggerLabel>
        <TriggerValue>{items?.[0].option.name}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
