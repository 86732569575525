import styled from 'styled-components';
import { getColorWithOpacity } from '@styles';
import { EditableFieldContainer } from '../styled';

export const LabelsContainer = styled(EditableFieldContainer)`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

export const LabelChip = styled.div`
  color: ${(props) => props.color};
  background-color: ${(props) => getColorWithOpacity(props.color, 20) || colors.white3};
  border-radius: 4px;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  svg {
    flex-shrink: 0;
  }
`;

export const Placeholder = styled.div`
  color: #9c9caa;
`;

export const SetColored = styled.div`
  display: flex;
  padding-left: 12px;
  align-items: center;

  margin-top: 8px;

  &&& {
    label {
      margin-left: 0;
    }

    .MuiTypography-body1 {
      padding-left: 8px;
      font-weight: 400;
    }

    .MuiSvgIcon-root {
      font-size: 20px;
    }
  }
`;
