import { BulkPanel } from '@common/BulkPanel';
import { useSelection } from '@components/Scheduler/useSelection';
import { ReactQueryKey } from '@enums';
import { PrivilegedTaskFilter, ReminderFilter } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { useTaskBulk } from '@hooks/useTaskBulk';
import { selectWorkspaceId } from '@state/selectors';
import { invalidateQueriesBy } from '@utils/reactQuery';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { DeepPartial } from 'redux';
import { Group } from './Group';
import { SortBy } from './SortBy';
import { Container, GroupList, Header, HeaderLeft } from './styled';
import { ViewSwitcher } from './ViewSwitcher';

type TaskGroup = {
  title: string;
  filter: DeepPartial<PrivilegedTaskFilter>;
  initialExpanded?: boolean;
  remindersFilter?: DeepPartial<ReminderFilter>;
};

export const TaskGroups = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const {
    update: { mutate: bulkUpdate }
  } = useTaskBulk();

  const queryClient = useQueryClient();

  const { selected, checkIsSelected, toggle, clearSelection, selectedCount } = useSelection([]);

  const handleBulkSubmit = useCallback(
    async (dto: any) => {
      bulkUpdate(
        {
          ...dto,
          ids: selected
        },
        {
          onSuccess: () => {
            clearSelection();
            setTimeout(() => {
              invalidateQueriesBy(queryClient, [ReactQueryKey.TasksList]);
            }, 1000);
          }
        }
      );
    },
    [bulkUpdate, selected, clearSelection, queryClient]
  );

  const groups = useMemo<TaskGroup[]>(() => {
    const currentDate = moment();

    return [
      {
        title: 'Overdue',
        initialExpanded: true,
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().startOf('day').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().startOf('day').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().startOf('day').toDate()
          }
        }
      },
      {
        title: 'This week',
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().endOf('week').toDate(),
                greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().endOf('week').toDate(),
                greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().endOf('week').toDate(),
            greaterThanOrEqualTo: currentDate.clone().startOf('day').toDate()
          }
        }
      },
      {
        title: 'Next week',
        filter: {
          isCompleted: { equalTo: false },
          or: [
            {
              endDate: {
                lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
                greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
                greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            lessThan: currentDate.clone().endOf('week').add(1, 'week').toDate(),
            greaterThan: currentDate.clone().startOf('week').add(1, 'week').toDate()
          }
        }
      },
      {
        title: 'Later',
        isCompleted: { equalTo: false },
        filter: {
          or: [
            {
              endDate: {
                greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
              }
            },
            {
              endDate: { isNull: true },
              startDate: {
                greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
              }
            }
          ]
        },
        remindersFilter: {
          dueDate: {
            greaterThan: currentDate.clone().endOf('week').add(1, 'week').toDate()
          }
        }
      },
      {
        title: 'Unscheduled',
        filter: {
          isCompleted: { equalTo: false },
          endDate: { isNull: true },
          startDate: { isNull: true }
        }
      },
      {
        title: 'Completed',
        filter: {
          isCompleted: { equalTo: true }
        }
      }
    ];
  }, []);

  return (
    <Container>
      <Header>
        <HeaderLeft>
          <SortBy />
        </HeaderLeft>
        <ViewSwitcher />
      </Header>

      <GroupList>
        {groups.map((group) => (
          <Group
            key={group.title}
            title={group.title}
            filter={group.filter}
            remindersFilter={group.remindersFilter}
            checkIsSelected={checkIsSelected}
            onToggleSelect={toggle}
            initialExpanded={group.initialExpanded}
          />
        ))}
      </GroupList>

      {selectedCount > 0 && (
        <BulkPanel
          countBulk={selectedCount}
          onSubmit={handleBulkSubmit}
          onCancel={clearSelection}
          isTask
          companyId={companyId}
        />
      )}
    </Container>
  );
};
