import React, { useState } from 'react';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { useClientFilterState } from '../../useClientFilterState';
import { List, Menu, MenuItem, Trigger, TriggerLabel, TriggerValue } from './styled';

export const ShowMonitored = () => {
  const [isOpen, setIsOpen] = useState(false);

  const {
    clientFilters: { monitored },
    updateFilters
  } = useClientFilterState();

  return (
    <Popover
      content={
        <Menu>
          <List>
            <MenuItem onClick={() => updateFilters({ monitored: null })}>All</MenuItem>
            <MenuItem onClick={() => updateFilters({ monitored: true })}>Only monitored</MenuItem>
            <MenuItem onClick={() => updateFilters({ monitored: false })}>Only not monitored</MenuItem>
          </List>
        </Menu>
      }
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Monitored:</TriggerLabel>
        <TriggerValue>{monitored == null && 'All' || (monitored && 'Yes' || 'No')}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
