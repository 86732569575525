import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { WorflowApiRequest } from '@services/api/types';
import workflowsApi from '@services/api/workflowsApi';
import { selectWorkspaceId } from '@state/selectors';
import { AutomationFromApi } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useToast } from '..';

export const useCreateAutomation = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<AutomationFromApi, Error, WorflowApiRequest>(
    async (req) => {
      try {
        const { data: workflow } = await workflowsApi.create(req, { companyId });

        return workflow as AutomationFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: (result) => {
        showSuccess('Automation created successfully');
        queryClient.setQueryData<AutomationFromApi[] | undefined>([ReactQueryKey.ProjectWorkflows], (old) => {
          if (!old) {
            return old;
          }

          return [result, ...old];
        });
      }
    }
  );
};
