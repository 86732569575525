import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  background-color: #e8ecef;
  border-radius: 8px;
  display: flex;
  align-items: center;

  > * {
    font-size: 12px;
    color: #778ca2;
    padding: 3px 16px;
    cursor: pointer;
    text-decoration: none;
  }
`;

export const Selected = styled.div`
  && {
    border-radius: 8px;
    background-color: ${colors.black};
    color: ${colors.white};
  }
`;
