import {
  IntegrationProvider,
  IntegrationSystemLogFilter,
  IntegrationSystemLogsConnection,
  SystemBudget
} from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { DeepPartial } from 'redux';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useIntegrations } from './integrations/useIntegrations';

export const useSystemsBudget = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: integrations } = useIntegrations();

  const integrationsById = useMemo(() => {
    if (!integrations) {
      return {};
    }

    return integrations.reduce(
      (acc, integration) => {
        acc[integration.id] = integration.provider;

        return acc;
      },
      {} as Record<number, IntegrationProvider>
    );
  }, [integrations]);

  const filter: DeepPartial<IntegrationSystemLogFilter> = {
    createdAt: {
      greaterThanOrEqualTo: DateTime.now().startOf('month'),
      lessThanOrEqualTo: DateTime.now().endOf('month')
    },
    integration: {
      companyId: {
        equalTo: companyId
      }
    }
  };

  return useQuery(
    [ReactQueryKey.SystemsSettings, ReactQueryKey.SystemsBudget, { companyId, filter, integrationsById }],
    async () => {
      try {
        const { systemBudgets, integrationSystemLogsConnection } = await postGraphql<{
          systemBudgets: SystemBudget[];
          integrationSystemLogsConnection: IntegrationSystemLogsConnection;
        }>(
          gql`
            query SYSTEMS_BUDGET_QUERY($companyId: Int!, $filter: IntegrationSystemLogFilter!) {
              systemBudgets(filter: { companyId: { equalTo: $companyId } }) {
                monthlyBudget
                overbudgetBehaviour
                whitelistProfiles {
                  id
                  name
                }
              }
              integrationSystemLogsConnection(filter: $filter) {
                groupedAggregates(groupBy: INTEGRATION_ID) {
                  keys
                  distinctCount {
                    id
                  }
                }
              }
            }
          `,
          { companyId, filter }
        );

        const currentHitsByProvider = integrationSystemLogsConnection.groupedAggregates.reduce(
          (acc, { keys, distinctCount }) => {
            const [integrationId] = keys;
            const provider = integrationsById[integrationId];
            acc[provider] = parseInt(distinctCount.id, 10);

            return acc;
          },
          {} as Record<IntegrationProvider, number>
        );

        return {
          budget: systemBudgets[0],
          currentHitsByProvider
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching systems budget', e);
      }
    },
    { enabled: integrations?.length > 0 }
  );
};
