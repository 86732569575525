import { Position } from 'geojson';
import { GeofencingSettings } from '@features/Platform/GeolocationSettings/types';

// Berkeley City Hall
export const mapCenter: Position = [-122.27088489585402, 37.86959720180845];

export const defaultGeofencing: Required<GeofencingSettings> = {
  enabled: true,
  radius: 600,
  auto: true,
  preventClockInWithoutGeofence: false
};
