import styled from 'styled-components';

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;

  b {
    font-weight: 500;
  }
`;
