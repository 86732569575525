import { Integration } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';

export const useIntegrations = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<Integration[]>([ReactQueryKey.Integration, 'useIntegrations', companyId], async () => {
    try {
      return (
        await postGraphql<{ integrations: Integration[] }>(
          gql`
            query INTEGRATION_LIST_QUERY($companyId: Int!) {
              integrations(condition: { companyId: $companyId }) {
                id
                companyId
                provider
                active
              }
            }
          `,
          { companyId }
        )
      ).integrations;
    } catch (e) {
      throw apiErrorHandler('Error fetching integration', e);
    }
  });
};
