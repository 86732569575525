import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  min-height: 100px;
`;

const Form = ({ className, children }) => {
  return <Wrapper className={className}>{children}</Wrapper>;
};

export default Form;
