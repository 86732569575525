import { Label as TLabel } from '@generated/types/graphql';
import { FormValidationRules, useForm, Form, InputField, SelectField } from '@kit/components/Form';
import React, { useCallback, useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useCreateLabel } from '@hooks/workspace/labels/useCreateLabel';
import { useUpdateLabel } from '@hooks/workspace/labels/useUpdateLabel';
import { Label, LABEL_COLORS, LabelColor } from '@domains/labels';
import { useDeleteLabel } from '@hooks/workspace/labels/useDeleteLabel';
import { ModalBody, ModalFooter, useConfirmDeleteModal } from '@common/PromiseModal';

type FormValues = {
  label: string;
  color: LabelColor;
};
interface Props {
  initialValues?: TLabel;
  onClose: () => void;
}

const mapInitialValuesToFormValues = (initialValues: TLabel): FormValues => ({
  label: initialValues.label,
  color: LABEL_COLORS.find((color) => color.color === initialValues.color) ?? null
});

export const LabelForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;

  const { mutateAsync: create } = useCreateLabel();
  const { mutateAsync: update } = useUpdateLabel();
  const { mutateAsync: remove } = useDeleteLabel();
  const confirmDelete = useConfirmDeleteModal();

  const postForm = async ({ label, color }: FormValues) => {
    const dto = {
      label,
      color: color.color
    };

    if (isNew) {
      await create(dto);
    } else {
      await update({
        id: initialValues.id,
        dto
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues ? mapInitialValuesToFormValues(initialValues) : { color: LABEL_COLORS[0] }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      label: {
        isRequired: true
      },
      color: {
        isRequired: true
      }
    }),
    []
  );

  const handleRemove = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this Label?')) {
      await remove(initialValues.id);
      onClose();
    }
  }, [confirmDelete, remove, initialValues, onClose]);

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField label="Label" name="label" control={control} />
        <SelectField
          label="Color"
          name="color"
          renderOption={(option: LabelColor) => <Label label={{ label: option.name, color: option.color }} />}
          options={LABEL_COLORS}
          control={control}
          isClearable={false}
        />
      </ModalBody>
      <ModalFooter>
        {!isNew && (
          <Button variant={ButtonVariant.Danger} onClick={handleRemove}>
            Delete
          </Button>
        )}
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </ModalFooter>
    </Form>
  );
};
