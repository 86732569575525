import { EmailAccountStatus } from '@generated/types/graphql';
import { Badge } from '@kit/ui/Badge';
import { Tooltip } from '@material-ui/core';
import React from 'react';

const STATUS_CONFIG_MAP: Record<EmailAccountStatus, { name: string; color: string; tooltip?: string }> = {
  [EmailAccountStatus.Invalid]: {
    name: 'Connection failed',
    color: '#D54855',
    tooltip: 'Authentication with your email provider is no longer valid. Please re-authenticate your email account.'
  },
  [EmailAccountStatus.Synced]: {
    name: 'Connected',
    color: '#009688'
  },
  [EmailAccountStatus.Syncing]: {
    name: 'Syncing',
    color: '#4D7CFE'
  }
};

export const Status = ({ status }: { status: EmailAccountStatus }) => {
  const { name, color, tooltip } = STATUS_CONFIG_MAP[status];

  if (tooltip) {
    return (
      <Tooltip title={tooltip} placement="top">
        <Badge color={color}>{name}</Badge>
      </Tooltip>
    );
  }

  return <Badge color={color}>{name}</Badge>;
};
