import { createStyles, makeStyles, Popover, Theme } from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { Container } from './styled';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popover: {
      pointerEvents: 'none',
      padding: theme.spacing(0),
      boxShadow: 'unset'
    },
    paper: {
      padding: theme.spacing(0),
      boxShadow: 'unset'
    }
  })
);

type Props = {
  popover: React.ReactNode;
}

export const MapPopover = ({ children, popover }: React.PropsWithChildren<Props>) => {
  const childrenRef = useRef();

  const [open, setOpen] = useState(false);

  const classes = useStyles();

  return (
    <Container>
      <div
        ref={childrenRef}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {children}
      </div>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={childrenRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={() => setOpen(false)}
        disableRestoreFocus
        keepMounted
      >
        {popover}
      </Popover>
    </Container>
  );
};