import { ProjectAdapted } from '@types';
import { toNumber } from 'lodash';
import { useState, useCallback, useMemo } from 'react';

interface Group {
  label: string;
}

export const useSelectionWithGroups = (groups: Group[]) => {
  const [selection, setSelection] = useState<Record<string, Record<number, ProjectAdapted>>>(
    {} as Record<string, Record<number, ProjectAdapted>>
  );

  const [dataByGroup, setDataByGroup] = useState<Record<string, ProjectAdapted[]>>(
    {} as Record<string, ProjectAdapted[]>
  );

  const toggleItem = useCallback((group: string, value: ProjectAdapted) => {
    setSelection((previous) => {
      const copy = { ...previous };

      if (copy[group] && copy[group][value.projectId]) {
        delete copy[group][value.projectId];
      } else {
        copy[group] = {
          ...copy[group],
          [value.projectId]: value
        };
      }

      return copy;
    });
  }, []);

  const isAllInGroupSelected = useCallback(
    (group: string) => {
      const availableIdsLength = dataByGroup[group]?.length || 0;

      return availableIdsLength > 0 && availableIdsLength === Object.keys(selection[group] || {}).length;
    },
    [selection, dataByGroup]
  );

  const isAllSelected = useMemo(() => {
    return groups.every((group) => isAllInGroupSelected(group.label));
  }, [groups, isAllInGroupSelected]);

  const toggleGroup = useCallback(
    (group: string) => {
      setSelection((previous) => {
        const copy = { ...previous };

        if (isAllInGroupSelected(group)) {
          delete copy[group];
        } else {
          const data = dataByGroup[group] || [];
          copy[group] = data.reduce((acc, item) => {
            acc[item.projectId] = item;

            return acc;
          }, {} as Record<number, ProjectAdapted>);
        }

        return copy;
      });
    },
    [isAllInGroupSelected, dataByGroup]
  );

  const setDataForGroup = useCallback((group: string, data: ProjectAdapted[]) => {
    setDataByGroup((previous) => ({
      ...previous,
      [group]: data
    }));
  }, []);

  const isItemSelected = useCallback(
    (group: string, value: ProjectAdapted) => {
      return Boolean(selection[group]?.[value.projectId]);
    },
    [selection]
  );

  const toggleAllSelected = useCallback(() => {
    if (isAllSelected) {
      setSelection({});
    } else {
      setSelection(
        groups.reduce((acc, group) => {
          const data = dataByGroup[group.label] || [];

          acc[group.label] = data.reduce((acc, item) => {
            acc[item.projectId] = item;

            return acc;
          }, {} as Record<number, ProjectAdapted>);

          return acc;
        }, {} as Record<string, Record<number, ProjectAdapted>>)
      );
    }
  }, [groups, isAllSelected, dataByGroup]);

  const clearSelection = useCallback(() => {
    setSelection({});
  }, []);

  const selectedIds = useMemo(() => {
    return Object.keys(selection).reduce((acc, group) => {
      const ids = Object.keys(selection[group]).map(toNumber);

      return [...acc, ...ids];
    }, [] as number[]);
  }, [selection]);

  const isAllSelectedItemsArchived = useMemo(() => {
    return Object.keys(selection).every((group) => {
      return Object.keys(selection[group]).every((id) => {
        return selection[group][toNumber(id)].projectDetail.isActive === false;
      });
    });
  }, [selection]);

  return {
    selectedIds,
    toggleItem,
    toggleGroup,
    isItemSelected,
    isAllSelected,
    toggleAllSelected,
    setDataForGroup,
    isAllInGroupSelected,
    clearSelection,
    isAllSelectedItemsArchived
  };
};
