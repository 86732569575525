import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { ModalFooter } from '@common/PromiseModal';
import { TabItem, Tabs } from '@kit/ui/Tabs';
import {
  Form,
  FormValidationRules,
  InlineEditableInputField,
  InlineEditableTextareaField,
  RichTextField,
  useForm
} from '@kit/components/Form';
import { Edit2 } from 'react-feather';
import { CopyButton } from '@common/ui';
import { SocialMailIcon } from '@kit/ui/icons/social/Mail';
import { SocialGmailIcon } from '@kit/ui/icons/social/Gmail';
import { SocialOutlookIcon } from '@kit/ui/icons/social/Outlook';
import { SocialFacebookIcon } from '@kit/ui/icons/social/Facebook';
import { clearQuillValue, convertQuillFormatToText, convertTextToQuillFormat, isQuilValueEmpty } from '@utils/quill';
import { Input } from '@kit/ui/Input';
import { SocialSmsIcon } from '@kit/ui/icons/social/Sms';
import { SocialWhatsappIcon } from '@kit/ui/icons/social/Whatsapp';
import { PromptImage } from './PromptImage';
import { config } from '../../../../config';
import { DEFAULT_SETTINGS } from './constants';
import {
  Body,
  Description,
  MessageAndPreview,
  FieldLabel,
  MessagePreview,
  Prompt,
  TitleInputWrapper,
  TitleView,
  DescriptionWrapper,
  DescriptionView,
  EditIcon,
  ReferralLink,
  ShareButtons,
  ShareButton,
  FieldsRow
} from './styled';
import { ReferralSettings } from './types';
import { useSocialShareLinks } from './useSocialShareLinks';
import { FakeFormField } from './FakeFormField';

interface Props {
  settings: ReferralSettings;
  onClose: () => void;
}

enum ReferralTab {
  PromptAndImage = 'prompt',
  Message = 'message'
}

const TABS: TabItem[] = [
  {
    id: ReferralTab.PromptAndImage,
    title: 'Referral prompt'
  },
  {
    id: ReferralTab.Message,
    title: 'Shareable message'
  }
];

type FormValues = {
  promptTitle: string;
  promptDescription: string;
  shareableMessage: string;
};

const getTokens = () => [
  {
    id: 'companyName',
    value: 'Company name'
  },
  {
    id: 'referralLink',
    value: 'Referral link'
  }
];

const SHAREABLE_MESSAGE_MAX_LENGTH = 300;

export const EditForm = ({ settings: initialSettings, onClose }: Props) => {
  const { mutateAsync: updateCompany } = useUpdateCompany();

  const companyId = useAppSelector(selectWorkspaceId);

  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalNewRequestUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}/new?r=1ab2c3`;

  const [settings, setSettings] = useState<ReferralSettings>(initialSettings);

  const handleImageChange = useCallback((imageUrl: string | null) => {
    setSettings((prev) => ({
      ...prev,
      promptImageUrl: imageUrl
    }));
  }, []);

  const [activeTab, setActiveTab] = useState(TABS[0]);

  const postForm = async (values: FormValues) => {
    const payload = {
      promptTitle: values.promptTitle || DEFAULT_SETTINGS.promptTitle,
      promptDescription: values.promptDescription || DEFAULT_SETTINGS.promptDescription,
      shareableMessage: values.shareableMessage || DEFAULT_SETTINGS.shareableMessage,
      promptImageUrl: settings.promptImageUrl || null,
      enabled: true
    };

    await updateCompany({
      settings: {
        referral: payload
      }
    });

    onClose();
  };

  const { handleSubmit, form } = useForm({
    onSubmit: postForm,
    defaultValues: {
      promptTitle: settings.promptTitle,
      promptDescription: settings.promptDescription,
      shareableMessage: convertTextToQuillFormat(settings.shareableMessage)
    }
  });

  const {
    control,
    watch,
    formState: { isSubmitting }
  } = form;

  const message = watch('shareableMessage');

  const makeMessagePreview = useCallback(
    (value: string) => {
      return clearQuillValue(convertQuillFormatToText(value))
        .replaceAll(/\[Company name\|~property:companyName\]/g, company.name)
        .replaceAll(/\[Referral link\|~property:referralLink\]/g, portalNewRequestUrl);
    },
    [company.name, portalNewRequestUrl]
  );

  const messagePreview = useMemo(() => makeMessagePreview(message), [message, makeMessagePreview]);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      promptTitle: {
        isRequired: true
      },
      promptDescription: {
        isRequired: true
      },
      shareableMessage: {
        isRequired: true,
        validate: (value) => {
          if (isQuilValueEmpty(value)) {
            return 'Shareable message is required';
          }

          const finalMessage = makeMessagePreview(value);
          if (finalMessage.length > SHAREABLE_MESSAGE_MAX_LENGTH) {
            return `Shareable message is too long (${finalMessage.length}/${SHAREABLE_MESSAGE_MAX_LENGTH} characters)`;
          }

          return undefined;
        }
      }
    }),
    [makeMessagePreview]
  );

  const socialLinks = useSocialShareLinks({ message: messagePreview, url: portalNewRequestUrl });

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <Tabs variant="outline" selected={activeTab.id} tabs={TABS} onChange={setActiveTab} />

        {activeTab.id === ReferralTab.PromptAndImage && (
          <div>
            <Description>
              Configure your own message & visuals to encourage referrals. Appears in the “Refer & Earn” modal window in
              a Client Portal.
              <br />
              <br />
              Click on a pen icon to edit content. You can also upload your image or use the default one.
            </Description>

            <Prompt>
              <div>
                <TitleInputWrapper>
                  <InlineEditableInputField
                    name="promptTitle"
                    control={control}
                    clearOnUnmount={false}
                    placeholder="Refer & Earn"
                    renderView={(value, onClick) => (
                      <TitleView isPlaceholder={!value} onClick={onClick}>
                        <div>{value || 'Refer & Earn'}</div>

                        <EditIcon>
                          <Edit2 size="16px" />
                        </EditIcon>
                      </TitleView>
                    )}
                  />
                </TitleInputWrapper>

                <DescriptionWrapper>
                  <InlineEditableTextareaField
                    placeholder={DEFAULT_SETTINGS.promptDescription}
                    name="promptDescription"
                    control={control}
                    clearOnUnmount={false}
                    renderView={(value, onClick) => (
                      <DescriptionView onClick={onClick} isPlaceholder={!value}>
                        {value || DEFAULT_SETTINGS.promptDescription}
                        <EditIcon>
                          <Edit2 size="16px" />
                        </EditIcon>
                      </DescriptionView>
                    )}
                  />
                </DescriptionWrapper>

                <FakeFormField isRequired label="Referral name">
                  <Input placeholder="Enter referral name here" />
                </FakeFormField>

                <FieldsRow>
                  <FakeFormField isRequired label="Referral phone">
                    <Input placeholder="+_ (___) ___ __ __" />
                  </FakeFormField>

                  <FakeFormField label="Referral email">
                    <Input placeholder="name@mail.com" />
                  </FakeFormField>
                </FieldsRow>

                <Button size={ButtonSize.Large} isBlock disabled variant={ButtonVariant.Primary}>
                  Submit referral
                </Button>
              </div>
              <div>
                <PromptImage imageUrl={settings.promptImageUrl} onChange={handleImageChange} />

                <div>
                  <FieldLabel>Share your link:</FieldLabel>
                  <ReferralLink>
                    <div>{portalNewRequestUrl}</div>
                    <CopyButton value={portalNewRequestUrl} />
                  </ReferralLink>
                </div>

                <ShareButtons>
                  <ShareButton href={socialLinks.sms} target="_blank">
                    <SocialSmsIcon />
                  </ShareButton>
                  <ShareButton href={socialLinks.mail} target="_blank">
                    <SocialMailIcon />
                  </ShareButton>
                  <ShareButton href={socialLinks.gmail} target="_blank">
                    <SocialGmailIcon />
                  </ShareButton>
                  <ShareButton href={socialLinks.outlook} target="_blank">
                    <SocialOutlookIcon />
                  </ShareButton>
                  <ShareButton href={socialLinks.whatsapp} target="_blank">
                    <SocialWhatsappIcon />
                  </ShareButton>
                  <ShareButton href={socialLinks.facebook} target="_blank">
                    <SocialFacebookIcon />
                  </ShareButton>
                </ShareButtons>
              </div>
            </Prompt>
          </div>
        )}

        {activeTab.id === ReferralTab.Message && (
          <div>
            <Description>Configure default message that your client can edit before sharing with friends.</Description>

            <MessageAndPreview>
              <div>
                <FieldLabel>Shareable message (max {SHAREABLE_MESSAGE_MAX_LENGTH} characters)</FieldLabel>
                <RichTextField
                  isToolbarHiddenCompletely
                  name="shareableMessage"
                  control={control}
                  getTokens={getTokens}
                  clearOnUnmount={false}
                />
              </div>
              <div>
                <FieldLabel>Message preview</FieldLabel>

                <MessagePreview>
                  <div dangerouslySetInnerHTML={{ __html: messagePreview }} />
                </MessagePreview>
              </div>
            </MessageAndPreview>
          </div>
        )}
      </Body>
      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Update
        </Button>
      </ModalFooter>
    </Form>
  );
};
