import { useMutation } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { errorHandler } from '@services/api/helpers';
import { api as companyApi } from '@services/api/companies';
import { fetchCompanies } from '@state/actions/companies';
import { UpdateCompanyRequest } from '@services/api/types';
import { useAppDispatch, useAppSelector } from './store';
import { useToast } from './useToast';

export const useUpdateCompany = () => {
  const { showSuccess } = useToast();
  const dispatch = useAppDispatch();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, Omit<UpdateCompanyRequest, 'id'>>(
    async (req) => {
      try {
        await companyApi.updateCompany({ id: companyId, ...req });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Company updated successfully');
        dispatch(fetchCompanies());
      }
    }
  );
};
