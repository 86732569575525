import styled from 'styled-components';
import { Checkbox } from '@material-ui/core';
import { colors } from '@styles';

export const Table = styled.table`
  position: sticky;
  top: 32px;
  z-index: 3;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #f8fafb;
  width: auto;

  th {
    white-space: normal;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 40px;
    text-align: left;
    padding-left: 16px;
    font-size: 12px;
    font-weight: 500;
    color: #828d9a;
    text-transform: uppercase;
  }
`;

export const TitleCell = styled.th`
  &&& {
    width: 318px;
    min-width: 318px;
    max-width: 318px;
    position: sticky;
    left: 0px;
    background-color: #f8fafb;
    padding-left: 8px;

    display: flex;
    align-items: center;
    gap: 16px;
  }
`;

export const BulkCheckbox = styled(Checkbox)`
  && {
    margin-left: 7px;
    margin-right: 12px;

    .MuiSvgIcon-fontSizeSmall {
      font-size: 26px;
    }
  }
`;

export const SelectionControlPanel = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  svg {
    cursor: pointer;
  }
`;

export const SelectedLabel = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: ${colors.black};
  text-transform: none;
`;

export const SelectionAndPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #f8fafb;
`;

export const RightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

export const MapSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const PerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;

  &&& {
    .MuiAutocomplete-inputRoot {
      padding: 0;

      .MuiAutocomplete-input {
        min-width: 50px;
      }
    }
  }
`;
