import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import * as Yup from 'yup';
import { BoxRow, BoxSubmit, InputField, Form, AvatarField } from '@components/common';
import { Formik } from 'formik';
import { fetchProfile, updateProfile } from '@services/SettingServices/accountSettingService';
import { LinearProgress } from '@material-ui/core';
import { AccountSettingType } from '@state/reducers/settings/accountReducer';
import { buttonType } from '@constants/ButtonType';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { Container, Body, Footer } from '../../common';

const Account = (props: Props) => {
  const { fetchProfile, userData, updateProfile } = props;

  const companyId = useAppSelector(selectWorkspaceId);

  useEffect(() => {
    fetchProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (values: AccountSettingType, { setSubmitting }) => {
    // make copy of it
    const payload = { ...values };

    updateProfile(payload, setSubmitting);
  };

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required!'),
    lastName: Yup.string().required('Last name is required!'),
    email: Yup.string().email().required('Email address is required!'),
    phone: Yup.string().required('Phone number is required!')
  });

  return (
    <Container>
      {!userData.isLoading && (
        <Formik initialValues={userData} onSubmit={onFormSubmit} validationSchema={validationSchema}>
          {({ isSubmitting }) => (
            <Form>
              {isSubmitting && <LinearProgress />}
              <Body>
                <BoxRow>
                  <AvatarField label="Profile picture" name="avatarUrl" companyId={companyId} />
                </BoxRow>
                <BoxRow half>
                  <InputField label="First Name" name="firstName" validate />
                  <InputField label="Last Name" name="lastName" validate />
                </BoxRow>
                <BoxRow half>
                  <InputField label="Email" name="email" validate />
                  <InputField label="Phone number" name="phone" validate />
                </BoxRow>
              </Body>
              <Footer>
                <BoxSubmit
                  disabled={isSubmitting}
                  primaryBtnText="SAVE"
                  secondaryBtnText="RESET"
                  secondaryBtnType={buttonType.RESET}
                />
              </Footer>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => {
  return {
    userData: state.accountSetting
  };
};

const mapDispatchToprops = (dispatch: any) => {
  return {
    fetchProfile: () => dispatch(fetchProfile()),
    updateProfile: (user: AccountSettingType, setSubmitting: (isSubmitting: boolean) => void) =>
      dispatch(updateProfile(user, setSubmitting))
  };
};

const connector = connect(mapStateToProps, mapDispatchToprops);
type Props = ConnectedProps<typeof connector>;

export default connector(Account);
