import React from 'react';
import { Control } from 'react-hook-form';
import { InputField } from '@kit/components/Form';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { useDeepCompareMemo } from '@react-hookz/web';
import { Integration, IntegrationProvider } from '@generated/types/graphql';
import { FormValues } from '../types';
import {
  ContentContainer,
  Title,
  TwoFields,
  EquationAndChart,
  Equation,
  PercentInput,
  PercentSymbol,
  ErrorMessage
} from './styled';
import { FleetsPie } from '../../SystemsOverview';
import { Panel, PanelHeader, PanelTitle, PanelDescription } from '../../styled';
import { FLEET_COLORS, PROVIDER_CONFIGS_MAP } from '../../constants';

interface Props {
  control: Control<FormValues>;
  totalMonitoredSystems: number;
  fleets: Fleet[];
  systemsRatioByFleetId: Record<string, number>;
  systemsRatioByProvider: Record<IntegrationProvider, number>;
  integrations: Integration[];
}

export const SystemsSimulation = ({
  integrations,
  totalMonitoredSystems,
  control,
  fleets,
  systemsRatioByFleetId,
  systemsRatioByProvider
}: Props) => {
  const byFleetPoints = useDeepCompareMemo(() => {
    if (!systemsRatioByFleetId || !fleets) {
      return [];
    }

    return fleets.map((fleet, index) => ({
      name: fleet.name,
      amount: Math.round((totalMonitoredSystems * systemsRatioByFleetId[`fleet-${fleet.id}`]) / 100),
      color: FLEET_COLORS[index % FLEET_COLORS.length]
    }));
  }, [fleets, systemsRatioByFleetId, totalMonitoredSystems]);

  const byProviderPoints = useDeepCompareMemo(() => {
    if (!systemsRatioByProvider || !integrations) {
      return [];
    }

    return integrations.map((integration) => ({
      name: PROVIDER_CONFIGS_MAP[integration.provider].name,
      amount: Math.round((totalMonitoredSystems * systemsRatioByProvider[integration.provider]) / 100),
      color: PROVIDER_CONFIGS_MAP[integration.provider].color
    }));
  }, [integrations, systemsRatioByProvider, totalMonitoredSystems]);

  const totalByFleetRatios = Object.values(systemsRatioByFleetId).reduce(
    (acc, ratio) => acc + parseFloat(ratio || 0),
    0
  );
  const totalByProviderRatios = Object.values(systemsRatioByProvider).reduce(
    (acc, ratio) => acc + parseFloat(ratio || 0),
    0
  );

  return (
    <Panel>
      <PanelHeader>
        <div>
          <PanelTitle>Systems simulation</PanelTitle>
          <PanelDescription>
            Check the number of monitored and total systems, the average new systems added per month. This helps you
            track and manage system performance effectively.
          </PanelDescription>
        </div>
      </PanelHeader>

      <ContentContainer>
        <div>
          <Title>Monitored Systems</Title>

          <TwoFields>
            <InputField control={control} name="monitoredSystems" label="Total monitored Systems" />
            <InputField control={control} name="averageMonthlyNew" label="Average new Systems, per month" />
          </TwoFields>
        </div>
        <div>
          <Title>Fleet division ratio</Title>

          <EquationAndChart>
            <div>
              <Equation>
                <div>100% =</div>
                {fleets.map((fleet, index) => (
                  <>
                    <PercentInput>
                      <InputField
                        key={fleet.id}
                        control={control}
                        name={`systemsRatioByFleetId.fleet-${fleet.id}`}
                        label={fleet.name}
                        type="number"
                        disabled={fleets.length === 1}
                      />
                      <PercentSymbol>%</PercentSymbol>
                    </PercentInput>
                    {index < fleets.length - 1 && <div>+</div>}
                  </>
                ))}
              </Equation>

              {totalByFleetRatios !== 100 && (
                <ErrorMessage>
                  The sum of all ratios should be 100%. Current sum is {totalByFleetRatios.toFixed(2)}%
                </ErrorMessage>
              )}
            </div>
            <FleetsPie total={totalMonitoredSystems} points={byFleetPoints} />
          </EquationAndChart>
        </div>

        <div>
          <Title>Source division ratio</Title>

          <EquationAndChart>
            <div>
              <Equation>
                <div>100% =</div>
                {integrations.map((integration, index) => (
                  <>
                    <PercentInput>
                      <InputField
                        key={integration.id}
                        control={control}
                        name={`systemsRatioByProvider.${integration.provider}`}
                        label={PROVIDER_CONFIGS_MAP[integration.provider].name}
                        type="number"
                        disabled={integrations.length === 1}
                      />
                      <PercentSymbol>%</PercentSymbol>
                    </PercentInput>
                    {index < integrations.length - 1 && <div>+</div>}
                  </>
                ))}
              </Equation>

              {totalByProviderRatios !== 100 && (
                <ErrorMessage>
                  The sum of all ratios should be 100%. Current sum is {totalByProviderRatios.toFixed(2)}%
                </ErrorMessage>
              )}
            </div>
            <FleetsPie total={totalMonitoredSystems} points={byProviderPoints} />
          </EquationAndChart>
        </div>
      </ContentContainer>
    </Panel>
  );
};
