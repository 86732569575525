import { colors } from '@styles';
import React from 'react';

import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { RecordType } from '@types';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { Container, Header, LabelCircle, Title, ProjectTitle } from './styled';

export const MonthEvent = ({ data }: any) => {
  const order = data.appointmentData;

  const isReminder = 'dueDate' in order;
  const ReminderIc = REMINDER_TYPE_TO_ICON[order.type];

  const isAppointment = isReminder && order.isAppointment;

  const isMultiDay =
    order.startDate &&
    order.endDate &&
    new Date(order.startDate).toDateString() !== new Date(order.endDate).toDateString();

  return (
    <Container isMultiDay={isMultiDay} color={order.isColored ? order.firstLabel?.color : null}>
      <Header>
        {!isReminder && !order.isColored && <LabelCircle color={order.firstLabel?.color} />}
        {!isReminder && <WorkOrderTypeIcon isField={order.isField} />}
        {isReminder && !isAppointment && !!ReminderIc && <ReminderIc color={colors.green} size="16px" />}
        {isAppointment && <CalendarIcon color={colors.green} size="16px" />}
        <Title isCompleted={order.isCompleted}>{order.title}</Title>
      </Header>
      <ProjectTitle>
        {[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}
      </ProjectTitle>
    </Container>
  );
};
