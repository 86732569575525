import { createStateContext } from 'react-use';
import { MainUser } from '@generated/types/graphql';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';

const [useSelectedWorkerState, SelectedWorkerProvider] = createStateContext<MainUser | null>(null);

const useSelectedWorker: typeof useSelectedWorkerState = () => {
  const settings = useAppSelector(selectCompanySettings);

  const [worker, setWorker] = useSelectedWorkerState();

  return [settings?.geolocation?.enabled ? worker : null, setWorker];
};

export { useSelectedWorker, SelectedWorkerProvider };
