import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { ChartPeriod, chartPeriodConfig } from '@hooks/systems/constants';
import { DateTime } from 'luxon';
import { EnergyPoint } from '@hooks/systems/types';
import { UseQueryOptions } from 'react-query/types/react/types';
import fleetChartsAPI, { ChartDTO, Meter, SystemEnergy, TotalEnergy } from '@services/api/fleetChartsAPI';

export type ProductionChart = { total: number | null; points: EnergyPoint[] };

export const useSystemsProductionChart = (
  systemsIds: number[],
  period: ChartPeriod,
  startAt: DateTime,
  endBefore: DateTime,
  opts?: UseQueryOptions<ProductionChart>
) =>
  useQuery<ProductionChart>(
    [ReactQueryKey.System, 'useSystemsProductionChart', systemsIds, { period, startAt, endBefore }],
    async () => {
      try {
        const periodConfig = chartPeriodConfig[period];

        const chartsDto: ChartDTO[] = [
          {
            metric: 'energy',
            kind: 'total',
            meter: Meter.Production,
            period: periodConfig.totalGranularity.toISO(),
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO()
          },
          {
            metric: 'energy',
            kind: 'totalTime',
            meter: Meter.Production,
            period: periodConfig.pointGranularity.toISO(),
            startAt: startAt?.toISO(),
            endBefore: endBefore?.toISO()
          }
        ];

        const {
          data: [total, points]
        } = await fleetChartsAPI.getSystemsCharts({ systems: systemsIds, charts: chartsDto });

        return {
          total: (total as TotalEnergy).value || 0,
          points: (points as SystemEnergy[]).map((point) => ({
            time: DateTime.fromISO(point.time).toLocal(),
            production: point.value
          }))
        };
      } catch (e) {
        throw apiErrorHandler('Error fetching systems production', e);
      }
    },
    {
      initialData: { total: null, points: [] },
      ...opts
    }
  );
