import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { FormTemplate, useFormMutations, useFormTemplate } from '@hooks/useForms';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { Property, RecordType } from '@types';
import { v4 as uuid } from '@lukeed/uuid';
import { Form } from '@generated/types/graphql';
import { Tabs } from '@kit/ui/Tabs';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { XIcon } from '@kit/ui/icons/X';
import { parseUtcDate } from '@utils/dates';
import moment from 'moment';
import { Link } from 'gatsby';
import { useToast } from '@hooks/useToast';
import { Tooltip } from '@material-ui/core';
import { AlertTriangle, Globe } from 'react-feather';
import { globalHistory, useLocation, useNavigate } from '@reach/router';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { isEqual } from 'lodash';
import { useDebouncedMemo } from '@hooks/useDebouncedMemo';
import { useEventListener } from '@react-hookz/web';
import { ConfirmClosing, ConfirmClosingResult, useModal } from '@common/PromiseModal';
import { Preview } from './Preview';
import { FormName } from './FormName';
import { ProjectProperties } from './ProjectProperties';
import { ElementProperties } from './ElementProperties';
import {
  FORM_FIELD_TYPES,
  FORM_FIELD_TYPE_CONFIGS,
  FormBuilderElement,
  FormBuilderFieldElement,
  FormBuilderGroupElement,
  FormFieldType,
  FormLayoutType,
  LAYOUT_ITEMS,
  mapFormLayoutsToDndStructure,
  mapProjectPropertyToFormBuilderElementConfig,
  mapDndStructureToFormTemplateLayout,
  mapProjectPropertyToFormFieldType
} from './helpers';
import {
  Container,
  Header,
  Breadcrumbs,
  Main,
  Sidebar,
  WorkArea,
  SidebarItem,
  SidebarItemClone,
  ConfigAndPreview,
  WorkAreaItem,
  FormLayout,
  WorkAreaGroupItem,
  WorkAreaGroupItemHeader,
  WorkAreaGroupItemBody,
  SectionTitleDescription,
  FieldTypesSectionTitle,
  FormFieldsSectionTitle,
  ConfigAndPreviewHeader,
  ProjectPropertiesContainer,
  PlaceholderFieldName,
  GroupItemsPlaceholder,
  RemoveElementButton,
  HeaderRight,
  LastUpdated
} from './styled';
import { QueryParamsEnum, useQueryParam } from '../../../../hooks';

interface Props {
  formId: string;
  onSave?: (newForm?: Form) => void;
}

const SIDEBAR_DROPPABLE_ID = 'droppable-sidebar';
const WORK_AREA_DROPPABLE_ID = 'droppable-work-area';
const SIDEBAR_DROPPABLE_GROUP_ID = 'droppable-sidebar-group';
const SIDEBAR_DROPPABLE_PROPERTIES_ID = 'droppable-sidebar-properties';
const GROUP_DND_TYPE = 'GROUP';
const ITEM_DND_TYPE = 'ITEM';

const TABS = [
  {
    id: 'properties',
    title: 'Edit field'
  },
  {
    id: 'preview',
    title: 'Mobile preview'
  }
];

const useIsSubmitting = () => {
  const isSubmittingRef = useRef(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const setSubmitting = useCallback((value: boolean) => {
    isSubmittingRef.current = value;
    setIsSubmitting(value);
  }, []);

  return {
    isSubmitting: isSubmitting || isSubmittingRef.current,
    setSubmitting
  };
};

export const FormBuilder = ({ form, onSave }: { form: FormTemplate; onSave?: (newForm?: Form) => void }) => {
  const [formName, setFormName] = useState('');
  const [layout, setLayout] = useState<string[]>(null);
  const [configsById, setConfigsById] = useState<{ [key: string]: FormBuilderElement }>(null);
  const [selectedTab, setSelectedTab] = useState(TABS[0]);
  const [selectedElement, setSelectedElement] = useState<string | number>(null);

  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const currentLocation = useLocation();
  const { openModal } = useModal();

  const { showError } = useToast();

  const {
    create: { mutateAsync: create },
    update: { mutateAsync: update }
  } = useFormMutations();
  const { allProperties } = useCompanyProperties({ recordType: RecordType.PROJECT, fullAccess: true });

  const propertiesById = useMemo(() => {
    if (!allProperties) {
      return {};
    }

    return allProperties.reduce(
      (acc, property) => {
        acc[property.id as number] = property;

        return acc;
      },
      {} as Record<number, Property>
    );
  }, [allProperties]);

  const onTabChange = useCallback((tab: (typeof TABS)[number]) => {
    setSelectedTab(tab);
  }, []);

  const { isSubmitting, setSubmitting } = useIsSubmitting();

  useEffect(() => {
    if (layout) {
      return;
    }

    const result = mapFormLayoutsToDndStructure(form.formLayouts);

    setLayout(result.layout);
    setConfigsById(result.configsById);
    setFormName(form.name);
  }, [form, layout, configsById]);

  const validate = useCallback(() => {
    const errors: { [key: string]: string[] } = {};

    Object.values(configsById).forEach((element) => {
      if (element.type === FormLayoutType.GROUP) {
        return;
      }

      if (!element.config.name) {
        errors[element.id] = ['Title is required'];
      }

      if (element.type === FormFieldType.SingleSelect || element.type === FormFieldType.MultiSelect) {
        if (
          !element.config.options ||
          element.config.options.length === 0 ||
          !element.config.options.some((option) => option.value.trim())
        ) {
          errors[element.id] = [...(errors[element.id] ?? []), 'Options are required'];
        }
      }
    });

    return errors;
  }, [configsById]);

  const handleSave = useCallback(async () => {
    if (isSubmitting) {
      return;
    }

    setSubmitting(true);

    let newForm;
    try {
      const dto = {
        id: form.id ?? undefined,
        name: formName || 'New form template',
        isTemplate: true,
        layouts: mapDndStructureToFormTemplateLayout(layout, configsById, true)
      };

      const errors = validate();

      if (Object.keys(errors).length > 0) {
        setErrors(errors);
        showError('Please fix errors before saving');

        setSubmitting(false);

        return;
      }

      if (form.id) {
        await update({ formId: form.id, dto });
      } else {
        newForm = await create({ dto });
      }
    } finally {
      setSubmitting(false);
    }

    if (onSave) {
      onSave(newForm);
    } else {
      navigate(`../${newForm?.id ?? form.id}`);
    }
  }, [
    form,
    navigate,
    formName,
    layout,
    configsById,
    onSave,
    create,
    update,
    validate,
    showError,
    setSubmitting,
    isSubmitting
  ]);

  const isDirty = useDebouncedMemo(
    () => {
      if (!form || isSubmitting || !layout) {
        return false;
      }

      if (form.name !== formName) {
        return true;
      }

      const layouts = mapDndStructureToFormTemplateLayout(layout, configsById, true);

      const beforeSave = mapFormLayoutsToDndStructure(form.formLayouts);

      const layoutsBeforeSave = mapDndStructureToFormTemplateLayout(beforeSave.layout, beforeSave.configsById, true);

      return !isEqual(layouts, layoutsBeforeSave);
    },
    [form, formName, layout, configsById, isSubmitting],
    300
  );

  useEventListener(window, 'beforeunload', (e) => {
    if (isDirty) {
      e.preventDefault();
      e.returnValue = '';
    }
  });

  useEffect(() => {
    return globalHistory.listen(async ({ action, location }) => {
      if (!isDirty) {
        return;
      }

      if (
        (action === 'PUSH' &&
          !location.pathname.includes(currentLocation.pathname) &&
          !location.href.includes('confirmed')) ||
        action === 'POP'
      ) {
        openModal<ConfirmClosingResult | void>(
          ({ onClose }) => (
            <ConfirmClosing
              onClose={(result) => {
                if (result === ConfirmClosingResult.SaveChanges) {
                  handleSave().then(() => {
                    navigate(`${location.pathname}?confirmed`);
                  });
                }

                onClose(result);

                if (result === ConfirmClosingResult.LeaveAndDiscard) {
                  navigate(`${location.pathname}?confirmed`);
                }
              }}
            />
          ),
          { title: 'Unsaved changes' }
        );

        navigate(`${currentLocation.pathname}`, { replace: true });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  const usedProjectPropertyIds = useMemo<Set<number>>(() => {
    if (!configsById) {
      return new Set();
    }

    return new Set(
      Object.values(configsById)
        .filter((config) => 'projectColumnId' in config.config && config.config.projectColumnId)
        .map((config) => config.config.projectColumnId)
    );
  }, [configsById]);

  const handleElementChange = useCallback((id: string | number, config: FormBuilderElement['config']) => {
    setConfigsById((prevConfigsById) => ({
      ...prevConfigsById,
      [id]: {
        ...prevConfigsById[id],
        config
      }
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];

      return newErrors;
    });
  }, []);

  const handleRemoveFieldElement = useCallback(
    ({ parentId, childId }: { parentId: string | number; childId: string | number }) =>
      () => {
        setConfigsById((prevConfigsById) => {
          const newConfigsById = {
            ...prevConfigsById,
            [parentId]: {
              ...prevConfigsById[parentId],
              children: (prevConfigsById[parentId] as FormBuilderGroupElement).children.filter((id) => id !== childId)
            }
          };

          delete newConfigsById[childId];

          return newConfigsById;
        });
      },
    []
  );

  const handleRemoveGroupElement = useCallback(
    (id: string | number) => () => {
      setConfigsById((prevConfigsById) => {
        const newConfigsById = {
          ...prevConfigsById
        };

        const group = newConfigsById[id] as FormBuilderGroupElement;

        group.children.forEach((childId) => {
          delete newConfigsById[childId];
        });

        delete newConfigsById[id];

        return newConfigsById;
      });

      setLayout((prevLayout) => prevLayout.filter((layoutId) => layoutId.toString() !== id.toString()));
    },
    []
  );

  const onDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      const { source, destination, type } = result;

      if (!source || !destination) {
        return;
      }

      // moving group
      if (type === GROUP_DND_TYPE) {
        // moving groups withing work area
        if (source.droppableId === WORK_AREA_DROPPABLE_ID && destination.droppableId === WORK_AREA_DROPPABLE_ID) {
          setLayout((prevLayout) => {
            const newLayout = [...prevLayout];
            newLayout.splice(source.index, 1);
            newLayout.splice(destination.index, 0, result.draggableId);

            return newLayout;
          });

          return;
        }

        // new group from sidebar to work area
        if (destination.droppableId === WORK_AREA_DROPPABLE_ID) {
          const newItem: FormBuilderGroupElement = {
            id: uuid(),
            type: result.draggableId,
            config: {
              name: ''
            },
            children: []
          };

          setConfigsById((prevConfigsById) => ({
            ...prevConfigsById,
            [newItem.id]: newItem
          }));

          setLayout((prevLayout) => {
            const newLayout = [...prevLayout];
            newLayout.splice(destination.index, 0, newItem.id);

            return newLayout;
          });

          setSelectedElement(newItem.id);
        }

        return;
      }

      // moving items
      if (type === ITEM_DND_TYPE) {
        if (source.droppableId === SIDEBAR_DROPPABLE_ID) {
          const newItem: FormBuilderFieldElement = {
            id: uuid(),
            type: result.draggableId as FormFieldType,
            config: {
              name: '',
              isEditable: true
            }
          };

          const { index } = destination;

          setConfigsById((prevConfigsById) => {
            const destinationGroup = prevConfigsById[destination.droppableId] as FormBuilderGroupElement;

            return {
              ...prevConfigsById,
              [destination.droppableId]: {
                ...destinationGroup,
                children: [
                  ...destinationGroup.children.slice(0, index),
                  newItem.id,
                  ...destinationGroup.children.slice(index)
                ]
              },
              [newItem.id]: newItem
            };
          });

          setSelectedElement(newItem.id);
        } else if (source.droppableId === SIDEBAR_DROPPABLE_PROPERTIES_ID) {
          const property = propertiesById[+result.draggableId];
          if (!property) {
            return;
          }

          const newItem = {
            id: uuid(),
            type: mapProjectPropertyToFormFieldType(property),
            config: mapProjectPropertyToFormBuilderElementConfig(property)
          };

          const { index } = destination;

          setConfigsById((prevConfigsById) => {
            const destinationGroup = prevConfigsById[destination.droppableId] as FormBuilderGroupElement;

            return {
              ...prevConfigsById,
              [destination.droppableId]: {
                ...destinationGroup,
                children: [
                  ...destinationGroup.children.slice(0, index),
                  newItem.id,
                  ...destinationGroup.children.slice(index)
                ]
              },
              [newItem.id]: newItem
            };
          });
          setSelectedElement(newItem.id);
        } else if (source.droppableId === destination.droppableId) {
          // moving items within group
          setConfigsById((prevConfigsById) => {
            const destinationGroup = prevConfigsById[source.droppableId] as FormBuilderGroupElement;
            const newChildren = [...destinationGroup.children];
            newChildren.splice(source.index, 1);
            newChildren.splice(destination.index, 0, result.draggableId);

            return {
              ...prevConfigsById,
              [source.droppableId]: {
                ...destinationGroup,
                children: newChildren
              }
            };
          });
        } else {
          // moving items between groups
          setConfigsById((prevConfigsById) => {
            const destinationGroup = prevConfigsById[destination.droppableId] as FormBuilderGroupElement;
            const sourceGroup = prevConfigsById[source.droppableId] as FormBuilderGroupElement;

            return {
              ...prevConfigsById,
              [source.droppableId]: {
                ...sourceGroup,
                children: sourceGroup.children.filter((id) => id !== result.draggableId)
              },
              [destination.droppableId]: {
                ...destinationGroup,
                children: [
                  ...destinationGroup.children.slice(0, destination.index),
                  result.draggableId,
                  ...destinationGroup.children.slice(destination.index)
                ]
              }
            };
          });
        }
      }
    },
    [propertiesById]
  );

  if (!layout || !configsById) {
    return null;
  }

  return (
    <>
      <Header>
        <FormName initialValue={formName} onChange={setFormName} />

        <HeaderRight>
          <LastUpdated>Last updated: {moment(parseUtcDate(form.updatedAt)).format('MM/DD/YYYY')}</LastUpdated>
          <Button disabled={!isDirty || isSubmitting} variant={ButtonVariant.Primary} onClick={handleSave}>
            Save
          </Button>
        </HeaderRight>
      </Header>
      <DragDropContext onDragEnd={onDragEnd}>
        <Main>
          <Sidebar>
            <FieldTypesSectionTitle>New form field</FieldTypesSectionTitle>
            <Droppable isDropDisabled droppableId={SIDEBAR_DROPPABLE_ID} type={ITEM_DND_TYPE}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {FORM_FIELD_TYPES.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <>
                          <SidebarItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                          >
                            {item.icon}
                            {item.label}
                          </SidebarItem>
                          {snapshot.isDragging && (
                            <SidebarItemClone>
                              {item.icon}
                              {item.label}
                            </SidebarItemClone>
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>
            <Droppable isDropDisabled droppableId={SIDEBAR_DROPPABLE_GROUP_ID} type={GROUP_DND_TYPE}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {LAYOUT_ITEMS.map((item, index) => (
                    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                      {(provided, snapshot) => (
                        <>
                          <SidebarItem
                            isDashed
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            style={provided.draggableProps.style}
                          >
                            {item.icon}
                            {item.label}
                          </SidebarItem>
                          {snapshot.isDragging && (
                            <SidebarItemClone isDashed>
                              {item.icon}
                              {item.label}
                            </SidebarItemClone>
                          )}
                        </>
                      )}
                    </Draggable>
                  ))}
                </div>
              )}
            </Droppable>

            <Droppable isDropDisabled droppableId={SIDEBAR_DROPPABLE_PROPERTIES_ID} type={ITEM_DND_TYPE}>
              {(provided) => (
                <ProjectPropertiesContainer ref={provided.innerRef} {...provided.droppableProps}>
                  <ProjectProperties usedProjectPropertyIds={usedProjectPropertyIds} />
                </ProjectPropertiesContainer>
              )}
            </Droppable>
          </Sidebar>
          <WorkArea>
            <Droppable droppableId={WORK_AREA_DROPPABLE_ID} type={GROUP_DND_TYPE}>
              {(provided) => (
                <FormLayout ref={provided.innerRef} {...provided.droppableProps}>
                  <FormFieldsSectionTitle>
                    Form fields{' '}
                    <SectionTitleDescription>(drag & drop to change order and create groups)</SectionTitleDescription>
                  </FormFieldsSectionTitle>
                  {layout.map((id, index) => (
                    <Draggable key={id} draggableId={id.toString()} index={index}>
                      {(provided, snapshot) =>
                        configsById[id].type === FormLayoutType.GROUP ? (
                          <WorkAreaGroupItem
                            isSelected={selectedElement?.toString() === id.toString()}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            onClick={() => setSelectedElement(id)}
                            style={provided.draggableProps.style}
                          >
                            <WorkAreaGroupItemHeader>
                              {configsById[id].config.name}
                              {!configsById[id].config.name && <PlaceholderFieldName>Group title</PlaceholderFieldName>}
                              {layout.length > 1 && (
                                <RemoveElementButton onClick={handleRemoveGroupElement(id)}>
                                  <XIcon size="16px" />
                                </RemoveElementButton>
                              )}
                            </WorkAreaGroupItemHeader>

                            <Droppable droppableId={id} type={ITEM_DND_TYPE}>
                              {(provided, snapshot) => (
                                <WorkAreaGroupItemBody ref={provided.innerRef} {...provided.droppableProps}>
                                  {configsById[id].children.map((childId: string, childIndex) => (
                                    <Draggable key={childId} draggableId={childId.toString()} index={childIndex}>
                                      {(provided, snapshot) => (
                                        <WorkAreaItem
                                          isSelected={selectedElement?.toString() === childId.toString()}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          isDragging={snapshot.isDragging}
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setSelectedElement(childId);
                                          }}
                                          style={provided.draggableProps.style}
                                        >
                                          {FORM_FIELD_TYPE_CONFIGS[configsById[childId].type].icon}
                                          {configsById[childId].config.name}
                                          {configsById[childId].config.projectColumnId && <Globe size="16px" />}
                                          {!configsById[childId].config.name && (
                                            <PlaceholderFieldName>Field title</PlaceholderFieldName>
                                          )}
                                          {errors[childId] && errors[childId].length > 0 && (
                                            <Tooltip
                                              title={
                                                <>
                                                  {errors[childId].map((error, index) => (
                                                    <div key={index}>{error}</div>
                                                  ))}
                                                </>
                                              }
                                            >
                                              <AlertTriangle size="16px" color="#D54855" />
                                            </Tooltip>
                                          )}
                                          <RemoveElementButton
                                            onClick={handleRemoveFieldElement({ parentId: id, childId })}
                                          >
                                            <XIcon size="16px" />
                                          </RemoveElementButton>
                                        </WorkAreaItem>
                                      )}
                                    </Draggable>
                                  ))}
                                  {!snapshot.isDraggingOver && configsById[id].children.length === 0 && (
                                    <GroupItemsPlaceholder>
                                      Drag & drop fields to add / remove them
                                    </GroupItemsPlaceholder>
                                  )}
                                  {provided.placeholder}
                                </WorkAreaGroupItemBody>
                              )}
                            </Droppable>
                          </WorkAreaGroupItem>
                        ) : (
                          <div>Invalid layout element</div>
                        )
                      }
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </FormLayout>
              )}
            </Droppable>
            <ConfigAndPreview>
              <ConfigAndPreviewHeader>
                <Tabs selected={selectedTab.id} onChange={onTabChange} variant="outline" tabs={TABS} />
              </ConfigAndPreviewHeader>

              {selectedTab.id === 'properties' && (
                <ElementProperties element={configsById[selectedElement]} onChange={handleElementChange} />
              )}

              {selectedTab.id === 'preview' && (
                <Preview formName={formName} configsById={configsById} layout={layout} />
              )}
            </ConfigAndPreview>
          </WorkArea>
        </Main>
      </DragDropContext>
    </>
  );
};

const NEW_FORM: FormTemplate = {
  id: null,
  isTemplate: true,
  name: '',
  formLayouts: [
    {
      id: uuid(),
      type: FormLayoutType.GROUP,
      name: '',
      childFormLayouts: []
    }
  ]
};

export const FormBuilderContainer = ({ formId, onSave }: Props) => {
  const isNew = formId === 'new';

  const [fromId] = useQueryParam(QueryParamsEnum.FromId);

  const isCloning = fromId && Boolean(+fromId);

  const idToLoad = useMemo(() => {
    if (isNew) {
      return fromId ? +fromId : undefined;
    }

    return +formId;
  }, [isNew, formId, fromId]);

  const { data: form, isLoading } = useFormTemplate(idToLoad);

  const initialData = useMemo(() => {
    if (isCloning) {
      if (isLoading || !form) {
        return null;
      }

      return { ...form, id: null, name: `${form.name} (copy)` };
    }

    if (isNew) {
      return NEW_FORM;
    }

    return isLoading || !form ? null : form;
  }, [isNew, form, isLoading, isCloning]);

  if (!initialData) {
    return null;
  }

  return <FormBuilder form={initialData} onSave={onSave} />;
};

export const FormBuilderPage = ({ formId }: { formId: string }) => {
  const isNew = formId === 'new';

  const { data: form, isLoading } = useFormTemplate(isNew ? undefined : +formId);

  if (!isNew && (isLoading || !form)) {
    return null;
  }

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">Templates</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>{form ? form.name : 'New form'}</div>
      </Breadcrumbs>

      <FormBuilderContainer formId={formId} />
    </Container>
  );
};
