import { useClick, useDismiss, UseFloatingOptions, useInteractions } from '@floating-ui/react';
import { useTooltip } from '@kit/ui/Floats';

/**
 * https://floating-ui.com/docs/react
 */
export const useClickTooltip = (opts: UseFloatingOptions) => {
  const tooltip = useTooltip(opts);

  const click = useClick(tooltip.context);
  const dismiss = useDismiss(tooltip.context, {
    referencePressEvent: 'click',
    outsidePressEvent: 'click'
  });

  const interactions = useInteractions([click, dismiss]);

  return {
    ...tooltip,
    ...interactions
  };
};
