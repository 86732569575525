import React from 'react';
import {
  BlueprintTaskButton,
  FakeWrapper,
  TextWrapper
} from '@components/templates/Workspace/Workflows/BlueprintTask/styled';

export const BlueprintFakeTask: React.FC = () => {
  return (
    <FakeWrapper>
      <BlueprintTaskButton>
        <TextWrapper />
      </BlueprintTaskButton>
    </FakeWrapper>
  );
};
