import React from 'react';
import styled from 'styled-components';

const Separator = ({ label }) => {
  const Separator = styled.div`
    display: block;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    color: #778ca2;
    margin: 12px 0;
  `;

  const SeparatorLabel = styled.span`
    position: relative;
    display: inline-block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 9999px;
      height: 1px;
      background: #e8ecef;
    }

    &:before {
      right: 100%;
      margin-right: 15px;
    }
    &:after {
      left: 100%;
      margin-left: 15px;
    }
  `;

  return (
    <Separator>{label && <SeparatorLabel>{label}</SeparatorLabel>}</Separator>
  );
};

export default Separator;
