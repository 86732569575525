import styled from 'styled-components';
import { scroll } from '@styles';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  max-width: 815px;
  max-height: calc(100vh - 32px - 64px - 80px - 32px);
  overflow: auto;
  ${scroll};
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const UsersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UsersDescription = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const UsersSections = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

export const UsersSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
`;

export const UsersLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #1d1d35;
`;

export const UsersChecks = styled.div`
  max-height: 132px; // supposed to be 5 entries
  overflow: auto;
  ${scroll};
`;

export const RadiusLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const MapContainer = styled.div`
  height: 196px;
  min-height: 196px;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
  background-color: #fff;
`;

export const GeofencingContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const GeofencingDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GeofencingTitle = styled.div<{ disabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) => (props.disabled ? '#828D9A' : '#1D1D35')};
`;

export const GeofencingSubtitle = styled.div<{ disabled: boolean }>`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => (props.disabled ? '#C8C8D3' : '#828D9A')};
`;

export const AutoManualHeader = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  padding-bottom: 8px;
`;

export const AutoManualValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AutoManualValueTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const AutoManualValueDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const RequiredStar = styled.span`
  &:before {
    content: ' *';
  }

  color: #d54855;
`;

export const Separator = styled.div`
  border-bottom: 1px solid #dfdfe8;
`;

export const BlockContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 8px;
`;

export const BlockTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
  padding-bottom: 4px;
`;

export const BlockDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
