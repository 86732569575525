import { AutomationStepType } from '@enums';
import { AutomationFromApi } from '@types';
import React, { useCallback, useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Switch } from '@kit/ui/Switch';
import { useUpdateAutomation } from '@hooks/automations/useUpdateAutomation';
import { Copy, Edit2 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useNavigate } from '@reach/router';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useDeleteAutomation } from '@hooks/automations/useDeleteAutomation';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useDuplicateAutomation } from '@hooks/automations/useDuplicateAutomation';
import { Row, Author } from './styled';
import { TRIGGER_TYPE_TITLES } from '../constants';

interface Props {
  automation: AutomationFromApi;
}

export const AutomationRow = ({ automation }: Props) => {
  const { mutateAsync: updateAutomation } = useUpdateAutomation();
  const { mutateAsync: duplicateAutomation } = useDuplicateAutomation();

  const { mutateAsync: deleteAutomation } = useDeleteAutomation();
  const confirmDelete = useConfirmDeleteModal();

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const trigger = automation.steps.find((step) => step.type === AutomationStepType.TRIGGER);

  const actions =
    automation.steps
      .filter((step) => step.type === AutomationStepType.ACTION)
      .map((step) => capitalize(step.key).replace('_', ' '))
      .join(', ') || '-';

  const handleToggleActive = useCallback(() => {
    updateAutomation({ id: automation.id, req: { isActive: !automation.isActive } });
  }, [updateAutomation, automation]);

  const navigate = useNavigate();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          navigate(`./${automation.id}`);
        }
      },
      {
        icon: <Copy size="16px" color="#9C9CAA" />,
        title: 'Duplicate',
        onClick: async () => {
          const { id } = await duplicateAutomation({ id: automation.id, name: `${automation.name} (copy)` });
          navigate(`./${id}`);
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this automation?')) {
            await deleteAutomation({ id: automation.id });
          }
        }
      }
    ];
  }, [navigate, automation, confirmDelete, deleteAutomation, duplicateAutomation]);

  const handleRowClick = useCallback(() => {
    navigate(`./${automation.id}`);
  }, [navigate, automation]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  return (
    <Row onClick={handleRowClick} onContextMenu={handleContextMenu}>
      <td>{automation.name}</td>
      <td>{trigger?.key ? TRIGGER_TYPE_TITLES[trigger.key] ?? capitalize(trigger.key).replace(/_/, ' ') : '-'}</td>
      <td>{actions}</td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Switch isActive={automation.isActive} onChange={handleToggleActive} />
      </td>
      <td>
        <Author>
          <UserAvatar user={automation.createdBy} size={20} />
          <div>
            {automation.createdBy.firstName} {automation.createdBy.lastName}
          </div>
        </Author>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu} />
      </td>
    </Row>
  );
};
