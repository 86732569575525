import { useMutation, useQueryClient } from 'react-query';
import { InsertManualGeoDTO } from '@types';
import { errorHandler } from '@services/api/helpers';
import geolocationAPI from '@services/api/geolocationAPI';
import { ReactQueryKey } from '@enums';

export const useInsertManualGeo = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto: InsertManualGeoDTO) => {
      try {
        return await geolocationAPI.insertManual(dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.Geolocation]);
      }
    }
  );
};
