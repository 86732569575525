import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #f8fafb;

  gap: 24px;

  > *:last-child {
    margin-left: auto;
  }
`;
