import { scroll } from '@styles';
import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
`;

export const Footer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: flex-end;
  gap: 24px;
  background-color: #fff;
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;

export const MemberList = styled.div`
  margin-top: 16px;

  max-height: 40vh;
  overflow: auto;
  ${scroll};
`;

export const MemberRow = styled.div`
  background: #fff;
  margin-bottom: 2px;
  border-radius: 4px;
  padding: 8px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const NameAndAvatar = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Email = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const DisabledOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  cursor: default;

  ${FieldLabel} {
    margin: 0;
  }
`;
