import { User } from '@generated/types/graphql';
import { Select } from '@kit/ui/Select';
import React, { useCallback } from 'react';
import { DropDownItem } from '@common/Selector/UserSelector/DropDownItem';
import { getFullName } from '@utils/utils';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import { DisabledOption, FieldLabel } from './styled';

interface Props {
  selected: User[];
  onSelect: (user: User) => void;
}

const isOptionSelected = (option: User, selected: User[]) =>
  selected.some((selectedUser) => selectedUser.id === option.id);

export const UserSelect = ({ selected, onSelect }: Props) => {
  const [inputValue, debouncedSearch, setInputValue] = useDebouncedState('', 300);

  const { data: users, isLoading } = useAllCompaniesUsers({ search: debouncedSearch });

  const handleChange = useCallback(
    (_, value) => {
      if (value) {
        onSelect(value);
      }
      setInputValue('');
    },
    [onSelect, setInputValue]
  );

  return (
    <div>
      <FieldLabel>Members</FieldLabel>

      <Select
        value={null}
        placeholder="Select members"
        getOptionLabel={getFullName}
        renderOption={(option) => {
          if (isOptionSelected(option, selected)) {
            return (
              <DisabledOption
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
                <FieldLabel>Already in this team</FieldLabel>
              </DisabledOption>
            );
          }

          return <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />;
        }}
        inputValue={inputValue}
        onInputChange={(_e, inputValue) => setInputValue(inputValue)}
        loading={isLoading}
        options={users}
        onChange={handleChange}
      />
    </div>
  );
};
