import React, { useMemo, useState } from 'react';
import { ZapIcon } from '@kit/ui/icons/Zap';
import { ChartMilestone, chartMilestoneConfig, chartMilestoneNames } from '@hooks/systems';
import { colors } from '@styles';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ContextMenu } from '@kit/components/ContextMenu';
import { useSystemsProductionChart } from '@hooks/systems/useSystemsProductionChart';
import { formatEnergy, gapfillPoints } from '@features/SystemPortfolio/utils';
import { Widget, WidgetMetric, WidgetMetricLabel, WidgetRight } from '../styled';
import { Legend, PowerIcon, SelectWithArrow, Title } from './styled';
import { SystemChart } from '@features/SystemPortfolio/components';

type Props = {
  allIds: number[];
};

export const ProductionWidget = ({ allIds }: Props) => {
  const [statsPeriod, setStatsPeriod] = useState<ChartMilestone>(ChartMilestone.yesterday);

  const milestoneConfig = chartMilestoneConfig[statsPeriod];
  const periodConfig = milestoneConfig.period;
  const statsInterval = milestoneConfig.interval();

  const {
    data: { total, points }
  } = useSystemsProductionChart(allIds, periodConfig, statsInterval?.start, statsInterval?.end);

  const gapfilledPoints = useMemo(
    () => gapfillPoints(points, periodConfig, 'energy', statsInterval),
    [points, periodConfig, statsInterval]
  );

  return (
    <Widget>
      <Legend>
        <Title>
          <PowerIcon>
            <ZapIcon size="16px" color="#FFFFFF" />
          </PowerIcon>
          Performance
        </Title>

        <div>
          <WidgetMetric>{formatEnergy(total)}</WidgetMetric>
          <WidgetMetricLabel>energy produced</WidgetMetricLabel>
        </div>

        <ContextMenu
          items={Object.values(ChartMilestone).map((period) => ({
            title: chartMilestoneNames[period],
            onClick: () => setStatsPeriod(period)
          }))}
        >
          <SelectWithArrow>
            <div>{chartMilestoneNames[statsPeriod]}</div>
            <ChevronDownIcon size={16} color={colors.green} />
          </SelectWithArrow>
        </ContextMenu>
      </Legend>
      <WidgetRight>
        <SystemChart points={gapfilledPoints} period={periodConfig} kind="energy" meters={{ production: true }} />
      </WidgetRight>
    </Widget>
  );
};
