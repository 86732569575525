import { List, ListItem, ListItemSecondaryAction, Popover } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown, User, Users, UserX } from 'react-feather';
import { uniq } from 'lodash';
import { SearchBar } from '@common/SearchBar';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Checkbox } from '@kit/ui/Checkbox';
import {
  FilterButton,
  GroupContainer,
  GroupItemsList,
  GroupSelectedCount,
  Menu,
  Remove,
  SelectedCount,
  Summary
} from './styled';

const Group = ({
  title,
  options,
  icon: Icon,
  selected,
  onSelect,
  search
}: {
  search: string;
  title: string;
  options: { id: number; title: string }[];
  selected: number[];
  onSelect: (selected: number[]) => void;
  icon: any;
}) => {
  const isAllSelected = useMemo(
    () => options.length > 0 && options.every(({ id }) => selected.includes(id)),
    [selected, options]
  );

  const selectedCount = useMemo(() => options.filter(({ id }) => selected.includes(id)).length, [selected, options]);

  const handleToggle = (id: number) => () => {
    if (selected.includes(id)) {
      onSelect(selected.filter((value) => value !== id));
    } else {
      onSelect(selected.concat([id]));
    }
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      onSelect(selected.filter((id) => !options.some((option) => option.id === id)));
    } else {
      onSelect(uniq(selected.concat(options.map(({ id }) => id))));
    }
  };

  const handleClear = useCallback(() => {
    onSelect(selected.filter((id) => !options.some((option) => option.id === id)));
  }, [onSelect, selected, options]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    return options.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase().trim()));
  }, [options, search]);

  return (
    <GroupContainer>
      <List disablePadding>
        <ListItem role={undefined} dense disableGutters>
          <Checkbox
            isChecked={isAllSelected}
            onChange={handleToggleAll}
            label={
              <>
                <Icon size="16px" color="#C8C8D3" />
                {title} (
                {filteredOptions.length === options.length
                  ? options.length
                  : `${filteredOptions.length}/${options.length}`}
                )
              </>
            }
          />

          {selectedCount > 0 && (
            <ListItemSecondaryAction>
              <GroupSelectedCount>
                {selectedCount}

                <Remove onClick={handleClear} />
              </GroupSelectedCount>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <GroupItemsList disablePadding>
        {filteredOptions.map((option) => {
          return (
            <ListItem style={{ paddingLeft: 16 }} key={option.id} role={undefined} dense disableGutters>
              <Checkbox
                isChecked={selected.includes(option.id)}
                onChange={handleToggle(option.id)}
                label={option.title}
              />
            </ListItem>
          );
        })}
      </GroupItemsList>
    </GroupContainer>
  );
};

interface Props {
  fieldName: string;
  label: string;
  assignees: any[];
  teams: any[];
  selected: number[];
  onSelect: (filter: { [key: string]: number[] }) => void;
}

export const ResoucesFilter = ({ fieldName, label, assignees, teams, selected, onSelect }: Props) => {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = useCallback(
    (selected: number[]) => {
      onSelect({ [fieldName]: selected });
    },
    [fieldName, onSelect]
  );

  const handleClear = useCallback(() => {
    handleSelect([]);
  }, [handleSelect]);

  const teamsOptions = useMemo(() => {
    return teams.map((team) => ({ ...team, title: team.name }));
  }, [teams]);

  const assigneeOptions = useMemo(() => {
    return assignees.map((assignee) => ({ ...assignee, title: `${assignee.firstName} ${assignee.lastName}` }));
  }, [assignees]);

  const selectedTeams = useMemo(() => {
    return teams.filter((team) => team.teamUsers.every(({ id }: { id: number }) => selected.includes(id)));
  }, [teams, selected]);

  const selectedTeamsIds = useMemo(() => selectedTeams.map(({ id }) => id), [selectedTeams]);

  const handleTeamSelect = useCallback(
    (selectedTeamIds: number[]) => {
      const teamsUsers = teams
        .filter(({ id }) => selectedTeamIds.includes(id))
        .map((team) => team.teamUsers)
        .flat()
        .map(({ id }) => id);

      const deselectedUsers = selectedTeams
        .filter((team) => !selectedTeamIds.includes(team.id))
        .map((team) => team.teamUsers)
        .flat()
        .map(({ id }) => id);

      onSelect({ [fieldName]: uniq([...selected, ...teamsUsers]).filter((id) => !deselectedUsers.includes(id)) });
    },
    [fieldName, onSelect, teams, selected, selectedTeams]
  );

  const handleUnassignedToggle = useCallback(() => {
    onSelect({
      [fieldName]: selected.includes('unassigned')
        ? selected.filter((id) => id !== 'unassigned')
        : [...selected, 'unassigned']
    });
  }, [selected, fieldName, onSelect]);

  return (
    <>
      <FilterButton aria-describedby={`filter-${fieldName}`} variant="outlined" onClick={handleClick}>
        {label}
        {selected.length > 0 && <SelectedCount>({selected.length})</SelectedCount>}
        <ChevronDown size="20px" />
      </FilterButton>
      <Popover
        id={`filter-${fieldName}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          <ListItem role={undefined} style={{ marginTop: 10 }} dense disableGutters>
            <Checkbox
              isChecked={selected.includes('unassigned')}
              onChange={handleUnassignedToggle}
              label={
                <>
                  <UserX size="16px" color="#C8C8D3" />
                  Unassigned
                </>
              }
            />
          </ListItem>

          <Group
            search={search}
            icon={Users}
            title="Teams"
            options={teamsOptions}
            selected={selectedTeamsIds}
            onSelect={handleTeamSelect}
          />
          <Group
            search={search}
            icon={User}
            title="Users"
            options={assigneeOptions}
            selected={selected}
            onSelect={handleSelect}
          />

          <Summary>
            <div>Selected: {selected.length}</div>
            <Button
              onClick={handleClear}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              disabled={selected.length === 0}
            >
              Clear all
            </Button>
          </Summary>
        </Menu>
      </Popover>
    </>
  );
};
