import React from 'react';

import { Container, Placeholder } from './styled';
import { FormBuilderElement } from '../helpers';
import { Form } from './Form';

interface Props {
  element: FormBuilderElement | null; // TODO
  onChange: (id: FormBuilderElement['id'], config: FormBuilderElement['config']) => void;
}

export const ElementProperties = ({ element, onChange }: Props) => {
  if (!element) {
    return (
      <Container>
        <Placeholder>Click on a field to see its configurations</Placeholder>
      </Container>
    );
  }

  return (
    <Container>
      <Form key={element.id} element={element} onChange={onChange} />
    </Container>
  );
};
