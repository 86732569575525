import { post, deleteRequest, put } from './base';
import { Urls } from './base/axios';

export type CreateLabelPayload = {
  label: string;
  color: string;
};

export type UpdateLabelPayload = Partial<
  CreateLabelPayload & {
    isActive: boolean;
  }
>;

export const labelsApi = {
  create: (companyId: number, dto: CreateLabelPayload) =>
    post<CreateLabelPayload, { id: number }>(`${Urls.project}/labels?companyId=${companyId}`, dto),

  update: (companyId: number, { id, dto }: { id: number; dto: UpdateLabelPayload }) =>
    put(`${Urls.project}/labels/${id}?companyId=${companyId}`, dto),

  delete: (id: number) => deleteRequest(`${Urls.project}/labels/${id}`)
};
