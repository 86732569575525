import React, { useCallback, useMemo } from 'react';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Form, FormValidationRules, InputField, SelectField, SwitchField, useForm } from '@kit/components/Form';
import { useCreateSystemAlertConfig } from '@hooks/workspace/systems/alertConfigs/useCreateSystemAlertConfig';
import { useUpdateSystemAlertConfig } from '@hooks/workspace/systems/alertConfigs/useUpdateSystemAlertConfig';
import { useDeleteSystemAlertConfig } from '@hooks/workspace/systems/alertConfigs/useDeleteSystemAlertConfig';
import { SystemAlertConfigListItem } from '@hooks/workspace/systems/alertConfigs/useSystemAlertConfigList';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { useAppSelector } from '@hooks/index';
import { isCustomerPortalEnabled } from '@state/selectors';
import { Badge } from '@kit/ui/Badge';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { Accordion, AccordionDetails, AccordionSummary, Condition, FormActions, LessMore, Title } from './styled';

interface Props {
  alert?: SystemAlertConfigListItem;
  isExpanded: boolean;
  onToggleExpand: (alert: any, isExpanded: boolean) => void;
  onCancelCreation: () => void;
}

type FormValues = {
  name: string;
  fleets: { id: number; name: string }[];
  showInPortal: boolean;
  condition: {
    metric: { label: string; value: SystemMetric };
    comparator: 'less' | 'more';
    percent: number; // 1...1000
    days: number; // 1...30
  };
};

export const AlertForm = ({ alert, isExpanded, onToggleExpand, onCancelCreation }: Props) => {
  const isNew = !alert?.id;

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const { mutateAsync: createAlert } = useCreateSystemAlertConfig();
  const { mutateAsync: updateAlert } = useUpdateSystemAlertConfig();
  const { mutateAsync: deleteAlert } = useDeleteSystemAlertConfig();
  const confirmDelete = useConfirmDeleteModal();

  const { data: fleets } = useFleetList();

  const handleToggle = useCallback(
    (_event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      onToggleExpand(alert, isExpanded);
    },
    [alert, onToggleExpand]
  );

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: alert
      ? {
          name: alert.name,
          fleets: alert.fleets,
          showInPortal: alert.showInPortal,
          condition: alert.condition
        }
      : {
          name: '',
          fleets: [],
          showInPortal: false,
          condition: {
            metric: { label: 'Energy production', value: SystemMetric.EnergyProduction },
            comparator: 'less',
            percent: 90,
            days: 3
          }
        },
    onSubmit: async (values, { reset }) => {
      const payload = {
        name: values.name,
        profiles: values.fleets.map((fleet) => fleet.id),
        showInPortal: values.showInPortal,
        metric: values.condition.metric.value,
        comparator: values.condition.comparator,
        percent: values.condition.percent,
        days: values.condition.days
      };

      if (isNew) {
        await createAlert(payload);
        onCancelCreation();
      } else {
        await updateAlert({ id: alert.id, dto: payload });
        reset(undefined, { keepDirty: false, keepDirtyValues: true });

        onToggleExpand(alert, false);
      }
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      fleets: {
        isRequired: true
      },
      'condition.metric': {
        isRequired: true
      },
      'condition.percent': {
        isRequired: true,
        min: 1,
        max: 1000
      },
      'condition.days': {
        isRequired: true,
        min: 1,
        max: 30
      }
    }),
    []
  );

  const {
    control,
    reset,
    watch,
    setValue,
    formState: { isSubmitting, isDirty }
  } = form;

  const comparator = watch('condition.comparator');

  const handleCancel = useCallback(() => {
    if (isNew) {
      onCancelCreation();

      return;
    }

    reset();
    onToggleExpand(alert, false);
  }, [reset, onToggleExpand, alert, isNew, onCancelCreation]);

  const handleDelete = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this Alert?')) {
      await deleteAlert(alert.id);
      onCancelCreation();
    }
  }, [alert, confirmDelete, deleteAlert, onCancelCreation]);

  const toggleLessMore = useCallback(() => {
    setValue('condition.comparator', comparator === 'less' ? 'more' : 'less');
  }, [comparator, setValue]);

  return (
    <Accordion expanded={isExpanded} onChange={handleToggle}>
      <AccordionSummary expandIcon={<ChevronDownIcon color="#9C9CAA" />}>
        <Title>
          {alert?.name || 'New Alert'}

          {isDirty && (!isNew || (isNew && !isExpanded)) && (
            <Badge color="#d54855" bgColor="#fff">
              Unsaved changes
            </Badge>
          )}
        </Title>
      </AccordionSummary>

      <AccordionDetails>
        <Form rules={rules} onSubmit={handleSubmit}>
          <InputField name="name" control={control} label="Alert name" />

          <SelectField
            name="fleets"
            control={control}
            label="Fleets"
            options={fleets}
            getOptionLabel={(fleet) => fleet.name}
            getOptionValue={(fleet) => fleet.id}
            isMulti
            getOptionSelected={(op, val) => op.id === val.id}
          />

          <Condition>
            <div>Alert when</div>
            <SelectField
              className="metric"
              name="condition.metric"
              control={control}
              options={[
                { label: 'Energy production', value: SystemMetric.EnergyProduction },
                { label: 'Energy consumption', value: SystemMetric.EnergyConsumption }
              ]}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isClearable={false}
            />
            <div>is</div>
            <LessMore onClick={toggleLessMore}>
              {comparator}
              <ChevronDownIcon size="16px" />
            </LessMore>
            <div>than</div>
            <InputField className="percent" type="number" name="condition.percent" control={control} />
            <div>%</div>
            <div>of</div>
            <InputField className="days" type="number" name="condition.days" control={control} />
            <div>days moving average</div>
          </Condition>

          {isPortalEnabled && <SwitchField name="showInPortal" label="Show this alert in Portal" control={control} />}

          <FormActions>
            {!isNew && (
              <Button onClick={handleDelete} variant={ButtonVariant.Danger}>
                Delete
              </Button>
            )}

            <Button variant={ButtonVariant.Secondary} onClick={handleCancel}>
              Cancel
            </Button>
            <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
              {isNew ? 'Save' : 'Update'}
            </Button>
          </FormActions>
        </Form>
      </AccordionDetails>
    </Accordion>
  );
};
