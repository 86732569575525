const makeMailShareLink = (body: string) => {
  return `mailto:?body=${encodeURIComponent(body)}`;
};

const makeSmsShareLink = (body: string) => {
  return `sms:?&body=${encodeURIComponent(body)}`;
};

const makeGmailShareLink = (body: string) => {
  return `https://mail.google.com/mail/?view=cm&fs=1&body=${encodeURIComponent(body)}`;
};

const makeOutlookShareLink = (body: string) => {
  return `https://outlook.live.com/owa/?path=/mail/action/compose&body=${encodeURIComponent(body)}`;
};

const makeWhatsappShareLink = (body: string) => {
  return `https://api.whatsapp.com/send?text=${encodeURIComponent(body)}`;
};

const makeFacebookShareLink = (url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
};

export const useSocialShareLinks = ({ message, url }: { message: string; url: string }) => {
  return {
    sms: makeSmsShareLink(message),
    mail: makeMailShareLink(message),
    gmail: makeGmailShareLink(message),
    outlook: makeOutlookShareLink(message),
    whatsapp: makeWhatsappShareLink(message),
    facebook: makeFacebookShareLink(url)
  };
};
