import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { RoleDTO } from '@types';
import rolesApi from '@services/api/rolesAPI';

export const useCreateRole = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<RoleDTO, Error, Partial<RoleDTO>>(
    async (dto) => {
      try {
        return (await rolesApi.create(companyId, dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: () => {
        showSuccess('Role created successfully');
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceRoles, companyId]);
      }
    }
  );
};
