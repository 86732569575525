import { RecordType } from '@types';
import React, { useCallback, useState } from 'react';
import { InfoIcon } from '@kit/ui/icons/Info';
import { Switch, SwitchVariant } from '@kit/ui/Switch';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { CornerUpLeft } from 'react-feather';
import { ENTITY_NAME_BY_RECORD_TYPE } from '@features/ProjectPortfolio/Project/History/FeedItem/constants';
import { XIcon } from '@kit/ui/icons/X';
import { ClientForm } from '@features/ClientPortfolio/List';
import { RequestForm } from '@features/RequestPortfolio/List';
import { ProjectForm } from '@features/ProjectPortfolio/List';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled, selectCompanyById, selectWorkspaceId } from '@state/selectors';
import {
  Container,
  InfoBlock,
  InfoBlockRow,
  InternalFormFakeModal,
  InternalFormFakeModalHeader,
  InternalFormFakeModalTitle
} from './styled';
import { config } from '../../../../../config';

interface Props {
  onClose: () => void;
  recordType: RecordType;
}

export const FormPreview = ({ onClose, recordType }: Props) => {
  const [isInternal, setIsInternal] = useState(true);
  const companyId = useAppSelector(selectWorkspaceId);
  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);
  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}`;

  const toggleIsInternal = useCallback(() => {
    setIsInternal((prev) => !prev);
  }, []);

  const portalPreview = recordType === RecordType.DEAL && !isInternal;

  return (
    <Container isDark={isInternal}>
      <InfoBlock isActive={!isInternal} isDark={isInternal}>
        <InfoBlockRow>
          <InfoIcon size="16px" />
          <div>You are in preview mode.</div>
        </InfoBlockRow>
        {isPortalEnabled && recordType === RecordType.DEAL && (
          <InfoBlockRow>
            <Switch
              label="External"
              secondLabel="Internal"
              variant={SwitchVariant.TwoWay}
              isActive={!isInternal}
              onChange={toggleIsInternal}
            />
          </InfoBlockRow>
        )}

        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          <CornerUpLeft size="16px" />
          Back to properties
        </Button>
      </InfoBlock>

      {portalPreview && (
        <iframe
          title="Portal preview"
          src={`${portalUrl}/new?preview=true`}
          style={{ width: '100%', height: '100%' }}
        />
      )}

      {!portalPreview && (
        <InternalFormFakeModal>
          <InternalFormFakeModalHeader>
            <InternalFormFakeModalTitle>Create {ENTITY_NAME_BY_RECORD_TYPE[recordType]}</InternalFormFakeModalTitle>

            <XIcon size="24px" color="#9C9CAA" onClick={onClose} />
          </InternalFormFakeModalHeader>

          {recordType === RecordType.ACCOUNT && <ClientForm isPreview onClose={onClose} />}

          {recordType === RecordType.DEAL && <RequestForm isPreview onClose={onClose} />}

          {recordType === RecordType.PROJECT && <ProjectForm isPreview onClose={onClose} />}
        </InternalFormFakeModal>
      )}
    </Container>
  );
};
