import { toNumber } from 'lodash';
import { useState, useCallback, useMemo } from 'react';

interface WithId {
  id: number;
}

export const useSelection = <T extends WithId>(items: WithId[]) => {
  const [selection, setSelection] = useState<Record<number, boolean>>({} as Record<number, boolean>);

  const toggle = useCallback((value: number) => {
    setSelection((previous) => {
      const copy = { ...previous };

      if (copy[value]) {
        delete copy[value];
      } else {
        copy[value] = true;
      }

      return copy;
    });
  }, []);

  const resetSelection = useCallback((values: number[]) => {
    setSelection(
      values.reduce((acc, value) => {
        acc[value] = true;

        return acc;
      }, {} as Record<number, boolean>)
    );
  }, []);

  const checkIsSelected = useCallback(
    (value: number) => {
      return Boolean(selection[value]);
    },
    [selection]
  );

  const selectedCount = useMemo(() => Object.keys(selection).length, [selection]);

  const isAllSelected = useMemo(() => items.length > 0 && selectedCount === items.length, [selectedCount, items]);

  const toggleAllSelected = useCallback(() => {
    if (isAllSelected) {
      setSelection({});
    } else {
      setSelection(
        items.reduce((acc, { id }) => {
          acc[id] = true;

          return acc;
        }, {} as Record<number, boolean>)
      );
    }
  }, [items, isAllSelected]);

  const clearSelection = useCallback(() => {
    setSelection({} as Record<number, boolean>);
  }, []);

  const selected = useMemo(() => Object.keys(selection).map(toNumber), [selection]);

  return {
    selected,
    isAllSelected,
    toggleAllSelected,
    toggle,
    checkIsSelected,
    selectedCount,
    clearSelection,
    resetSelection
  };
};
