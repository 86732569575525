import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { useLocation } from '@reach/router';
import { useCallback, useEffect, useRef, useState } from 'react';

export const makeTitleHref = (title: string) => {
  return title.toLowerCase().replace(' ', '-');
};

export const useNav = () => {
  const { hash } = useLocation();

  const [activeItem, setActiveItem] = useState<string | null>(hash ? hash.slice(1) : null);

  const observer = useRef<IntersectionObserver>(null);

  const isJustClicked = useRef(false);
  const clickTimeout = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
        if (visibleSection && !isJustClicked.current) {
          setActiveItem(visibleSection.id);
        }
      },
      { threshold: 0.8 }
    );

    const sections = document.querySelectorAll('[data-section]');

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  useEffectOnceBy(
    () => {
      if (!activeItem) {
        return;
      }
      // scroll to the active section
      const activeSection = document.getElementById(activeItem);

      if (activeSection) {
        activeSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    () => true // TODO // Boolean(company) && !isNotificationLoading
  );

  const handleNavItemClick = useCallback((e) => {
    isJustClicked.current = true;

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }

    setActiveItem(e.target.href.split('#')[1]);

    clickTimeout.current = setTimeout(() => {
      isJustClicked.current = false;
    }, 500);
  }, []);

  return {
    activeItem,
    onNavItemClick: handleNavItemClick
  };
};
