import React, { useMemo, useRef, useState } from 'react';
import { MapEvent, MapRef, Marker } from 'react-map-gl';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { useSystemsClusters } from '@hooks/systems/useSystemsClusters';
import { ClusterMarker, ClusterText, SystemMarker } from '@features/SystemPortfolio/List/Table/SystemsMap/styled';
import { useClickTooltip } from '@kit/ui/Floats';
import { offset } from '@floating-ui/react';
import distance from '@turf/distance';
import { useThrottledCallback } from '@react-hookz/web';
import { Map, useFitBounds } from '@kit/ui/Map';
import { SystemPopup } from '@features/SystemPortfolio/List/Table/SystemsMap/SystemPopup';
import { MapPin } from 'react-feather';
import { SystemClusterRecord } from '@generated/types/graphql';

type Props = {
  allIds: number[];
};

export const SystemsMap = ({ allIds }: Props) => {
  // set cluster size about the same size as a minimal cluster marker
  const [pixelDistance, setPixelDistance] = useState(1);
  const calculatePixelDistance = useThrottledCallback(
    ({ target }: MapEvent) => {
      const rect = target.getContainer().getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;
      const point1 = target.unproject([centerX - 12, centerY]);
      const point2 = target.unproject([centerX + 12, centerY]);

      setPixelDistance(distance(point1.toArray(), point2.toArray(), { units: 'degrees' }));
    },
    [],
    500,
    true
  );

  const { data: clusters } = useSystemsClusters(allIds, pixelDistance);

  const mapRef = useRef<MapRef>();

  const clustersBounds = useMemo(
    () => clusters.map((cluster) => [cluster.center.x, cluster.center.y]) as [number, number][],
    [clusters]
  );
  useFitBounds(clustersBounds, mapRef);

  return (
    <Map initialViewState={{ zoom: 4 }} ref={mapRef} onRender={calculatePixelDistance}>
      {clusters.map((cluster) => (
        <SystemOrClusterMarker key={cluster.cluster} cluster={cluster} />
      ))}
    </Map>
  );
};

const SystemOrClusterMarker = ({ cluster }: { cluster: SystemClusterRecord }) => {
  const [showPopup, setShowPopup] = useState(false);
  const systemPopup = useClickTooltip({
    open: !!showPopup,
    onOpenChange: setShowPopup,
    placement: 'top',
    middleware: [offset(12)]
  });

  return (
    <>
      <Marker key={`cluster-${cluster.cluster}`} longitude={cluster.center.x} latitude={cluster.center.y}>
        {+cluster.clusterSize > 1 ? (
          <ClusterMarker colors={cluster.clusterStatuses.map((status) => SYSTEM_STATUS_COLORS[status].bgColor)}>
            <ClusterText colors={cluster.clusterStatuses.map((status) => SYSTEM_STATUS_COLORS[status].color)}>
              {cluster.clusterSize}
            </ClusterText>
          </ClusterMarker>
        ) : (
          <div ref={systemPopup.refs.setReference} {...systemPopup.getReferenceProps()}>
            <SystemMarker>
              <MapPin
                stroke={SYSTEM_STATUS_COLORS[cluster.clusterStatuses[0]].color}
                fill={SYSTEM_STATUS_COLORS[cluster.clusterStatuses[0]].bgColor}
              />
            </SystemMarker>
          </div>
        )}
      </Marker>

      {showPopup && (
        <div ref={systemPopup.refs.setFloating} style={systemPopup.floatingStyles} {...systemPopup.getFloatingProps()}>
          <SystemPopup systemId={cluster.sampleSystemId} />
        </div>
      )}
    </>
  );
};
