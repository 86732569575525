import styled from 'styled-components';
import { colors } from '@styles';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 25px;
  cursor: pointer;
`;

export const FakeWrapper = styled(Wrapper)`
  opacity: 0;
  pointer-events: none;
`;

export const BlueprintTaskButton = styled.div<{ color?: string }>`
  position: relative;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 184px;
  max-width: 184px;
  padding: 8px;
  white-space: nowrap;
  height: 40px;
  border-radius: 4px;
  gap: 4px;
  background-color: ${(props) => props.color || colors.white2};
  color: ${(props) => (props.color ? colors.white2 : colors.black)};

  > button {
    opacity: 0;
    position: absolute;
    left: -23px;
  }

  &:hover,
  &:focus {
    > button {
      opacity: 1;
    }
  }

  svg {
    flex-shrink: 0;
  }
`;

export const AddAutomationButton = styled.button`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: 0;
  position: absolute;
  right: -23px;
  left: unset !important;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;
`;

export const TextWrapper = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 500;
`;

export const Left = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
`;

export const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
