import React, { useCallback, useRef } from 'react';
import { Switch } from '@kit/ui/Switch';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Bell } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import {
  CompanyNoficationSettingsTreeItem,
  CompanyNotificationEventSettings
} from '@hooks/workspace/notifications/useCompanyNotifications';
import { normalizeRoleName } from '@utils/roles';
import { useUpdateCompanyNotification } from '@hooks/workspace/notifications/useUpdateCompanyNofification';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useUpdateEffect } from '@react-hookz/web';
import {
  getDeliveryMethodsString,
  getSingleDestinationReceiversList,
  isOnlySingleDestinationEvent,
  SINGLE_DESTINATION_TITLES_MAP
} from './helpers';
import {
  ErrorText,
  Event,
  EventContent,
  EventHeader,
  EventTitle,
  EventToggle,
  ListItem,
  ListItemDescription,
  ListItemTitle,
  Receivers,
  SecondaryText,
  TextBlock
} from './styled';
import { EventForm } from './EventForm';

const makeTitleHref = (title: string) => {
  return title.toLowerCase().replace(' ', '-');
};

const EntityEvent = ({ event }: { event: CompanyNotificationEventSettings }) => {
  const { openModal } = useModal();

  const [isEnabled, debouncedIsEnabled, setIsEnabled] = useDebouncedState(event.settings.enabled, 300);

  const isOneLineList = isOnlySingleDestinationEvent(event.eventSchema.eventType);

  const fixedReceivers = getSingleDestinationReceiversList(event.settings);

  const { mutateAsync: update } = useUpdateCompanyNotification();

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <EventForm event={event} onClose={onClose} />, {
      title: `Edit "${event.eventSchema.title}"`
    });
  }, [event, openModal]);

  const { entityType, eventType } = event.settings;

  useUpdateEffect(() => {
    update({
      entityType,
      eventType,
      enabled: debouncedIsEnabled
    });
  }, [debouncedIsEnabled, update, entityType, eventType]);

  const handleToggle = useCallback(() => {
    setIsEnabled((prev) => !prev);
  }, [setIsEnabled]);

  const deliveryMethodString = getDeliveryMethodsString(event.settings);

  return (
    <Event>
      <EventToggle>
        {event.eventSchema.always ? (
          <Bell size="24px" color="#9C9CAA" />
        ) : (
          <Switch isActive={isEnabled} onChange={handleToggle} />
        )}
      </EventToggle>
      <EventContent>
        <EventHeader>
          <div>
            <EventTitle isDisabled={!isEnabled}>{event.eventSchema.title}</EventTitle>
            <SecondaryText>{event.eventSchema.description}</SecondaryText>
          </div>

          <Button disabled={!isEnabled} onClick={handleEdit} variant={ButtonVariant.Flat}>
            Edit
          </Button>
        </EventHeader>

        {isEnabled && (
          <>
            <TextBlock>
              <SecondaryText>Delivery methods:</SecondaryText>
              <div>
                {deliveryMethodString}
                {deliveryMethodString.length === 0 && <ErrorText>No delivery methods selected.</ErrorText>}
              </div>
            </TextBlock>
            <TextBlock>
              <SecondaryText>Receivers:</SecondaryText>
              {isOneLineList && <div>{fixedReceivers}</div>}
            </TextBlock>
            {!isOneLineList && (
              <Receivers>
                {event.settings.singleReceivers.length > 0 && (
                  <TextBlock>
                    <SecondaryText>&#x2022; Key Members:</SecondaryText>
                    <div>
                      {event.settings.singleReceivers
                        .map((receiver) => SINGLE_DESTINATION_TITLES_MAP[receiver])
                        .join(', ')}
                    </div>
                  </TextBlock>
                )}
                {event.settings.roleReceivers.length > 0 && (
                  <TextBlock>
                    <SecondaryText>&#x2022; Roles:</SecondaryText>
                    <div>{event.settings.roleReceivers.map((role) => normalizeRoleName(role.name)).join(', ')}</div>
                  </TextBlock>
                )}
                {event.settings.teamReceivers.length > 0 && (
                  <TextBlock>
                    <SecondaryText>&#x2022; Teams:</SecondaryText>
                    <div>{event.settings.teamReceivers.map((team) => team.name).join(', ')}</div>
                  </TextBlock>
                )}
              </Receivers>
            )}
          </>
        )}
      </EventContent>
    </Event>
  );
};

interface Props {
  entity: CompanyNoficationSettingsTreeItem;
}

export const Entity = ({ entity }: Props) => {
  const nodeRef = useRef<HTMLDivElement>(null);

  return (
    <ListItem ref={nodeRef} key={entity.title} data-section id={makeTitleHref(entity.title)}>
      <ListItemTitle>{entity.title}</ListItemTitle>
      <ListItemDescription>{entity.description}</ListItemDescription>

      {entity.events.map((event) => (
        <EntityEvent key={event.eventSchema.eventType} event={event} />
      ))}
    </ListItem>
  );
};
