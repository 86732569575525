import { colors, device } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 24px;
  height: calc(100vh - 48px);
  overflow: auto;

  &:focus-visible {
    outline: none;
  }
`;

export const Content = styled.div``;

export const ProjectDetailsContainer = styled.div`
  z-index: 4;
  background: #f7f7fc;
  height: calc(100vh - 88px);
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: auto;

  width: 100%;

  margin-left: auto;

  ${device.md`
  height: calc(100vh - 48px - 64px);
        width: 80%;
        box-shadow: -16px -16px 200px 0px #1D1D3552;
    `}
`;

export const OuterButtons = styled.div`
  position: absolute;
  top: 48px;
  width: 100%;
  display: flex;

  > * {
    flex: 1;
  }

  .close {
    order: 2;
  }

  .prev {
    order: 1;
  }

  .next {
    order: 3;
  }

  ${device.md`
  right: 80%;
  top: 72px;
  width: auto;
        position: absolute;
        z-index: 5;
        gap: 8px;
        flex-direction: column;

        > * {
            flex: auto;
        }

        .close,
        .next,
        .prev {
            order: initial;
        }

    `};
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: #f7f7fc;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  ${device.md`
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    `}
`;

export const TaskOuterButtons = styled(OuterButtons)`
  right: 960px;
  z-index: 6;

  top: 194px;

  ${OuterButton} {
    background-color: #fff;
  }
`;
export const CloseButton = styled(OuterButton)`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     margin-bottom: 8px;
    `}
`;

export const ArrowButton = styled(OuterButton)`
  height: 40px;
  top: 24px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  ${device.md`
     width: 40px;
     height: 32px;
    `}
`;

export const TaskContainer = styled.div<{ isFullScreen: boolean; width?: string }>`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 5;
  background: ${colors.white};
  ${(props) =>
    props.isFullScreen
      ? css`
          top: 0;
          left: 0;
          z-index: 102;
        `
      : css`
          width: ${props.width || '960px'};
          height: calc(100vh - 48px - 64px);
          box-shadow: -16px -16px 200px 0px #1d1d3552;

          @media (max-width: 767px) {
            width: 100%;
            height: 100%;
            overflow: auto;
            z-index: 102;
          }
        `}
`;

export const CurrentNotificationContainer = styled.div<{ width: string }>`
  position: absolute;
  top: 48px;
  right: 0;
  width: ${(props) => props.width};
  z-index: 6;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
