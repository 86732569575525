import React, { useMemo } from 'react';
import { colors } from '@styles';
import { formatNumber } from '@utils/utils';
import { ProjectRequestStatus } from '@generated/types/graphql';
import { STATUS_CONFIG } from '@features/RequestPortfolio/constants';
import { Container, Tile, TileNumber } from './styled';
import { useClientFilterState } from '../useClientFilterState';
import { useTotalsByStatus } from '../Table/useTotalsByStatus';

export const StatusTiles = () => {
  const { totals } = useTotalsByStatus();
  const { clientFilters, updateFilters } = useClientFilterState();
  const tiles = useMemo(() => {
    return [
      {
        label: 'All',
        value: totals.all,
        isSelected: !clientFilters.status,
        color: colors.black,
        status: null
      },
      {
        label: STATUS_CONFIG[ProjectRequestStatus.New].label,
        value: totals[ProjectRequestStatus.New],
        isSelected: clientFilters.status === ProjectRequestStatus.New,
        color: STATUS_CONFIG[ProjectRequestStatus.New].color,
        status: ProjectRequestStatus.New
      },
      {
        label: STATUS_CONFIG[ProjectRequestStatus.AppointmentScheduled].label,
        value: totals[ProjectRequestStatus.AppointmentScheduled],
        isSelected: clientFilters.status === ProjectRequestStatus.AppointmentScheduled,
        color: STATUS_CONFIG[ProjectRequestStatus.AppointmentScheduled].color,
        status: ProjectRequestStatus.AppointmentScheduled
      },
      {
        label: STATUS_CONFIG[ProjectRequestStatus.AppointmentCompleted].label,
        value: totals[ProjectRequestStatus.AppointmentCompleted],
        isSelected: clientFilters.status === ProjectRequestStatus.AppointmentCompleted,
        color: STATUS_CONFIG[ProjectRequestStatus.AppointmentCompleted].color,
        status: ProjectRequestStatus.AppointmentCompleted
      },
      {
        label: STATUS_CONFIG[ProjectRequestStatus.Overdue].label,
        value: totals[ProjectRequestStatus.Overdue],
        isSelected: clientFilters.status === ProjectRequestStatus.Overdue,
        color: STATUS_CONFIG[ProjectRequestStatus.Overdue].color,
        status: ProjectRequestStatus.Overdue
      }
    ];
  }, [clientFilters.status, totals]);

  return (
    <Container>
      {tiles.map((tile) => (
        <Tile onClick={() => updateFilters({ status: tile.status })} key={tile.label} isSelected={tile.isSelected}>
          <TileNumber color={tile.color}>{formatNumber(tile.value)}</TileNumber>
          <div>{tile.label}</div>
        </Tile>
      ))}
    </Container>
  );
};
