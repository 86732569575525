import React from 'react';
import { GroupedTable } from './GroupedTable';
import { Container } from './styled';
import {
  GroupsDataStateProvider,
  GroupsStateProvider,
  SelectionStateProvider
} from '@features/SystemPortfolio/List/Table/useSelection';

export const Table = () => {
  return (
    <GroupsStateProvider>
      <SelectionStateProvider>
        <GroupsDataStateProvider>
          <Container>
            <GroupedTable />
          </Container>
        </GroupsDataStateProvider>
      </SelectionStateProvider>
    </GroupsStateProvider>
  );
};
