import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  background-color: #f7f7fc;
  width: 60vw;
  max-width: 800px;
`;

export const Footer = styled.div`
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  gap: 24px;
  background-color: #fff;

  > *:first-child:nth-last-child(1) {
    margin-left: auto;
  }
`;

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 32px;

  b {
    font-weight: 500;
  }
`;

export const Stats = styled.div`
  display: grid;
  grid-template-columns: 80px 64px 1fr;
  gap: 16px;
  align-items: center;
`;
