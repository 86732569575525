import React from 'react';
import styled from 'styled-components';
import { input, error } from '@styles';
import { InputLabel } from '@common/ui';
import { IProps } from './type';

const InputWrapper = styled.div`
  margin-bottom: 18px;
`;

const Input = styled.input`
  ${input}
  width: 100%;
  height: 52px;
`;

const ErrorMessage = styled.span`
  ${error}
`;

const InputField = (props: IProps) => {
  const {
    label,
    text,
    error,
    errorMessage,
    type,
    value,
    disabled,
    fn
  } = props;

  return (
    <InputWrapper>
      <InputLabel htmlFor={label}>
        {[text[0].toUpperCase() + text.slice(1)]}
        {error ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
      </InputLabel>
      <Input
        type={type || label}
        name={label}
        id={label}
        placeholder={`Enter your ${text}`}
        onChange={fn}
        value={value}
        error={error}
        disabled={disabled ? 'disabled' : ''}
      />
    </InputWrapper>
  );
};

export default InputField;
