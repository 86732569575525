import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding-top: 10px;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const GroupList = styled.div`
  ${scroll};

  max-height: calc(100vh - 91px - 48px - 10px - 34px);
  overflow-y: auto;
  padding-bottom: 32px;
`;
