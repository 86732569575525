import { SelectField } from '@kit/components/Form';
import { AutomationField } from '@types';
import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { TaskStatusEntity } from '@generated/types/graphql';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { ANY_OPTION } from './constants';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
  isFieldOnly?: boolean;
  isOfficeOnly?: boolean;
}

export const WorkOrderStatusField = ({ field, isDisabled, control, isFieldOnly, isOfficeOnly }: Props) => {
  const { data: options = [] } = useWorkOrderStatuses();
  const filteredOptions = useMemo(
    () =>
      (options || []).filter((option) => {
        if (isFieldOnly) {
          return !option.officeOnly;
        }

        if (isOfficeOnly) {
          return !option.fieldOnly;
        }

        return true;
      }),
    [options, isFieldOnly, isOfficeOnly]
  );

  return (
    <SelectField
      name={field.key}
      disabled={isDisabled}
      label={field.label}
      control={control}
      options={!field.required ? [ANY_OPTION].concat(filteredOptions) : filteredOptions}
      getOptionLabel={(option: TaskStatusEntity) => option.label}
      getOptionValue={(option: TaskStatusEntity) => option.id}
      renderOption={(option: TaskStatusEntity) =>
        option.id === ANY_OPTION.id ? option.label : <WorkOrderStatus maxWidth="none" status={option} />
      }
    />
  );
};
