import styled from 'styled-components';

export const Container = styled.div`
  .mapboxgl-popup-content {
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    background: none;
  }
`;
