import { useToast } from '@hooks/useToast';
import { useEffect } from 'react';

export const useLimitWarning = (count: number, limit: number) => {
  const { showWarning } = useToast();
  useEffect(() => {
    if (count > limit) {
      showWarning(`Only ${limit} out of ${count} users are displayed. Use "Resource" filter to see others.`);
    }
  }, [count, limit, showWarning]);
};
