import React from 'react';
import { PanelDescription, PanelHeader, PanelTitle } from '../styled';

export const Header = () => (
  <PanelHeader>
    <div>
      <PanelTitle>Systems overview</PanelTitle>
      <PanelDescription>
        Check the number of monitored and total systems, the average new systems added per month. This helps you track
        and manage system performance effectively.
      </PanelDescription>
    </div>
  </PanelHeader>
);
