import styled from 'styled-components';

export const ClusterMarker = styled.div<{ colors: string[] }>`
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  border-radius: 50%;
  min-width: 24px;
  padding: 4px;
  &:before {
    content: '';
    float: left;
    width: auto;
    padding-bottom: 100%;
  }

  background: conic-gradient(
    ${(props) =>
      props.colors
        .map(
          (color, index, colors) =>
            `${color} ${(360.0 / colors.length) * index}deg ${(360.0 / colors.length) * (index + 1)}deg`
        )
        .join(', ')}
  );
`;

export const ClusterText = styled.div<{ colors: string[] }>`
  background: conic-gradient(
      ${(props) =>
          props.colors
              .map(
                  (color, index, colors) =>
                      `${color} ${(360.0 / colors.length) * index}deg ${(360.0 / colors.length) * (index + 1)}deg`
              )
              .join(', ')}
  );
  background-clip: text;
  color: transparent;
  font-size: 14px;
  font-weight: 500;
`;

export const SystemMarker = styled.div`
  cursor: pointer;
`;