import React, { useMemo, useState } from 'react';
import { Geolocation } from '@generated/types/graphql';
import { Marker } from 'react-map-gl';
import { useClickTooltip } from '@kit/ui/Floats';
import { ActualPointPopup } from '@components/Scheduler/Dispatcher/Map/TaskPopup';
import { ActualRoutes } from '@hooks/geolocation';
import { offset } from '@floating-ui/react';
import { Circle } from './styled';

type Props = {
  actualRoutes: ActualRoutes;
};

export const ActualPointsLayer = ({ actualRoutes = {} }: Props) => {
  const geolocations = useMemo(() => Object.values(actualRoutes).flat(), [actualRoutes]);

  return (
    <>
      {geolocations.map((geolocation) => (
        <ActualPoint geolocation={geolocation} />
      ))}
    </>
  );
};

const ActualPoint = ({ geolocation }: { geolocation: Geolocation }) => {
  const { longitude, latitude } = geolocation;
  const [showPopup, setShowPopup] = useState(false);

  const popup = useClickTooltip({
    open: showPopup,
    onOpenChange: setShowPopup,
    placement: 'right',
    middleware: [offset(-5)]
  });

  return (
    <>
      <Marker longitude={longitude} latitude={latitude}>
        <div ref={popup.refs.setReference} {...popup.getReferenceProps()}>
          <Circle />
        </div>
      </Marker>

      {showPopup && (
        <div ref={popup.refs.setFloating} style={popup.floatingStyles} {...popup.getFloatingProps()}>
          <ActualPointPopup geolocation={geolocation} handleClose={() => setShowPopup(false)} />
        </div>
      )}
    </>
  );
};
