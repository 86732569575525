export const PER_PAGE_OPTIONS = [10, 20, 50].map((value) => ({ label: value.toString(), value }));

export type SortOption = {
  id: string;
  name: string;
};

export const SORT_OPTIONS: SortOption[] = [
  { id: 'createdAt', name: 'Created' },
  { id: 'name', name: 'Name' }
];
