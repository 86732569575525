import React from 'react';

export const SocialGmailIcon = ({ size = 24 }: { size?: string | number }) => (
  <svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.83203 8.96667C1.83203 6.35309 1.83203 5.0463 2.34067 4.04804C2.78808 3.16995 3.50199 2.45605 4.38008 2.00864C5.37833 1.5 6.68512 1.5 9.2987 1.5H15.3654C17.9789 1.5 19.2857 1.5 20.284 2.00864C21.1621 2.45605 21.876 3.16995 22.3234 4.04804C22.832 5.0463 22.832 6.35309 22.832 8.96667V15.0333C22.832 17.6469 22.832 18.9537 22.3234 19.952C21.876 20.83 21.1621 21.544 20.284 21.9914C19.2857 22.5 17.9789 22.5 15.3654 22.5H9.2987C6.68512 22.5 5.37833 22.5 4.38008 21.9914C3.50199 21.544 2.78808 20.83 2.34067 19.952C1.83203 18.9537 1.83203 17.6469 1.83203 15.0333V8.96667Z"
      fill="white"
    />
    <path
      d="M16.8724 6.39258L12.3821 9.89689L7.78906 6.39258V6.39353L7.79461 6.39826V11.3052L12.3303 14.8853L16.8724 11.4435V6.39258Z"
      fill="#EA4335"
    />
    <path
      d="M18.0503 5.53979L16.8711 6.39219V11.4431L20.5817 8.59423V6.87806C20.5817 6.87806 20.1313 4.42693 18.0503 5.53979Z"
      fill="#FBBC05"
    />
    <path
      d="M16.8711 11.4436V17.9948H19.7151C19.7151 17.9948 20.5244 17.9115 20.5827 16.989V8.59473L16.8711 11.4436Z"
      fill="#34A853"
    />
    <path d="M7.79482 17.9997V11.3045L7.78906 11.2998L7.79482 17.9997Z" fill="#C5221F" />
    <path
      d="M7.78713 6.3934L6.61437 5.54573C4.53338 4.43288 4.08203 6.88306 4.08203 6.88306V8.59922L7.78713 11.3004V6.3934Z"
      fill="#C5221F"
    />
    <path d="M7.78906 6.39355V11.3005L7.79482 11.3053V6.39829L7.78906 6.39355Z" fill="#C5221F" />
    <path
      d="M4.08203 8.59961V16.9939C4.13937 17.9173 4.94958 17.9997 4.94958 17.9997H7.79361L7.78713 11.2998L4.08203 8.59961Z"
      fill="#4285F4"
    />
  </svg>
);
