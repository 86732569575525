import { RefObject, useMemo } from 'react';
import { LngLatBounds, LngLatLike } from 'mapbox-gl';
import { useCustomCompareEffect } from '@react-hookz/web';
import { MapRef } from 'react-map-gl';

export const useFitBounds = (sources: LngLatLike[], map: RefObject<MapRef | undefined>) => {
  const bounds = useMemo<LngLatBounds | null>(() => {
    if (!sources.length || sources.length <= 1) {
      return null;
    }

    try {
      const newBounds = new LngLatBounds(sources[0], sources[0]);

      sources.slice(1).forEach((source) => {
        newBounds.extend(source);
      });

      return newBounds;
    } catch (error) {
      console.error(`Failed to fit sources %o into bounds`, sources);

      return null;
    }
  }, [sources]);

  useCustomCompareEffect(
    () => {
      if (!bounds) {
        return;
      }

      try {
        map?.current?.fitBounds(bounds, {
          padding: 100,
          // maxZoom: 15,
          linear: true,
          animate: true,
          duration: 1000
        });
      } catch (error) {
        console.error('Failed to fit bounds:', { error, clustersBounds: bounds });
      }
    },
    [bounds],
    ([bounds1], [bounds2]) =>
      (!bounds1 && !bounds2) || (bounds1 && bounds2 && bounds1.ne === bounds2.ne && bounds1.sw === bounds2.sw)
  );
};
