import React from 'react';
import { Form } from '@types';
import { PaperTooltip } from '@common/ui';
import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip';
import { FileText } from 'react-feather';
import { colors } from '@styles';
import { IconAndCounter, TooltipAutomation, TooltipHeader, TooltipWrapper } from './styled';

interface Props {
  forms: Form[];
  onFormClick: (form: Form) => void;
  placement?: TooltipProps['placement'];
}

export const FormsList = ({ placement = 'top', forms, onFormClick }: Props) => {
  if (!forms.length) {
    return null;
  }

  return (
    <PaperTooltip
      placement={placement}
      interactive
      title={
        <TooltipWrapper>
          <TooltipHeader>Forms</TooltipHeader>
          {forms.map((form) => (
            <TooltipAutomation
              key={form.id}
              onClick={(e) => {
                e.stopPropagation();
                onFormClick(form);
              }}
            >
              <FileText color={colors.green} size="16px" />
              {form.name}
            </TooltipAutomation>
          ))}
        </TooltipWrapper>
      }
    >
      <IconAndCounter>
        <FileText color={colors.green} size="16px" />
        {forms.length}
      </IconAndCounter>
    </PaperTooltip>
  );
};
