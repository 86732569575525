import { CheckActionRequest } from '@services/api/types';
import workflowsApi from '@services/api/workflowsApi';
import { useMutation } from 'react-query';
import { useToast } from '..';

export const useCheckAction = () => {
  const { showSuccess, showError } = useToast();

  return useMutation<Boolean, Error, CheckActionRequest>(async (req) => {
    try {
      const { data: result, status } = await workflowsApi.checkAction(req);

      if (status === 201 && !result.error) {
        showSuccess('Callback URL is working!');
      } else {
        showError(result.error);
      }

      return !result.error;
    } catch (error) {
      showError(error?.message);

      return false;
    }
  });
};
