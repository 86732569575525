import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 16px;

  margin-bottom: 16px;

  > * {
    min-width: 100px;
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  line-height: 40px;
  font-size: 12px;
`;
