import { MAIN_MENU_COLLAPSED_WIDTH, MAIN_MENU_WIDTH } from '@components/LayoutNew/MainMenu/constants';
import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  font-size: 14px;
  > form {
    height: 100%;
  }
`;

export const Header = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e8ecef;
  padding: 24px;
`;

export const Title = styled.h3`
  margin: 0;
  font-weight: 500;
  font-size: 20px;
`;

export const Body = styled.div`
  padding: 16px 24px;
  height: ${(props) => `calc(100% - 72px - ${props.isEdit ? '0px' : '72px'})`};
  overflow: auto;
  ${scroll};
`;

export const Footer = styled.div`
  height: 72px;
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 24px;
  border-top: 1px solid #e8ecef;
`;

export const IconButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  color: ${colors.gray};
  &:hover {
    color: ${colors.black};
  }
`;

export const GeneralInfo = styled.div`
  margin-top: 24px;
`;

export const FieldLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;

  padding-top: ${(props) => (props.fixPadding ? '14px' : 0)};
`;

export const FieldBlock = styled.div`
  display: ${(props) => (props.block ? 'block' : 'flex')};
  align-items: ${(props) => (props.centered ? 'center' : 'flex-start')};
  gap: 16px;
  margin-bottom: 16px;

  > *:last-child {
    flex: 1;
  }

  .ql-editor {
    a {
      word-break: break-word;
    }
  }

  ${FieldLabel} {
    margin-bottom: ${(props) => (props.block ? '8px' : 0)};
    width: ${(props) => (props.block ? 'auto' : '75px')};
  }
`;

export const FormSlidePanel = styled.div<{ isMainMenuExpanded: boolean }>`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 6;
  background: #f7f7fc;
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: ${({ isMainMenuExpanded }) =>
    `calc(100vw - ${isMainMenuExpanded ? MAIN_MENU_WIDTH : MAIN_MENU_COLLAPSED_WIDTH} - 133px)`};
  height: calc(100vh - 47px);
  box-shadow: -16px -16px 200px 0px #1d1d3552;
  padding: 16px;
  height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    box-shadow: none;
  }
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: #f7f7fc;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CloseButton = styled(OuterButton)`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  width: 40px;
`;

export const OuterButtons = styled.div`
  position: absolute;
  top: 24px;
  left: -40px;
  z-index: 5;
  gap: 8px;
  flex-direction: column;
  display: flex;
`;
