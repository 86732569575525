// extract stepper (<Stepper>) from './ImportSettings' into separate component

import React from 'react';
import { Check } from 'react-feather';
import { CurrentStepCircle, NotStartedStepCircle, Step, StepCircle, Stepper } from './styled';

export const ImportStepper = () => {
  return (
    <Stepper>
      <Step>
        <StepCircle>
          <Check size="16px" />
        </StepCircle>
        Upload
      </Step>
      <Step>
        <StepCircle>
          <Check size="16px" />
        </StepCircle>
        Header Row Selection
      </Step>
      <Step>
        <StepCircle>
          <Check size="16px" />
        </StepCircle>
        Mapping
      </Step>
      <Step>
        <StepCircle>
          <Check size="16px" />
        </StepCircle>
        Review
      </Step>
      <Step>
        <CurrentStepCircle>5</CurrentStepCircle>
        Options
      </Step>
      <Step isActive={false}>
        <NotStartedStepCircle>6</NotStartedStepCircle>
        Completed
      </Step>
    </Stepper>
  );
};
