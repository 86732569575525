import { useCreateAutomation } from '@hooks/automations/useCreateAutomation';
import { useUpdateAutomation } from '@hooks/automations/useUpdateAutomation';
import { FormField } from '@kit/components/Form/FormField';
import { Input } from '@kit/ui/Input';
import { Switch } from '@kit/ui/Switch';
import { useNavigate } from '@reach/router';
import React, { useCallback, useState } from 'react';
import { AutomationFromApi } from '@types';
import { useCreateAutomationStep } from '@hooks/automations/useCreateAutomationStep';
import { useUpdateEffect } from '@react-hookz/web';
import { AutomationStepType, Trigger } from '@enums';
import { Header } from './styled';

interface Props {
  initialValues?: AutomationFromApi;
  onCreated?: (automation: AutomationFromApi) => void;
}

export const NameAndActive = ({ initialValues, onCreated }: Props) => {
  const isNew = !initialValues?.id;

  const [name, setName] = useState(initialValues?.name || '');
  const [isActive, setIsActive] = useState(initialValues?.isActive ?? true);

  const { mutateAsync: createAutomation } = useCreateAutomation();
  const { mutateAsync: updateAutomation } = useUpdateAutomation();
  const { mutateAsync: createAutomationStep } = useCreateAutomationStep();

  const navigate = useNavigate();

  useUpdateEffect(() => {
    if (!initialValues?.id) {
      return;
    }

    updateAutomation({
      id: initialValues?.id,
      req: { isActive }
    });
  }, [isActive, updateAutomation, initialValues?.id]);

  const handleToggleActive = useCallback(() => {
    setIsActive((prev) => !prev);
  }, []);

  const handleNameChange = useCallback((e) => {
    setName(e.target.value);
  }, []);

  const handleNameBlur = useCallback(async () => {
    if (!name) {
      return;
    }

    if (isNew) {
      const createdAutomation = await createAutomation({
        name,
        isActive: true
      });

      const initialSteps = initialValues?.steps || [
        {
          type: AutomationStepType.TRIGGER,
          key: Trigger.TASK_COMPLETED
        }
      ];

      if (initialSteps) {
        const steps = await Promise.all(
          initialSteps.map((step) =>
            createAutomationStep({
              automationId: createdAutomation.id,
              req: step
            })
          )
        );

        createdAutomation.steps = steps;
      }

      if (onCreated) {
        onCreated({ ...createdAutomation, context: initialValues.context });

        return;
      }

      navigate(`../${createdAutomation.id}`);
    } else {
      updateAutomation({
        id: initialValues?.id,
        req: { name }
      });
    }
  }, [isNew, initialValues, name, createAutomation, updateAutomation, navigate, onCreated, createAutomationStep]);

  const handleNameKeyPress = useCallback((e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      e.target.blur();
    }
  }, []);

  return (
    <Header>
      <FormField label="Automation name">
        <Input
          value={name}
          onKeyDown={handleNameKeyPress}
          onBlur={handleNameBlur}
          onChange={handleNameChange}
          placeholder="Type an automation name here..."
        />
      </FormField>

      {!isNew && <Switch label="Active automation" isActive={isActive} onChange={handleToggleActive} />}
    </Header>
  );
};
