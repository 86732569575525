import { Input } from '@kit/ui/Input';
import React, { useCallback, useState } from 'react';
import { RecordType } from '@types';
import { usePropertyGroupMutation } from '@hooks/usePropertyGroup';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Body, Footer } from './styled';

interface Props {
  scope: RecordType;
  onClose: () => void;
  initialValues?: {
    id: number;
    name: string;
  };
}

export const GroupForm = ({ scope, initialValues, onClose }: Props) => {
  const [name, setName] = useState(initialValues?.name ?? '');
  const [isLoading, setIsLoading] = useState(false);
  const {
    create: { mutateAsync: create },
    update: { mutateAsync: update }
  } = usePropertyGroupMutation();

  const handleNameChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>((event) => {
    setName(event.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    if (initialValues) {
      await update({ groupId: initialValues.id, dto: { name } });
    } else {
      await create({ dto: { name, scope } });
    }

    onClose();
  }, [initialValues, scope, name, update, create, isLoading, onClose]);

  return (
    <>
      <Body>
        <Input value={name} onChange={handleNameChange} />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!name || isLoading} variant={ButtonVariant.Primary} onClick={handleSubmit}>
          {initialValues ? 'Update' : 'Create'}
        </Button>
      </Footer>
    </>
  );
};
