import React from 'react';
import { TaskViewFilterSubType } from '@types';
import { useSchedulerTasks } from './useTasks';
import { Header } from '../components/Header';
import { useClientFilterState } from '../useClientFilterState';

export const SchedulerHeader = ({ onAddNewTask }: any) => {
  const [selectedFilters, setSelectedFilters] = useClientFilterState();
  const { total, visibleCount } = useSchedulerTasks();

  return (
    <Header
      totalCount={total}
      visibleCount={visibleCount}
      selectedFilters={selectedFilters}
      onFilterChange={setSelectedFilters}
      onAddNewTask={onAddNewTask}
      subType={TaskViewFilterSubType.SCHEDULER}
    />
  );
};
