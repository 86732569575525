import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border: 1px solid #dfdfe8;
  border-radius: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 15px 5px rgba(39, 12, 82, 0.16);
  max-width: 370px;
  width: 370px;
`;

export const FirstRow = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
`;

export const Title = styled.div`
  display: flex;
  gap: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #1d1d35;
  min-width: 32px;
`;

export const TitleShrink = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TitleNoShrink = styled.div`
  min-width: 16px;
`;

export const SecondRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const SecondRowBadge = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 2px 8px 2px 8px;
  border: 1px solid #dfdfe8;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
`;

export const ThirdRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const FieldLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const TaskDate = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #1d1d35;
`;

export const FourthRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #235dff;
`;

export const FifthRow = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #235dff;
`;

export const Separator = styled.div`
  width: 100%;
  border-top: 1px solid #dfdfe8;
`;

export const SixthRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const SeventhRow = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const Assignee = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #1d1d35;
`;
