import React, { forwardRef } from 'react';
import { Geolocation, MainUser } from '@generated/types/graphql';
import { Avatar } from '@kit/ui/Avatar';
import { getFullName } from '@utils';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import { DateTime, Duration, Interval } from 'luxon';
import { Calendar, Home, X } from 'react-feather';
import { formatKilometers, formatOrdinal, formatTripDuration, formatVisitInterval } from '@utils/format';
import { PriorityIcon } from '@common/PriorityIcon';
import { ButtonSize, ButtonVariant, IconButton } from '@kit/ui/Button';
import length from '@turf/length';
import { lineString } from '@turf/helpers';
import { Route } from '@hooks/geolocation';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import {
  Container,
  FirstColumn,
  FirstRow,
  Task,
  TaskFirstRow,
  TaskIndex,
  TaskRoute,
  TasksContainer,
  TaskSecondRow,
  TaskSeparator,
  TaskSeparatorLine,
  TaskTitle,
  TaskTitleNoShrink,
  TaskTitleShrink,
  TaskVisit,
  WorkerHeader,
  WorkerSubtitle,
  WorkerTitle
} from './styled';
import { TaskWithDates } from '../types';

type Props = {
  worker: MainUser;
  dayPlan: TaskWithDates[];
  dayRoute: Geolocation[];
  plannedRoute: Route;
  handleClose: () => void;
} & React.HTMLAttributes<any>;

export const WorkerPopup = forwardRef(
  ({ worker, dayPlan = [], dayRoute = [], plannedRoute = null, handleClose, ...rest }: Props, ref) => {
    const { openDrawer } = useDrawersContext();
    const openTaskDrawer = (taskId: number) => openDrawer(DrawerEntity.WORK_ORDER, taskId, []);

    return (
      <Container ref={ref} {...rest}>
        <FirstRow>
          <WorkerHeader>
            <Avatar user={worker} size={32} />
            <div>
              <WorkerTitle>{getFullName(worker)}</WorkerTitle>
              <WorkerSubtitle>{worker.email}</WorkerSubtitle>
            </div>
          </WorkerHeader>
          <IconButton variant={ButtonVariant.Flat} size={ButtonSize.Small} onClick={handleClose}>
            <X size={16} color="#9C9CAA" />
          </IconButton>
        </FirstRow>

        <TasksContainer>
          {dayPlan.map((task, index, tasks) => {
            const taskInterval = Interval.fromDateTimes(
              DateTime.fromJSDate(task.startDate),
              DateTime.fromJSDate(task.endDate)
            );

            const taskRoute = dayRoute.filter((location) => location.taskId === task.id);

            const actualDuration =
              taskRoute.length >= 2
                ? DateTime.fromISO(taskRoute[taskRoute.length - 1].timestamp).diff(
                    DateTime.fromISO(taskRoute[0].timestamp)
                  )
                : null;

            const actualDistance =
              taskRoute.length >= 2
                ? length(lineString(taskRoute.map((location) => [location.longitude, location.latitude])), {
                    units: 'meters'
                  })
                : null;

            const hasActual = !!actualDuration && !!actualDistance;

            let plannedDuration = null;
            let plannedDistance = null;
            if (plannedRoute) {
              const firstTaskLegIndex =
                plannedRoute.legs.length === tasks.length
                  ? 0 // = fist leg is worker going from "home" to the first task site
                  : -1; // = legs are exactly drives between tasks sites

              const taskLeg = plannedRoute.legs[index + firstTaskLegIndex];
              if (taskLeg) {
                plannedDuration = Duration.fromMillis(taskLeg.duration * 1000);
                plannedDistance = taskLeg.distance;
              }
            }

            const hasPlanned = !hasActual && !!plannedDuration && !!plannedDistance;

            return (
              <>
                {hasActual || hasPlanned ? (
                  <>
                    {index === 0 ? (
                      <FirstColumn>
                        <Home size={12} color="#9C9CAA" />
                      </FirstColumn>
                    ) : (
                      <div />
                    )}

                    <TaskRoute key={`${task.id}-route`} actual={hasActual}>
                      {hasActual && `${formatTripDuration(actualDuration)} (${formatKilometers(actualDistance)} km)`}
                      {hasPlanned && `${formatTripDuration(plannedDuration)} (${formatKilometers(plannedDistance)} km)`}
                    </TaskRoute>
                  </>
                ) : null}

                <FirstColumn>
                  {(index > 0 || hasActual || hasPlanned) && (
                    <TaskSeparator key={`${task.id}-separator`} first={index === 0} short={!hasActual && !hasPlanned}>
                      <TaskSeparatorLine actual={hasActual} first={index === 0} short={!hasActual && !hasPlanned} />
                    </TaskSeparator>
                  )}
                  <TaskIndex key={`${task.id}-index`}>{index + 1}</TaskIndex>
                </FirstColumn>

                <Task
                  key={`${task.id}-task`}
                  labelColor={task.firstLabel?.color}
                  labelFill={task.isColored ? task.firstLabel?.color : null}
                  onClick={() => openTaskDrawer(task.id)}
                >
                  <TaskFirstRow>
                    <TaskTitle>
                      <TaskTitleNoShrink>
                        <WorkOrderTypeIcon isField={task.isField} size="16px" />
                      </TaskTitleNoShrink>
                      <TaskTitleShrink>
                        {task.uid ? `#${task.uid} · ` : ''}
                        {task.title}
                      </TaskTitleShrink>
                      <TaskTitleNoShrink>
                        <PriorityIcon priority={task.priority} />
                      </TaskTitleNoShrink>
                    </TaskTitle>

                    <WorkOrderStatus status={task.taskStatus} />
                  </TaskFirstRow>

                  <TaskSecondRow darkMode={task.isColored && !!task.firstLabel?.color}>
                    <div>{formatVisitInterval(taskInterval)}</div>
                    <div>{taskInterval.length('hours').toLocaleString('en-US', { maximumFractionDigits: 1 })} hr</div>
                    {task.visitIndex >= 0 && (
                      <TaskVisit>
                        <Calendar size={12} />
                        {formatOrdinal(task.visitIndex + 1)} visit
                      </TaskVisit>
                    )}
                  </TaskSecondRow>
                </Task>
              </>
            );
          })}
        </TasksContainer>
      </Container>
    );
  }
);
