import { ReactQueryKey } from '@enums';
import { useActivity } from '@hooks/useActivity';
import { useQueryClient } from 'react-query';

interface Props {
  id: number;
  parentId?: number;
}

export const FeedItemToLoad = ({ id, parentId }: Props) => {
  const queryClient = useQueryClient();

  useActivity({
    feedItemId: parentId ?? id,
    onSuccess: (data) => {
      const feedItem = data?.pages?.[0]?.results?.[0];

      if (feedItem) {
        queryClient.setQueriesData([ReactQueryKey.ProjectActivity, ReactQueryKey.DispatcherActivity], (oldData) => {
          if (!oldData?.pages) {
            return oldData;
          }

          // replace the feed item with the new one
          // if it is a reply, we need to replace the feed item with the new one and remove old parent item
          const newPages = oldData.pages.map((page) => {
            const newResults = page.results.map((item) => {
              if (item.id === feedItem.id) {
                return feedItem;
              }

              if (item.id === parentId) {
                return null;
              }

              return item;
            });

            return {
              ...page,
              results: newResults.filter((item) => item !== null)
            };
          });

          return {
            ...oldData,
            pages: newPages
          };
        });
      }
    }
  });

  return null;
};
