import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import integrationsAPI from '@services/api/integrationsAPI';
import { IntegrationProgress } from '@types';
import { UseQueryOptions } from 'react-query/types/react/types';

export const useIntegrationsProgress = (integrationIds: number[], opts?: UseQueryOptions<IntegrationProgress[]>) =>
  useQuery(
    [ReactQueryKey.Integration, 'useIntegrationsProgress', integrationIds],
    async () => {
      try {
        if (integrationIds.length === 0) {
          return [];
        }

        const data = await Promise.all(
          integrationIds.map(async (id) => {
            const progress = (await integrationsAPI.getInitiateProgress(id)).data;

            return {
              ...progress,
              integrationId: id
            };
          })
        );

        return data;
      } catch (e) {
        throw apiErrorHandler('Error fetching integrations progress', e);
      }
    },
    {
      ...opts
    }
  );
