import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useConnectEmailAccount } from '@hooks/workspace/emails/useConnectEmailAccount';
import { useDisconnectEmailAccount } from '@hooks/workspace/emails/useDisconnectEmailAccount';
import { EmailAccount } from '@generated/types/graphql';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useUpdateEmailAccount } from '@hooks/workspace/emails/useUpdateEmailAccount';
import { Body, Fields, Footer } from './styled';
import { AccessList, MemberSetting, SendAndReceiveOption } from '../../AccessList';

const OPTIONS: SendAndReceiveOption[] = [
  {
    id: 1,
    name: 'Send and receive only their notifications',
    description: 'User can send emails and receive Inbox notifications about replies on their emails',
    canSend: true,
    canReceiveAll: false
  },
  {
    id: 2,
    name: 'Send and receive all notifications',
    description: 'User can send emails and receive Inbox notifications about all replies on all member’s emails',
    canSend: true,
    canReceiveAll: true
  },
  {
    id: 3,
    name: 'Can’t send, but receive all notifications',
    description: 'User can’t send emails, but receive Inbox notifications about all replies on all member’s emails',
    canSend: false,
    canReceiveAll: true
  }
];

type FormValues = {
  email: string;
  alias: string;
};

interface Props {
  initialValues?: EmailAccount;
  onClose: () => void;
}

export const ConnectEmailForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;

  const accessListRef = useRef<MemberSetting[]>([]);

  const { mutateAsync: connect } = useConnectEmailAccount();
  const { mutateAsync: update } = useUpdateEmailAccount();
  const { mutateAsync: disconnect } = useDisconnectEmailAccount();
  const confirmDelete = useConfirmDeleteModal();

  const [isDisconnecting, setIsDisconnecting] = useState(false);

  const handleDisconnect = useCallback(async () => {
    if (
      await confirmDelete(
        `You're about to disconnect this email account. \n
        This will result in all emails sent to and from this account to be removed from Coperniq Clients and
        Projects.`,
        'Disconnect'
      )
    ) {
      setIsDisconnecting(true);
      await disconnect(initialValues.id);
      onClose();
      setIsDisconnecting(false);
    }
  }, [initialValues, disconnect, onClose, confirmDelete]);

  const postForm = async (values: FormValues) => {
    if (isNew) {
      const url = await connect({
        email: values.email,
        redirectTo: `${window.location.pathname}?email=${values.email}`,
        alias: values.alias
      });

      window.location.href = url;
    } else {
      const users = accessListRef.current.map((member) => ({
        userId: member.user.id,
        canSend: member.sendAndReceive.canSend,
        canReceiveAll: member.sendAndReceive.canReceiveAll
      }));

      await update({ id: initialValues.id, alias: values.alias, users });
      onClose();
    }
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues
      ? {
          email: initialValues.emailAddress,
          alias: initialValues.alias
        }
      : undefined
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      email: {
        isRequired: true,
        pattern: {
          value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,63}$/,
          message: 'Invalid email address'
        }
      },
      alias: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <Fields>
          <InputField disabled={!isNew} label="Email" name="email" control={control} />
          <InputField label="Name" placeholder='Recipients will see this as "From:"' name="alias" control={control} />
        </Fields>
        {!isNew && (
          <AccessList
            ref={accessListRef}
            placeholder="No one can use this email yet"
            users={initialValues.emailAccountUsers}
            sendAndReceiveOptions={OPTIONS}
          />
        )}
      </Body>
      <Footer>
        {!isNew && (
          <Button disabled={isSubmitting || isDisconnecting} variant={ButtonVariant.Danger} onClick={handleDisconnect}>
            Disconnect
          </Button>
        )}
        <Button disabled={isDisconnecting || isSubmitting} variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || isDisconnecting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Connect' : 'Save'}
        </Button>
      </Footer>
    </Form>
  );
};
