import { useAppSelector } from '@hooks/store';
import { getToken } from '@services/api/base/axios';
import { selectUser, selectWorkspaceId } from '@state/selectors';
import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import * as io from 'socket.io-client';
import { useQueryClient } from 'react-query';
import { ReactQueryKey } from '@enums';
import { config } from '../../../../config';
import { FEED_TYPES } from './constants';

const socketInstance: { current: io.Socket | null } = {
  current: null
};

export const useSubscribeToUpdates = () => {
  const user = useAppSelector(selectUser);
  const token = getToken();
  const companyId = useAppSelector(selectWorkspaceId);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (user.userId && user.userId !== 0 && (socketInstance.current === null || !socketInstance.current.connected)) {
      // @ts-ignore
      socketInstance.current = io(`${config.socketIoUrl}`, {
        query: `jwt=${token}`,
        path: '/socket',
        rejectUnauthorized: false
      });

      socketInstance.current?.on('connect', () => {
        // socketInstance.current?.emit('join', `WORK-ORDER-CHANGES-${companyId}`);
        socketInstance.current?.emit('join', `FEED-CHANGES-${companyId}`);
        // can also leave channel by emitting @{leave} event
      });

      socketInstance.current?.on('FEED-CREATED', (payload) => {
        const shouldBeInserted = FEED_TYPES.some(({ entity, event }) => {
          return event === payload.eventType && entity === payload.event;
        });

        if (shouldBeInserted) {
          queryClient.setQueriesData([ReactQueryKey.ProjectActivity, ReactQueryKey.DispatcherActivity], (oldData) => {
            if (!oldData?.pages) {
              return oldData;
            }

            const { pages } = oldData;
            const firstPage = {
              ...oldData.pages[0],
              results: [
                { id: payload.id, parentId: payload.parentFeedId, shouldBeFetched: true },
                ...oldData.pages[0].results
              ]
            };

            return {
              ...oldData,
              pages: [firstPage, ...pages.slice(1)]
            };
          });
        }
      });
    }

    return () => {
      if (socketInstance.current != null) {
        socketInstance.current.disconnect();
      }
    };
  }, [user, companyId, queryClient, token]);
};
