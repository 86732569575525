import styled, { css } from 'styled-components';
import { scroll } from '@styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border: 1px solid #dfdfe8;
  border-radius: 16px;
  background-color: #ffffff;
  max-width: 370px;
  width: 370px;
`;

export const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding-bottom: 10px;
`;

export const WorkerHeader = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const WorkerTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #1d1d35;
`;

export const WorkerSubtitle = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const TasksContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 8px;
  overflow: auto;
  ${scroll};
`;

export const TaskIndex = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #000000;
`;

export const Task = styled.div<{ labelColor?: string; labelFill?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 8px 8px 8px 12px;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  color: #1d1d35;
  min-width: 32px;
  cursor: pointer;

  ${(props) =>
    props.labelColor &&
    css`
      border-left: 4px solid ${props.labelColor};
    `}

  ${(props) =>
    props.labelFill &&
    css`
      border-color: ${props.labelFill};
      background-color: ${props.labelFill};
      color: #ffffff;

      svg {
        stroke: #ffffff;
      }
    `}
`;

export const TaskFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  min-width: 32px;
`;

export const TaskTitle = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  min-width: 32px;
`;

export const TaskTitleShrink = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TaskTitleNoShrink = styled.div`
  min-width: 16px;
`;

export const TaskSecondRow = styled.div<{ darkMode: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;

  &,
  * {
    color: ${(props) => (props.darkMode ? '#E4E8EC' : '#828D9A')};
    stroke: ${(props) => (props.darkMode ? '#E4E8EC' : '#828D9A')};
  }
`;

export const TaskVisit = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const TaskSeparator = styled.div<{ first: boolean; short: boolean }>`
  justify-self: center;
  transform: rotate(90deg) translateY(-1px)
    ${(props) => (props.short && 'translateX(-44px)') || (props.first && 'translateX(-28px)') || 'translateX(-64px)'};
`;

export const TaskSeparatorLine = styled.div<{ actual: boolean; first: boolean; short: boolean }>`
  border-top: 1px;
  border-top-style: ${(props) => (props.actual ? 'solid' : 'dashed')};
  border-top-color: ${(props) => (props.actual ? '#828D9A' : '#C8C8D3')};
  height: 0;
  width: ${(props) => (props.short && '44px') || (props.first && '28px') || '64px'};
  position: absolute;
`;

export const TaskRoute = styled.div<{ actual: boolean }>`
  min-height: 12px;
  font-size: 10px;
  font-weight: 400;
  color: ${(props) => (props.actual ? '#1D1D35' : '#828D9A')};
`;

export const FirstColumn = styled.div`
  justify-self: center;
`;
