import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { navigate } from 'gatsby';
import styled from 'styled-components';

import Props, { DropLast } from './types';
import {
  formLogin,
  googleLogin,
  registerCompany,
  inviteFormLogin,
  inviteGoogleLogin,
  checkInviteValidity
} from '../../services/AuthenticationServices/AuthenticationService';
import SignIn from './SignIn';
import { device } from '../../styles';
import login from '../../assets/img/right.jpg';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Alert from '../common/Alert';

const AuthenticationWrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const AuthenticationLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0 20px;

  ${device.sm`
    width: 50%;
  `}
`;

const AuthenticationRight = styled.div`
  display: none;
  background-color: rgba(29, 29, 53, 0.8);
  background-image: url(${login});
  background-size: cover;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  ${device.sm`
    display: block;
    width: 50%;
  `}
`;

const Authentication: React.FC<IProps> = (props: IProps) => {
  const {
    loading,
    onFormLogin,
    onInviteFormLogin,
    onGoogleLogin,
    checkInviteValidityHandler,
    error,
    isInvitePage,
    isForgotPassword
  } = props;

  const onFormLoginSubmit = (data: {
    email: string;
    password: string;
    inviteToken: string;
  }) => {
    if (isInvitePage) {
      onInviteFormLogin(data);
    } else {
      onFormLogin(data);
    }
  };

  const redirectToSignIn = () => {
    navigate('/authentication');
  };

  const onMountCheckInviteValidity = (
    ...params: DropLast<Parameters<typeof checkInviteValidity>>
  ) => {
    checkInviteValidityHandler(...params, redirectToSignIn);
  };

  let content = (
    <SignIn
      submit={onFormLoginSubmit}
      googleSubmit={onGoogleLogin}
      loading={loading}
      error={error}
      isInvitePage={isInvitePage}
      checkValidity={onMountCheckInviteValidity}
    />
  );
  if (isForgotPassword) {
    content = <ForgotPassword />;
  }

  return (
    <AuthenticationWrapper>
      <AuthenticationLeft>{content}</AuthenticationLeft>
      <AuthenticationRight />
      <Alert />
    </AuthenticationWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: state.authentication.loading,
  error: state.authentication.error
});

const mapDispatchToProps = (dispatch) => ({
  onFormLogin: (data: { email: string; password: string }) =>
    dispatch(formLogin(data)),
  onInviteFormLogin: (data: {
    email: string;
    password: string;
    inviteToken: string;
  }) => dispatch(inviteFormLogin(data)),
  onGoogleLogin: (token: string) => {
    dispatch(googleLogin(token));
  },
  checkInviteValidityHandler: (
    ...params: Parameters<typeof checkInviteValidity>
  ) => {
    dispatch(checkInviteValidity(...params));
  },
  onInviteGoogleLogin: (token: string, inviteToken: string) => {
    dispatch(inviteGoogleLogin(token, inviteToken));
  },
  onRegisterCompany: (data: {
    companyName: string;
    firstName: string;
    lastName: string;
    password: string;
    email: string;
  }) => {
    dispatch(registerCompany(data));
  }
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & Props;

export default connector(Authentication);
