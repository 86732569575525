import styled from 'styled-components';
import { fonts } from '@styles';

export const TooltipContent = styled.div`
  padding: 8px 4px;
  font-size: 14px;
  font-weight: 400;
  font-family: ${fonts.primary};
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  margin-bottom: 8px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WorkOrderItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  cursor: pointer;
  padding: 0px 4px;

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const WorkOrderItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  svg {
    flex-shrink: 0;
  }
`;

export const SeeAll = styled.div`
  cursor: pointer;
  color: #235dff;
  display: flex;
  align-items: center;
  gap: 4px;
`;
