import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import workflowsApi from '@services/api/workflowsApi';
import { AutomationFromApi, StepFromApi } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '..';

type Params = {
  req: Pick<StepFromApi, 'key' | 'fields'>;
  automationId: number;
  stepId: number;
};

export const useUpdateAutomationStep = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<StepFromApi, Error, Params>(
    async ({ automationId, stepId, req }) => {
      try {
        const { data: step } = await workflowsApi.updateStep(automationId, stepId, req);

        return step as StepFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ automationId, stepId, req }) => {
        await queryClient.cancelQueries([ReactQueryKey.ProjectWorkflows]);

        const previousWorkflows = queryClient.getQueryData<AutomationFromApi[]>([ReactQueryKey.ProjectWorkflows]);

        queryClient.setQueryData<AutomationFromApi[] | undefined>([ReactQueryKey.ProjectWorkflows], (old) => {
          if (!old) {
            return old;
          }

          return old.map((automation) => {
            if (automation.id === automationId) {
              return {
                ...automation,
                steps: automation.steps.map((step) => {
                  if (step.id === stepId) {
                    return {
                      ...step,
                      ...req
                    };
                  }

                  return step;
                })
              };
            }

            return automation;
          });
        });

        return { previousWorkflows };
      },
      onError: (err, variables, context) => {
        if (context?.previousWorkflows) {
          queryClient.setQueryData<AutomationFromApi[]>([ReactQueryKey.ProjectWorkflows], context.previousWorkflows);
        }
      },
      onSuccess: () => {
        showSuccess('Automation updated successfully');
      }
    }
  );
};
