import React, { useCallback, useMemo } from 'react';

import { useAppSelector } from '@hooks/index';
import { selectCompanySettings } from '@state/selectors';
import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useConfirmDeleteModal, useConfirmModal, useModal } from '@common/PromiseModal';
import { Badge } from '@kit/ui/Badge';
import { Check, Pause, Play, XCircle } from 'react-feather';
import { BadgeSize } from '@kit/ui/Badge/types';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { useMonitorAllSystems } from '@hooks/systems/useMonitorAllSystems';
import { formatNumber } from '@utils/utils';
import { DateTime } from 'luxon';
import { useHistoricalHits } from '@hooks/workspace/systems/useHistoricalHits';
import { IntegrationSystemLogGroupBy } from '@generated/types/graphql';
import { IntegrationProgress } from '@types';
import { Loader } from '@kit/ui/Loader';
import { useDeleteIntegration } from '@hooks/workspace/systems/integrations';
import { Title, Card, Header, HeaderRight, Description, Logo, List, LimitTitle, LimitProgress } from './styled';
import { ApiHitsLimitType, ProviderConfig } from '../types';
import { PROVIDER_CONFIGS } from '../constants';

type Provider = ProviderConfig & {
  isInstalled: boolean;
  integrationId?: number;
  isActive: boolean;
  isInitializing: boolean;
};

interface Props {
  progress: IntegrationProgress[];
}

export const ProviderList = ({ progress }: Props) => {
  const settings = useAppSelector(selectCompanySettings);

  const { data: installedIntegrations } = useIntegrations();

  const isMockShown = settings?.features?.fleetMock;

  const providers = useMemo<Provider[]>(() => {
    return PROVIDER_CONFIGS.map((provider) => {
      const activeIntegration = installedIntegrations?.find((integration) => integration.provider === provider.id);

      if (provider.isMock && !isMockShown) {
        return false;
      }

      return {
        ...provider,
        integrationId: activeIntegration?.id,
        isInstalled: Boolean(activeIntegration),
        isActive: activeIntegration?.active,
        isInitializing: progress.some((p) => p.integrationId === activeIntegration?.id && p.inProgress)
      };
    })
      .filter(Boolean)
      .toSorted((a, b) => {
        if (a.isInstalled === b.isInstalled) {
          return 0;
        }

        return a.isInstalled ? -1 : 1;
      });
  }, [installedIntegrations, isMockShown, progress]);

  return (
    <div>
      <Title>Source</Title>

      <List>
        {providers.map((provider) => (
          <ProviderCard key={provider.id} provider={provider} />
        ))}
      </List>
    </div>
  );
};

const ProviderCard = ({ provider }: { provider: Provider }) => {
  const { openModal } = useModal();
  const confirm = useConfirmModal();
  const confirmDelete = useConfirmDeleteModal();

  const hitsRequestParams =
    provider.apiHitsLimit?.type === ApiHitsLimitType.Monthly
      ? {
          from: DateTime.now().startOf('month').toJSDate(),
          to: DateTime.now().endOf('month').toJSDate(),
          groupBy: IntegrationSystemLogGroupBy.CreatedAtTruncatedToMonth
        }
      : {
          from: DateTime.now().minus({ day: 1 }).startOf('day').toJSDate(),
          to: DateTime.now().minus({ day: 1 }).endOf('day').toJSDate(),
          groupBy: IntegrationSystemLogGroupBy.CreatedAtTruncatedToDay
        };

  const { data: historicalHits } = useHistoricalHits(hitsRequestParams, {
    isEnabled: Boolean(provider.isInstalled && provider.apiHitsLimit)
  });

  const currentHits = useMemo(() => {
    if (!historicalHits) {
      return 0;
    }

    return Object.values(historicalHits).reduce((acc, hits) => acc + hits[provider.id], 0);
  }, [historicalHits, provider.id]);

  const { mutateAsync: monitorAll } = useMonitorAllSystems();
  const { mutateAsync: remove } = useDeleteIntegration();

  const handleInstallClick = useCallback(() => {
    const Form = provider.connectForm;

    openModal<void>(({ onClose }) => <Form isMock={provider.isMock} onClose={onClose} />, {
      title: `${provider.name} Source`
    });
  }, [provider, openModal]);

  const contextMenuItems = useMemo<MenuItem[]>(() => {
    if (!provider.isInstalled) {
      return [];
    }

    return [
      provider.isActive && {
        icon: <Pause size="16px" color="#9C9CAA" />,
        title: 'Pause integration',
        onClick: () => {
          monitorAll({ integrationId: provider.integrationId, monitor: false });
        }
      },
      !provider.isActive && {
        icon: <Play size="16px" color="#9C9CAA" />,
        title: 'Re-start integration',
        onClick: async () => {
          if (
            await confirm({
              confirmText: `Once you start the Fleet Systems Monitoring integration, we will begin tracking and monitoring Systems based on your Fleets configuration. Please ensure that you have:

	1.	Assigned all Systems to their respective Fleets.
	2.	Set up the required budget.
	3.	Configured appropriate notifications and alerts.

Do you want to proceed?
            `
            })
          ) {
            monitorAll({ integrationId: provider.integrationId, monitor: true });
          }
        }
      },

      // After discussion with the team, we decided to remove the ability to uninstall integrations
      // from the UI. This is because it's a destructive action and we don't want to make it too easy
      false && {
        icon: <XCircle size="16px" color="#D54855" />,
        title: 'Uninstall integration',
        onClick: async () => {
          if (
            await confirmDelete(
              'Are you sure you want to uninstall integration with SolarEdge? All your SolarEdge systems will be deleted!',
              'Uninstall'
            )
          ) {
            await remove(provider.integrationId);
          }
        }
      }
    ].filter(Boolean);
  }, [provider, monitorAll, confirm, confirmDelete, remove]);

  return (
    <Card>
      <Header>
        <Logo>
          <img src={provider.logo} alt={provider.name} />
        </Logo>
        <Description>{provider.description}</Description>
        <HeaderRight>
          {provider.isInstalled && !provider.isInitializing && (
            <>
              {provider.isActive && (
                <Badge size={BadgeSize.Large} bgColor="#CDF3DF" color="#009A47">
                  <Check size="16px" />
                  Installed
                </Badge>
              )}

              {!provider.isActive && (
                <Badge color="#778CA2" bgColor="#E4E8EC" size={BadgeSize.Large}>
                  <Pause size="16px" />
                  Paused
                </Badge>
              )}

              <ContextMenu items={contextMenuItems} />
            </>
          )}

          {provider.isInstalled && provider.isInitializing && (
            <Badge color="#778CA2" bgColor="#E4E8EC" size={BadgeSize.Large}>
              <Loader size={20} /> Initializing...
            </Badge>
          )}

          {!provider.isInstalled && (
            <Button onClick={handleInstallClick} variant={ButtonVariant.Primary}>
              Install
            </Button>
          )}
        </HeaderRight>
      </Header>

      {provider.isInstalled && provider.apiHitsLimit && !provider.isInitializing && (
        <div>
          <LimitTitle>
            <div>
              Total Provider API calls{' '}
              {provider.apiHitsLimit.type === ApiHitsLimitType.Monthly
                ? `in ${DateTime.now().toFormat('MMMM, y')}`
                : `yesterday, ${DateTime.now().minus({ day: 1 }).toFormat('DD')}`}{' '}
              / Provider {provider.apiHitsLimit.type} limit
            </div>
            <div>
              {formatNumber(currentHits)} / {formatNumber(provider.apiHitsLimit.limit)}
            </div>
          </LimitTitle>

          <LimitProgress>
            <progress max="100" value={(currentHits / provider.apiHitsLimit.limit) * 100} />
          </LimitProgress>
        </div>
      )}
    </Card>
  );
};
