import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles
} from '@material-ui/core';
import { ModalFooter } from '@common/PromiseModal';
import { Badge } from '@kit/ui/Badge';

export const List = styled.div`
  margin-top: 16px;

  form {
    width: 100%;
  }
`;

export const Accordion = withStyles({
  root: {
    borderTop: '1px solid #DFDFE8',
    boxShadow: 'none',
    margin: '0 -24px',
    padding: '0 24px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 'auto -24px'
    }
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    minHeight: 56,
    overflow: 'hidden',
    alignItems: 'center',
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 56
    }
  },

  content: {
    overflow: 'hidden',
    alignItems: 'center',
    gap: '16px',
    '&.Mui-expanded': {
      margin: '12px 0'
    }
  }
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    transition: 'background-color 0.15s ease-in-out',
    padding: '0 0 16px 0',
    '& > div': {
      width: '100%'
    }
  }
}))(MuiAccordionDetails);

export const FormActions = styled(ModalFooter)`
  padding: 16px 0 0;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TitleRight = styled.div`
  margin-left: auto;
`;

export const TitleAndDefault = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 32px;

  > * {
    margin-bottom: 0;
  }

  > *:first-child {
    flex: 1;
  }

  > *:last-child:not(:first-child) {
    margin-top: 16px;
  }
`;

export const ProviderFormContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid #dfdfe8;
  padding-top: 24px;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const ProviderFormHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
`;

export const Logo = styled.div`
  flex-shrink: 0;
  display: flex;

  img {
    height: 24px;
    width: auto;
  }
`;

export const MetricList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const MetricsGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 28px;
`;

export const EnabledMetric = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;

  svg {
    flex-shrink: 0;
  }
`;

export const MetricsGroupTitle = styled.div`
  margin-bottom: 12px;
`;

export const Chip = styled(Badge)<{ isDisabled?: boolean; isChecked?: boolean }>`
  border-style: ${({ isDisabled }) => (isDisabled ? 'dashed' : 'solid')};
  font-weight: 400;
  user-select: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ isChecked }) => (isChecked ? '#E4E8EC' : '#FFFFFF')};
  border-color: ${({ isChecked }) => (isChecked ? '#E4E8EC' : '#DFDFE8')};
`;
