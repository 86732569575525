import styled from 'styled-components';
import { Widget } from '../styled';

export const PieWidget = styled(Widget)`
  align-items: center;
  justify-content: center;
`;

export const PieContainer = styled.div`
  min-height: 144px;
  min-width: 144px;
  height: 144px;
  width: 144px;
`;

export const PieLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 120px;
`;

export const LegendLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  font-size: 12px;
  font-weight: 400;
`;

export const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;