import React, { useCallback } from 'react';
import { ProjectAdapted } from '@types';
import { Checkbox } from '@kit/ui/Checkbox';
import { ArrowRightIcon } from '@kit/ui/icons/ArrowRight';
import { ProgressIcon } from '@kit/ui/icons/Progress';
import { useRoutes } from '@hooks/useRoutes';
import { Status } from '@features/ProjectPortfolio/components/Status';
import { Archive } from 'react-feather';
import solarPanelImage from '@features/ProjectPortfolio/assets/solarPanel.svg';
import { Badge } from '@kit/ui/Badge';
import {
  Container,
  Image,
  Td,
  Right,
  FirstLine,
  SecondLine,
  Title,
  OverdueBadge,
  ImageAndCheckboContainer,
  CheckboxContainer,
  WorkOrderCounters,
  WorkOrderCounter,
  ImageContainer
} from './styled';

import { WorkOrderListPopover } from './WorkOrderListPopover';
import { PinnedCommentsPopover } from './PinnedCommentsPopover';

interface Props {
  project: ProjectAdapted;
  isSelected: boolean;
  toggleItem: (project: ProjectAdapted) => void;
}

export const TitleCell = React.memo(({ project, isSelected, toggleItem }: Props) => {
  const { companyId } = useRoutes();
  const handleCheckboxChange = useCallback(() => {
    toggleItem(project);
  }, [toggleItem, project]);

  const handleClick = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
  }, []);

  return (
    <Td isSelected={isSelected}>
      <Container>
        <ImageAndCheckboContainer>
          <ImageContainer>
            {!project.projectDetail.imageUrl && !project.projectDetail.streetViewUrl && (
              <img src={solarPanelImage} width="26px" alt="" />
            )}
            {(project.projectDetail.imageUrl || project.projectDetail.streetViewUrl) && (
              <Image
                style={{
                  backgroundImage: `url(${project.projectDetail.imageUrl || project.projectDetail.streetViewUrl})`
                }}
              />
            )}
          </ImageContainer>
          <CheckboxContainer>
            <Checkbox isChecked={isSelected} onChange={handleCheckboxChange} />
          </CheckboxContainer>
        </ImageAndCheckboContainer>
        <Right>
          <FirstLine>
            <Status status={project.projectDetail.status} />
            {!project.projectDetail.isActive && <Archive color="#9C9CAA" size="16px" />}
            <Title
              isGray={!project.projectDetail.isActive}
              onClick={handleClick}
              href={`/${companyId}/projects/${project.projectId}`}
            >
              <span>#{project.projectDetail.uid}:</span> {project.projectDetail.title}
            </Title>
          </FirstLine>
          <SecondLine>
            {project.projectDetail.stage && (
              <Badge maxWidth="160px" color="#1D1D35" bgColor="#DFDFE8">
                <span>{project.projectDetail.stage.name}</span>
              </Badge>
            )}
            <OverdueBadge record={project.projectDetail} />

            <WorkOrderCounters>
              <WorkOrderListPopover
                workOrders={project.projectDetail.inProgressWorkOrders}
                title={
                  <>
                    {' '}
                    <ProgressIcon color="#4D7CFE" size="16px" />
                    <div>In progress ({project.projectDetail.inProgressWorkOrders.totalCount})</div>
                  </>
                }
              >
                <WorkOrderCounter>
                  <ProgressIcon color="#4D7CFE" size="16px" />
                  <div>{project.projectDetail.inProgressWorkOrders.totalCount}</div>
                </WorkOrderCounter>
              </WorkOrderListPopover>
              <WorkOrderListPopover
                workOrders={project.projectDetail.plannedWorkOrders}
                title={
                  <>
                    {' '}
                    <ArrowRightIcon color="#4D7CFE" size="16px" />
                    <div>Planning ({project.projectDetail.plannedWorkOrders.totalCount})</div>
                  </>
                }
              >
                <WorkOrderCounter>
                  <ArrowRightIcon color="#4D7CFE" size="16px" />
                  <div>{project.projectDetail.plannedWorkOrders.totalCount}</div>
                </WorkOrderCounter>
              </WorkOrderListPopover>

              <PinnedCommentsPopover
                recordId={project.projectDetail.id}
                comments={project.projectDetail.pinnedComments.nodes}
              />
            </WorkOrderCounters>
          </SecondLine>
        </Right>
      </Container>
    </Td>
  );
});
