import React, { useCallback } from 'react';
import { Plus } from 'react-feather';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ContactForm } from '@features/ClientPortfolio/Client/Overview/Stakeholders/ContactForm';
import { SortBy } from './SortBy';
import { Search } from './Search';
import { Container, Filters, Right } from './styled';

export const Header = () => {
  const { openModal } = useModal();

  const handleAddClick = useCallback(() => {
    openModal<void>(({ onClose }) => <ContactForm onClose={onClose} />, { title: 'Create Contact' });
  }, [openModal]);

  return (
    <Container>
      <Filters>
        <Search />
        <SortBy />
      </Filters>
      <Right>
        <div>
          <Button onClick={handleAddClick} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Contact
          </Button>
        </div>
      </Right>
    </Container>
  );
};
