import React, { useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Form, FormValidationRules, SelectField, SwitchField, useForm } from '@kit/components/Form';
import { AggregateColumn, ImportMatchFoundStrategy, ImportMatchNotFoundStrategy } from '@types';
import { Layout } from 'react-feather';
import { ImportStepper } from './Stepper';
import {
  Actions,
  FieldsGrid,
  FormTitle,
  LastStep,
  LastStepForm,
  OptionDescription,
  OptionTitle,
  Separator,
  SettingsDescription,
  SettingsSection,
  SettingsTitle
} from './styled';

export type FormValues = {
  useDeduplication: boolean;

  groupBy: {
    id: number;
    name: string;
  };

  deduplicationBy: {
    id: number;
    name: string;
    propertyId: number | AggregateColumn;
  };

  actionIfMatched: {
    id: ImportMatchFoundStrategy;
    name: string;
    description: string;
  };

  actionIfNotMatched: {
    id: ImportMatchNotFoundStrategy;
    name: string;
  };
};

interface Props {
  importType: 'projects' | 'requests';
  spreadsheetColumns: { id: number; name: string; propertyId?: number | AggregateColumn }[];
  onCancel: () => void;
  onStart: (values: FormValues) => Promise<void>;
}

const renderOptionWithDescription = (option: { id: number; name: string; description: string }) => (
  <div>
    <OptionTitle>{option.name}</OptionTitle>
    <OptionDescription>{option.description}</OptionDescription>
  </div>
);

const ACTION_IF_MATCHED_OPTIONS = [
  {
    id: ImportMatchFoundStrategy.Update,
    name: 'Only add missing information',
    description:
      "Spreadsheet information will only be imported when matching fields don't exist in Coperniq. Nothing else will be changed. This is the recommended option."
  },
  {
    id: ImportMatchFoundStrategy.Create,
    name: 'Replace Record information in Coperniq',
    description:
      'Existing Record information in Coperniq will be replaced with information from the spreadsheet. Missing information will also be added.'
  },
  {
    id: ImportMatchFoundStrategy.Skip,
    name: 'Skip the Record',
    description: 'Spreadsheet rows will be skipped. Nothing will be imported'
  }
];

const ACTION_IF_NOT_MATCHED_OPTIONS = [
  { id: ImportMatchNotFoundStrategy.Create, name: 'Create a new Record' },
  { id: ImportMatchNotFoundStrategy.Skip, name: 'Skip the Record' }
];

const ALLOWED_GROUP_BY_PROPERTIES = [
  {
    id: -1,
    name: 'Title',
    mappedName: 'title'
  },
  {
    id: -3,
    name: 'Site Address',
    mappedName: 'address'
  },
  {
    id: -32,
    name: 'Contact Email',
    mappedName: 'primaryEmail'
  },
  {
    id: -33,
    name: 'Contact Phone',
    mappedName: 'primaryPhone'
  }
];

export const ImportSettings = ({ spreadsheetColumns, importType, onCancel, onStart }: Props) => {
  const postForm = async (values: FormValues) => {
    return onStart(values);
  };

  const groupByOptions = useMemo(() => {
    return ALLOWED_GROUP_BY_PROPERTIES.filter((property) =>
      spreadsheetColumns.find((column) => column.id === property.mappedName)
    );
  }, [spreadsheetColumns]);

  const deduplicationByOptions = useMemo(() => {
    return spreadsheetColumns.filter((column) => column.propertyId);
  }, [spreadsheetColumns]);

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      useDeduplication: true,
      groupBy: groupByOptions[0],
      deduplicationBy: null,
      actionIfMatched: ACTION_IF_MATCHED_OPTIONS[0],
      actionIfNotMatched: ACTION_IF_NOT_MATCHED_OPTIONS[0]
    },
    onSubmit: postForm
  });

  const {
    control,
    watch,
    formState: { isSubmitting }
  } = form;

  const [isDeduplicationUsed] = watch(['useDeduplication']);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      deduplicationBy: {
        isRequired: isDeduplicationUsed
      }
    }),
    [isDeduplicationUsed]
  );

  return (
    <LastStep>
      <ImportStepper />

      <LastStepForm>
        <Form rules={rules} onSubmit={handleSubmit}>
          <FormTitle>Customize your import</FormTitle>

          <SettingsSection isEnabled>
            <div>
              <Layout size="24px" color="#9C9CAA" />
            </div>
            <div>
              <SettingsTitle>Group related rows</SettingsTitle>
              <SettingsDescription>
                If your spreadsheet has one contact (or address) per row, the related rows can be grouped together into
                a single Client when importing. Just select which column to group by.
              </SettingsDescription>

              <FieldsGrid>
                <SelectField
                  getOptionLabel={(option) => option.name}
                  name="groupBy"
                  isClearable={false}
                  control={control}
                  label="Spreadsheet column to group rows by"
                  options={groupByOptions}
                />
              </FieldsGrid>
            </div>
          </SettingsSection>

          <Separator />

          <SettingsSection isEnabled={isDeduplicationUsed}>
            <div>
              <SwitchField name="useDeduplication" control={control} label="" />
            </div>
            <div>
              <SettingsTitle>Match existing {importType === 'projects' ? 'Projects' : 'Requests'}</SettingsTitle>
              <SettingsDescription>
                To avoid duplicates, we can check the {importType === 'projects' ? 'Projects' : 'Requests'} in Coperniq
                and compare them to your spreadsheet. If there’s a match then only missing Properties can be added,
                everything can be updated, or the row can be skipped.
              </SettingsDescription>

              <FieldsGrid>
                <SelectField
                  getOptionLabel={(option) => option.name}
                  name="deduplicationBy"
                  control={control}
                  label="Coperniq Property to compare:"
                  options={deduplicationByOptions}
                />

                <SelectField
                  getOptionLabel={(option) => option.name}
                  name="actionIfMatched"
                  control={control}
                  label="If a match is found"
                  options={ACTION_IF_MATCHED_OPTIONS}
                  renderOption={renderOptionWithDescription}
                />
              </FieldsGrid>
            </div>
          </SettingsSection>

          <Actions>
            <Button variant={ButtonVariant.Secondary} onClick={onCancel}>
              Cancel
            </Button>

            <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
              Start import
            </Button>
          </Actions>
        </Form>
      </LastStepForm>
    </LastStep>
  );
};
