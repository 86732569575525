import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-top: 24px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
`;

export const Subtitle = styled.h1`
  font-size: 14px;
  font-weight: 400;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-width: 775px;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 24px;

  border: 1px solid #dfdfe8;
  border-radius: 8px;
  background-color: #ffffff;
`;

export const SettingBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const SettingBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SettingHeader = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
`;

export const SettingDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SettingTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const SettingSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const SettingFields = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
`;

export const FieldName = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const FieldValue = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const MapContainer = styled.div`
  height: 196px;
  min-height: 196px;
  width: 100%;
`;
