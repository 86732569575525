import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 0 24px;
`;

export const Tile = styled.div<{ isSelected: boolean }>`
  flex: 1;
  height: 72px;
  border-radius: 8px;
  border: 1px solid;

  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;

  font-size: 14px;
  font-weight: 400;
  cursor: ${({ isSelected }) => (isSelected ? 'default' : 'pointer')};

  border-color: ${({ isSelected }) => (isSelected ? colors.green : 'transparent')};
  background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#FFFFFF')};

  &:hover {
    background-color: ${({ isSelected }) => (isSelected ? '#E3F6F4' : '#E3F6F4')};
  }

  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;
`;

export const TileNumber = styled.div<{ color: string }>`
  font-size: 32px;
  font-weight: 500;
  color: ${({ color }) => color};
`;
