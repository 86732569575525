import React from 'react';

export const DragHandleIcon = ({
  size = '16px',
  color = 'currentColor'
}: {
  size?: string | number;
  color?: string;
}) => (
  <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16667 2.66634C7.16667 3.40272 6.56971 3.99967 5.83333 3.99967C5.09695 3.99967 4.5 3.40272 4.5 2.66634C4.5 1.92996 5.09695 1.33301 5.83333 1.33301C6.56971 1.33301 7.16667 1.92996 7.16667 2.66634Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16667 8.00033C7.16667 8.73671 6.56971 9.33366 5.83333 9.33366C5.09695 9.33366 4.5 8.73671 4.5 8.00033C4.5 7.26395 5.09695 6.66699 5.83333 6.66699C6.56971 6.66699 7.16667 7.26395 7.16667 8.00033Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.16667 13.3333C7.16667 14.0697 6.56971 14.6667 5.83333 14.6667C5.09695 14.6667 4.5 14.0697 4.5 13.3333C4.5 12.597 5.09695 12 5.83333 12C6.56971 12 7.16667 12.597 7.16667 13.3333Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8327 2.66634C11.8327 3.40272 11.2357 3.99967 10.4993 3.99967C9.76297 3.99967 9.16602 3.40272 9.16602 2.66634C9.16602 1.92996 9.76297 1.33301 10.4993 1.33301C11.2357 1.33301 11.8327 1.92996 11.8327 2.66634Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8327 8.00033C11.8327 8.73671 11.2357 9.33366 10.4993 9.33366C9.76297 9.33366 9.16602 8.73671 9.16602 8.00033C9.16602 7.26395 9.76297 6.66699 10.4993 6.66699C11.2357 6.66699 11.8327 7.26395 11.8327 8.00033Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8327 13.3333C11.8327 14.0697 11.2357 14.6667 10.4993 14.6667C9.76297 14.6667 9.16602 14.0697 9.16602 13.3333C9.16602 12.597 9.76297 12 10.4993 12C11.2357 12 11.8327 12.597 11.8327 13.3333Z"
      fill={color}
    />
  </svg>
);
