import styled from 'styled-components';

export const MapLayersButtonContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 16px;
  right: 16px;
  text-align: right;
`;

export const MapLayersButton = styled.div`
  height: 34px;
  padding: 0 8px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  border: 1px solid #d2d9e1;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  user-select: none;
  transition: background-color 0.1s ease-in-out;

  &:hover {
    background-color: #f0f0f0;
  }
`;

export const LayersMenu = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #fff;
  border: 1px solid #d2d9e1;
  border-radius: 4px;
`;

export const Menu = styled.div`
  margin-top: 4px;
`;
