import React, { useCallback, useMemo, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { Team } from '@generated/types/graphql';
import { hasEntityAccess } from '@utils/roles';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Edit2, Plus } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useTeams, useTeamsMutations } from '@hooks/useTeams';
import { UserAvatar } from '@kit/components/UserAvatar';
import { TeamForm } from '../Form';
import { Author, Container, Description, Header, HeaderLeft, PageHeader, Table, TableContainer, Title } from './styled';

interface RowProps {
  team: Team;
}

export const TableRow = ({ team }: RowProps) => {
  const { openModal } = useModal();
  const companyId = useAppSelector(selectWorkspaceId);
  const {
    delete: { mutateAsync: deleteTeam }
  } = useTeamsMutations(companyId);

  const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);

  const confirmDelete = useConfirmDeleteModal();

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <TeamForm initialValues={team} onClose={onClose} />, {
      title: 'Edit Team'
    });
  }, [openModal, team]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        onClick: handleEdit,
        isHidden: !hasEntityAccess(team, 'edit')
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,
        onClick: async () => {
          if (await confirmDelete(`Are you sure you want to delete team "${team.name}"?`)) {
            await deleteTeam(team);
          }
        },
        isHidden: !hasEntityAccess(team, 'delete')
      }
    ].filter(({ isHidden }) => !isHidden);
  }, [confirmDelete, handleEdit, deleteTeam, team]);

  const handleContextMenu = useCallback<React.MouseEventHandler<HTMLDivElement>>((e) => {
    e.preventDefault();

    setContextMenuPosition({
      x: e.pageX,
      y: e.pageY
    });
  }, []);

  const handleCloseContextMenu = useCallback(() => {
    setContextMenuPosition(null);
  }, []);

  return (
    <tr onContextMenu={handleContextMenu} className={!hasEntityAccess(team, 'edit') && 'readonly'} onClick={handleEdit}>
      <td>{team.name}</td>
      <td>{team.teamUsers.length}</td>
      <td>
        {team.createdByUser ? (
          <Author>
            <UserAvatar user={team.createdByUser} size={20} />
            <div>
              {team.createdByUser.firstName} {team.createdByUser.lastName}
            </div>
          </Author>
        ) : (
          '-'
        )}
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {menuItems.length > 0 && (
          <ContextMenu point={contextMenuPosition} items={menuItems} onClose={handleCloseContextMenu} />
        )}
      </td>
    </tr>
  );
};

export const List = () => {
  const [search, setSearch] = useState('');
  const companyId = useAppSelector(selectWorkspaceId);
  const { data: teams } = useTeams(companyId);
  const { openModal } = useModal();

  const handleAdd = useCallback(() => {
    openModal<void>(({ onClose }) => <TeamForm onClose={onClose} />, { title: 'Create Team' });
  }, [openModal]);

  const filteredTeams = useMemo(() => {
    if (!search) {
      return teams;
    }

    const normalizedSearch = search.toLowerCase();

    return teams.filter((team) => {
      return team.name.toLowerCase().includes(normalizedSearch);
    });
  }, [search, teams]);

  return (
    <Container>
      <PageHeader>
        <Title>Teams</Title>
        <Description>Manage your teams</Description>
      </PageHeader>
      <Header>
        <HeaderLeft>
          <Button onClick={handleAdd} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Team
          </Button>
          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
      </Header>
      <TableContainer>
        <Table>
          <thead>
            <th>Name</th>
            <th>Users</th>
            <th>Created by</th>
            <th />
          </thead>
          <tbody>
            {filteredTeams.map((team) => (
              <TableRow key={team.id} team={team} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
