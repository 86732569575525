import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { WorflowApiRequest } from '@services/api/types';
import workflowsApi from '@services/api/workflowsApi';
import { AutomationFromApi } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '..';

export const useUpdateAutomation = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<AutomationFromApi, Error, { req: Partial<WorflowApiRequest>; id: number }>(
    async ({ id, req }: { req: Partial<WorflowApiRequest>; id: number }) => {
      try {
        const { data: workflow } = await workflowsApi.update(id, req);

        return workflow as AutomationFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate: async ({ id, req }) => {
        await queryClient.cancelQueries([ReactQueryKey.ProjectWorkflows]);
        const previousWorkflows = queryClient.getQueryData<AutomationFromApi[]>([ReactQueryKey.ProjectWorkflows]);
        queryClient.setQueryData<AutomationFromApi[] | undefined>([ReactQueryKey.ProjectWorkflows], (old) => {
          if (!old) {
            return old;
          }

          return old.map((workflow) => {
            if (workflow.id === id) {
              return {
                ...workflow,
                ...req
              };
            }

            return workflow;
          });
        });

        return { previousWorkflows };
      },
      onError: (err, variables, context) => {
        if (context?.previousWorkflows) {
          queryClient.setQueryData<AutomationFromApi[]>([ReactQueryKey.ProjectWorkflows], context.previousWorkflows);
        }
      },
      onSuccess: () => {
        showSuccess('Automation updated successfully');
      }
    }
  );
};
