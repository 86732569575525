import styled from 'styled-components';

export const Header = styled.div`
  margin-top: 16px;
  margin-bottom: 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`;

export const DateRangeTypeTrigger = styled.div`
  color: #009688;
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

export const PeriodSwitch = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const PeriodValue = styled.div<{ isFixedWidth?: boolean }>`
  width: ${({ isFixedWidth }) => (isFixedWidth ? '100px' : 'auto')};
  text-align: center;
`;

export const PointTooltip = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #ffffff;
  border: #f7f7fc solid 1px;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
  width: 100%;
`;

export const TooltipLabel = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const TooltipValue = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
`;

export const Legend = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  font-size: 12px;
  font-weight: 400;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
