import React, { useCallback, useImperativeHandle, useRef, useState } from 'react';
import { CompanyPhoneUser, EmailAccountUser, MainUser } from '@generated/types/graphql';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Select } from '@kit/ui/Select';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { v4 as uuid } from '@lukeed/uuid';
import { useAllCompaniesUsers } from '@hooks/useCompanyUsers';
import DropDownItem from '@common/Selector/UserSelector/DropDownItem';
import { getFullName } from '@utils/utils';
import {
  List,
  Header,
  MemberRow,
  Member,
  PlaceholderRow,
  OptionTitle,
  OptionDescription,
  Remove,
  Scrollable
} from './styled';

export interface SendAndReceiveOption {
  id: number;
  name: string;
  description: string;
  canSend: boolean;
  canReceiveAll: boolean;
}

interface Props {
  users: (CompanyPhoneUser | EmailAccountUser)[];
  sendAndReceiveOptions: SendAndReceiveOption[];
  placeholder: string;
}

export type MemberSetting = {
  id: string;
  user: MainUser;
  sendAndReceive: SendAndReceiveOption;
  isNew?: boolean;
};

const mapEmailAccountUserToMemberSetting =
  (options: SendAndReceiveOption[]) =>
  (user: EmailAccountUser): MemberSetting => ({
    id: uuid(),
    user: user.user,
    sendAndReceive:
      options.find((option) => option.canSend === user.canSend && option.canReceiveAll === user.canReceiveAll) ?? null
  });

const renderOptionWithDescription = (option: { id: number; name: string; description: string }) => (
  <div>
    <OptionTitle>{option.name}</OptionTitle>
    <OptionDescription>{option.description}</OptionDescription>
  </div>
);

export const AccessList = React.forwardRef<MemberSetting[], Props>(
  ({ users, sendAndReceiveOptions, placeholder }, ref) => {
    const scrollableRef = useRef<HTMLDivElement>(null);

    const [members, setMembers] = useState<MemberSetting[]>(
      users.map(mapEmailAccountUserToMemberSetting(sendAndReceiveOptions))
    );
    const { data: companyUsers } = useAllCompaniesUsers();

    const handleAddMember = useCallback(() => {
      setMembers((prev) => [
        ...prev,
        {
          id: uuid(),
          isNew: true,
          user: null,
          sendAndReceive: sendAndReceiveOptions[0]
        }
      ]);

      setTimeout(() => {
        scrollableRef.current?.scrollTo({ top: scrollableRef.current.scrollHeight, behavior: 'smooth' });
      }, 0);
    }, [sendAndReceiveOptions]);

    const handleRemove = useCallback(
      (id: string) => () => {
        setMembers((prev) => prev.filter((member) => member.id !== id));
      },
      []
    );

    const handleChangeUser = useCallback(
      (id: string) => (_e: any, user: MainUser) => {
        setMembers((prev) => prev.map((member) => (member.id === id ? { ...member, user } : member)));
      },
      []
    );

    const handleChangeSetting = useCallback(
      (id: string) => (_e: any, setting: SendAndReceiveOption) => {
        setMembers((prev) =>
          prev.map((member) => (member.id === id ? { ...member, sendAndReceive: setting } : member))
        );
      },
      []
    );

    useImperativeHandle(ref, () => {
      return members.filter((member) => member.user && member.sendAndReceive);
    });

    return (
      <List>
        <Header>
          <div>Member</div>
          <div>Send & Receive details</div>
          <div />
        </Header>

        {members.length === 0 && (
          <PlaceholderRow>
            <div>{placeholder}</div>
            <div />
          </PlaceholderRow>
        )}
        <Scrollable ref={scrollableRef}>
          {members.map((accessUser) => (
            <MemberRow key={accessUser.id}>
              {(!accessUser.user || accessUser.isNew) && (
                <Select
                  getOptionDisabled={(option) => members.some((member) => member.user?.id === option.id)}
                  value={accessUser.user}
                  onChange={handleChangeUser(accessUser.id)}
                  getOptionLabel={getFullName}
                  options={companyUsers}
                  noOptionsText="User not found"
                  renderOption={(option: any) => (
                    <DropDownItem id={option.id} avatarUrl={option.avatarUrl} name={getFullName(option)} />
                  )}
                />
              )}
              {!accessUser.isNew && accessUser.user && (
                <Member>
                  <UserAvatar user={accessUser.user} />
                  <div>
                    {accessUser.user.firstName} {accessUser.user.lastName}
                  </div>
                </Member>
              )}
              <Select
                value={accessUser.sendAndReceive ?? null}
                onChange={handleChangeSetting(accessUser.id)}
                isClearable={false}
                options={sendAndReceiveOptions}
                renderOption={renderOptionWithDescription}
                getOptionLabel={(option) => option.name}
              />
              <Remove onClick={handleRemove(accessUser.id)}>
                <TrashIcon size="16px" color="#D54855" />
              </Remove>
            </MemberRow>
          ))}
        </Scrollable>

        <MemberRow>
          <div>
            <Button onClick={handleAddMember} isUpperCase={false} variant={ButtonVariant.Flat}>
              + Add member
            </Button>
          </div>
          <div />
        </MemberRow>
      </List>
    );
  }
);
