import { useAppSelector } from '@hooks/store';
import { Link } from '@reach/router';
import { selectWorkspaceId } from '@state/selectors';
import React from 'react';

import { QueryParamsEnum } from '@hooks/useQueryParam';
import { Container, Selected } from './styled';

export const ViewSwitcher = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return (
    <Container>
      <Link to={`/${companyId}/scheduler?${QueryParamsEnum.CalendarViewType}=month`}>Month</Link>
      <Link to={`/${companyId}/scheduler?${QueryParamsEnum.CalendarViewType}=week`}>Week</Link>
      <Selected>List</Selected>
    </Container>
  );
};
