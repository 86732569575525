import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { selectWorkspaceId } from '@state/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import blueprintAPI from '@services/api/blueprintAPI';
import { useToast } from '..';

type CreateWorkflowPayload = {
  name: string;
  description: string;
  type: string;
};

export const useCreateWorkflow = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<number, Error, CreateWorkflowPayload>(
    async (req) => {
      try {
        const { data: workflow } = await blueprintAPI.create(companyId, req);

        return workflow.id;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Workflow created successfully');
        queryClient.invalidateQueries([ReactQueryKey.WorkflowList]);
      }
    }
  );
};
