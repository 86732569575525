import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import projectApi from '@services/api/projectApi';
import { StartImportPayload } from '@types';
import { useToast } from '..';

type StartImportResult = {
  historyId: number;
  totalRows: number;
  status: string;
};

export const useStartImport = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();

  return useMutation<StartImportResult, Error, StartImportPayload>(
    async (dto) => {
      try {
        const { data } = await projectApi.startImport(dto);

        return data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Import started`);
        queryClient.invalidateQueries([ReactQueryKey.ImportHistory]);
      }
    }
  );
};
