import { ImportRowStatus } from '@generated/types/graphql';
import { ImportHistoryListItem } from '@hooks/import/useImportHistoryList';
import { useMemo } from 'react';

export const useImportCounters = (record: ImportHistoryListItem) => {
  return useMemo(() => {
    const extractCounterFromAggregates = (status: ImportRowStatus) => {
      const counter = record.importRowsConnection.importStatusCount.find((aggregate) =>
        aggregate.status.includes(status)
      );

      return counter ? parseInt(counter.count.id, 10) : 0;
    };

    return {
      total: record.totalRows,
      created:
        extractCounterFromAggregates(ImportRowStatus.Created) + extractCounterFromAggregates(ImportRowStatus.Replaced),
      updated: extractCounterFromAggregates(ImportRowStatus.Updated),
      skipped: extractCounterFromAggregates(ImportRowStatus.Skipped),
      failed: extractCounterFromAggregates(ImportRowStatus.Failed)
    };
  }, [record]);
};
