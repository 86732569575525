import { PrivilegedTaskFilter, ReminderFilter } from '@generated/types/graphql';
import { Collapse } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { ChevronDown, ChevronRight } from 'react-feather';
import { DeepPartial } from 'redux';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useListViewTasks } from '../../useTasks';

import { Container, LoadMore, Title } from './styled';
import { Reminder, Task } from './Task';

interface Props {
  title: string;
  filter: DeepPartial<PrivilegedTaskFilter>;
  remindersFilter?: DeepPartial<ReminderFilter>;
  checkIsSelected: (id: number) => boolean;
  onToggleSelect: (id: number) => void;
  initialExpanded?: boolean;
}

export const Group = ({
  title,
  filter,
  remindersFilter,
  checkIsSelected,
  onToggleSelect,
  initialExpanded = false
}: Props) => {
  const [isOpen, setIsOpen] = useState(initialExpanded);

  const { tasks, total, fetchNextPage } = useListViewTasks({
    isEnabled: true,
    filters: filter,
    remindersFilters: remindersFilter
  });

  const toggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const handleLoadMore = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  const { openDrawer } = useDrawersContext();

  const handleTaskClick = useCallback(
    (id: number) => {
      openDrawer(
        DrawerEntity.WORK_ORDER,
        id,
        tasks.filter((task) => !('dueDate' in task)).map((task) => task.id)
      );
    },
    [openDrawer, tasks]
  );

  return (
    <Container>
      <Title onClick={toggle}>
        {isOpen ? <ChevronDown size="16px" /> : <ChevronRight size="16px" />}
        {title} ({total})
      </Title>
      <Collapse in={isOpen}>
        {tasks.map((task) =>
          'dueDate' in task ? (
            <Reminder key={task.id} task={task} />
          ) : (
            <Task
              key={task.id}
              task={task}
              isSelected={checkIsSelected(task.id)}
              onToggleSelect={onToggleSelect}
              onTaskClick={handleTaskClick}
            />
          )
        )}

        {tasks.length < total && (
          <LoadMore>
            <Button onClick={handleLoadMore} variant={ButtonVariant.Secondary}>
              Load more
            </Button>
          </LoadMore>
        )}
      </Collapse>
    </Container>
  );
};
