import React from 'react';
import { Container } from './styled';

interface Props {
  title: string;
  icon?: React.ReactNode;
  isWhiteBackground?: boolean;
}

export const Chip = ({ title, icon, isWhiteBackground = false }: Props) => {
  return (
    <Container isWhiteBackground={isWhiteBackground}>
      {icon}
      <div>{title}</div>
    </Container>
  );
};
