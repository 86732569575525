import React, { useEffect, useMemo } from 'react';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useRoleList } from '@hooks/workspace/roles/useRoleList';
import { normalizeRoleName } from '@utils/roles';
import { Breadcrumbs, Container } from './styled';
import { EditForm } from './EditForm';

interface Props {
  roleId: string;
}

export const Page = ({ roleId }: Props) => {
  const { data: roles, isLoading } = useRoleList();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const role = useMemo(() => {
    if (!roles) {
      return null;
    }

    return roles.find((role) => role.id === +roleId);
  }, [roles, roleId]);

  if (isLoading || !role) {
    return null;
  }

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">Roles</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>{normalizeRoleName(role.name)}</div>
      </Breadcrumbs>

      <EditForm initialValues={role} />
    </Container>
  );
};
