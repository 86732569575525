import { TextFieldFormik } from '@common/ui';
import { colors } from '@styles';
import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const TitleField = styled(TextFieldFormik)`
  && {
    .MuiInputBase-input {
      font-size: 20px;

      &::placeholder {
        font-size: 20px;
        color: ${colors.gray};
        font-weight: 500;
        font-style: normal;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border: none;
      border-bottom: 1px solid ${colors.gray3};
      border-radius: 0;
    }
  }
`;

export const TitleView = styled(EditableFieldContainer)`
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : colors.black)};

  @media (max-width: 767px) {
    white-space: initial;
  }
`;
