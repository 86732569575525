import React, { useCallback, useMemo, useState } from 'react';

import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Eye, Folder, Plus } from 'react-feather';
import { SearchBar } from '@common/SearchBar';
import { RecordType } from '@types';
import { usePropertyGroups } from '@hooks/usePropertyGroup';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useParams } from '@reach/router';
import { capitalize } from 'lodash';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled } from '@state/selectors';
import { GroupForm } from './GroupForm';
import {
  Title,
  Description,
  Table,
  Container,
  Header,
  HeaderLeft,
  ActionsAndSearch,
  PreviewBackground
} from './styled';
import { GroupRow } from './GroupRow';
import { PropertyForm } from './PropertyForm';
import { PropertyRow } from './PropertyRow';
import { FormPreview } from './FormPreview';

const getRecordTypeFromUrl = (type: string): RecordType => {
  switch (type) {
    case 'client':
      return RecordType.ACCOUNT;
    case 'request':
      return RecordType.DEAL;
    default:
      return RecordType.PROJECT;
  }
};

export const List = () => {
  const params = useParams();

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const recordType = getRecordTypeFromUrl(params.recordType);

  const [search, setSearch] = useState('');

  const { openModal } = useModal();

  const [isPreviewShown, setIsPreviewShown] = useState(false);

  const { data: groups } = usePropertyGroups({ scope: recordType, fullAccess: true });

  const createMenuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Plus size="16px" color="#9C9CAA" />,
        title: 'Create Property',
        onClick: () => {
          openModal<void>(({ onClose }) => <PropertyForm groups={groups} scope={recordType} onClose={onClose} />, {
            title: 'Create Property'
          });
        }
      },
      {
        icon: <Folder size="16px" color="#9C9CAA" />,
        title: `Create Group`,
        onClick: () => {
          openModal<void>(({ onClose }) => <GroupForm scope={recordType} onClose={onClose} />, {
            title: 'Create Group'
          });
        }
      }
    ];
  }, [openModal, groups, recordType]);

  const filteredGroups = useMemo(() => {
    return groups
      .map((group) => {
        return {
          ...group,
          properties: group.properties.filter((property) => {
            return property?.name?.toLowerCase()?.includes(search.toLowerCase());
          })
        };
      })
      .filter((group) => (search.length > 0 ? group.properties.length > 0 : true));
  }, [groups, search]);

  const togglePreview = useCallback(() => {
    setIsPreviewShown((prev) => !prev);
  }, []);

  const externalFormEnabled = isPortalEnabled && recordType === RecordType.DEAL;

  return (
    <Container>
      <Header>
        <div>
          <Title>{capitalize(params.recordType)} properties</Title>
          <Description>Configure your {capitalize(params.recordType)} properties</Description>
        </div>
        <ActionsAndSearch>
          <HeaderLeft>
            <ContextMenu items={createMenuItems}>
              <Button variant={ButtonVariant.Primary}>
                <Plus size="16px" />
                Property
              </Button>
            </ContextMenu>

            <SearchBar placeholder="Search property by name..." onValueChange={setSearch} />
          </HeaderLeft>

          <Button variant={ButtonVariant.Flat} onClick={togglePreview}>
            <Eye size="16px" />
            Preview form{externalFormEnabled ? 's' : ''}
          </Button>
        </ActionsAndSearch>
      </Header>

      <Table>
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Default</th>
          <th>Internal</th>
          {externalFormEnabled && <th>External</th>}
          <th>Access</th>
          <th />
        </thead>
        <tbody>
          {filteredGroups.map((group) => (
            <>
              <GroupRow
                externalFormEnabled={externalFormEnabled}
                groups={groups}
                key={group.id}
                group={group}
                scope={recordType}
              />

              {group.properties.map((property, propertyIndex) => (
                <PropertyRow
                  isSearching={search.length > 0}
                  scope={recordType as RecordType}
                  groups={groups}
                  position={propertyIndex}
                  group={group}
                  key={property.id}
                  property={property}
                  externalFormEnabled={externalFormEnabled}
                />
              ))}
            </>
          ))}
        </tbody>
      </Table>

      {isPreviewShown && (
        <PreviewBackground>
          <FormPreview recordType={recordType} onClose={togglePreview} />
        </PreviewBackground>
      )}
    </Container>
  );
};
