import { Trigger } from '@enums';

export const TRIGGER_TYPE_TITLES: { [key in Trigger]?: string } = {
  [Trigger.TASK_COMPLETED]: 'Work Order completed',
  [Trigger.TASK_STATUS_MOVEMENT]: 'Work Order status updated',
  [Trigger.DEAL_MOVEMENT]: 'Request stage updated',
  [Trigger.PROJECT_MOVEMENT]: 'Project stage updated',
  [Trigger.PROJECT_SLA_VIOLATION]: 'Project SLA violation',
  [Trigger.DEAL_SLA_VIOLATION]: 'Request SLA violation',
  [Trigger.CALL_UPSERTED]: 'Call created or updated',
  [Trigger.PROJECT_PROPERTY_UPDATED]: 'Project property updated',
  [Trigger.DEAL_PROPERTY_UPDATED]: 'Request property updated',
  [Trigger.DEAL_STATUS_MOVEMENT]: 'Request status updated',
  [Trigger.PROJECT_STATUS_MOVEMENT]: 'Project status updated'
};
