import { EnfakeLogo, EnphaseLogo, LunarEdgeLogo, SolarEdgeLogo } from '@assets/svg';
import { IntegrationProvider } from '@generated/types/graphql';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { ApiHitsLimitType, ProviderConfig } from './types';
import { EnphaseForm } from './connectForms/Enphase';
import { SolarEdgeForm } from './connectForms/SolarEdge';

export const PROVIDER_CONFIGS_MAP: Record<IntegrationProvider, ProviderConfig> = {
  [IntegrationProvider.Enphase]: {
    id: IntegrationProvider.Enphase,
    name: 'Enphase',
    logo: EnphaseLogo,
    connectForm: EnphaseForm,
    isMock: false,
    color: '#f37321',
    apiHitsLimit: {
      type: ApiHitsLimitType.Monthly,
      limit: 1_500_000
    },
    description:
      'Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers',
    metricsConfig: {
      [SystemMetric.EnergyProduction]: {
        available: true,
        enablable: true,
        defaultEnabled: true
      },
      [SystemMetric.EnergyConsumption]: {
        available: true,
        enablable: true,
        defaultEnabled: true
      },
      [SystemMetric.EnergyImport]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.EnergyExport]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.PowerProduction]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.PowerConsumption]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.PowerImport]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.PowerExport]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.Storage]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.Metadata]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Devices]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      }
    },
    metricsDescription: `Each metric takes 1 hit (for Metadata - 2 hits) to Enphase API.\nPower Consumption, Import and Export are not available in Enphase API.`,
    // hits -> dollars according to current Enphase pricing https://developer-v4.enphase.com/installer-plans
    getHitsCost: (hits: number) => Math.max(0, hits - 10000) * 0.005,
    getHitsByEnabledMetrics: (enabledMetrics: SystemMetric[]) => {
      const hits = enabledMetrics.reduce((acc, metric) => {
        if (metric === SystemMetric.Metadata) {
          return acc + 2;
        }

        return acc + 1;
      }, 0);

      return hits;
    }
  },
  [IntegrationProvider.SolarEdge]: {
    id: IntegrationProvider.SolarEdge,
    name: 'SolarEdge',
    logo: SolarEdgeLogo,
    connectForm: SolarEdgeForm,
    isMock: false,
    color: '#eb002c',
    description:
      'Get all data for solar micro-inverters, battery energy storage, and EV charging stations for your customers',
    metricsConfig: {
      [SystemMetric.EnergyProduction]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyConsumption]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyImport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyExport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerProduction]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerConsumption]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerImport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerExport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Storage]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Metadata]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Devices]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      }
    },
    metricsDescription: 'All requests are free of charge for SolarEdge.',
    getHitsCost: () => 0,
    getHitsByEnabledMetrics: (enabledMetrics: SystemMetric[]) => {
      let result = 0;

      const energyMetrics = [
        SystemMetric.EnergyConsumption,
        SystemMetric.EnergyProduction,
        SystemMetric.EnergyExport,
        SystemMetric.EnergyImport
      ];
      const powerMetrics = [
        SystemMetric.PowerConsumption,
        SystemMetric.PowerProduction,
        SystemMetric.PowerExport,
        SystemMetric.PowerImport
      ];

      if (enabledMetrics.some((metric) => energyMetrics.includes(metric))) {
        result += 1;
      }

      if (enabledMetrics.some((metric) => powerMetrics.includes(metric))) {
        result += 1;
      }

      if (enabledMetrics.includes(SystemMetric.Storage)) {
        result += 1;
      }

      if (enabledMetrics.includes(SystemMetric.Devices)) {
        result += 1;
      }

      return result;
    }
  },
  [IntegrationProvider.Enfake]: {
    id: IntegrationProvider.Enfake,
    name: 'Enfake',
    logo: EnfakeLogo,
    connectForm: EnphaseForm,
    isMock: true,
    color: '#5f6161',
    apiHitsLimit: {
      type: ApiHitsLimitType.Monthly,
      limit: 1_500_000
    },
    description: 'Enphase API Mock',
    metricsConfig: {
      [SystemMetric.EnergyProduction]: {
        available: true,
        enablable: true,
        defaultEnabled: true
      },
      [SystemMetric.EnergyConsumption]: {
        available: true,
        enablable: true,
        defaultEnabled: true
      },
      [SystemMetric.EnergyImport]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.EnergyExport]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.PowerProduction]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.PowerConsumption]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.PowerImport]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.PowerExport]: {
        available: false,
        enablable: false,
        defaultEnabled: false
      },
      [SystemMetric.Storage]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      },
      [SystemMetric.Metadata]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Devices]: {
        available: true,
        enablable: true,
        defaultEnabled: false
      }
    },
    metricsDescription: `Each metric takes 1 hit (for Metadata - 2 hits) to Enphase API.\nPower Consumption, Import and Export are not available in Enphase API.`,
    // hits -> dollars according to current Enphase pricing https://developer-v4.enphase.com/installer-plans
    getHitsCost: (hits: number) => Math.max(0, hits - 10000) * 0.005,
    getHitsByEnabledMetrics: (enabledMetrics: SystemMetric[]) => {
      const hits = enabledMetrics.reduce((acc, metric) => {
        if (metric === SystemMetric.Metadata) {
          return acc + 2;
        }

        return acc + 1;
      }, 0);

      return hits;
    }
  },
  [IntegrationProvider.LunarEdge]: {
    id: IntegrationProvider.LunarEdge,
    name: 'LunarEdge',
    logo: LunarEdgeLogo,
    connectForm: SolarEdgeForm,
    isMock: true,
    color: '#4d7cfe',
    description: 'SolarEdge API Mock',
    metricsConfig: {
      [SystemMetric.EnergyProduction]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyConsumption]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyImport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.EnergyExport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerProduction]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerConsumption]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerImport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.PowerExport]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Storage]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Metadata]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      },
      [SystemMetric.Devices]: {
        available: true,
        enablable: false,
        defaultEnabled: true
      }
    },
    metricsDescription: 'All requests are free of charge for SolarEdge.',
    getHitsCost: () => 0,
    getHitsByEnabledMetrics: (enabledMetrics: SystemMetric[]) => {
      let result = 0;

      const energyMetrics = [
        SystemMetric.EnergyConsumption,
        SystemMetric.EnergyProduction,
        SystemMetric.EnergyExport,
        SystemMetric.EnergyImport
      ];
      const powerMetrics = [
        SystemMetric.PowerConsumption,
        SystemMetric.PowerProduction,
        SystemMetric.PowerExport,
        SystemMetric.PowerImport
      ];

      if (enabledMetrics.some((metric) => energyMetrics.includes(metric))) {
        result += 1;
      }

      if (enabledMetrics.some((metric) => powerMetrics.includes(metric))) {
        result += 1;
      }

      if (enabledMetrics.includes(SystemMetric.Storage)) {
        result += 1;
      }

      if (enabledMetrics.includes(SystemMetric.Devices)) {
        result += 1;
      }

      return result;
    }
  }
};

export const PROVIDER_CONFIGS = Object.values(PROVIDER_CONFIGS_MAP);

export const FLEET_COLORS = [
  '#009688',
  '#8683DF',
  '#509DEA',
  '#71C5C5',
  '#7DC875',
  '#F8D272',
  '#F09334',
  '#AA0101',
  '#C8C8D3'
];

export const SIDEBAR_BUDGET_PORTAL_NODE_ID = 'budget-place-node';
