import React from 'react';
import styled from 'styled-components';
import { colors } from '@styles';

const LoaderElement = styled.div<{ size: number; bgcolor: string; color: string}>`
  margin: auto;
  text-indent: -9999em;
  width: ${(props) => (props.size ? `${props.size}px` : '40px')};
  height: ${(props) => (props.size ? `${props.size}px` : '40px')};
  border-radius: 50%;
  
  background: ${(props) => (props.color || colors.green)};
  background: linear-gradient(
    to right,
    ${(props) => props.color || colors.green} 10%,
    rgba(255, 255, 255, 0) 42%
  );
  position: relative;
  animation: load3 1s infinite linear;
  transform: translateZ(0);

  &:before {
    width: 50%;
    height: 50%;
    background: ${(props) => (props.color || colors.green)};
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  &:after {
    background: ${(props) => (props.bgcolor || colors.white)};
    width: 90%;
    height: 90%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @keyframes load3 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }  
`;

type Props = {
  size?: number;
  color?: string;
  bgcolor?: string;
};

const Loader = (props: Props) => {
  return <LoaderElement {...props} />;
};

export default Loader;
