import React, { useCallback, useState } from 'react';
import { PrivilegedTask } from '@generated/types/graphql';
import { ReactQueryKey } from '@enums';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { SelectedWorkerProvider } from '@components/Scheduler/Dispatcher/useSelectedWorker';
import { SelectedDateProvider } from '@components/Scheduler/Dispatcher/useSelectedDate';
import { QueryParamsEnum, useQueryParam } from '@hooks';
import { Resources } from './Resources';
import { CalendarContainer, Main, TrayContainer, Wrapper } from '../styled';
import { DispatcherTray } from './Tray';
import { DispatherHeader } from './Header';
import { Map } from './Map';
import { useTaskDrawer } from '../useTaskDrawer';
import { ActivityContainer, Content } from './styled';
import { Activity } from './Activity';
import { useSubscribeToWOUpdates } from '../useSubscribeToWOUpdates';

const isDispatched = (workOrder: PrivilegedTask) =>
  workOrder.assignee &&
  (workOrder.startDate || workOrder.endDate || workOrder.taskVisitsByTaskIdConnection.nodes.length);

const subscribeOptions = {
  trayQueryKey: ReactQueryKey.DispatcherTrayTasks,
  calendarQueryKey: ReactQueryKey.TasksDispatcher,
  checkIfWorkOrderIsInCalendar: isDispatched
};

const Dispatcher = () => {
  const [isTrayCollapsed, setIsTrayCollapsed] = useState(false);
  const [isActivityCollapsed, setIsActivityCollapsed] = useState(true);

  const { onAddClick } = useTaskDrawer();

  useSubscribeToWOUpdates(subscribeOptions);

  const handleToggleTray = useCallback(() => {
    if (!isTrayCollapsed) {
      setIsTrayCollapsed(true);
    } else {
      setIsTrayCollapsed(false);
      setIsActivityCollapsed(true);
    }
  }, [isTrayCollapsed]);

  const handleToggleActivity = useCallback(() => {
    if (!isActivityCollapsed) {
      setIsActivityCollapsed(true);
    } else {
      setIsActivityCollapsed(false);
      setIsTrayCollapsed(true);
    }
  }, [isActivityCollapsed]);

  const { openDrawer } = useDrawersContext();

  const handleTaskClick = useCallback(
    (workOrder: PrivilegedTask) => {
      openDrawer(DrawerEntity.WORK_ORDER, workOrder.id, []);
    },
    [openDrawer]
  );

  const [currentView] = useQueryParam(QueryParamsEnum.CalendarViewType);

  return (
    <SelectedWorkerProvider>
      <SelectedDateProvider>
        <Wrapper>
          <TrayContainer isCollapsed={isTrayCollapsed}>
            <DispatcherTray isCollapsed={isTrayCollapsed} onToggleIsCollapsed={handleToggleTray} />
          </TrayContainer>
          <Content>
            <DispatherHeader onAddNewTask={onAddClick} />
            <Main>
              <CalendarContainer>
                <Resources
                  isTrayCollapsed={isTrayCollapsed}
                  isActivityCollapsed={isActivityCollapsed}
                  onTaskClick={handleTaskClick}
                />
                {currentView === 'day' && <Map />}
              </CalendarContainer>
            </Main>
          </Content>
          <ActivityContainer isCollapsed={isActivityCollapsed}>
            <Activity isCollapsed={isActivityCollapsed} onToggleIsCollapsed={handleToggleActivity} />
          </ActivityContainer>
        </Wrapper>
      </SelectedDateProvider>
    </SelectedWorkerProvider>
  );
};

export { Dispatcher };
