import React, { useMemo } from 'react';
import { Switch } from '@kit/ui/Switch';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { merge } from 'lodash';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useModal } from '@common/PromiseModal';
import { RadiusMap } from '@features/Platform/GeolocationSettings/RadiusMap';
import {
  CompanyNoficationSettingsTreeItem,
  useCompanyNotifications
} from '@hooks/workspace/notifications/useCompanyNotifications';
import { EntityType } from '@generated/types/graphql';
import { NotificationEntity } from '@features/Platform/Notifications';
import { defaultGeofencing } from '@features/Platform/GeolocationSettings/const';
import { useRolesWithGeoSettings, useTeamsWithGeoSettings } from '@hooks/geolocation';
import { normalizeRoleName } from '@utils';
import {
  FieldName,
  FieldValue,
  Header,
  MapContainer,
  Panel,
  Sections,
  SettingBlock,
  SettingBody,
  SettingDescription,
  SettingFields,
  SettingHeader,
  SettingSubtitle,
  SettingTitle,
  Subtitle,
  Title
} from './styled';
import { GeolocationSettingsForm } from './Form';

export const GeolocationSettings = () => {
  const companySettings = useAppSelector(selectCompanySettings);

  const { mutateAsync: updateCompany } = useUpdateCompany();

  const handleChangeEnabled = async () => {
    await updateCompany({
      settings: {
        geolocation: {
          enabled: !companySettings?.geolocation?.enabled
        }
      }
    });
  };

  const geofencing = merge(defaultGeofencing, companySettings?.geolocation?.geofencing);

  const { openModal } = useModal();

  const handleEdit = async () => {
    await openModal<void>(({ onClose }) => <GeolocationSettingsForm onClose={onClose} />, {
      title: 'Edit “Geolocation”'
    });
  };

  const { data: notificationSettings } = useCompanyNotifications();
  const geoNotificationSetting = useMemo<CompanyNoficationSettingsTreeItem>(() => {
    const setting = notificationSettings.find((entity) => entity.entityType === EntityType.Geolocation);

    return setting
      ? {
          ...setting,
          title: 'Geolocation notifications'
        }
      : null;
  }, [notificationSettings]);

  const { data: rolesSettings } = useRolesWithGeoSettings();
  const enabledRoles = useMemo(
    () => rolesSettings.filter((role) => role.geolocationUserSettings[0]?.enabled),
    [rolesSettings]
  );

  const { data: teamsSettings } = useTeamsWithGeoSettings();
  const enabledTeams = useMemo(
    () => teamsSettings.filter((team) => team.geolocationUserSettingsByTeamId[0]?.enabled),
    [teamsSettings]
  );

  return (
    <Sections>
      <Header>
        <Title>Geolocation</Title>
        <Subtitle>Set up geolocation settings here</Subtitle>
      </Header>

      <Panel>
        <SettingBlock>
          <Switch isActive={companySettings?.geolocation?.enabled} onChange={handleChangeEnabled} />

          <SettingBody>
            <SettingHeader>
              <SettingDescription>
                <SettingTitle>Geolocation</SettingTitle>
                <SettingSubtitle>
                  Geolocation is the identification of the real-world geographic location of assignees. By using this
                  feature you are responsible for ensuring compliance with local laws and regulations.
                </SettingSubtitle>
              </SettingDescription>

              <Button
                disabled={!companySettings?.geolocation?.enabled}
                onClick={handleEdit}
                variant={ButtonVariant.Flat}
              >
                Edit
              </Button>
            </SettingHeader>

            {companySettings?.geolocation?.enabled && (
              <>
                <SettingFields>
                  <FieldName>Geolocated users:</FieldName>
                  <FieldValue>
                    {[
                      ...enabledRoles.map((role) => normalizeRoleName(role.name)),
                      ...enabledTeams.map((team) => team.name)
                    ].join(', ')}
                  </FieldValue>
                  <FieldName>Geofencing:</FieldName>
                  <FieldValue>{geofencing.enabled ? 'On' : 'Off'}</FieldValue>
                  <FieldName>Geofencing radius:</FieldName>
                  <FieldValue>{geofencing.radius} ft</FieldValue>
                  <FieldName>Clock in and out when near a location:</FieldName>
                  <FieldValue>{geofencing.auto ? 'Automatically' : 'Manually'}</FieldValue>
                  <FieldName>Block users to clock in and out outside geofenced area:</FieldName>
                  <FieldValue>{geofencing.preventClockInWithoutGeofence ? 'Yes' : 'No'}</FieldValue>
                </SettingFields>

                <MapContainer>
                  <RadiusMap radius={geofencing.radius} />
                </MapContainer>
              </>
            )}
          </SettingBody>
        </SettingBlock>
      </Panel>

      {geoNotificationSetting && <NotificationEntity entity={geoNotificationSetting} />}
    </Sections>
  );
};
