import styled from 'styled-components';

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  height: 100%;
  background-color: ${({selected}) => selected && '#DBE5FF'};
  cursor: pointer;
`;

export const Info = styled.div`
  margin-left: 8px;
`;

export const Name = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #1D1D35;
`;

export const Subtitle = styled.div`
  font-size: 10px;
  font-weight: 400;
  color: #98A9BC;
`;