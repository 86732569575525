import { useMutation, useQueryClient } from 'react-query';
import { errorHandler } from '@services/api/helpers';
import geolocationAPI from '@services/api/geolocationAPI';
import { ReactQueryKey } from '@enums';

export const useUpdateUsersGeolocation = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (dto: { roles: number[]; teams: number[] }) => {
      try {
        return await geolocationAPI.updateUsers(dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceRoles]);
        queryClient.invalidateQueries([ReactQueryKey.WorkspaceTeams]);
        queryClient.invalidateQueries([ReactQueryKey.GeolocationSettings]);
      }
    }
  );
};
