import React, { useCallback, useState } from 'react';
import { ReactQueryKey } from '@enums';
import { PrivilegedTask } from '@generated/types/graphql';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { Calendar } from './Calendar';

import { HideAppointmentTooltipGlobalStyle, Wrapper, Main, TrayContainer, CalendarContainer, Content } from '../styled';
import { SchedulerTray } from './Tray';
import { SchedulerHeader } from './Header';
import { useTaskDrawer } from '../useTaskDrawer';
import { useSubscribeToWOUpdates } from '../useSubscribeToWOUpdates';

const isScheduled = (workOrder: PrivilegedTask) =>
  workOrder.startDate || workOrder.endDate || workOrder.taskVisitsByTaskIdConnection.nodes.length;

const subscribeOptions = {
  trayQueryKey: ReactQueryKey.SchedulerTrayTasks,
  calendarQueryKey: ReactQueryKey.TasksScheduler,
  checkIfWorkOrderIsInCalendar: isScheduled
};

const Scheduler = () => {
  const [isTrayCollapsed, setIsTrayCollapsed] = useState(false);
  const { onAddClick } = useTaskDrawer();

  useSubscribeToWOUpdates(subscribeOptions);

  const handleToggleTray = useCallback(() => {
    setIsTrayCollapsed((prev) => !prev);
  }, []);

  const { openDrawer } = useDrawersContext();

  const handleTaskClick = useCallback(
    (workOrder: PrivilegedTask) => {
      openDrawer(DrawerEntity.WORK_ORDER, workOrder.id, []);
    },
    [openDrawer]
  );

  return (
    <>
      <HideAppointmentTooltipGlobalStyle />
      <Wrapper>
        <TrayContainer isCollapsed={isTrayCollapsed}>
          <SchedulerTray isCollapsed={isTrayCollapsed} onToggleIsCollapsed={handleToggleTray} />
        </TrayContainer>
        <Content>
          <SchedulerHeader onAddNewTask={onAddClick} />
          <Main>
            <CalendarContainer>
              <Calendar isTrayCollapsed={isTrayCollapsed} onTaskClick={handleTaskClick} />
            </CalendarContainer>
          </Main>
        </Content>
      </Wrapper>
    </>
  );
};

export { Scheduler };
