import styled from 'styled-components';
import { List as SystemsSettingsList } from '../styled';

export const List = styled(SystemsSettingsList)`
  [data-section] {
    > * {
      border: 2px solid #ffbd13;
    }
  }
`;

export const Warning = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: #fcf2db;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
`;

export const WarningHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
  font-size: 18px;

  font-weight: 500;

  svg {
    flex-shrink: 0;
  }
`;

export const WarningFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;
