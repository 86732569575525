import { colors } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 4px 4px;
  ${({ isMultiDay, color }) =>
    // eslint-disable-next-line no-nested-ternary
    isMultiDay
      ? css`
          background-color: ${color || '#cceae7'};
          color: ${color ? '#fff' : colors.black};
        `
      : color
      ? css`
          background-color: ${color};
          color: #fff;
        `
      : ''};
  min-height: 38px;

  border-radius: 4px;
  margin: 0 4px;
`;

export const LabelCircle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color || '#fff'};
  border: ${(props) => (props.color ? 'none' : '1px solid black')};
  display: inline-block;
  margin-bottom: 1px;
  margin-right: 4px;
  flex-shrink: 0;
`;

export const Title = styled.div`
  font-weight: 500;
  text-decoration: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const ProjectTitle = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: ${(props) => props.paddingLeft ?? ''};
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 4px;
  }
`;
