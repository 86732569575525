import styled from 'styled-components';

export const PageHeader = styled.div`
  padding: 24px 0;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
`;

export const Container = styled.div`
  display: flex;
  gap: 24px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 775px;
  padding-bottom: 64px;
  flex: 1;
`;

export const SideNav = styled.div`
  width: 140px;
  position: sticky;
  right: 0;
  top: 0;
  height: fit-content;
`;

export const SideNavItem = styled.a<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#009688' : '#828D9A')};
  font-size: 14px;
  display: block;
  font-weight: 500;
  padding: 12px 0;
  cursor: pointer;
  transition:
    color 0.2s ease-in-out,
    border-right 0.2s ease-in-out;
  text-decoration: none;
  border-right: ${({ isActive }) => (isActive ? '2px solid #009688' : '1px solid #DFDFE8')};

  &:hover {
    color: ${({ isActive }) => (isActive ? '#009688' : '#000')};
  }
`;

export const Title = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 16px;
`;

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  white-space: nowrap;

  &&& {
    .MuiOutlinedInput-input {
      color: #1d1d35;
    }
  }
`;

export const SettingsPanel = styled.div`
  padding: 24px;
  background-color: #ffffff;
  border-radius: 8px;
  border: 1px solid #dfdfe8;

  display: flex;
  gap: 8px;
`;

export const SettingsPanelToggle = styled.div`
  flex: 0 0 50px;
  text-align: center;
`;

export const SettingsPanelContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SettingsPanelHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
`;

export const SettingsPanelTitle = styled.h3<{ isDisabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
  color: ${({ isDisabled }) => (isDisabled ? '#828d9a' : '#000')};
`;

export const SettingsPanelSecondaryText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
