import React, { useCallback, useState } from 'react';
import { ProjectAdapted } from '@types';
import { Loader } from '@kit/ui/Loader';
import { Collapse } from '@material-ui/core';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Checkbox } from '@kit/ui/Checkbox';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { useGroupedRecordIds } from '../useGroupedRecordIds';
import { useColumns } from '../useColumns';
import { Container, HeaderContainer, Header, Title, LoaderContainer } from './styled';
import { TableBody } from '../TableBody';
import { useInitialGroupedRecords } from '../useRecords';
import { TableHeader } from '../TableHeader';
import { useSelectionWithGroups } from '../useSelection';
import { useClientFilterState } from '../../useClientFilterState';

interface GroupProps {
  group: {
    label: string;
    ids: number[];
    records: ProjectAdapted[];
  };

  checkIsAllSelected: (group: string) => boolean;
  onToggleAllSelected: (group: string) => void;
  checkIsItemSelected: (group: string, project: ProjectAdapted) => boolean;
  toggleItem: (group: string, project: ProjectAdapted) => void;
  onDataChange: (group: string, projects: ProjectAdapted[]) => void;
}

const Group = ({
  group,
  checkIsAllSelected,
  onToggleAllSelected,
  checkIsItemSelected,
  toggleItem,
  onDataChange
}: GroupProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const columns = useColumns();
  const { openDrawer } = useDrawersContext();

  const toggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const onToggleAllSelectedInGroup = useCallback(() => {
    onToggleAllSelected(group.label);
  }, [group.label, onToggleAllSelected]);

  const checkIsItemSelectedInGroup = useCallback(
    (project: ProjectAdapted) => {
      return checkIsItemSelected(group.label, project);
    },
    [checkIsItemSelected, group.label]
  );

  const toggleItemInGroup = useCallback(
    (project: ProjectAdapted) => {
      toggleItem(group.label, project);
    },
    [group.label, toggleItem]
  );

  const onGroupDataChange = useCallback(
    (projects: ProjectAdapted[]) => {
      onDataChange(group.label, projects);
    },
    [group.label, onDataChange]
  );

  const handleRowClick = useCallback(
    (id: number) => {
      openDrawer(DrawerEntity.REQUEST, id, group.ids);
    },
    [openDrawer, group.ids]
  );

  return (
    <Container>
      <HeaderContainer>
        <thead>
          <tr>
            <th>
              <Header>
                <Title onClick={toggle}>
                  <Checkbox isChecked={checkIsAllSelected(group.label)} onChange={onToggleAllSelectedInGroup} />
                  {isCollapsed ? (
                    <ChevronUpIcon size="24px" color="#9C9CAA" />
                  ) : (
                    <ChevronDownIcon size="24px" color="#9C9CAA" />
                  )}
                  <div>
                    {group.label} ({group.ids.length})
                  </div>
                </Title>
              </Header>
            </th>
            {columns.map((column) => (
              <th key={column.id} aria-label={column.name} />
            ))}
          </tr>
        </thead>
      </HeaderContainer>
      <Collapse in={!isCollapsed} timeout="auto">
        <TableBody
          firstPageData={group.records}
          ids={group.ids}
          checkIsItemSelected={checkIsItemSelectedInGroup}
          toggleItem={toggleItemInGroup}
          onDataChange={onGroupDataChange}
          onRowClick={handleRowClick}
        />
      </Collapse>
    </Container>
  );
};

export const GroupedTable = () => {
  const { groupedIds, isLoading: isGroupedIdsLoading } = useGroupedRecordIds();
  const {
    clientFilters: { perPage }
  } = useClientFilterState();

  const { groups, isLoading: isRecordsLoading } = useInitialGroupedRecords(
    groupedIds?.groups,
    perPage.value,
    !isGroupedIdsLoading
  );

  const {
    isAllSelected,
    toggleAllSelected,
    isItemSelected,
    toggleGroup,
    toggleItem,
    isAllInGroupSelected,
    setDataForGroup,
    selectedIds,
    isAllSelectedItemsArchived,
    clearSelection
  } = useSelectionWithGroups(groups);

  if (isGroupedIdsLoading || isRecordsLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      <TableHeader
        isAllSelected={isAllSelected}
        onToggleAllSelected={toggleAllSelected}
        selectedIds={selectedIds}
        clearSelection={clearSelection}
        isAllSelectedArchived={isAllSelectedItemsArchived}
        isGroupedData
      />
      {groups.map((group) => (
        <Group
          key={group.label}
          group={group}
          checkIsAllSelected={isAllInGroupSelected}
          onToggleAllSelected={toggleGroup}
          checkIsItemSelected={isItemSelected}
          toggleItem={toggleItem}
          onDataChange={setDataForGroup}
        />
      ))}
    </>
  );
};
