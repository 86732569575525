import styled from 'styled-components';

export const Container = styled.div`
  max-width: 775px;
`;

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

export const Header = styled.div`
  padding: 24px 0;
  margin-bottom: 32px;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const H2 = styled.h2`
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: 500;
`;

export const FieldLabel = styled.label`
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;
