import React, { useCallback } from 'react';
import {
  Container,
  FirstLine,
  FourthLine,
  Header,
  Image,
  Number,
  ProjectTitle,
  SecondLine,
  StatTitle,
  StatValue,
  ThirdLine,
  Title
} from './styled';
import { Skeleton } from '@material-ui/lab';
import { Address } from '@kit/ui/anchors/Address';
import { SystemStatus } from '@features/SystemPortfolio/components';
import { useSystemOnMap } from '@hooks/systems';
import { renderColumnPlain, SystemColumn } from '@features/SystemPortfolio/constants';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { DrawerEntity, useDrawersContext } from '@contexts/DrawersContext';
import { AccountType } from '@types';
import { Home } from 'react-feather';
import { BuildingIcon } from '@kit/ui/icons/Building';
import { Box } from '@material-ui/core';

type Props = {
  systemId: number;
};

export const SystemPopup = ({ systemId }: Props) => {
  const { data: system } = useSystemOnMap(systemId);

  const { openDrawer } = useDrawersContext();
  const handleOpenSystemClick = useCallback(() => {
    openDrawer(DrawerEntity.SYSTEM, systemId, []);
  }, [openDrawer]);

  if (!system || systemId !== system.id) {
    return (
      <Container>
        <Skeleton animation="wave" width={260} height={277} />
      </Container>
    );
  }

  return (
    <Container>
      <FirstLine>
        <Image style={{ backgroundImage: `url(${system.project.imageUrl || system.project.streetViewUrl})` }} />
        <Header>
          <ProjectTitle title={system.project.title}>
            {system.project.accountType === AccountType.RESIDENTIAL ? <Home size={12} /> : <BuildingIcon size={12} />}
            {system.project.title}
          </ProjectTitle>
          <Box display="flex">
            <Number>#{system.number}:</Number>&nbsp;<Title title={system.name}>{system.name}</Title>
          </Box>
        </Header>
      </FirstLine>

      <SecondLine>
        <Address value={system.project.address[0]} />
      </SecondLine>

      <ThirdLine>
        <SystemStatus system={system} />
      </ThirdLine>

      <FourthLine>
        <div>
          <StatValue>{renderColumnPlain(system, SystemColumn.peakPower)}</StatValue>
          <StatTitle>Peak power</StatTitle>
        </div>
        <div>
          <StatValue>{renderColumnPlain(system, SystemColumn.productionYesterday)}</StatValue>
          <StatTitle>Energy yesterday</StatTitle>
        </div>
      </FourthLine>

      <Button variant={ButtonVariant.Primary} onClick={handleOpenSystemClick}>
        View system details
      </Button>
    </Container>
  );
};
