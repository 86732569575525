import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Props from './type';

import { checkbox, redirectLink } from '../../../../styles';
import { resetPasswordResetState } from '../../../../state/actions/authentication/authenticationAction';
import { connect, ConnectedProps } from 'react-redux';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 10px 0 25px;
`;

const Checkbox = styled.div`
  ${checkbox}
`;

const StyledLink = styled(Link)`
  ${redirectLink}
`;

const RememberMeForgot: React.FC<IProps> = (props: IProps) => {
  const { onResetPasswordResetState, isInvitePage } = props;
  const [checked, setChecked] = useState(false);

  const handleClick = (event: { stopPropagation: () => void }) => {
    setChecked(!checked);
  };

  const onForgotPasswordClick = () => {
    onResetPasswordResetState();
  };
  return (
    <Wrapper>
      <Checkbox checked={checked}>
        <input
          type="checkbox"
          name="forgot"
          id="forgot"
          checked={checked}
          onChange={handleClick}
        />
        <figure onClick={handleClick} />
        <label htmlFor="forgot">Remember me</label>
      </Checkbox>

      {isInvitePage ? (
        <StyledLink to="#"> Generate strong password</StyledLink>
      ) : (
        <StyledLink to="/forgot-password" onClick={onForgotPasswordClick}>
          Forgot password
        </StyledLink>
      )}
    </Wrapper>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPasswordResetState: () => dispatch(resetPasswordResetState())
  };
};
const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type IProps = PropsFromRedux & Props;
export default connector(RememberMeForgot);
