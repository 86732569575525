import styled from 'styled-components';

export const OverbudgetSection = styled.div`
  margin: 40px 0;
`;

export const OverbudgetSectionTitle = styled.div`
  color: #828d9a;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  text-transform: uppercase;
`;

export const OverbudgetSectionDescription = styled.div`
  margin: 16px 0;
  font-size: 14px;
`;

export const RadioSelectContainer = styled.div`
  margin-left: 24px;
`;
