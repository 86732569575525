import { useDynamicDropdownValues } from '@hooks/automations/useDynamicDropdownOptions';
import { SelectField } from '@kit/components/Form';
import { AutomationField } from '@types';
import React from 'react';
import { Control } from 'react-hook-form';
import { ANY_OPTION } from './constants';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
}

export const DynamicSelectField = ({ field, isDisabled, control }: Props) => {
  const { data: options, isFetched } = useDynamicDropdownValues({ entity: field.dynamic!.entity });

  if (!options || !isFetched) {
    return null;
  }

  return (
    <SelectField
      name={field.key}
      disabled={isDisabled}
      label={field.label}
      control={control}
      options={!field.required ? [ANY_OPTION].concat(options) : options}
      getOptionLabel={(option: any) => option.value ?? option.name}
      getOptionValue={(option: any) => option.key}
    />
  );
};
