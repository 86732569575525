import React, { useMemo, useCallback } from 'react';
import { AlertTriangle } from 'react-feather';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Link, useNavigate } from '@reach/router';
import { Form, useForm, FormValidationRules } from '@kit/components/Form';
import { useSystemsStats } from '@hooks/workspace/systems/useSystemsStats';
import { useFleetList, MetricConfigs } from '@hooks/workspace/systems/fleets/useFleetList';
import { useSystemsBudget } from '@hooks/workspace/systems/useSystemsBudget';
import { isEqual } from 'lodash';
import { useModal } from '@common/PromiseModal';
import { DateTime } from 'luxon';
import { useUpdateSystemsBudget } from '@hooks/workspace/systems/useUpdateSystemsBudget';

import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { ProfileCreateDTO } from '@services/api/systemProfilesAPI';
import { Integration, IntegrationProvider } from '@generated/types/graphql';
import { v4 as uuid } from '@lukeed/uuid';
import { useDeepCompareEffect } from 'react-use';
import { SystemsSimulation } from './SystemsSimulation';
import { SystemsBreakdown } from '../SystemsBreakdown';
import { FormValues } from './types';
import { FleetsUi } from '../Fleets';
import { Header } from '../Header';
import { Container, SideNav, SideNavItem } from '../styled';
import { makeTitleHref, useNav } from '../useNav';
import { calculateExpectedBill } from '../helpers';
import { List, Warning, WarningFooter, WarningHeader } from './styled';
import { ExpectedBill } from './ExpectedBill';
import { useExpectedBill } from '../useExpectedBill';
import { ConfirmSave } from './ConfirmSave';
import { useUpdateFleets } from './useUpdateFleets';

const SECTIONS = ['Systems simulation', 'Fleets', 'Systems breakdown'];

const mapProfileCreateDTOToFleet = (dto: ProfileCreateDTO, integrations: Integration[]) => {
  return {
    id: uuid(),
    name: dto.name,
    default: dto.default,
    systemProfileConfigs: dto.configs.map((config) => ({
      frequency: config.frequency,
      metrics: config.enabledMetrics.reduce((acc, metric) => {
        acc[metric] = {
          enabled: true,
          by: 'auto'
        };

        return acc;
      }, {} as MetricConfigs),
      integration: integrations.find((integration) => integration.id === config.integration)
    }))
  };
};

export const Simulation = () => {
  const { data } = useSystemsStats();
  const { data: initialFleets = [] } = useFleetList();
  const { data: installedIntegrations } = useIntegrations();
  const { data: budgetInfo } = useSystemsBudget();
  const navigate = useNavigate();
  const { openModal } = useModal();
  const { mutateAsync: updateBudget } = useUpdateSystemsBudget();
  const updateFleets = useUpdateFleets();
  const { activeItem, onNavItemClick } = useNav();
  const currentExpectedBillByProvider = useExpectedBill(initialFleets);

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      monitoredSystems: data?.monitored || 0,
      averageMonthlyNew: data?.averageMonthlyNew || 0,
      fleets: initialFleets,
      systemsRatioByFleetId: Object.keys(data?.byFleetId || {}).reduce(
        (acc, fleetId) => {
          acc[`fleet-${fleetId}`] = data.total > 0 ? (data.byFleetId[fleetId] / data.total) * 100 : 0;

          return acc;
        },
        {} as Record<string, number>
      ),
      systemsRatioByProvider: Object.keys(data?.byIntegrationId || {}).reduce(
        (acc, integrationId) => {
          const integration = installedIntegrations.find(
            (integration) => integration.id === parseInt(integrationId, 10)
          );
          if (!integration) {
            return acc;
          }
          acc[integration.provider] = data.total > 0 ? (data.byIntegrationId[integrationId] / data.total) * 100 : 0;

          return acc;
        },
        {} as Record<IntegrationProvider, number>
      )
    },
    onSubmit: async ({ fleets }) => {
      const { currentHitsByProvider, budget } = budgetInfo ?? { currentHitsByProvider: {} };
      const { averageMonthlyNew, byFleetId, byFleetIdByIntegrationId, total } = data;

      const newExpectedBillByProvider = calculateExpectedBill({
        currentHitsByProvider,
        averageMonthlyNewSystems: averageMonthlyNew,
        systemsByFleetId: byFleetId,
        systemsByFleetIdByIntegrationId: byFleetIdByIntegrationId,
        totalSystems: total,
        fleets,
        days: DateTime.now().endOf('month').diff(DateTime.now().startOf('day'), 'days').days,
        daysInMonth: DateTime.now().daysInMonth
      });

      const totalNewExpectedBill = Object.values(newExpectedBillByProvider).reduce((acc, cost) => acc + cost, 0);
      const totalCurrentExpectedBill = Object.values(currentExpectedBillByProvider).reduce(
        (acc, cost) => acc + cost,
        0
      );

      if (isEqual(fleets, initialFleets)) {
        navigate('..');

        return;
      }

      if (totalNewExpectedBill !== totalCurrentExpectedBill) {
        const newBudget = await openModal<number | void>(
          ({ onClose }) => (
            <ConfirmSave
              currentBudget={budget?.monthlyBudget}
              totalNewExpectedBill={totalNewExpectedBill}
              totalCurrentExpectedBill={totalCurrentExpectedBill}
              onClose={onClose}
            />
          ),
          { title: 'Confirm Budget' }
        );

        if (newBudget !== undefined && newBudget !== budget?.monthlyBudget) {
          await updateBudget({ monthlyBudget: newBudget });
        }
      }

      await updateFleets(fleets);

      navigate('..');
    }
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      monitoredSystems: {
        isRequired: true
      },
      averageMonthlyNew: {
        isRequired: true
      }
    }),
    []
  );

  const { control, watch, reset, setValue } = form;

  const { monitoredSystems, averageMonthlyNew, fleets } = watch();

  const systemsRatioByFleetIndex = watch(fleets.map((fleet) => `systemsRatioByFleetId.fleet-${fleet.id}`));
  const systemsRatioByProviderIndex = watch(
    installedIntegrations.map((integration) => `systemsRatioByProvider.${integration.provider}`)
  );

  const systemsRatioByFleetId = fleets.reduce(
    (acc, fleet, index) => {
      acc[`fleet-${fleet.id}`] = systemsRatioByFleetIndex[index];

      return acc;
    },
    {} as Record<string, number>
  );

  const systemsRatioByProvider = installedIntegrations.reduce(
    (acc, integration, index) => {
      acc[integration.provider] = systemsRatioByProviderIndex[index];

      return acc;
    },
    {} as Record<IntegrationProvider, number>
  );

  useDeepCompareEffect(() => {
    reset((prev) => ({
      ...prev,
      systemsRatioByFleetId: fleets.reduce(
        (acc, fleet) => {
          acc[`fleet-${fleet.id}`] = prev.systemsRatioByFleetId[`fleet-${fleet.id}`] ?? 0;

          return acc;
        },
        {} as Record<string, number>
      )
    }));
  }, [fleets]);

  const systemsByFleetId = useMemo(() => {
    return Object.keys(systemsRatioByFleetId).reduce(
      (acc, key) => {
        const fleetId = key.replace('fleet-', '');
        acc[fleetId] = Math.round((monitoredSystems * systemsRatioByFleetId[key]) / 100);

        return acc;
      },
      {} as Record<string, number>
    );
  }, [monitoredSystems, systemsRatioByFleetId]);

  const systemsByFleetIdByIntegrationId = useMemo(() => {
    return Object.keys(systemsByFleetId).reduce(
      (acc, fleetId) => {
        acc[fleetId] = Object.keys(systemsRatioByProvider).reduce(
          (acc2, key) => {
            const provider = key as IntegrationProvider;

            const integration = installedIntegrations.find((integration) => integration.provider === provider);
            if (!integration) {
              return acc2;
            }

            return {
              ...acc2,
              [integration.id]: Math.round((systemsByFleetId[fleetId] * systemsRatioByProvider[provider]) / 100)
            };
          },
          {} as Record<number, number>
        );

        return acc;
      },
      {} as Record<number, Record<number, number>>
    );
  }, [systemsByFleetId, systemsRatioByProvider, installedIntegrations]);

  const expectedBillForWholeMonth = useMemo(() => {
    return calculateExpectedBill({
      averageMonthlyNewSystems: parseInt(averageMonthlyNew, 10),
      systemsByFleetId,
      systemsByFleetIdByIntegrationId,
      totalSystems: parseInt(monitoredSystems, 10),
      fleets,
      days: 30,
      daysInMonth: 30
    });
  }, [monitoredSystems, averageMonthlyNew, fleets, systemsByFleetId, systemsByFleetIdByIntegrationId]);

  const simulatedStats = useMemo(() => {
    return {
      total: parseInt(monitoredSystems, 10),
      averageMonthlyNew: parseInt(averageMonthlyNew, 10),
      byFleetId: systemsByFleetId,
      byFleetIdByIntegrationId: systemsByFleetIdByIntegrationId
    };
  }, [monitoredSystems, averageMonthlyNew, systemsByFleetId, systemsByFleetIdByIntegrationId]);

  const handleBottomSubmit = useCallback(() => {
    handleSubmit();
  }, [handleSubmit]);

  return (
    <div>
      <Header />

      <Container>
        <List>
          <Form rules={rules} onSubmit={handleSubmit}>
            <Warning>
              <WarningHeader>
                <AlertTriangle size="24px" color="#F1AA12" />
                <div>You’re in Simulation mode now</div>
              </WarningHeader>
              <div>
                You can enter necessary parameters to see how your systems will perform. While the data is only
                approximate, it still provides valuable insights to predict system behavior and budget.
                <br />
                All information provided is for reference purposes. You can save changes and apply them to actual
                performance.
              </div>
              <WarningFooter>
                <Link to="..">
                  <Button variant={ButtonVariant.Secondary}>Cancel and exit simulation</Button>
                </Link>
                <Button type="submit" variant={ButtonVariant.Primary}>
                  Save changes and exit simulation mode
                </Button>
              </WarningFooter>
            </Warning>

            <div data-section id={makeTitleHref('Systems simulation')}>
              <SystemsSimulation
                control={control}
                totalMonitoredSystems={monitoredSystems}
                fleets={fleets}
                systemsRatioByFleetId={systemsRatioByFleetId}
                systemsRatioByProvider={systemsRatioByProvider}
                integrations={installedIntegrations}
              />
            </div>
          </Form>
          <div data-section id={makeTitleHref('Fleets')}>
            <FleetsUi
              fleets={fleets}
              installedIntegrations={installedIntegrations}
              createFleet={(payload: ProfileCreateDTO) => {
                const ensureDefault = (fleet) => {
                  if (payload.default === true) {
                    return {
                      ...fleet,
                      default: false
                    };
                  }

                  return fleet;
                };
                setValue('fleets', [
                  ...fleets.map(ensureDefault),
                  mapProfileCreateDTOToFleet(payload, installedIntegrations)
                ]);
              }}
              updateFleet={({ id, dto }) => {
                const ensureDefault = (fleet) => {
                  if (dto.default === true) {
                    return {
                      ...fleet,
                      default: false
                    };
                  }

                  return fleet;
                };

                setValue(
                  'fleets',
                  fleets.map((fleet) =>
                    fleet.id === id
                      ? { ...mapProfileCreateDTOToFleet(dto, installedIntegrations), id }
                      : ensureDefault(fleet)
                  )
                );
              }}
              deleteFleet={(id: number) => {
                setValue(
                  'fleets',
                  fleets.filter((fleet) => fleet.id !== id)
                );
              }}
              isSimulation
            />
          </div>
          <div data-section id={makeTitleHref('Systems breakdown')}>
            <SystemsBreakdown fleets={fleets} stats={simulatedStats} />
          </div>

          <WarningFooter>
            <Link to="..">
              <Button variant={ButtonVariant.Secondary}>Cancel and exit simulation</Button>
            </Link>
            <Button onClick={handleBottomSubmit} variant={ButtonVariant.Primary}>
              Save changes and exit simulation mode
            </Button>
          </WarningFooter>
        </List>

        <SideNav>
          {SECTIONS.map((section) => (
            <SideNavItem
              onClick={onNavItemClick}
              isActive={makeTitleHref(section) === activeItem}
              key={section}
              href={`#${makeTitleHref(section)}`}
            >
              {section}
            </SideNavItem>
          ))}

          <ExpectedBill billPerProvider={expectedBillForWholeMonth} />
        </SideNav>
      </Container>
    </div>
  );
};

export const SimulationPage = () => {
  const { data } = useSystemsStats();
  const { data: initialFleets = [] } = useFleetList();
  const { data: installedIntegrations } = useIntegrations();
  const { data: budgetInfo } = useSystemsBudget();

  if (!data || !installedIntegrations || !budgetInfo || !initialFleets?.length) {
    return null;
  }

  return <Simulation />;
};
