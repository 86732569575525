import { SmartViewSaveButton } from '@features/SmartViews/SaveButton';
import { useCurrentSmartView } from '@hooks/smartViews/useCurrentSmartView';
import { SmartViewCondition, SmartViewFilter, SmartViewFilterType, TaskViewFilterSubType } from '@types';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  ClientTrayFilters,
  useClientTrayFilterState,
  DEFAULT_CLIENT_TRAY_FILTERS
} from '@components/Scheduler/useClientTrayFilterState';
import { cloneDeep, merge } from 'lodash';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useClientFilterState, DEFAULT_CLIENT_FILTERS, ClientFilterState } from '../../useClientFilterState';

const isNotEmptyPredicate = (value: any) => value !== -1;

type CombinedClientFiltersState = {
  clientFilters: ClientFilterState;
  clientTrayFilters: ClientTrayFilters;
  subType: TaskViewFilterSubType;
  calendarViewType: string;
};

const mapSmartViewFilterToClientFilters = (smartView: SmartViewFilter) => {
  const {
    members = [],
    priorities = [],
    clientStatuses = [],
    requestStatuses = [],
    projectStatuses = [],
    tags = [],
    templates = [],
    reminderTypes = [],
    statuses = [],
    query = '',
    map = DEFAULT_CLIENT_FILTERS.map
  } = smartView.conditions.filter;

  return merge(cloneDeep(DEFAULT_CLIENT_FILTERS), {
    assignees: members.filter(isNotEmptyPredicate),
    priorities: priorities.filter(isNotEmptyPredicate),
    clientStatuses: clientStatuses.filter(isNotEmptyPredicate),
    requestStatuses: requestStatuses.filter(isNotEmptyPredicate),
    projectStatuses: projectStatuses.filter(isNotEmptyPredicate),
    labels: tags.filter(isNotEmptyPredicate),
    templates: templates.filter(isNotEmptyPredicate),
    reminderTypes: reminderTypes.filter(isNotEmptyPredicate),
    statuses: statuses.filter(isNotEmptyPredicate),
    query,
    map
  });
};

const mapSmartViewFilterToClientTrayFilters = (smartView: SmartViewFilter) => {
  const { trayFilters = {} } = smartView.conditions.filter;

  const {
    statuses = [],
    templates = [],
    clientStatuses = [],
    requestStatuses = [],
    projectStatuses = [],
    query = ''
  } = trayFilters;

  return merge(cloneDeep(DEFAULT_CLIENT_TRAY_FILTERS), {
    statuses: statuses.filter(isNotEmptyPredicate),
    templates: templates.filter(isNotEmptyPredicate),
    clientStatuses: clientStatuses.filter(isNotEmptyPredicate),
    requestStatuses: requestStatuses.filter(isNotEmptyPredicate),
    projectStatuses: projectStatuses.filter(isNotEmptyPredicate),
    query: query ?? ''
  });
};

const mapClientFiltersToSmartViewFilter = (filters: CombinedClientFiltersState): SmartViewCondition => {
  const { clientFilters, clientTrayFilters } = filters;

  return {
    filter: {
      query: clientFilters.query,
      members: clientFilters.assignees,
      priorities: clientFilters.priorities,
      clientStatuses: clientFilters.clientStatuses,
      requestStatuses: clientFilters.requestStatuses,
      projectStatuses: clientFilters.projectStatuses,
      tags: clientFilters.labels,
      templates: clientFilters.templates,
      reminderTypes: clientFilters.reminderTypes,
      statuses: clientFilters.statuses,
      trayFilters: {
        statuses: clientTrayFilters.statuses,
        templates: clientTrayFilters.templates,
        clientStatuses: clientTrayFilters.clientStatuses,
        requestStatuses: clientTrayFilters.requestStatuses,
        projectStatuses: clientTrayFilters.projectStatuses,
        query: clientTrayFilters.query
      },
      map: {
        showAllRoutes: clientFilters.map.showAllRoutes,
        showUndispatchedWorkOrders: clientFilters.map.showUndispatchedWorkOrders
      }
    }
  };
};

const DEFAULT_CALENDAR_VIEW_TYPE_BY_SUBTYPE: Record<TaskViewFilterSubType, string> = {
  [TaskViewFilterSubType.SCHEDULER]: 'month',
  [TaskViewFilterSubType.DISPATCHER]: 'week',
  [TaskViewFilterSubType.LIST]: ''
};
interface Props {
  subType: TaskViewFilterSubType;
}

export const Views = ({ subType }: Props) => {
  const [clientFilters, setClientFilters] = useClientFilterState();
  const [clientTrayFilters, setClientTrayFilters] = useClientTrayFilterState();
  const [calendarViewType] = useQueryParam(QueryParamsEnum.CalendarViewType);

  const combinedDefaultClientFilters = useMemo<CombinedClientFiltersState>(
    () => ({
      clientFilters: DEFAULT_CLIENT_FILTERS,
      clientTrayFilters: DEFAULT_CLIENT_TRAY_FILTERS,
      subType,
      calendarViewType: DEFAULT_CALENDAR_VIEW_TYPE_BY_SUBTYPE[subType]
    }),
    [subType]
  );

  const combinedClientFilters = useMemo(
    () => ({
      clientFilters,
      clientTrayFilters,
      subType,
      calendarViewType: calendarViewType || DEFAULT_CALENDAR_VIEW_TYPE_BY_SUBTYPE[subType]
    }),
    [clientFilters, clientTrayFilters, subType, calendarViewType]
  );

  const currentSmartView = useCurrentSmartView();

  useEffect(() => {
    // for some r eason React renders this component even after navigating away from the page
    if (!['scheduler', 'dispatcher-new', 'list'].some((path) => window.location.pathname.includes(path))) {
      return;
    }

    if (currentSmartView) {
      setClientFilters((prev) => ({
        ...prev,
        ...mapSmartViewFilterToClientFilters(currentSmartView)
      }));
      setClientTrayFilters((prev) => ({
        ...prev,
        ...mapSmartViewFilterToClientTrayFilters(currentSmartView)
      }));
    } else {
      setClientFilters((prev) => ({
        ...prev,
        ...DEFAULT_CLIENT_FILTERS
      }));
      setClientTrayFilters((prev) => ({
        ...prev,
        ...DEFAULT_CLIENT_TRAY_FILTERS
      }));
    }
  }, [currentSmartView, setClientFilters, setClientTrayFilters]);

  const mapperToSvFilter = useCallback(
    (filters: CombinedClientFiltersState) => {
      const result = mapClientFiltersToSmartViewFilter(filters);

      result.filter.subType = subType;
      result.filter.calendarViewType = (calendarViewType as string) || DEFAULT_CALENDAR_VIEW_TYPE_BY_SUBTYPE[subType];

      return result;
    },
    [subType, calendarViewType]
  );

  return (
    <SmartViewSaveButton
      defaultClientFilters={combinedDefaultClientFilters}
      clientFilters={combinedClientFilters}
      type={SmartViewFilterType.TASK}
      clientFiltersToSvFiltersFn={mapperToSvFilter}
    />
  );
};
