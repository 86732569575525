import { FormControlLabel } from '@common/ui';
import { Popover, RadioGroup } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Radio } from '@common/ui/Radio';
import { ChevronDown, ChevronUp } from 'react-feather';
import { Menu, Container, Trigger, TriggerLabel, TriggerValue } from './styled';
import { useClientFilterState } from '../../useClientFilterState';

const SORT_BY_TITLES_MAP: { [key: string]: string } = {
  createdAt: 'Oldest first',
  '-createdAt': 'Newest first'
};

export const SortBy = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { clientFilters, updateFilters } = useClientFilterState();

  const [sortBy, setSortBy] = useState(clientFilters.sortByCreatedAtAsc ? 'createdAt' : '-createdAt');

  useEffect(() => {
    updateFilters({
      sortByCreatedAtAsc: sortBy === 'createdAt'
    });
  }, [sortBy, updateFilters]);

  const open = Boolean(anchorEl);

  const handleSortByChange = useCallback((_: any, value: string) => {
    setSortBy(value);
  }, []);

  return (
    <>
      <Container>
        <Trigger onClick={handleClick}>
          <TriggerLabel>Sort by:</TriggerLabel>
          <TriggerValue>{SORT_BY_TITLES_MAP[sortBy]}</TriggerValue>
          {open ? <ChevronUp size="16px" /> : <ChevronDown size="16px" />}
        </Trigger>
      </Container>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <RadioGroup value={sortBy} aria-label="position" name="sortBy" onChange={handleSortByChange}>
            <FormControlLabel value="-createdAt" control={<Radio />} label="Newest first" labelPlacement="end" />

            <FormControlLabel value="createdAt" control={<Radio />} label="Oldest first" labelPlacement="end" />
          </RadioGroup>
        </Menu>
      </Popover>
    </>
  );
};
