import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border-radius: 16px;
  border: solid 1px #dfdfe8;
  background-color: #fff;
  max-width: 350px;
`;

export const FirstLine = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const Image = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 56px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 4px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 0;
`;

export const ProjectTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #235dff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Title = styled.span`
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Number = styled.span`
  font-size: 18px;
  font-weight: normal;
`;

export const SecondLine = styled.div`
  display: flex;
`;

export const ThirdLine = styled.div`
  display: flex;
`;

export const FourthLine = styled.div`
  display: flex;
  gap: 40px;
`;

export const StatValue = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const StatTitle = styled.div`
  font-size: 12px;
  font-weight: normal;
  color: #828d9a;
`;