import React, { useState } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { RoleDTO } from '@types';
import { Tooltip } from '@material-ui/core';
import { HelpCircleIcon } from '@kit/ui/icons/Help';
import { isFeatureFlagLikePermissionScope } from './helpers';
import { PermissionLevel, PermissionMatrixRowGroup, PermissionMatrixScope, PermissionMatrixSubject } from './types';
import { PermissionTitle, Table, Td, Th } from './styled';
import { PERMISSIONS_MATRIX_CONFIG } from './constants';

interface Props {
  permissions: RoleDTO['settings'];
  onChange: (permissions: RoleDTO['settings']) => void;
  isReadOnly: boolean;
}

export const PermissionsMatrix = ({ permissions, onChange, isReadOnly }: Props) => {
  const [hoveredCell, setHoveredCell] = useState<{
    subject: PermissionMatrixSubject;
    row: number;
    col: number;
    isChecked: boolean;
  } | null>(null);

  return (
    <>
      <Table>
        <thead>
          <Th width="40%" />
          <Th width="15%">View</Th>
          <Th width="15%">Edit</Th>
          <Th width="15%">Create</Th>
          <Th width="15%">Delete</Th>
        </thead>
        {PERMISSIONS_MATRIX_CONFIG.map((group) =>
          group.isThreeLevel ? (
            <ThreeLevelMatrix
              isReadOnly={isReadOnly}
              key={group.subject}
              group={group}
              permissions={permissions}
              onChange={onChange}
            />
          ) : (
            <tbody key={group.subject}>
              {group.scopes
                .filter((scope) => !isFeatureFlagLikePermissionScope(scope))
                .map((scope, rowIndex) => (
                  <tr key={`${group.subject}_${scope.scope}`}>
                    <td>
                      <PermissionTitle>
                        {scope.label}
                        {scope.tooltip && (
                          <Tooltip title={scope.tooltip}>
                            <HelpCircleIcon size="16px" color="#9C9CAA" />
                          </Tooltip>
                        )}
                      </PermissionTitle>
                    </td>
                    {[PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Create, PermissionLevel.Delete].map(
                      (level, cellIndex) => {
                        const isChecked =
                          cellIndex <=
                          [
                            PermissionLevel.View,
                            PermissionLevel.Edit,
                            PermissionLevel.Create,
                            PermissionLevel.Delete
                          ].indexOf(permissions[group.subject]?.[scope.scope]);
                        const isHoveredCellChecked = hoveredCell?.isChecked ?? false;
                        const isHoveredCurrentCell =
                          group.subject === hoveredCell?.subject &&
                          rowIndex === hoveredCell?.row &&
                          cellIndex === hoveredCell?.col;

                        const willBeChecked =
                          group.subject === hoveredCell?.subject &&
                          !isHoveredCurrentCell &&
                          !isHoveredCellChecked &&
                          !isChecked &&
                          rowIndex === hoveredCell?.row &&
                          cellIndex <= hoveredCell?.col;
                        const willBeUnchecked =
                          group.subject === hoveredCell?.subject &&
                          !isHoveredCurrentCell &&
                          isHoveredCellChecked &&
                          isChecked &&
                          rowIndex === hoveredCell?.row &&
                          cellIndex >= hoveredCell?.col;

                        return (
                          <Td
                            key={level}
                            willBeChecked={willBeChecked}
                            willBeUnchecked={willBeUnchecked}
                            isChecked={isChecked}
                            isDisabled={isReadOnly}
                          >
                            {scope.levels.includes(level) ? (
                              <Checkbox
                                isDisabled={isReadOnly}
                                isChecked={willBeChecked || isChecked}
                                onMouseEnter={() =>
                                  setHoveredCell({ row: rowIndex, col: cellIndex, isChecked, subject: group.subject })
                                }
                                onMouseLeave={() => setHoveredCell(null)}
                                onChange={() => {
                                  const newIsChecked = !isChecked;

                                  onChange({
                                    ...permissions,
                                    [group.subject]: {
                                      ...permissions[group.subject],
                                      [scope.scope]: newIsChecked
                                        ? level
                                        : [
                                            PermissionLevel.View,
                                            PermissionLevel.Edit,
                                            PermissionLevel.Create,
                                            PermissionLevel.Delete
                                          ][cellIndex - 1] ?? PermissionLevel.None
                                    }
                                  });
                                }}
                              />
                            ) : (
                              'n/a'
                            )}
                          </Td>
                        );
                      }
                    )}
                  </tr>
                ))}
            </tbody>
          )
        )}
      </Table>

      <Table>
        <thead>
          <Th width="40%">Feature</Th>
          <Th width="60">Grant access</Th>
        </thead>

        <tbody>
          {PERMISSIONS_MATRIX_CONFIG.map((group) =>
            group.scopes
              .filter((scope) => isFeatureFlagLikePermissionScope(scope))
              .map((scope) => (
                <tr key={`${group.subject}_${scope.scope}`}>
                  <td>
                    <PermissionTitle>
                      {scope.label}
                      {scope.tooltip && (
                        <Tooltip title={scope.tooltip}>
                          <HelpCircleIcon size="16px" color="#9C9CAA" />
                        </Tooltip>
                      )}
                    </PermissionTitle>
                  </td>
                  <td>
                    <Checkbox
                      isDisabled={isReadOnly}
                      isChecked={permissions[group.subject]?.[scope.scope] === PermissionLevel.Edit}
                      onChange={() => {
                        onChange({
                          ...permissions,
                          [group.subject]: {
                            ...permissions[group.subject],
                            [scope.scope]:
                              permissions[group.subject]?.[scope.scope] === PermissionLevel.Edit
                                ? PermissionLevel.None
                                : PermissionLevel.Edit
                          }
                        });
                      }}
                    />
                  </td>
                </tr>
              ))
          )}
        </tbody>
      </Table>
    </>
  );
};

const ThreeLevelMatrix = ({
  group,
  permissions,
  onChange,
  isReadOnly
}: {
  group: PermissionMatrixRowGroup;
  permissions: RoleDTO['settings'];
  onChange: (permissions: RoleDTO['settings']) => void;
  isReadOnly: boolean;
}) => {
  const [hoveredCell, setHoveredCell] = useState<{ row: number; col: number; isChecked: boolean } | null>(null);

  return (
    <tbody>
      <tr>
        <td>
          <PermissionTitle>
            {group.label}
            {group.tooltip && (
              <Tooltip title={group.tooltip}>
                <HelpCircleIcon size="16px" color="#9C9CAA" />
              </Tooltip>
            )}
          </PermissionTitle>
        </td>
        <td colSpan={4} />
      </tr>
      {group.scopes.map((scope, rowIndex) => (
        <tr key={`${group.subject}_${scope.scope}`}>
          <td>
            <PermissionTitle isNested>
              {scope.label}
              {scope.tooltip && (
                <Tooltip title={scope.tooltip}>
                  <HelpCircleIcon size="16px" color="#9C9CAA" />
                </Tooltip>
              )}
            </PermissionTitle>
          </td>
          {[PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Create, PermissionLevel.Delete].map(
            (level, cellIndex) => {
              const isChecked =
                cellIndex <=
                [PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Create, PermissionLevel.Delete].indexOf(
                  permissions[group.subject]?.[scope.scope]
                );
              const isHoveredCellChecked = hoveredCell?.isChecked ?? false;
              const isHoveredCurrentCell = rowIndex === hoveredCell?.row && cellIndex === hoveredCell?.col;

              const willBeChecked =
                !isHoveredCurrentCell &&
                !isHoveredCellChecked &&
                !isChecked &&
                rowIndex <= hoveredCell?.row &&
                cellIndex <= hoveredCell?.col;
              const willBeUnchecked =
                !isHoveredCurrentCell &&
                isHoveredCellChecked &&
                isChecked &&
                rowIndex >= hoveredCell?.row &&
                cellIndex >= hoveredCell?.col;

              return (
                <Td
                  willBeChecked={willBeChecked}
                  willBeUnchecked={willBeUnchecked}
                  isChecked={isChecked}
                  isDisabled={isReadOnly}
                  key={level}
                >
                  {scope.levels.includes(level) ? (
                    <Checkbox
                      isChecked={willBeChecked || isChecked}
                      onMouseEnter={() => setHoveredCell({ row: rowIndex, col: cellIndex, isChecked })}
                      onMouseLeave={() => setHoveredCell(null)}
                      isDisabled={isReadOnly}
                      onChange={() => {
                        const newIsChecked = !isChecked;

                        if (newIsChecked) {
                          const beforeScopes = group.scopes.slice(0, rowIndex);
                          const newLevelIndex = [
                            PermissionLevel.View,
                            PermissionLevel.Edit,
                            PermissionLevel.Create,
                            PermissionLevel.Delete
                          ].indexOf(level);

                          onChange({
                            ...permissions,
                            [group.subject]: {
                              ...permissions[group.subject],
                              [scope.scope]: level,
                              ...beforeScopes.reduce(
                                (acc, scope) => {
                                  const currentLevelIndex = [
                                    PermissionLevel.View,
                                    PermissionLevel.Edit,
                                    PermissionLevel.Create,
                                    PermissionLevel.Delete
                                  ].indexOf(permissions[group.subject]?.[scope.scope]);
                                  acc[scope.scope] =
                                    currentLevelIndex < newLevelIndex
                                      ? level
                                      : permissions[group.subject]?.[scope.scope];

                                  return acc;
                                },
                                {} as Record<PermissionMatrixScope, PermissionLevel>
                              )
                            }
                          });
                        } else {
                          const newLevel =
                            [
                              PermissionLevel.View,
                              PermissionLevel.Edit,
                              PermissionLevel.Create,
                              PermissionLevel.Delete
                            ][cellIndex - 1] ?? PermissionLevel.None;
                          const newLevelIndex = [
                            PermissionLevel.View,
                            PermissionLevel.Edit,
                            PermissionLevel.Create,
                            PermissionLevel.Delete
                          ].indexOf(newLevel);
                          const afterScopes = group.scopes.slice(rowIndex + 1);

                          onChange({
                            ...permissions,
                            [group.subject]: {
                              ...permissions[group.subject],
                              [scope.scope]: newLevel,
                              ...afterScopes.reduce(
                                (acc, scope) => {
                                  const currentLevelIndex = [
                                    PermissionLevel.View,
                                    PermissionLevel.Edit,
                                    PermissionLevel.Create,
                                    PermissionLevel.Delete
                                  ].indexOf(permissions[group.subject]?.[scope.scope]);

                                  acc[scope.scope] =
                                    currentLevelIndex > newLevelIndex
                                      ? newLevel
                                      : permissions[group.subject]?.[scope.scope];

                                  return acc;
                                },
                                {} as Record<PermissionMatrixScope, PermissionLevel>
                              )
                            }
                          });
                        }
                      }}
                    />
                  ) : (
                    'n/a'
                  )}
                </Td>
              );
            }
          )}
        </tr>
      ))}
    </tbody>
  );
};
