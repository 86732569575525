import { EntityType, SmsEventType, TaskEventType, WorkflowEventType } from '@enums';

export const FEED_TYPES = [
  {
    entity: EntityType.TASK,
    event: TaskEventType.CREATED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.ASSIGNED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.COMPLETED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.STATUS_MOVED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.UNASSIGNED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.RESCHEDULED
  },
  {
    entity: EntityType.TASK,
    event: TaskEventType.COMMENTED
  },
  {
    entity: EntityType.SMS,
    event: SmsEventType.RECEIVED
  },
  {
    entity: EntityType.SMS,
    event: SmsEventType.SENT
  },
  {
    entity: EntityType.WORKFLOW,
    event: WorkflowEventType.EXECUTED_SUCCESSFULLY
  },
  {
    entity: EntityType.WORKFLOW,
    event: WorkflowEventType.FAILED_TO_EXECUTE
  }
];
