import { ReactQueryKey } from '@enums';
import { CompanyUser } from '@generated/types/graphql';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import userApi from '@services/api/userApi';
import { useMutation, useQueryClient } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '..';

type Params = {
  userId: number;
  companyId: number;
};

export const useDeleteUser = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<{ id: number }, Error, number>(
    async (userId) => {
      try {
        await userApi.deactivateUser({ userId, companyId });

        return { id: userId };
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async ({ id }) => {
        showSuccess('Successfully deleted the user');
        queryClient.setQueryData<CompanyUser[] | undefined>([ReactQueryKey.CompanyMembersList, companyId], (old) => {
          if (!old) {
            return old;
          }

          return old.filter((user) => user.user.id !== id);
        });
      }
    }
  );
};
