import styled from 'styled-components';

export const ListItem = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;
  border: 1px solid #dfdfe8;
`;

export const ListItemTitle = styled.h2`
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8px;
`;

export const ListItemDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
  white-space: pre-line;
`;

export const Event = styled.div`
  padding: 24px 0;
  display: flex;
  gap: 8px;

  border-bottom: 1px solid #dfdfe8;

  &:last-child {
    border-bottom: none;
  }
`;

export const EventToggle = styled.div`
  flex: 0 0 50px;
  text-align: center;
`;

export const EventContent = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EventHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  justify-content: space-between;
`;

export const EventTitle = styled.h3<{ isDisabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 4px;
  color: ${({ isDisabled }) => (isDisabled ? '#828d9a' : '#000')};
`;

export const SecondaryText = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;

export const TextBlock = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;

  > *:first-child {
    width: 120px;
  }

  > *:last-child {
    flex: 1;
  }
`;

export const Receivers = styled.div`
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ErrorText = styled.span`
  color: #d54855;
  font-size: 14px;
  font-weight: 400;
`;
