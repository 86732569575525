import React, { useEffect } from 'react';
import { Header } from './Header';
import { Container, Content } from './styled';
import { List } from './List';

export const Inbox = () => {
  const containerNode = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerNode.current?.focus();
  }, []);

  return (
    <Container tabIndex={-1} ref={containerNode}>
      <Header />
      <Content>
        <List />
      </Content>
    </Container>
  );
};
