import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { PropertyValue } from '@common/Properties/PropertyValue';
import styled from 'styled-components';

import { ProjectAdapted, Property } from '@types';
import { Loader } from '@kit/ui/Loader';
import { Container, LoadMore, LoaderWrapper, Row } from './styled';
import { usePagedRecords } from '../useRecords';
import { TitleCell } from './TitleCell';
import { useColumns } from '../useColumns';
import { useClientFilterState } from '../../useClientFilterState';

export const PropertyWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;

  &:hover,
  &:focus {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0;
  }
`;

const TemplateTableCell = (props) => {
  const { children, ...rest } = props;

  return (
    <td {...rest}>
      <PropertyWrapper>{children}</PropertyWrapper>
    </td>
  );
};

const ValueWrapperInner = styled.div<{ disableName: boolean; isOverflowAllowed: boolean }>`
  display: block;
  min-width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});
  width: calc(${(props) => (props.disableName ? '100% - 20px' : '60% - 20px')});

  ${({ isOverflowAllowed }) =>
    !isOverflowAllowed &&
    `
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  word-break: break-word;
  `};
`;

export const ValueWrapper = React.forwardRef((props, ref) => <ValueWrapperInner {...props} ref={ref} />);

interface TableRowProps {
  project: ProjectAdapted;
  isSelected: boolean;
  toggleItem: (project: ProjectAdapted) => void;
  columns: Property[];
  onClick: (id: number) => void;
}

const TableRow = memo(({ project, isSelected, toggleItem, columns, onClick }: TableRowProps) => {
  const handleTitleClick = useCallback(() => {
    onClick(project.projectId);
  }, [onClick, project.projectId]);

  return (
    <Row key={project.projectId} isSelected={isSelected} onClick={handleTitleClick}>
      <TitleCell project={project} isSelected={isSelected} toggleItem={toggleItem} />
      {columns.map((column) => (
        <PropertyValue
          key={`${project.projectId}_${column.id}`}
          MainWrapper={TemplateTableCell}
          ValueWrapper={ValueWrapper}
          project={project}
          property={column}
          withTooltip
          withCopy
        />
      ))}
    </Row>
  );
});

interface Props {
  ids: number[];
  firstPageData?: ProjectAdapted[];
  checkIsItemSelected: (project: ProjectAdapted) => boolean;
  toggleItem: (project: ProjectAdapted) => void;
  onDataChange?: (data: ProjectAdapted[]) => void;
  onRowClick: (id: number) => void;
}

export const TableBody = ({ ids, firstPageData, checkIsItemSelected, toggleItem, onDataChange, onRowClick }: Props) => {
  const columns = useColumns();

  const {
    clientFilters: { perPage }
  } = useClientFilterState();

  const { data, isLoading, fetchNextPage, isFetchingNextPage } = usePagedRecords(ids, firstPageData, perPage.value);

  const flat = useMemo(() => {
    if (!data) {
      return [];
    }

    return data.pages.reduce((acc, page) => [...acc, ...page.projects], [] as ProjectAdapted[]);
  }, [data]);

  const handleLoadMoreClick = useCallback(() => {
    fetchNextPage();
  }, [fetchNextPage]);

  useEffect(() => {
    if (onDataChange) {
      onDataChange(flat);
    }
  }, [flat, onDataChange]);

  if (isLoading) {
    return (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    );
  }

  return (
    <Container>
      <tbody>
        {flat.map((project) => (
          <TableRow
            key={project.projectId}
            project={project}
            columns={columns}
            isSelected={checkIsItemSelected(project)}
            toggleItem={toggleItem}
            onClick={onRowClick}
          />
        ))}
      </tbody>

      {ids.length > flat.length && (
        <tfoot>
          <tr>
            <td>
              {isFetchingNextPage && (
                <LoaderWrapper top="-5px">
                  <Loader size={48} />
                </LoaderWrapper>
              )}

              {!isFetchingNextPage && (
                <LoadMore onClick={handleLoadMoreClick}>Load more ({ids.length - flat.length})</LoadMore>
              )}
            </td>
            <td colSpan={columns.length} />
          </tr>
        </tfoot>
      )}
    </Container>
  );
};
