import { useMutation, useQueryClient } from 'react-query';
import { useAppSelector, useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import systemsBudgetAPI, { BudgetUpdateDTO } from '@services/api/systemsBudgetAPI';
import { ReactQueryKey } from '@enums';
import { selectWorkspaceId } from '@state/selectors';

export const useUpdateSystemsBudget = () => {
  const queryClient = useQueryClient();

  const companyId = useAppSelector(selectWorkspaceId);

  const { showSuccess } = useToast();

  return useMutation<void, Error, BudgetUpdateDTO>(
    async (dto) => {
      try {
        await systemsBudgetAPI.update(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Budget updated successfully`);
        queryClient.invalidateQueries([ReactQueryKey.SystemsSettings, ReactQueryKey.SystemsBudget]);
      }
    }
  );
};
