import { colors } from '@styles';
import React from 'react';
import { REMINDER_TYPE_TO_ICON } from '@common/Reminder/ReminderTypeSelector';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { PrivilegedTask } from '@generated/types/graphql';
import { CalendarIcon } from '@kit/ui/icons/Calendar';
import { Container, Header, Text, Title, WorkOrderStatus } from './styled';

export const WeekEvent = ({ data }: any) => {
  const order = data.appointmentData as PrivilegedTask;

  const ReminderIc = REMINDER_TYPE_TO_ICON[order.type];

  if ('dueDate' in order) {
    const isAppointment = order.isAppointment;

    return (
      <Container>
        <Header>
          {isAppointment && <CalendarIcon color={colors.green} size="16px" />}
          {!isAppointment && Boolean(ReminderIc) && <ReminderIc color={colors.green} size="16px" />}
          <Text isCompleted={order.isCompleted}>{order.title}</Text>
        </Header>
        <Text>{[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}</Text>
      </Container>
    );
  }

  return (
    <Container borderColor={order.firstLabel?.color} color={order.isColored ? order.firstLabel?.color : null}>
      <Title isCompleted={order.isCompleted}>
        <WorkOrderTypeIcon isField={order.isField} />
        <Text>{order.title}</Text>
      </Title>
      <Text>{[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}</Text>

      <div>
        <WorkOrderStatus status={order.taskStatus} />
      </div>
    </Container>
  );
};
