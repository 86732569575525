import styled from 'styled-components';

export const ContentContainer = styled.div`
  padding: 16px;
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  margin-top: 24px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Title = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const TwoFields = styled.div`
  display: flex;
  gap: 40px;

  > * {
    flex: 1;
    margin-bottom: 0;
  }
`;

export const EquationAndChart = styled(TwoFields)`
  align-items: center;
`;

export const Equation = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  font-size: 14px;
  font-weight: 400;

  .field-container {
    width: 52px;
    min-width: 52px;
    flex-shrink: 0;

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
`;

export const PercentInput = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const PercentSymbol = styled.div`
  color: #828d9a;
`;

export const ErrorMessage = styled.div`
  color: #d54855;
  font-size: 12px;
  font-weight: 400;
`;
