import styled, { css } from 'styled-components';
import { WorkOrderStatus as WorkOrderStatusComponent } from '@components/WorkOrder/WorkOrderStatus';

export const Container = styled.div`
  padding: 4px;
  height: 100%;
  border-radius: 4px;
  border-left: 4px solid ${(props) => props.borderColor || '#fff'};
  ${(props) =>
    props.color
      ? css`
          background-color: ${props.color};
          color: #fff;
        `
      : ''}
`;

export const Text = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
  margin-bottom: 4px;

  padding-left: ${(props) => props.paddingLeft ?? ''};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Title = styled(Text)`
  display: flex;
  align-items: center;

  svg {
    flex-shrink: 0;
    margin-right: 2px;
  }
`;

export const WorkOrderStatus = styled(WorkOrderStatusComponent)`
  max-width: 100%;
  margin-left: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > * {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
    margin-right: 4px;
  }
`;
