import React from 'react';
import { Cell, Customized, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { formatNumber } from '@utils/utils';
import { Square } from 'react-feather';
import { LegendLabel, LegendLine, PieContainer, PieLegend } from './styled';

interface Props {
  total: number;
  points: {
    name: string;
    amount: number;
    color: string;
  }[];
}

export const FleetsPie = ({ total, points }: Props) => {
  return (
    <PieContainer>
      <ResponsiveContainer>
        <PieChart width={400} height={400} data={points}>
          <Pie
            dataKey="amount"
            nameKey="name"
            data={points}
            cy="50%"
            outerRadius="100%"
            innerRadius="80%"
            fill="#009688"
            startAngle={90}
            endAngle={-270}
          >
            {points.map(({ color }, index) => (
              <Cell key={`cell-${index}`} fill={color} />
            ))}
          </Pie>
          <Customized
            // eslint-disable-next-line
                  component={() => (
              <text>
                <tspan x="50%" y="55%" textAnchor="middle" fontSize={24} fontWeight={500}>
                  {formatNumber(total)}
                </tspan>
              </text>
            )}
          />
        </PieChart>
      </ResponsiveContainer>

      <PieLegend>
        {points.map(({ name, amount, color }) => (
          <LegendLine key={name}>
            <LegendLabel>
              <Square size="16px" color={color} fill={color} />

              {name}
            </LegendLabel>

            {formatNumber(amount)}
          </LegendLine>
        ))}
      </PieLegend>
    </PieContainer>
  );
};
