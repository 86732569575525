export enum ReviewChannelType {
  Google = 'Google',
  Yelp = 'Yelp',
  BBB = 'BBB'
}

export type ReviewChannel = {
  type: ReviewChannelType;
  url: string;
};

export type ReviewsSettings = {
  enabled: boolean;
  channels: ReviewChannel[];
};
