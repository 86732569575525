import { scroll } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  height: 100%;
`;

export const Header = styled.div<{ isCollapsed: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 16px;
`;

export const Title = styled.div<{ isRotated: boolean }>`
  font-size: 18px;
  font-weight: 500;

  ${(props) =>
    props.isRotated
      ? css`
          font-size: 12px;
          font-weight: 400;
          transform: rotate(-90deg);
          transform-origin: center;
          position: absolute;
          right: calc(100% - 2px);
          top: 50px;
          white-space: nowrap;
          height: 100%;
        `
      : ''}
`;

export const Toggle = styled.div<{ isCollapsed: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${(props) => (props.isCollapsed ? '-58px' : '')};
`;

export const FeedContainer = styled.div`
  display: flex;
  overflow: auto;
  height: auto;
  overflow: hidden;
`;

export const Feed = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${scroll};

  & > div:first-of-type > * {
    border-top: 0;
  }
`;
