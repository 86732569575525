import styled, { css } from 'styled-components';
import { Content as BaseContent } from '../styled';

export const ACTIVITY_PANEL_WIDTH = '317px';
export const ACTIVITY_PANEL_COLLAPSED_WIDTH = '39px';

export const Content = styled(BaseContent)`
  padding-right: 16px;
`;

export const ActivityContainer = styled.div<{ isCollapsed: boolean }>`
  width: ${ACTIVITY_PANEL_WIDTH};
  min-width: ${ACTIVITY_PANEL_WIDTH};
  flex: 0 0 ${ACTIVITY_PANEL_WIDTH};
  background-color: #fff;
  overflow: auto;
  transition: margin-right 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-left: 1px solid #dfdfe8;
  ${(props) =>
    props.isCollapsed
      ? css`
          margin-right: calc(-${ACTIVITY_PANEL_WIDTH} + ${ACTIVITY_PANEL_COLLAPSED_WIDTH});
          padding-left: calc(${ACTIVITY_PANEL_COLLAPSED_WIDTH} + 16px);
        `
      : ''};

  @media (max-width: 767px) {
    display: none;
  }
`;
