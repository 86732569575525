import React from 'react';
import { formatCurrencyWithoutCents } from '@utils/utils';
import { Cell, Customized, Pie, PieChart } from 'recharts';

const EMPTY_POINTS = [{ provider: 'placeholder', amount: 100 }];

interface Props {
  size?: number;
}

export const PlaceholderPieChart = ({ size = 140 }: Props) => (
  <PieChart width={size} height={size} data={EMPTY_POINTS}>
    <Pie
      dataKey="amount"
      nameKey="provider"
      data={EMPTY_POINTS}
      cy="50%"
      outerRadius="100%"
      innerRadius="80%"
      fill="#F7F7FC"
      startAngle={90}
      endAngle={-270}
      isAnimationActive={false}
    >
      {EMPTY_POINTS.map((_entry, index) => (
        <Cell key={`cell-${index}`} fill="#F7F7FC" />
      ))}
    </Pie>
    <Customized
      // eslint-disable-next-line
    component={() => (
        <text>
          <tspan x="50%" y="55%" textAnchor="middle" fontSize={24} fontWeight={500}>
            {formatCurrencyWithoutCents(0)}
          </tspan>
        </text>
      )}
    />
  </PieChart>
);
