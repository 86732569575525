import { AppointmentDragging, View } from 'devextreme-react/scheduler';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Button from 'devextreme/ui/button';
import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.css';
import moment from 'moment';
import { ReactQueryKey } from '@enums';
import { useAppDispatch, useAppSelector } from '@hooks/store';
import { updateTask } from '@components/Calendar/redux/actions';
import { InfiniteData, useQueryClient } from 'react-query';
import { find } from 'lodash/fp';
import { hasEntityAccessNew } from '@utils/roles';
import { navigate } from 'gatsby';
import { addHours } from '@utils/dates';
import { orderBy } from 'lodash';
import { CellClickEvent, ViewType, AppointmentClickEvent, AppointmentUpdatedEvent } from 'devextreme/ui/scheduler';
import { selectWorkspaceId } from '@state/selectors';
import { Popover } from '@material-ui/core';
import { useDrawerOpenState } from '@components/LayoutNew/MainMenu/useDrawerOpenState';
import { useRemindersMutations } from '@hooks/useReminders';
import { PaginatedTasks } from '@components/Scheduler/useTasks';
import { md } from '@utils/isMobile';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import querystring from 'querystring';
import { useLocation } from '@reach/router';
import { PrivilegedTask } from '@generated/types/graphql';
import { useWorkOrderStatuses } from '@hooks/workOrders/useWorkOrderStatuses';
import { MAIN_MENU_COLLAPSED_WIDTH, MAIN_MENU_WIDTH } from '@components/LayoutNew/MainMenu/constants';
import { TRAY_COLLAPSED_WIDTH, TRAY_WIDTH } from '@components/Scheduler/styled';
import { useToast } from '@hooks/useToast';
import { useModal } from '@common/PromiseModal';
import { ReminderView } from '@features/Work/ReminderView';
import { WeekEvent } from './WeekEvent';
import { MonthEvent } from './MonthEvent';
import { EventListTooltip } from '../../components/EventListTooltip';
import { OverflowLabel, SchedulerWrapper } from './styled';
import { SCHEDULER_DRAGGING_GROUP_ID } from '../../consts';
import { useFilterState, useSchedulerTasks } from '../useTasks';

interface Props {
  onTaskClick: (task: any) => void;
  isTrayCollapsed: boolean;
}

const isPhone = md.phone();

export const Calendar = ({ onTaskClick, isTrayCollapsed }: Props) => {
  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);
  const [isDrawerOpen] = useDrawerOpenState();
  const { showError } = useToast();
  const { data: statuses = [] } = useWorkOrderStatuses();
  const { openModal } = useModal();

  const {
    updateMutation: { mutate: updateReminder }
  } = useRemindersMutations(companyId);

  const schedulerRef = useRef();

  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView = 'month'] = useQueryParam(QueryParamsEnum.CalendarViewType);

  const location = useLocation();

  const handleViewChange = useCallback(
    async (view: string) => {
      const params = querystring.parse(location.search?.substring(1));
      params[QueryParamsEnum.CalendarViewType] = view;

      navigate(`?${querystring.encode(params)}`);
    },
    [location.search]
  );

  const [, setFilterState] = useFilterState();

  useEffect(() => {
    setFilterState({
      dateRangeFilter: {
        startDate:
          currentView === 'week'
            ? moment(currentDate).startOf('week').toDate()
            : moment(currentDate).startOf('M').subtract(7, 'd').toDate(),
        endDate:
          currentView === 'week'
            ? moment(currentDate).endOf('week').toDate()
            : moment(currentDate).endOf('M').add(7, 'd').toDate()
      }
    });
  }, [currentView, currentDate, setFilterState]);

  const { tasks: events } = useSchedulerTasks();

  const preparedEvents = useMemo(() => {
    const result = events.map((event) => {
      let startDateInternal;
      let endDateInternal;
      const isReminder = 'dueDate' in event;
      const startDate = isReminder ? undefined : event.startDate;
      const endDate = isReminder ? event.dueDate : event.endDate;

      if (startDate && endDate) {
        const isTooShort = moment(endDate).diff(moment(startDate), 'm') < 30;

        startDateInternal = startDate;
        endDateInternal = isTooShort ? addHours(startDate, 1) : endDate;
      } else if (startDate) {
        startDateInternal = startDate;
        endDateInternal = addHours(startDate, 1);
      } else {
        startDateInternal = endDate;
        endDateInternal = addHours(endDate, 1);
      }

      return {
        ...event,
        allDay: !event.isVisit && (event.startDateAllDay || event.endDateAllDay),
        text: event.title,
        startDateInternal,
        endDateInternal
      };
    });

    return orderBy(result, (event) => moment(event.endDateInternal).diff(moment(event.startDateInternal)), 'desc');
  }, [events]);

  const handleAddEventToCalendar = useCallback(
    async (e: any) => {
      const { id, project, isField } = e.fromData;

      const { startDateInternal } = e.itemData;

      const startDate = new Date(startDateInternal);

      if (currentView === 'month') {
        startDate.setHours(12);
        startDate.setMinutes(0);
      }

      const endDate = addHours(startDate, 1);

      let updatePayload = {
        id,
        projectId: project.id
      } as any;

      if (isField) {
        updatePayload = {
          ...updatePayload,
          visits: [
            {
              startDate,
              endDate,
              startDateAllDay: false,
              endDateAllDay: false
            }
          ]
        };
      } else {
        updatePayload = {
          ...updatePayload,
          startDate,
          endDate
        };
      }

      const result = await dispatch(updateTask(updatePayload));

      if (!result || result.error) {
        return;
      }

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.SchedulerTrayTasks],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          return {
            ...data,
            pages: data.pages.map((page) => ({
              ...page,
              totalCount: data.pages[0].totalCount - 1,
              tasks: page.tasks.filter((task) => task.id !== id)
            }))
          };
        }
      );

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksScheduler],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          const pages = data.pages.slice();

          let taskToAdd: PrivilegedTask;

          if (!updatePayload.visits) {
            taskToAdd = {
              ...e.fromData,
              ...updatePayload,
              taskStatus: find({ id: result.status }, statuses)
            };
          } else {
            taskToAdd = {
              ...e.fromData,
              taskStatus: find({ id: result.status }, statuses),
              taskVisitsByTaskIdConnection: {
                nodes: result.visits
              }
            };
          }

          pages[0] = {
            ...pages[0],
            totalCount: pages[0].totalCount + 1,
            tasks: pages[0].tasks.concat([taskToAdd])
          };

          return {
            ...data,
            pages
          };
        }
      );

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        if (!updatePayload.visits) {
          return {
            ...data,
            ...updatePayload,
            taskStatus: find({ id: result.status }, statuses)
          };
        } else {
          return {
            ...data,
            taskStatus: find({ id: result.status }, statuses),
            taskVisitsByTaskIdConnection: {
              nodes: result.visits
            }
          };
        }
      });
    },
    [dispatch, queryClient, currentView, statuses]
  );

  const handleRemoveEventFromCalendar = useCallback(
    async (e: any) => {
      const { id, project, dueDate, isVisit } = e.itemData;

      if (dueDate || isVisit) {
        e.cancel = true;

        return;
      }

      const result = await dispatch(
        updateTask({
          id,
          projectId: project.id,
          startDate: null,
          endDate: null,
          visits: []
        })
      );

      queryClient.invalidateQueries<InfiniteData<PaginatedTasks>>([
        ReactQueryKey.Tasks,
        ReactQueryKey.SchedulerTrayTasks
      ]);

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        return {
          ...data,
          startDate: null,
          endDate: null,
          visits: [],
          taskStatus: find({ id: result.status }, statuses)
        };
      });

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksScheduler],
        (data) => {
          if (!data || !data.pages) {
            return data;
          }

          const pages = data.pages.slice();
          pages[0] = {
            ...pages[0],
            totalCount: pages[0].totalCount - 1,
            tasks: pages[0].tasks.filter((task) => task.id !== id)
          };

          return {
            ...data,
            pages
          };
        }
      );
    },
    [dispatch, queryClient, statuses]
  );

  const handleUpdateEvent = useCallback(
    async (e: AppointmentUpdatedEvent) => {
      const event = e.appointmentData;

      const {
        id,
        visitId,
        taskVisitsByTaskIdConnection,
        project,
        startDate,
        endDate,
        startDateInternal,
        endDateInternal
      } = event;

      if ('dueDate' in event) {
        updateReminder({
          id,
          dto: { dueDate: startDateInternal },
          companyId
        });

        return;
      }

      const payload = { id, projectId: project.id } as any;

      if (visitId) {
        const visitIndexToUpdate = taskVisitsByTaskIdConnection.nodes.findIndex((visit) => visit.id === visitId);
        const updatedVisits = [...taskVisitsByTaskIdConnection.nodes];
        let startDate;
        let endDate;

        if (currentView === 'week') {
          startDate = startDateInternal;
          endDate = endDateInternal;
        } else {
          // view = month, time can't be changed, so only date changes
          startDate = startDateInternal;
          endDate = endDateInternal;
        }

        updatedVisits[visitIndexToUpdate] = {
          ...updatedVisits[visitIndexToUpdate],
          startDate,
          endDate
        };

        payload.visits = updatedVisits;
      } else if (currentView === 'week') {
        payload.startDate = startDateInternal;
        payload.endDate = endDateInternal;
      } else {
        if (endDate) {
          payload.endDate = endDateInternal;
        }
        if (startDate) {
          payload.startDate = startDateInternal;
        }
      }

      const result = await dispatch(updateTask(payload));

      if (!result || result.error) {
        return;
      }

      queryClient.setQueriesData<InfiniteData<PaginatedTasks>>(
        [ReactQueryKey.Tasks, ReactQueryKey.TasksScheduler],
        (data) => {
          if (!data) {
            return data;
          }

          const pages = data.pages.slice();

          let taskUpdatePayload: PrivilegedTask;

          if (payload.visits) {
            taskUpdatePayload = {
              taskVisitsByTaskIdConnection: {
                nodes: result.visits
              }
            };
          } else {
            taskUpdatePayload = {
              startDate: payload.startDate ?? null,
              endDate: payload.endDate ?? null
            };
          }

          pages[0] = {
            ...pages[0],
            tasks: pages[0].tasks.map((task) =>
              task.id === id
                ? {
                    ...task,
                    ...taskUpdatePayload
                  }
                : task
            )
          };

          return {
            ...data,
            pages
          };
        }
      );

      queryClient.setQueryData([ReactQueryKey.Tasks, ReactQueryKey.TasksDetails, id], (data) => {
        if (!data) {
          return data;
        }

        let taskUpdatePayload: PrivilegedTask;

        if (payload.visits) {
          taskUpdatePayload = {
            taskVisitsByTaskIdConnection: {
              nodes: result.visits
            }
          };
        } else {
          taskUpdatePayload = {
            startDate: payload.startDate ?? null,
            endDate: payload.endDate ?? null
          };
        }

        return {
          ...data,
          ...taskUpdatePayload
        };
      });
    },
    [dispatch, queryClient, currentView, companyId, updateReminder]
  );

  const handleAppointmentClick = useCallback(
    (e: AppointmentClickEvent) => {
      const { id, project } = e.appointmentData;

      // prevent event toolip showing up
      e.cancel = true;

      if ('dueDate' in e.appointmentData) {
        if (!project) {
          showError(`You don't have access to the project`);

          return;
        }

        openModal<void>(({ onClose }) => <ReminderView initialValues={e.appointmentData} onClose={onClose} />, {
          isHeaderShown: false
        });

        return;
      }
      /*
    // for bulk checkbox only
    if (e.event.target.type === 'checkbox') {
      return;
    }
    */

      // TODO check what exactly is modified
      // take unmodified by devextreme-scheduler task object
      const task = find({ id }, preparedEvents);

      if (task && hasEntityAccessNew(task, 'view')) {
        onTaskClick(task);
      }
    },
    [onTaskClick, preparedEvents, showError, openModal]
  );

  const renderTimeCell = useCallback((itemData: any) => {
    return <div>{itemData.text.replace(':00', '')}</div>;
  }, []);

  const renderEventOverflowCollector = useCallback((props) => {
    const { appointmentCount } = props;

    return <OverflowLabel>...{appointmentCount} more</OverflowLabel>;
  }, []);

  const handleAppointmentFormOpening = useCallback((e: any) => {
    e.cancel = true;
  }, []);

  const handleContentReady = useCallback(() => {
    const todayButton = document.getElementById('schedulerTodayButton');

    if (!todayButton) {
      const element = document.querySelectorAll('.dx-scheduler-navigator .dx-collection');
      const container = document.createElement('div');
      container.id = 'schedulerTodayButton';
      container.classList.add('today-button');

      element[0].prepend(container);

      // eslint-disable-next-line no-new
      new Button(container, {
        text: 'Today',
        onClick: () => {
          schedulerRef.current?.instance.option('currentDate', new Date());
        }
      });
    }

    const listViewButton = document.getElementById('schedulerListViewButton');

    if (!listViewButton) {
      const element = document.querySelectorAll('.dx-scheduler-view-switcher');
      const container = document.createElement('div');
      container.id = 'schedulerListViewButton';
      container.classList.add('list-view-button');

      element[0].parentElement.appendChild(container);

      // eslint-disable-next-line no-new
      new Button(container, {
        text: 'List',
        onClick: (e) => {
          e.cancel = true;
          e.event.stopPropagation();
          e.event.stopImmediatePropagation();
          navigate(`/${companyId}/list`);
        }
      });
    }
  }, [companyId]);

  const [dayPopover, setDayPopover] = React.useState<{ element: HTMLElement; startDate: Date; endDate: Date } | null>(
    null
  );

  const handleDayPopoverClose = () => {
    setDayPopover(null);
  };

  const handleCalendarCellClick = useCallback(({ cellData, cellElement }: CellClickEvent) => {
    setDayPopover({
      element: cellElement,
      startDate: cellData.startDate,
      endDate: cellData.endDate
    });
  }, []);

  const eventsForRange = useMemo(() => {
    if (!dayPopover) {
      return [];
    }

    const rangeStart = moment(dayPopover.startDate);
    const rangeEnd = moment(dayPopover.endDate);

    return preparedEvents.filter((event) => {
      const startDate = 'dueDate' in event ? undefined : event.startDate;
      const endDate = 'dueDate' in event ? event.dueDate : event.endDate;

      if (startDate && !endDate) {
        const date = moment(startDate);

        if (date.isBetween(rangeStart, rangeEnd, 'minutes', '[)')) {
          return true;
        }
      }

      if (endDate && !startDate) {
        const date = moment(endDate);

        if (date.isBetween(rangeStart, rangeEnd, 'seconds', '[)')) {
          return true;
        }
      }

      if (startDate && endDate) {
        return (
          rangeStart.isBetween(startDate, endDate, 'seconds', '[)') ||
          rangeEnd.isBetween(startDate, endDate, 'seconds', '(]') ||
          moment(endDate).isBetween(rangeStart, rangeEnd, 'seconds', '(]') ||
          moment(startDate).isBetween(rangeStart, rangeEnd, 'minutes', '[)')
        );
      }

      return false;
    });
  }, [preparedEvents, dayPopover]);

  const handlePopoverEventClick = useCallback(
    (task: any) => {
      if (task && hasEntityAccessNew(task, 'view')) {
        if ('dueDate' in task) {
          if (!task.project) {
            showError(`You don't have access to the project`);

            return;
          }

          openModal<void>(({ onClose }) => <ReminderView initialValues={task} onClose={onClose} />, {
            isHeaderShown: false
          });

          return;
        }

        onTaskClick(task);
        setDayPopover(null);
      }
    },
    [onTaskClick, showError, openModal]
  );

  const width = useMemo(() => {
    if (isPhone) {
      return `calc(100vw - 40px)`;
    }

    const menuWidth = isDrawerOpen ? MAIN_MENU_WIDTH : MAIN_MENU_COLLAPSED_WIDTH;
    const trayWidth = isTrayCollapsed ? TRAY_COLLAPSED_WIDTH : TRAY_WIDTH;

    return `calc(100vw - ${menuWidth} - 32px - ${trayWidth})`;
  }, [isDrawerOpen, isTrayCollapsed]);

  return (
    <div>
      <SchedulerWrapper
        ref={schedulerRef}
        height="calc(100vh - 48px - 91px)"
        width={width}
        defaultCurrentView="week"
        currentView={currentView as ViewType}
        onCurrentDateChange={setCurrentDate}
        onCurrentViewChange={handleViewChange}
        dataSource={preparedEvents}
        cellDuration={60}
        editing
        onAppointmentUpdated={handleUpdateEvent}
        onAppointmentClick={handleAppointmentClick}
        timeCellRender={renderTimeCell}
        maxAppointmentsPerCell={currentView === 'month' ? 5 : 'auto'}
        appointmentCollectorRender={renderEventOverflowCollector}
        onAppointmentFormOpening={handleAppointmentFormOpening}
        appointmentTooltipComponent={EventListTooltip}
        focusStateEnabled={false}
        onContentReady={handleContentReady}
        startDateExpr="startDateInternal"
        endDateExpr="endDateInternal"
        onCellClick={handleCalendarCellClick}
      >
        <View type="month" appointmentComponent={MonthEvent} />
        <View type="week" cellDuration={30} appointmentComponent={WeekEvent} />
        <AppointmentDragging
          group={SCHEDULER_DRAGGING_GROUP_ID}
          onRemove={handleRemoveEventFromCalendar}
          onAdd={handleAddEventToCalendar}
        />
      </SchedulerWrapper>

      <Popover
        open={Boolean(dayPopover) && eventsForRange.length > 0}
        anchorEl={dayPopover?.element}
        onClose={handleDayPopoverClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <div>
          {eventsForRange.map((event) => (
            <EventListTooltip onClick={handlePopoverEventClick} key={event.id} data={{ appointmentData: event }} />
          ))}
        </div>
      </Popover>
    </div>
  );
};
