import { colors, scroll } from '@styles';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding-top: 16px;
  font-size: 12px;
  line-height: 18px;
  height: 100%;

  .dx-draggable-source {
    border-radius: 4px;
    background: ${colors.green};
    opacity: 0.5;
  }
  .dx-draggable-source > * {
    opacity: 0;
  }

  #tray {
    height: calc(100% - 3 * 16px - 36px - 18px - 36px);
    overflow: auto;
    ${scroll};
  }
`;

export const SearchAndFilter = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  &&& {
    > * {
      margin: 0 !important;
      background: #fff;
      flex: 1;
      min-width: 0;
      max-width: calc(50% - 8px);
    }
  }
`;

export const Filters = styled.div`
  margin: 16px 0;
  display: flex;
  gap: 16px;

  > * {
    flex: 1;
  }
  && {
    > * {
      margin: 0 !important;
      background: #fff;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
`;

export const Toggle = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${(props) => (props.isCollapsed ? '-26px' : '')};
`;

export const Title = styled.span<{ isRotated?: boolean }>`
  ${(props) =>
    props.isRotated
      ? css`
          transform: rotate(90deg);
          transform-origin: center;
          position: absolute;
          left: calc(100% - 30px);
          top: 75px;
          white-space: nowrap;
          height: 100%;
        `
      : ''}
`;
