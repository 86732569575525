import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { labelsApi, CreateLabelPayload } from '@services/api/labelsApi';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';

export const useCreateLabel = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, CreateLabelPayload>(
    async (dto) => {
      try {
        await labelsApi.create(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Label created successfully');
        queryClient.invalidateQueries([ReactQueryKey.LabelList, companyId]);
      }
    }
  );
};
