import { ProjectRequestStatus, ProjectStatus, TaskStatus } from '@generated/types/graphql';
import { AccountStatus } from '@types';
import { createGlobalState } from 'react-use';

export type ClientTrayFilters = {
  statuses: TaskStatus[];
  templates: number[];
  clientStatuses: AccountStatus[];
  projectStatuses: ProjectStatus[];
  requestStatuses: ProjectRequestStatus[];
  query: string;
};

export const DEFAULT_CLIENT_TRAY_FILTERS: ClientTrayFilters = {
  statuses: [],
  templates: [],
  clientStatuses: [],
  projectStatuses: [],
  requestStatuses: [],
  query: ''
};

export const useClientTrayFilterState = createGlobalState<ClientTrayFilters>(DEFAULT_CLIENT_TRAY_FILTERS);
