import { ReactQueryKey } from '@enums';
import { Label } from '@generated/types/graphql';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { labelsApi } from '@services/api/labelsApi';

export const useDeleteLabel = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, number>(
    async (id) => {
      try {
        await labelsApi.update(companyId, { id, dto: { isActive: false } });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async (_, id) => {
        showSuccess('Label deleted successfully');
        queryClient.setQueryData<Label[] | undefined>([ReactQueryKey.LabelList, companyId], (old) => {
          if (!old) {
            return old;
          }

          return old.filter((label) => label.id !== id);
        });
      }
    }
  );
};
