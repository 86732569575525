import React from 'react';
import { usePortalNotifications } from '@hooks/workspace/notifications/usePortalNotifications';
import { Title, Description } from '../styled';
import { makeTitleHref } from '../helpers';
import { List } from './styled';
import { Entity } from './Entity';

export const Notifications = () => {
  const { data: notificationTypes = [] } = usePortalNotifications();

  return (
    <div data-section id={makeTitleHref('Notifications')}>
      <Title>Notifications</Title>
      <Description>
        Send notifications to your clients about project status changes, scheduled visits, request statuses, requested
        documents, and more.
      </Description>

      <List>
        {notificationTypes.map((entity) => (
          <Entity key={entity.title} entity={entity} />
        ))}
      </List>
    </div>
  );
};
