import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import systemAlertsAPI, { AlertConfigDTO } from '@services/api/systemAlertsAPI';
import { ReactQueryKey } from '@enums';

export const useUpdateSystemAlertConfig = () => {
  const queryClient = useQueryClient();

  const { showSuccess } = useToast();

  return useMutation<void, Error, { id: number; dto: Partial<AlertConfigDTO> }>(
    async ({ id, dto }) => {
      try {
        await systemAlertsAPI.updateAlertConfig(id, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Alert updated successfully`);
        queryClient.invalidateQueries([ReactQueryKey.SystemsSettings, ReactQueryKey.SystemsAlertsSettings]);
      }
    }
  );
};
