import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import workflowsApi from '@services/api/workflowsApi';
import { selectWorkspaceId } from '@state/selectors';
import { AutomationFromApi } from '@types';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useToast } from '..';

export const useDuplicateAutomation = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<AutomationFromApi, Error, { id: number; name: string }>(
    async ({ id, name }) => {
      try {
        const { data: workflow } = await workflowsApi.duplicate(id, { name }, { companyId });

        return workflow as AutomationFromApi;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Automation created successfully');
        queryClient.invalidateQueries([ReactQueryKey.ProjectWorkflows]);
      }
    }
  );
};
