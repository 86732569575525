import { PortalNotificationEvent } from '@hooks/workspace/notifications/usePortalNotifications';

export const getDeliveryMethodsString = (event: PortalNotificationEvent) => {
  const { settings, eventSchema } = event;
  const methods = [];

  if (eventSchema.scheduled) {
    if (settings.offset1Email || settings.offset2Email) {
      methods.push('Email');
    }
    if (settings.offset1Sms || settings.offset2Sms) {
      methods.push('SMS');
    }
  } else {
    if (settings.emailEnabled) {
      methods.push('Email');
    }

    if (settings.smsEnabled) {
      methods.push('SMS');
    }
  }

  return methods.join(', ');
};
