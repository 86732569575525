import React, { useCallback, useState } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { useSystemAlertConfigList } from '@hooks/workspace/systems/alertConfigs/useSystemAlertConfigList';
import { Panel, PanelDescription, PanelHeader, PanelTitle } from '../styled';
import { List } from './styled';
import { AlertForm } from './AlertForm';

type Alert = {
  id: number;
};

export const Alerts = () => {
  const { data: alerts = [] } = useSystemAlertConfigList();

  const [expanded, setExpanded] = useState<number | null>(null);
  const [isNewAlertShown, setIsNewAlertShown] = useState(false);

  const handleChange = useCallback((fleet: Alert, isExpanded: boolean) => {
    if (!fleet) {
      setExpanded(isExpanded ? 0 : null);
    } else {
      setExpanded(isExpanded ? fleet.id : null);
    }
  }, []);

  const handleAddAlert = useCallback(() => {
    setIsNewAlertShown(true);
    setExpanded(0);
  }, []);

  const handleCancelCreation = useCallback(() => {
    setExpanded(null);
    setIsNewAlertShown(false);
  }, []);

  return (
    <Panel>
      <PanelHeader>
        <div>
          <PanelTitle>Alerts</PanelTitle>
          <PanelDescription>Set up alerts to control your system perfomance.</PanelDescription>
        </div>

        <Button onClick={handleAddAlert} variant={ButtonVariant.Primary}>
          <Plus size="16px" />
          Alert
        </Button>
      </PanelHeader>

      <List>
        {alerts.map((alert) => (
          <AlertForm
            key={alert.id}
            alert={alert}
            isExpanded={alert.id === expanded}
            onToggleExpand={handleChange}
            onCancelCreation={handleCancelCreation}
          />
        ))}

        {isNewAlertShown && (
          <AlertForm
            isExpanded={expanded === 0}
            onToggleExpand={handleChange}
            onCancelCreation={handleCancelCreation}
          />
        )}
      </List>
    </Panel>
  );
};
