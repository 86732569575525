import React from 'react';
import { Header, Tables, Title } from './styled';
import { Emails } from './Emails';
import { Phones } from './Phones';

export const EmailsAndPhones = () => {
  return (
    <div>
      <Header>
        <Title>Emails & Phones</Title>
      </Header>

      <Tables>
        <Emails />
        <Phones />
      </Tables>
    </div>
  );
};
