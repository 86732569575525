import { useMemo } from 'react';
import { useSystemsBudget } from '@hooks/workspace/systems/useSystemsBudget';
import { useSystemsStats } from '@hooks/workspace/systems/useSystemsStats';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { DateTime } from 'luxon';
import { calculateExpectedBill } from './helpers';

export const useExpectedBill = (fleets: Fleet[]) => {
  const { data: budgetInfo } = useSystemsBudget();
  const { data: stats } = useSystemsStats();

  return useMemo(() => {
    if (!budgetInfo || !stats || !fleets?.length) {
      return null;
    }

    const { currentHitsByProvider } = budgetInfo;

    const { averageMonthlyNew, byFleetId, byFleetIdByIntegrationId, total } = stats;

    return calculateExpectedBill({
      currentHitsByProvider,
      averageMonthlyNewSystems: averageMonthlyNew,
      systemsByFleetId: byFleetId,
      systemsByFleetIdByIntegrationId: byFleetIdByIntegrationId,
      totalSystems: total,
      fleets,
      days: DateTime.now().endOf('month').diff(DateTime.now().startOf('day'), 'days').days,
      daysInMonth: DateTime.now().daysInMonth
    });
  }, [stats, budgetInfo, fleets]);
};
