import React, { useState } from 'react';
import { useInsertManualGeo } from '@hooks/geolocation';
import { LngLat } from 'react-map-gl';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { DateTime } from 'luxon';
import { UserSelector } from '@common/Selector/UserSelector';
import { DispatcherTask, useDispatherTasks } from '@components/Scheduler/Dispatcher/useTasks';
import { Select } from '@kit/ui/Select';
import { useAppSelector } from '@hooks';
import { selectCompanySettings } from '@state/selectors';
import { Checkbox } from '@kit/ui/Checkbox';
import { Buttons, Container, DebugFrame, DebugLabel, Title } from './styled';

type Props = {
  point: LngLat;
  handleClose: () => void;
};

export const ManualPointPopup = ({ point, handleClose }: Props) => {
  const settings = useAppSelector(selectCompanySettings);
  const isFakeRoutesEnabled = !!settings?.features?.geolocationDebug;

  const { mutateAsync } = useInsertManualGeo();

  const [selectedUser, setSelectedUser] = useState();

  const [selectedTask, setSelectedTask] = useState<DispatcherTask | null>(null);

  const [buildRouteTo, setBuildRouteTo] = useState(false);
  const [runDummyFrom, setRunDummyFrom] = useState(false);
  const [runDummyInstant, setRunDummyInstant] = useState(true);

  const onSave = async () => {
    await mutateAsync({
      userId: selectedUser.id,
      taskId: selectedTask.id,
      deviceId: 'web',
      latitude: point.lat,
      longitude: point.lng,
      timestamp: DateTime.now().toISO(),
      buildRouteTo,
      runDummyFrom,
      runDummyInstant
    });

    handleClose();
  };

  const { allWorkOrders } = useDispatherTasks();

  return (
    <Container>
      <Title>Set location manually</Title>

      <UserSelector
        isMultiple={false}
        selectedUsers={[selectedUser].filter(Boolean)}
        setSelectedUsers={(users) => setSelectedUser(users[0])}
      />

      <Select
        options={allWorkOrders}
        value={selectedTask}
        onChange={(_, value) => setSelectedTask(value)}
        renderOption={(task) => `#${task.uid} · ${task.title}`}
        getOptionLabel={(task) => task.title}
        isMulti={false}
        placeholder="Search WO..."
      />

      {isFakeRoutesEnabled && (
        <DebugFrame>
          <DebugLabel>Debug</DebugLabel>

          <Checkbox
            label={
              <span>
                Also imitate route <b>to</b> new point from the latest known position
              </span>
            }
            isChecked={buildRouteTo}
            onChange={setBuildRouteTo}
          />

          <Checkbox
            isChecked={runDummyFrom}
            onChange={setRunDummyFrom}
            label={
              <span>
                Also imitate route <b>from</b> new point to WO site
              </span>
            }
          />

          <div style={{ paddingLeft: 32 }}>
            <Checkbox
              isChecked={runDummyInstant}
              onChange={setRunDummyInstant}
              isDisabled={!runDummyFrom}
              label="... instantly"
            />
          </div>
        </DebugFrame>
      )}

      <Buttons>
        <Button variant={ButtonVariant.Secondary} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={ButtonVariant.Primary} onClick={onSave} disabled={!selectedUser || !selectedTask}>
          Save
        </Button>
      </Buttons>
    </Container>
  );
};
