import { useClickOutside, usePrevious } from '@react-hookz/web';
import { useFormikContext } from 'formik';
import { useEffect, useRef, useState } from 'react';

export const useInlineUpdateField = (onUpdated: (values: any) => void) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef();
  const prevIsEdit = usePrevious(isEdit);

  const { values } = useFormikContext();

  useEffect(() => {
    if (!isFocused) {
      setIsEdit(false);
    }
  }, [isFocused]);

  useClickOutside(ref, () => {
    if (!isFocused) {
      setIsEdit(false);
    }
  });

  useEffect(() => {
    if (prevIsEdit && !isEdit) {
      onUpdated(values);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, prevIsEdit, values]);

  return {
    isEdit,
    setIsEdit,
    isFocused,
    setIsFocused,
    nodeRef: ref
  };
};
