import styled from 'styled-components';
import { Header as HeaderComponent } from '../components/Header';

export const Container = styled.div`
  padding: 0 24px;
`;

export const Header = styled(HeaderComponent)`
  padding: 0 24px;
`;
