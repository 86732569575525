import styled, { css } from 'styled-components';
import { WorkOrderStatus as WorkOrderStatusComponent } from '@components/WorkOrder/WorkOrderStatus';

export const ReminderContainer = styled.div`
  padding: 3px;
  height: 100%;
  border-radius: 4px;
  opacity: ${(props) => props.opacity};
`;

export const Container = styled.div<{ labelColor?: string; labelFill?: string; opacity?: number }>`
  padding: 3px;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
  ${(props) =>
    props.labelColor &&
    css`
      border-left: 4px solid ${props.labelColor};
    `}
  ${(props) =>
    props.labelFill &&
    css`
      border-color: ${props.labelFill};
      background-color: ${props.labelFill};
      color: #ffffff;

      svg {
        stroke: #ffffff;
      }
    `}
  opacity: ${(props) => props.opacity};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  > *:first-child {
    max-width: 70%;
  }
`;

export const Text = styled.div<{ isBold?: boolean; isCompleted?: boolean }>`
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: ${(props) => (props.isBold ? '500' : '400')};
  text-decoration: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
`;

export const Title = styled(Text)`
  display: flex;
  align-items: center;
  gap: 4px;

  svg {
    flex-shrink: 0;
  }
`;

export const WorkOrderStatus = styled(WorkOrderStatusComponent)`
  max-width: 100%;
  margin-left: auto;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  > * {
    margin-bottom: 0;
  }

  svg {
    flex-shrink: 0;
    margin-right: 2px;
  }
`;

export const TimelineWhiskers = styled.svg`
  position: absolute;
  left: 0;
  top: 100%;
  overflow: visible;
  height: 1px; // https://gitlab.com/coperniq/core/-/issues/1230
`;

export const WhiskerTooltip = styled.div`
  background-color: #080813cc;
  color: #ffffff;
  border-radius: 4px;
  padding: 4px;
  font-size: 12px;
  font-weight: 400;
`;
