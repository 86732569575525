import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import phoneAPI from '@services/api/phoneAPI';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useMutation, useQueryClient } from 'react-query';

export const useDeleteCompanyPhone = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<void, Error, number>(
    async (id) => {
      try {
        await phoneAPI.deletePhone(companyId, id);
      } catch (e) {
        throw apiErrorHandler('Error deleting phone', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyPhones, companyId]);
      }
    }
  );
};
