import React, { useMemo } from 'react';

import { Form, useForm, FormValidationRules, RichTextField, SelectField } from '@kit/components/Form';
import { useTokens } from '@hooks/useTokens';
import { convertQuillFormatToText, isQuilValueEmpty } from '@utils/quill';
import { RecordType } from '@types';
import { useSmsMutations } from '@hooks/useSms';
import { useAppSelector } from '@hooks/store';
import { selectCurrentUserId } from '@state/selectors';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useCompanyPhoneList } from '@hooks/workspace/phones/useCompanyPhoneList';
import { Body, Footer } from './styled';

interface Props {
  recordIds: number[];
  onClose: () => void;
}

const TO_OPTIONS = [
  { id: 'all', title: 'All contacts' },
  { id: 'primary', title: 'Primary contact' }
];

interface FormValues {
  from: { id: number; title: string };
  to: { id: string; title: string };
  message: string;
}

export const Sms = ({ recordIds, onClose }: Props) => {
  const { data: companyPhones = [] } = useCompanyPhoneList();
  const currentUserId = useAppSelector(selectCurrentUserId);

  const { sendBulkSms } = useSmsMutations();

  const { getAllTokens, getTokensFieldPhone } = useTokens(RecordType.PROJECT, true);

  const postForm = async (values: FormValues) => {
    await sendBulkSms.mutateAsync({
      targetProjectIds: recordIds,
      toPrimaryContactPhoneOnly: values.to.id === 'primary',
      // to: clearQuillValue(convertQuillFormatToText(values.to)),
      text: convertQuillFormatToText(values.message),
      fromCompanyPhoneId: values.from.id
    });

    onClose();
  };

  const { handleSubmit, form } = useForm<FormValues>({
    onSubmit: postForm,
    defaultValues: {}
  });

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      to: {
        isRequired: true
        /* validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        } */
      },

      message: {
        isRequired: true,
        validate: (value) => {
          if (value && isQuilValueEmpty(value as string)) {
            return 'This is required';
          }

          return undefined;
        }
      }
    }),
    []
  );

  const {
    formState: { isSubmitting },
    control
  } = form;

  const fromFieldOptions =
    companyPhones
      .filter((phone) => phone.companyPhoneUsers?.some((user) => user.canSend && user.user?.id === currentUserId))
      .map(({ id, alias, phone }) => ({
        id,
        alias,
        phone,
        title: `${alias} (${phone})`
      })) || [];

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <SelectField
          label="From"
          name="from"
          getOptionLabel={(option) => option.title}
          options={fromFieldOptions}
          control={control}
        />

        <SelectField
          name="to"
          label="To"
          options={TO_OPTIONS}
          isClearable={false}
          control={control}
          getOptionLabel={(option) => option.title}
        />

        {false && (
          <RichTextField
            name="to"
            getTokens={getTokensFieldPhone}
            isToolbarShown={false}
            isToolbarHiddenCompletely
            isOneLine
            control={control}
            label="To"
            placeholder=""
          />
        )}

        <RichTextField
          name="message"
          getTokens={getAllTokens}
          control={control}
          placeholder="Type here..."
          isToolbarShown={false}
        />
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Send
        </Button>
      </Footer>
    </Form>
  );
};
