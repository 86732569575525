import { useConfirmDeleteModal } from '@common/PromiseModal';
import React, { useCallback, useEffect, useMemo } from 'react';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { CheckboxField, Form, FormValidationRules, InputField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { SyncFrequency } from '@generated/types/graphql';
import { Badge } from '@kit/ui/Badge';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { Control } from 'react-hook-form';
import { ProfileCreateDTO, SystemMetric } from '@services/api/systemProfilesAPI';
import { Pause } from 'react-feather';
import { BadgeSize } from '@kit/ui/Badge/types';
import { MetricsField } from './MetricsField';
import { FormValues } from './types';
import { PROVIDER_CONFIGS, PROVIDER_CONFIGS_MAP } from '../constants';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormActions,
  Logo,
  ProviderFormContainer,
  ProviderFormHeader,
  Title,
  TitleAndDefault,
  TitleRight
} from './styled';

interface Props {
  fleet: Fleet;
  isExpanded: boolean;
  onToggleExpand: (fleet: Fleet, isExpanded: boolean) => void;
  onCancelCreation: () => void;
  onFleetChange?: (fleet: Fleet) => void;

  createFleet: (payload: ProfileCreateDTO) => Promise<void>;
  updateFleet: (payload: { id: number; dto: ProfileCreateDTO }) => Promise<void>;
  deleteFleet: (id: number) => Promise<void>;
  isSimulation?: boolean;
}

const FREQUENCY_OPTIONS = [
  // { value: SyncFrequency.Hour, label: 'Hourly' },
  { value: SyncFrequency.Day, label: 'Daily' }
  // { value: SyncFrequency.Month, label: 'Monthly' }
];

export const FleetForm = ({
  fleet,
  isExpanded,
  onToggleExpand,
  onCancelCreation,
  onFleetChange,
  createFleet,
  updateFleet,
  deleteFleet,
  isSimulation = false
}: Props) => {
  const isNew = !fleet.id;

  const confirmDelete = useConfirmDeleteModal();

  const handleToggle = useCallback(
    (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      onToggleExpand(fleet, isExpanded);
    },
    [fleet, onToggleExpand]
  );

  const { form, handleSubmit } = useForm<FormValues>({
    defaultValues: {
      name: fleet.name || '',
      isDefault: fleet.default || false,
      ...fleet.systemProfileConfigs.reduce((acc, config) => {
        acc[`configs.${config.integration.provider}.frequency`] = {
          value: config.frequency,
          label: FREQUENCY_OPTIONS.find(({ value }) => value === config.frequency)?.label || config.frequency
        };
        acc[`configs.${config.integration.provider}.enabledMetrics`] = Object.entries(config.metrics)
          .filter(([, { enabled }]) => enabled)
          .map(([metric]) => metric as SystemMetric);

        return acc;
      }, {} as FormValues)
    },
    onSubmit: async (values, { reset }) => {
      const payload = {
        name: values.name,
        default: values.isDefault,
        configs: Object.entries(values.configs).map(([provider, { frequency, enabledMetrics }]) => ({
          integration: fleet.systemProfileConfigs.find(({ integration }) => integration.provider === provider)
            ?.integration.id,
          frequency: frequency.value as SyncFrequency,
          enabledMetrics: enabledMetrics as SystemMetric[]
        }))
      };

      if (isNew) {
        await createFleet(payload);

        onCancelCreation();
      } else {
        await updateFleet({ id: fleet.id, dto: payload });
        reset(undefined, { keepDirty: false, keepDirtyValues: true, keepDefaultValues: false, keepValues: true });
        onToggleExpand(fleet, false);
      }
    }
  });

  const {
    control,
    formState: { isDirty, isSubmitting },
    reset,
    watch
  } = form;

  useEffect(() => {
    reset(
      (prev) => ({
        ...prev,
        isDefault: fleet.default
      }),
      { keepDirty: true }
    );
  }, [fleet, reset]);

  useEffect(() => {
    if (!onFleetChange) {
      return () => {};
    }

    const subscription = watch((values, { name, type }) => {
      if (type !== 'change') {
        return;
      }

      if (!name.startsWith('configs.')) {
        return;
      }

      onFleetChange({
        ...fleet,
        systemProfileConfigs: fleet.systemProfileConfigs.map((config) => ({
          ...config,
          frequency: values.configs[config.integration.provider].frequency.value as SyncFrequency,
          metrics: Object.keys(config.metrics).reduce(
            (acc, metric) => {
              acc[metric as SystemMetric] = {
                enabled: values.configs[config.integration.provider].enabledMetrics.includes(metric as SystemMetric),
                by: 'auto'
              };

              return acc;
            },
            {} as FormValues['configs'][string]['metrics']
          )
        }))
      });
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [fleet, watch, onFleetChange]);

  const handleCancel = useCallback(() => {
    if (isNew) {
      onCancelCreation();

      return;
    }

    reset();
    onToggleExpand(fleet, false);
  }, [reset, onToggleExpand, fleet, isNew, onCancelCreation]);

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      ...PROVIDER_CONFIGS.reduce((acc, { id }) => {
        acc[`configs.${id}.frequency`] = {
          isRequired: true
        };

        return acc;
      }, {} as FormValidationRules<FormValues>)
    }),
    []
  );

  const handleDelete = useCallback(async () => {
    if (
      isSimulation ||
      (await confirmDelete(
        'Are you sure you want to delete this Fleet?\nAll associated systems will be moved to default Fleet.',
        'Confirm and delete'
      ))
    ) {
      await deleteFleet(fleet.id);
      onCancelCreation();
    }
  }, [isSimulation, confirmDelete, deleteFleet, fleet.id, onCancelCreation]);

  return (
    <Accordion expanded={isExpanded} onChange={handleToggle}>
      <AccordionSummary expandIcon={<ChevronDownIcon color="#9C9CAA" />}>
        <Title>
          {fleet.name || 'New Fleet'}

          {isDirty && (!isNew || (isNew && !isExpanded)) && (
            <Badge color="#d54855" bgColor="#fff">
              Unsaved changes
            </Badge>
          )}
        </Title>
        <TitleRight>
          {fleet.default && (
            <Badge color="#828D9A" bgColor="#fff">
              Default Fleet
            </Badge>
          )}
        </TitleRight>
      </AccordionSummary>

      <AccordionDetails>
        <Form rules={rules} onSubmit={handleSubmit}>
          <TitleAndDefault>
            <InputField name="name" control={control} label="Fleet name" />
            {!fleet.default && <CheckboxField label="Make this Fleet default" name="isDefault" control={control} />}
          </TitleAndDefault>

          <div>
            {fleet.systemProfileConfigs.map((config) => (
              <ProviderForm key={config.integration.provider} config={config} control={control} />
            ))}
          </div>

          <FormActions>
            {!isNew && !fleet.default && (
              <Button variant={ButtonVariant.Danger} onClick={handleDelete}>
                Delete
              </Button>
            )}

            <Button variant={ButtonVariant.Secondary} onClick={handleCancel}>
              Cancel
            </Button>
            {!isSimulation && (
              <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
                {isNew ? 'Save' : 'Update'}
              </Button>
            )}
            {isSimulation && (
              <Button type="submit" variant={ButtonVariant.Primary}>
                Simulate
              </Button>
            )}
          </FormActions>
        </Form>
      </AccordionDetails>
    </Accordion>
  );
};

const ProviderForm = ({
  config,
  control
}: {
  config: Fleet['systemProfileConfigs'][number];
  control: Control<FormValues>;
}) => {
  const providerConfig = PROVIDER_CONFIGS_MAP[config.integration.provider];

  return (
    <ProviderFormContainer>
      <ProviderFormHeader>
        <Logo>
          <img src={providerConfig.logo} alt={providerConfig.name} />
        </Logo>

        {!config.integration.active && (
          <Badge color="#778CA2" bgColor="#E4E8EC" size={BadgeSize.Large}>
            <Pause size="16px" />
            Paused
          </Badge>
        )}
      </ProviderFormHeader>

      <SelectField
        name={`configs.${providerConfig.id}.frequency`}
        control={control}
        label="Update frequency"
        options={FREQUENCY_OPTIONS}
        isClearable={false}
      />

      <MetricsField
        name={`configs.${providerConfig.id}.enabledMetrics`}
        control={control}
        config={config}
        hint={providerConfig.metricsDescription}
      />
    </ProviderFormContainer>
  );
};
