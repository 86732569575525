import { colors } from '@styles';
import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: 16px 0;
  max-width: 700px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;

  > *:first-child {
    flex: 1;
    max-width: 500px;
  }
`;

export const ArrowContainer = styled.div`
  text-align: center;
`;
