import styled from 'styled-components';
import { fonts, colors } from '@styles';

export const Wrapper = styled.div`
  background-color: #e8ecef;
  border-radius: 4px;
  padding: 17px 20px;
  margin: 20px 0;

  &:first-child {
    margin-top: 0;
  }
`;

export const TooltipWrapper = styled.div`
  font-size: 12px;
  line-height: 1.25;
  font-family: ${fonts.primary};

  p {
    &:empty {
      display: none;
    }

    margin-top: 10px;
  }
`;

export const Title = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 1.25;
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  svg {
    margin-left: 5px;
  }
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 1.25;
  color: ${colors.gray};
  strong {
    font-weight: 500;
  }
`;
