import { ImportHistoryListItem } from '@hooks/import/useImportHistoryList';
import React from 'react';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { Badge } from '@kit/ui/Badge';
import { useDownloadFailedRows } from '@hooks/import/useDownloadFailedRows';
import { Download } from 'react-feather';
import { ImportHistoryStatus } from '@generated/types/graphql';
import { useDownloadSkippedRows } from '@hooks/import/useDownloadSkippedRows';
import { useImportCounters } from '../useImportCounters';
import { Body, Description, Footer, Stats } from './styled';

interface Props {
  importHistoryRecord: ImportHistoryListItem;
  onClose: () => void;
}

const IN_PROGRESS_STATUSES = [ImportHistoryStatus.InProgress, ImportHistoryStatus.Undoing, ImportHistoryStatus.Pending];

export const Details = ({ importHistoryRecord, onClose }: Props) => {
  const recordType = importHistoryRecord.type === 'PROJECT' ? 'Projects' : 'Requests';
  const counters = useImportCounters(importHistoryRecord);
  const { mutateAsync: downloadFailedRows } = useDownloadFailedRows();
  const { mutateAsync: downloadSkippedRows } = useDownloadSkippedRows();

  const isInProgress = IN_PROGRESS_STATUSES.includes(importHistoryRecord.status);

  if (isInProgress) {
    return (
      <div>
        <Body>
          <div>Your {recordType} are importing...</div>
        </Body>
        <Footer>
          <Button onClick={onClose} variant={ButtonVariant.Primary}>
            Got it
          </Button>
        </Footer>
      </div>
    );
  }

  return (
    <div>
      <Body>
        <Description>
          <div>Your {recordType} have been imported.</div>
          <div>
            We’ve separated the invalid {recordType} into their own spreadsheet, detailing why they failed. We also
            separated skipped {recordType} into their own spreadsheet. Please download it below, review, fix errors,
            then re-import it to add them to Coperniq.{' '}
          </div>
          <div>
            <b>The spreadsheet of skipped or failed {recordType} will be available for a month.</b>
          </div>
        </Description>

        <Stats>
          <Badge color="#009A47" bgColor="#CDF3DF">
            Created
          </Badge>
          <div>
            {counters.created} / {counters.total}
          </div>
          <div />
          <Badge color="#009A47" bgColor="#CDF3DF">
            Updated
          </Badge>
          <div>
            {counters.updated} / {counters.total}
          </div>
          <div />
          <Badge color="#F1AA12" bgColor="#FCF2DB">
            Skipped
          </Badge>
          <div>
            {counters.skipped} / {counters.total}
          </div>
          <div>
            {counters.skipped > 0 && (
              <Button
                size={ButtonSize.Small}
                isUpperCase={false}
                onClick={() => downloadSkippedRows(importHistoryRecord.id)}
                variant={ButtonVariant.Flat}
              >
                <Download size={16} />
                Download skipped {recordType} (.csv)
              </Button>
            )}
          </div>
          <Badge color="#D54855" bgColor="#F7DADD">
            Failed
          </Badge>
          <div>
            {counters.failed} / {counters.total}
          </div>
          <div>
            {counters.failed > 0 && (
              <Button
                size={ButtonSize.Small}
                isUpperCase={false}
                onClick={() => downloadFailedRows(importHistoryRecord.id)}
                variant={ButtonVariant.Flat}
              >
                <Download size={16} />
                Download failed {recordType} (.csv)
              </Button>
            )}
          </div>
        </Stats>
      </Body>
      <Footer>
        {false && <Button variant={ButtonVariant.Danger}>Undo import</Button>}
        <Button onClick={onClose} variant={ButtonVariant.Primary}>
          Got it
        </Button>
      </Footer>
    </div>
  );
};
