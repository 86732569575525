import { SearchBar } from '@common/SearchBar';
import { useClientFilterState } from '@components/Scheduler/useClientFilterState';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useUpdateEffect } from '@react-hookz/web';
import React, { useEffect } from 'react';

export const Search = () => {
  const [clientFilters, setClientFilters] = useClientFilterState();
  const [search, debouncedSearch, setSearch] = useDebouncedState(clientFilters.query, 300);

  useEffect(() => {
    setClientFilters((prev: any) => ({
      ...prev,
      query: debouncedSearch
    }));
  }, [debouncedSearch, setClientFilters]);

  useUpdateEffect(() => {
    setSearch(clientFilters.query);
  }, [clientFilters.query]);

  return <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />;
};
