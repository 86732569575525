import React, { useCallback, useEffect } from 'react';
import { SubTaskField } from '@common/index';
import { useFormikContext } from 'formik';
import { debounce, isEqual } from 'lodash';
import { usePrevious } from '@react-hookz/web';
import { CommonFieldProps } from '../types';

export const ChecklistField = ({ onUpdated }: CommonFieldProps) => {
  const { values } = useFormikContext();

  const value = values.subTasks;
  const prevValue = usePrevious(value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onUpdateDebounced = useCallback(debounce(onUpdated, 1000), [values, value, prevValue]);

  useEffect(() => {
    if (prevValue !== undefined && !isEqual(value, prevValue)) {
      onUpdateDebounced(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, value, prevValue]);

  return <SubTaskField isCheckboxDisabled name="subTasks" />;
};
