import { CompanyUser } from '@generated/types/graphql';
import { selectWorkspaceId } from '@state/selectors';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { apiErrorHandler } from '@utils/api';
import { useAppSelector } from '..';

export const useUserList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<CompanyUser[]>([ReactQueryKey.CompanyMembersList, companyId], async () => {
    try {
      const data = await postGraphql<{ companyUsers: CompanyUser[] }>(
        gql`
          query USER_LIST_QUERY($companyId: Int) {
            companyUsers(filter: { companyId: { equalTo: $companyId }, isActive: { equalTo: true } }) {
              id
              user {
                id
                firstName
                lastName
                avatarUrl
                email
                phone
              }
              companyUserRoles {
                roles {
                  id
                  name
                }
              }
            }
          }
        `,
        { companyId }
      );

      return data.companyUsers;
    } catch (e) {
      throw apiErrorHandler('Error fetching company members', e);
    }
  });
};
