import styled from 'styled-components';

export const ContentContainer = styled.div`
  padding: 16px;
  border: 1px solid #dfdfe8;
  border-radius: 8px;
  margin-top: 24px;
`;

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 64px;
`;

export const Left = styled.div`
  width: 236px;
  min-width: 236px;
  max-width: 236px;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Right = styled.div`
  flex: 1;
`;

export const Label = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
`;

export const Value = styled.div`
  font-size: 20px;
  font-weight: 500;
`;

export const PieLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 150px;
  flex-shrink: 0;
`;

export const LegendLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 400;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
`;

export const PieContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 180px;

  > *:first-child {
    min-height: 180px;
  }
`;
