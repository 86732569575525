import React from 'react';
import { Tooltip } from '@material-ui/core';
import { colors } from '@styles';
import { HelpCircle } from 'react-feather';
import { Wrapper, Title, Text, TooltipWrapper } from './styled';

interface InfoPanelProps {
  title?: string;
  text?: HTMLElement;
  tooltip?: string;
}

export const InfoPanel: React.FC<InfoPanelProps> = (props) => {
  const {
    title,
    text,
    tooltip
  } = props;

  return (
    <Wrapper>
      <Title>
        {title}
        <Tooltip
          title={(
            <TooltipWrapper>
              {tooltip}
            </TooltipWrapper>
          )}
          placement="top"
          arrow
        >
          <HelpCircle size={16} color={colors.gray} />
        </Tooltip>
      </Title>
      <Text>{text}</Text>
    </Wrapper>
  );
};
