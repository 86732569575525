import React from 'react';

import { TaskGroups } from './TaskGroups';
import { ListViewHeader } from './Header';
import { useTaskDrawer } from '../useTaskDrawer';
import { Container } from './styled';

export const ListView = () => {
  const { onAddClick } = useTaskDrawer();

  return (
    <>
      <ListViewHeader onAddNewTask={onAddClick} />
      <Container>
        <TaskGroups />
      </Container>
    </>
  );
};
