import { scroll } from '@styles';
import styled from 'styled-components';

export const Body = styled.div`
  padding: 24px;
  min-width: 800px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  padding: 24px;
  background-color: #fff;
`;

export const Label = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
`;

export const TextBlock = styled.div`
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  margin-top: 24px;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;

export const Columns = styled.div`
  display: flex;
  gap: 40px;
  font-size: 12px;
  font-weight: 400;

  > * {
    flex: 1;
    max-height: 344px;
    overflow-y: auto;
    ${scroll};
  }
`;

export const ColumnTitle = styled.div`
  margin-bottom: 8px;
  background-color: #f7f7fc;
  position: sticky;
  top: 0;
  padding: 4px 0;
  z-index: 1;
`;
