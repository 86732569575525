import { TaskViewFilterSubType } from '@types';
import React from 'react';
import { useClientFilterState } from '../useClientFilterState';
import { Header } from './styled';

export const ListViewHeader = ({ onAddNewTask }: any) => {
  const [selectedFilters, setSelectedFilters] = useClientFilterState();

  return (
    <Header
      totalCount={0}
      visibleCount={0}
      selectedFilters={selectedFilters}
      onFilterChange={setSelectedFilters}
      onAddNewTask={onAddNewTask}
      subType={TaskViewFilterSubType.LIST}
    />
  );
};
