import { useSessionStorageValue } from '@react-hookz/web';
import { LocalStorageKey } from '@enums';
import { createGlobalState } from 'react-use';

export const useShowMap = () => {
  const { value, set } = useSessionStorageValue(LocalStorageKey.systemsShowMap, { defaultValue: false });

  return [value, set] as [boolean, typeof set];
};

export const useMapWidth = createGlobalState(0);