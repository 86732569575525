import { System } from '@generated/types/graphql';
import React, { useMemo } from 'react';
import { useSystemsBudget } from '@hooks/workspace/systems/useSystemsBudget';
import { useSystemsStats } from '@hooks/workspace/systems/useSystemsStats';

import { calculateExpectedBill, ChangedExpectedBill, useExpectedBill } from '@features/Platform/SystemsSettings';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { DateTime } from 'luxon';
import { isEqual } from 'lodash';
import { BillContainer } from './styled';

interface Props {
  systems: System[];
  newFleet: { id: number; name: string };
}

export const Bill = ({ systems, newFleet }: Props) => {
  const { data: fleets = [] } = useFleetList();
  const { data: budgetInfo } = useSystemsBudget();
  const { data: stats } = useSystemsStats();

  const systemsToProcess = useMemo(() => {
    return systems.filter((system) => system.profile?.id !== newFleet?.id);
  }, [systems, newFleet]);

  const currentExpectedBillByProvider = useExpectedBill(fleets);

  const selectedSystemsFleetsCount = useMemo(() => {
    return systemsToProcess.reduce((acc, system) => {
      if (system.profile) {
        acc[system.profile.id] = (acc[system.profile.id] || 0) + 1;
      }

      return acc;
    }, {});
  }, [systemsToProcess]);

  const selectedSystemsByFleetByIntegrationCount = useMemo(() => {
    return systemsToProcess.reduce((acc, system) => {
      if (system.profile && system.integration) {
        acc[system.profile.id] = acc[system.profile.id] || {};

        acc[system.profile.id][system.integration.id] = (acc[system.profile.id][system.integration.id] || 0) + 1;
      }

      return acc;
    }, {});
  }, [systemsToProcess]);

  const newExpectedBillByProvider = useMemo(() => {
    if (!budgetInfo || !stats || !fleets?.length || !newFleet) {
      return null;
    }

    const { currentHitsByProvider } = budgetInfo;

    const {
      averageMonthlyNew,
      byFleetId: currentByFleetId,
      byFleetIdByIntegrationId: currentByFleetIdByIntegrationId,
      total
    } = stats;

    const byFleetId = fleets.reduce(
      (acc, fleet) => {
        const currentCount = currentByFleetId[fleet.id] || 0;
        const selectedCount = selectedSystemsFleetsCount[fleet.id] || 0;

        if (fleet.id !== newFleet.id) {
          acc[fleet.id] = currentCount - selectedCount;

          return acc;
        }

        acc[fleet.id] = currentCount + systems.length;

        return acc;
      },
      {} as Record<number, number>
    );

    const systemsByFleetIdByIntegrationId = fleets.reduce(
      (acc, fleet) => {
        const currentCount = currentByFleetIdByIntegrationId[fleet.id] || {};
        const selectedCount = selectedSystemsByFleetByIntegrationCount[fleet.id] || {};

        if (fleet.id !== newFleet.id) {
          acc[fleet.id] = Object.keys(selectedCount).reduce(
            (acc2, integrationId) => {
              // eslint-disable-next-line no-param-reassign
              acc2[integrationId] = (acc2[integrationId] || 0) - (selectedCount[integrationId] ?? 0);

              return acc2;
            },
            { ...currentCount } as Record<string, number>
          );

          return acc;
        }

        acc[fleet.id] = Object.keys(selectedSystemsByFleetByIntegrationCount).reduce(
          (acc2, oldFleetId) => {
            Object.keys(selectedSystemsByFleetByIntegrationCount[oldFleetId]).forEach((integrationId) => {
              // eslint-disable-next-line no-param-reassign
              acc2[integrationId] =
                (acc2[integrationId] || 0) + (selectedSystemsByFleetByIntegrationCount[oldFleetId][integrationId] || 0);
            });

            return acc2;
          },
          { ...currentCount } as Record<string, number>
        );

        return acc;
      },
      {} as Record<number, Record<string, number>>
    );

    return calculateExpectedBill({
      currentHitsByProvider,
      averageMonthlyNewSystems: averageMonthlyNew,
      systemsByFleetId: byFleetId,
      systemsByFleetIdByIntegrationId,
      totalSystems: total,
      fleets,
      days: DateTime.now().endOf('month').diff(DateTime.now().startOf('day'), 'days').days,
      daysInMonth: DateTime.now().daysInMonth
    });
  }, [
    budgetInfo,
    stats,
    fleets,
    newFleet,
    selectedSystemsFleetsCount,
    systems,
    selectedSystemsByFleetByIntegrationCount
  ]);

  const isChanged =
    newFleet && newExpectedBillByProvider && !isEqual(currentExpectedBillByProvider, newExpectedBillByProvider);

  return (
    <BillContainer isChanged={isChanged}>
      <ChangedExpectedBill billPerProvider={newExpectedBillByProvider ?? currentExpectedBillByProvider ?? {}} />
    </BillContainer>
  );
};
