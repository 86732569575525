import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { System } from '@generated/types/graphql';
import { useUpdateSystemsFleet } from '@hooks/systems';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { Form, FormValidationRules, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import React from 'react';
import { Bill } from './Bill';

interface Props {
  systems: System[];
  onClose: () => void;
}

const ACTION_OPTIONS = [{ name: 'Update Fleet', id: 'updateFleet' }];

interface FormValues {
  action: { name: string; id: string };
  fleet: { id: number; name: string };
}

const RULES: FormValidationRules<FormValues> = {
  action: {
    isRequired: true
  },
  fleet: {
    isRequired: true
  }
};

export const BulkEdit = ({ systems, onClose }: Props) => {
  const { data: fleets = [] } = useFleetList();
  const { mutateAsync: updateFleets } = useUpdateSystemsFleet();

  const {
    form: {
      watch,
      control,
      formState: { isSubmitting }
    },
    handleSubmit
  } = useForm<FormValues>({
    defaultValues: {
      action: ACTION_OPTIONS[0],
      fleet: null
    },
    onSubmit: async ({ action, fleet }) => {
      const ids = systems.map((system) => system.id);

      if (action.id === 'updateFleet') {
        await updateFleets({ systemIds: ids, fleetId: fleet.id });
      }

      onClose();
    }
  });

  const action = watch('action');
  const fleet = watch('fleet');

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody width="500px">
        <SelectField
          label="Bulk edit type"
          name="action"
          options={ACTION_OPTIONS}
          control={control}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
          isClearable={false}
        />

        <SelectField
          label="Fleet"
          name="fleet"
          options={fleets}
          control={control}
          getOptionLabel={(option) => option.name}
          getOptionValue={(option) => option.id}
        />

        {action?.id === 'updateFleet' && <Bill systems={systems} newFleet={fleet} />}
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Apply
        </Button>
      </ModalFooter>
    </Form>
  );
};
