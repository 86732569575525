//Action types
import * as actionTypes from '../actionTypes/actionTypes';
import { IActionType } from '../actionTypes/actionTypeInterface';

export const updateAccountSettingAction = (payload): IActionType => {
  return {
    type: actionTypes.UPDATE_ACCOUNT_SETTINGS,
    payload
  };
};
