import { ReactQueryKey } from '@enums';
import { useAppSelector } from '@hooks/store';
import phoneAPI from '@services/api/phoneAPI';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useMutation, useQueryClient } from 'react-query';

type Params = {
  id: number;
  alias: string;
  users: {
    userId: number;
    canSend: boolean;
    canReceiveAll: boolean;
  }[];
};

export const useUpdateCompanyPhone = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<string, Error, Params>(
    async (params) => {
      try {
        await phoneAPI.updatePhone(companyId, params.id, { alias: params.alias, users: params.users });
      } catch (e) {
        throw apiErrorHandler('Error updating phone', e);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([ReactQueryKey.CompanyPhones, companyId]);
      }
    }
  );
};
