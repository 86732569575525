import { Team, User } from '@generated/types/graphql';
import { FormValidationRules, useForm, Form, InputField } from '@kit/components/Form';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { useTeamsMutations } from '@hooks/useTeams';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { UpdateTeamDTO } from '@types';
import { Trash2 } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { UserSelect } from './UserSelect';
import { Body, Email, Footer, MemberList, MemberRow, NameAndAvatar } from './styled';

type FormValues = {
  name: string;
};

interface Props {
  initialValues?: Team;
  onClose: () => void;
}

export const TeamForm = ({ initialValues, onClose }: Props) => {
  const isNew = !initialValues;

  const [users, setUsers] = useState<User[]>(initialValues?.teamUsers ?? []);

  const companyId = useAppSelector(selectWorkspaceId);
  const { create, update } = useTeamsMutations(companyId);

  const postForm = async (values: FormValues) => {
    const dto: UpdateTeamDTO = {
      name: values.name,
      workers: users.map(({ id }) => id)
    };

    if (isNew) {
      await create.mutateAsync({ dto });
    } else {
      await update.mutateAsync({ id: initialValues.id, dto });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    defaultValues: initialValues ? { name: initialValues.name } : {}
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      }
    }),
    []
  );

  const handleAddUser = useCallback((user: User) => {
    setUsers((prev) => [user, ...prev]);
  }, []);

  const handleRemoveUser = useCallback(
    (user: User) => () => {
      setUsers((prev) => prev.filter((prevUser) => prevUser.id !== user.id));
    },
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField label="Team name" name="name" control={control} />

        <UserSelect selected={users} onSelect={handleAddUser} />

        <MemberList>
          {users.map((user) => (
            <MemberRow key={user.id}>
              <NameAndAvatar>
                <UserAvatar user={user} size={20} />
                <div>
                  {user.firstName} {user.lastName}
                  <Email>{user.email || user.phone}</Email>
                </div>
              </NameAndAvatar>

              <Button
                onClick={handleRemoveUser(user)}
                isUpperCase={false}
                variant={ButtonVariant.Flat}
                size={ButtonSize.Small}
              >
                <Trash2 size="16px" />
                Remove
              </Button>
            </MemberRow>
          ))}
        </MemberList>
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
