import React, { useCallback, useMemo, useState } from 'react';

import { TabItem, Tabs } from '@kit/ui/Tabs';
import { SearchBar } from '@common/SearchBar';
import { useWorkflowList } from '@hooks/workflows/useWorkflowList';
import { useModal } from '@common/PromiseModal';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { WorkflowRow } from './Row';
import { Table, Title, Container, Header, HeaderLeft, TableContainer, PageHeader } from './styled';
import { WorkflowForm } from '../Form';

enum WorkflowSubject {
  Project = 'project',
  Deal = 'deal'
}

const TABS: TabItem[] = [
  {
    id: 'all',
    title: 'All'
  },
  {
    id: WorkflowSubject.Project,
    title: 'Projects'
  },
  {
    id: WorkflowSubject.Deal,
    title: 'Requests'
  }
];

export const List = () => {
  const [selectedTab, setSelectedTab] = useState<TabItem>(TABS[0]);
  const [search, setSearch] = useState('');
  const { openModal } = useModal();

  const { data: allWorkflows } = useWorkflowList();

  const filteredRows = useMemo(() => {
    if (!allWorkflows) {
      return [];
    }

    return allWorkflows
      .filter((workflow) => {
        if (selectedTab.id === 'all') {
          return true;
        }

        return selectedTab.id === workflow.type.toLowerCase();
      })
      .filter((workflow) => {
        if (!search) {
          return true;
        }

        return (
          workflow.name.toLowerCase().includes(search.toLowerCase()) ||
          (workflow.description ?? '').toLowerCase().includes(search.toLowerCase())
        );
      });
  }, [search, allWorkflows, selectedTab]);

  const handleNewClick = useCallback(() => {
    openModal<void>(({ onClose }) => <WorkflowForm onClose={onClose} />, { title: 'Create Workflow' });
  }, [openModal]);

  return (
    <Container>
      <PageHeader>
        <Title>Workflows</Title>
      </PageHeader>
      <Header>
        <HeaderLeft>
          <Button onClick={handleNewClick} variant={ButtonVariant.Primary}>
            <Plus size="16px" />
            Workflow
          </Button>

          <SearchBar placeholder="Search..." onValueChange={setSearch} />
        </HeaderLeft>
        <Tabs tabs={TABS} selected={selectedTab.id} onChange={setSelectedTab} />
      </Header>

      <TableContainer>
        <Table>
          <thead>
            <th>Name</th>
            <th>Description</th>
            <th>Created</th>
            <th>Created by</th>
            <th />
          </thead>
          <tbody>
            {filteredRows.map((workflow) => (
              <WorkflowRow key={workflow.id} workflow={workflow} />
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </Container>
  );
};
