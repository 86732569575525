import { colors } from '@styles';
import styled from 'styled-components';
import { Input } from '@kit/ui/Input';

export const FormNameView = styled.div<{ isPlaceholder?: boolean }>`
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  margin-top: 0;
  white-space: nowrap;
  overflow: hidden;
  flex: 1;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : colors.black)};

  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  background-color: transparent;
  transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

  &:hover {
    border-color: #dfdfe8;
    background-color: ${colors.white};
  }
`;

export const FormNameInput = styled(Input)`
&& {
.MuiOutlinedInput-input {
    font-size: 24px;
    line-height: 29px;

    font-weight: 500;
    padding: 9px 12px;
    padding-left: 13px;
    height: auto;
    letter-spacing normal;
}
}
`;
