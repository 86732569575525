import { colors } from '@styles';
import styled from 'styled-components';
import { Mode } from '@components/Scheduler/components/MapView/useMapMode';
import { Circle } from 'react-feather';

export const Container = styled.div`
  position: relative;
  z-index: 1;
  filter: drop-shadow(0px -2px 4px rgba(0, 150, 136, 0.12));
`;

export const Inner = styled.div`
  position: absolute;
  bottom: -40px;
  width: 100%;
  z-index: 1;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background: #f8fafb;
  border: 1px solid #d2d9e1;
  font-size: 14px;
  padding: 0 16px;
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  color: #98a9bc;
  svg {
    cursor: pointer;
    &:hover {
      color: ${colors.black};
    }
  }
`;

export const MapContainer = styled.div<{ mode: Mode }>`
  display: ${(props) => (props.mode === 'hidden' ? 'none' : 'block')};
  height: ${(props) =>
    props.mode === 'full' ? 'calc(100vh - 91px - 48px - 80px)' : 'calc((100vh - 91px - 48px - 40px - 80px)/2)'};
  background: white;
  bottom: 0;
  width: 100%;
  position: relative;
  border: 1px solid #d2d9e1;

  &:focus {
    outline: none;
  }

  .mapboxgl-canvas {
    &:focus {
      outline: none;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
`;

export const LiveCircle = styled(Circle)`
  animation: ani-breath 1.8s ease infinite;

  @keyframes ani-breath {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const LoaderContainer = styled.div`
  width: 24px;
`;
