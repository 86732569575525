import styled from 'styled-components';
import { Scheduler as DevextremeScheduler } from 'devextreme-react/scheduler';
import { colors, fonts, scroll } from '@styles';

export const SchedulerWrapper = styled(DevextremeScheduler)`
  && {
    .dx-widget {
      font-family: ${fonts.primary};
    }

    .dx-scheduler-header {
      height: 40px;
    }

    .dx-scheduler-all-day-panel {
      padding-right: 4px;
    }

    .today-button {
      color: #98A9BC;
      margin-right: 8px;
      position: absolute;
      left: 0;
    }

    .dx-toolbar-after {
      display: flex;
      align-items: center;
    }

    .dx-scheduler-view-switcher {
      position: absolute;
      right: 52px;
    }

    .dx-scheduler-navigator .dx-collection {
      margin-left: 74px;
    }

    .dx-scheduler-view-switcher .dx-buttongroup .dx-widget {
      background-color: #E8ECEF;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .list-view-button {
      padding: 3px 16px;
      background-color: #E8ECEF;
      color: #778CA2;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border: none;
    }

    .list-view-button .dx-button-content {
      padding: 0;
    }

    .dx-scheduler-navigator .dx-button-has-icon .dx-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      color: #98A9BC;
    }

    .dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
      min-width: 168px;
    }

    .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
      padding-left: 0;
      padding-right: 0;
    }

    .dx-scheduler-navigator .dx-button-has-icon .dx-button-content {
      padding: 3px;
    }

    .dx-scheduler-navigator .dx-button-has-text .dx-button-content {
      padding: 3px 16px;
    }

    .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .dx-scheduler-navigator .dx-button {
      border-color: #E8ECEF;
    }

    .dx-widget.dx-toolbar .dx-button {
      font-size: 12px;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained {
      border: none;
      padding: 3px 16px;
    }

    .dx-scheduler-view-switcher .dx-button-mode-contained.dx-buttongroup-first-item.dx-button {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    .dx-scheduler-view-switcher .dx-button-mode-contained.dx-buttongroup-last-item.dx-button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained .dx-button-content {
      padding: 0;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
      background-color: #E8ECEF;
      color: #778CA2;
    }

    .dx-scheduler-view-switcher .list-view-button {
      background-color: #E8ECEF !important;
      color: #778CA2 !important;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
      background-color: #1D1D35;
      color: #fff;
      border-radius: 8px;
    }

    .dx-scheduler-header {
      background-color: #fff;
      border: none;
    }
/*
    .dx-scheduler-date-table-scrollable {
      overflow-x: auto;
      overflow-y: auto;
      ${scroll};
    }
  
    .dx-scheduler-scrollable-fixed-content {  
      height: auto;  
    }*/

    .dx-scrollable-wrapper {
      height: ${(props) => (props.currentView === 'week' ? 'auto' : '100%')};
    }
    

    .dx-scheduler-header-panel,
    .dx-scheduler-header-panel-empty-cell {
      margin-top: 0;
    }

    .dx-scheduler-header-panel-cell {
      text-transform: uppercase;
      color: #252631;
    }

    .dx-scheduler-header-panel-empty-cell,
    .dx-scheduler-header-panel-cell {
      border-right-color: transparent;
      border-left-color: transparent;
    }

    .dx-scheduler-header-row,
    .dx-scheduler-header-panel-empty-cell {
      background-color: #E8ECEF;
    }

    .dx-scheduler-work-space-week .dx-scheduler-date-table-cell,
    .dx-scheduler-work-space-week .dx-scheduler-time-panel-cell {
      height: 24px;
    }

    .dx-scheduler-work-space-week .dx-scheduler-date-table-cell {
      border-top: none;
    }

    .dx-scheduler-time-panel-cell {
      padding-right: 0;
      text-align: center;
    }

    .dx-scheduler-all-day-title  {
      background: #fff;
    }
    
    .dx-scheduler-time-panel-cell,
    .dx-scheduler-all-day-title {
      color: #98A9BC;
      font-size: 12px;
      font-weight: normal;
    }

    .dx-scheduler-time-panel-cell > div {
      margin-top: -9px;
    }

    .dx-scheduler-time-panel-cell::after {
      display: none;
    }

    .dx-scheduler-work-space {
      border: 1px solid #C8C8D3;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 0;
    }

    .dx-scheduler-date-table-cell {
      border-color: #F2F4F6 !important;
    }

    .dx-scheduler-work-space-week .dx-scheduler-header-panel-empty-cell,
    .dx-scheduler-time-panel  {
      width: 50px !important;
    }

    .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
      color: ${colors.green};
    }

    .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell::before {
      display: none;
    }

    .dx-scheduler-work-space-day .dx-scheduler-date-time-indicator,
    .dx-scheduler-work-space-week .dx-scheduler-date-time-indicator,
    .dx-scheduler-work-space-work-week .dx-scheduler-date-time-indicator {
      margin-left: 50px;
    }

    .dx-scheduler-date-time-indicator {
      background-color: #ED4947;
    }

    .dx-scheduler-date-time-indicator::before,
    .dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell::before {
      display: none;
    }

    .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .dx-scheduler-date-table-cell-text {
      color: #000;
      font-size: 12px;
      padding-right: 4px;
      line-height: 18px;
      margin-top: 2px;
    }

    
    .dx-scheduler-work-space-month .dx-scheduler-date-table-cell {
      height: 230px;
    }

    .dx-scheduler-date-table-scrollable {
      overflow-y: auto;
        ${scroll};
    }
    .dx-scheduler-work-space-month .dx-scrollable-wrapper {
      height: auto;
    }
    .dx-scheduler-appointment-content {
      overflow: visible;
    }
/*
    .dx-scheduler-work-space-month .dx-scrollable-content {
      overflow-y: auto;
      ${scroll};
    }*/
    
    .dx-scheduler-work-space-month .dx-scheduler-date-table-current-date .dx-scheduler-date-table-cell-text {
      color: ${colors.green};
      font-weight: normal;
      width: 18px;
      height: 18px;
      margin-left: auto;
      padding-right: 0;
      border-radius: 50%;
      background-color: #CCEAE7;
      text-align: center;
      margin-right: 4px;
    }

    .dx-scheduler-work-space-month .dx-scheduler-appointment-content {
      font-size: 12px;
      padding: 0;
      height: 38px !important;
      min-height: 38px !important;
      overflow: visible;
    }

    .dx-scheduler-work-space-month  .dx-scheduler-appointment.dx-scheduler-appointment {
      box-shadow: none;
      background-color: transparent;
      color: black;
      min-height: 38px;
      height: 38px;
    }

    .dx-scheduler-appointment.dx-scheduler-appointment { 
      font-size: 12px;
      line-height: 14px;
      border: none;
    }

    .dx-scheduler-work-space-week .dx-scheduler-appointment.dx-scheduler-appointment {
      box-shadow: 0px 4px 8px rgba(26, 3, 64, 0.08);
      background: #FFFFFF;
      color: black;
      border-radius: 4px;
    }

    .dx-scheduler-work-space-week .dx-scheduler-appointment-content {
      padding: 0;
    }

    .dx-scheduler-appointment-collector {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .dx-scheduler-appointment-collector.dx-button.dx-state-hover::before {
      display: none;
    }

    .dx-scheduler-appointment-collector.dx-button.dx-button-default {
      pointer-events: none;
    }


`;

export const OverflowLabel = styled.span`
  color: ${colors.green};
  font-size: 10px;
  pointer-events: none;
`;
