import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Menu = styled.div`
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  padding: 8px;
  max-height: 300px;
  overflow: auto;
  width: 300px;
  ${scroll};
`;

export const SortByRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;

  > *:first-child {
    flex: 1;
    .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
      padding: 0;
    }
  }
`;

export const AddButton = styled.div`
  cursor: pointer;
  color: ${colors.green};
  font-size: 12px;
  font-weight: 500;
  margin-top: 16px;
`;

export const IconButton = styled.div<{ isHidden?: boolean }>`
  padding: 4px;
  cursor: pointer;
  color: #9c9caa;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${(props) => (props.isHidden ? 'hidden' : 'visible')};
  &:hover {
    color: ${colors.black};
  }
`;

export const OrderButton = styled.div`
  width: 32px;
  height: 32px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8ecef;
  color: ${colors.green};
`;

export const Trigger = styled.div`
  padding: 8px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #778ca2;
  background-color: ${colors.white};
  border: 1px solid #e8ecef;
  min-width: 152px;
  cursor: pointer;

  > *:last-child {
    margin-left: auto;
  }
`;

export const TriggerLabel = styled.div``;

export const TriggerValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.black};
`;
