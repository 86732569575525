import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Loader } from '@kit/ui/Loader';
import { Box, Collapse } from '@material-ui/core';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Checkbox } from '@kit/ui/Checkbox';
import { COLUMNS } from '@features/SystemPortfolio/constants';
import { GroupField, queryToExpr, SystemsGroup, useSystemsGroups } from '@hooks/systems';
import { merge, sortBy } from 'lodash';
import { SystemStatus } from '@generated/types/graphql';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { Badge } from '@common/Badge';
import { useAppSelector } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { ProductionWidget, StatusesWidget } from '@features/SystemPortfolio/List/widgets';
import { PowerWidget } from '@features/SystemPortfolio/List/widgets/PowerWidget';
import { useMapWidth, useShowMap } from '@features/SystemPortfolio/List/Table/useShowMap';
import { useMeasure } from 'react-use';
import { SystemsMap } from '../SystemsMap';
import { useClientFilterState } from '../../useClientFilterState';
import { useSelectionWithGroups } from '../useSelection';
import { TableHeader } from '../TableHeader';
import { TableBody } from '../TableBody';
import {
  Container,
  CoveringMapInner,
  CoveringMapOuter,
  GroupContainer,
  Header,
  HeaderContainer,
  LoaderContainer,
  Scrollable,
  StatsContainer,
  Title
} from './styled';

const statusOrder: { [status in SystemStatus]: number } = {
  [SystemStatus.Error]: 1,
  [SystemStatus.Warning]: 2,
  [SystemStatus.Normal]: 3
};

interface GroupProps {
  group: SystemsGroup;
}

const Group = ({ group }: GroupProps) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggle = useCallback(() => {
    setIsCollapsed((prev) => !prev);
  }, []);

  const { toggleGroup, isAllInGroupSelected } = useSelectionWithGroups();

  const {
    clientFilters: { groupBy }
  } = useClientFilterState();

  return (
    <GroupContainer>
      <HeaderContainer>
        <thead>
          <tr>
            <th key="header">
              <Header>
                <Title onClick={toggle}>
                  <Checkbox isChecked={isAllInGroupSelected(group.label)} onChange={() => toggleGroup(group.label)} />
                  {isCollapsed ? (
                    <ChevronUpIcon size="24px" color="#9C9CAA" />
                  ) : (
                    <ChevronDownIcon size="24px" color="#9C9CAA" />
                  )}
                  <Box display="flex" gridColumnGap={5} alignItems="center">
                    {(groupBy.id === GroupField.status &&
                      ((group.label && (
                        <Badge
                          color={SYSTEM_STATUS_COLORS[group.label as SystemStatus].color}
                          bgColor={SYSTEM_STATUS_COLORS[group.label as SystemStatus].bgColor}
                        >
                          {group.label.toUpperCase()}
                        </Badge>
                      )) ||
                        'None')) ||
                      group.label}
                    <span>({group.ids.length})</span>
                  </Box>
                </Title>
              </Header>
            </th>
            {Array(COLUMNS.length - 1).map((_, i) => (
              <th key={i} />
            ))}
          </tr>
        </thead>
      </HeaderContainer>
      <Collapse in={!isCollapsed} timeout="auto">
        <TableBody firstPageData={group.systems} ids={group.ids} groupLabel={group.label} />
      </Collapse>
    </GroupContainer>
  );
};

export const GroupedTable = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  const {
    clientFilters: { groupBy, sortBy: orderBy, perPage, query, monitored }
  } = useClientFilterState();

  const {
    data: { groups, allIds },
    isLoading,
    isError,
    error
  } = useSystemsGroups({
    groupBy: groupBy?.id || null,
    orderBy: orderBy.map(({ option, isDesc }) => [option.id, isDesc]),
    first: perPage.value,
    offset: 0,
    condition: {
      monitored: monitored != null ? monitored : undefined
    },
    filter: merge(
      {
        integration: { companyId: { equalTo: companyId } }
      },
      queryToExpr(query)
    )
  });

  const { setGroups } = useSelectionWithGroups();
  useEffect(() => setGroups(groups.map((group) => group.label)), [groups, setGroups]);

  const groupsSorted = useMemo(
    () =>
      !groupBy
        ? groups
        : sortBy(
            groups,
            (group) => (groupBy.id === GroupField.status && statusOrder[group.systems[0]?.status]) || 0,
            (group) => group.label
          ),
    [groups, groupBy]
  );

  const [showMap] = useShowMap();
  const [, setMapWidth] = useMapWidth();
  const [mapOuterRef, mapOuterRect] = useMeasure();
  useEffect(() => {
    setMapWidth(mapOuterRect.width);
  }, [mapOuterRect, setMapWidth]);

  if (isError) {
    return <LoaderContainer>{error.message}</LoaderContainer>;
  }

  if (isLoading) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <Container>
      <StatsContainer>
        <StatusesWidget allIds={allIds} />
        <PowerWidget allIds={allIds} />
        <ProductionWidget allIds={allIds} />
      </StatsContainer>

      <Scrollable>
        <TableHeader />

        {showMap && (
          <CoveringMapOuter ref={mapOuterRef}>
            <CoveringMapInner>
              <SystemsMap allIds={allIds} />
            </CoveringMapInner>
          </CoveringMapOuter>
        )}

        {groupBy ? (
          groupsSorted.map((group) => <Group key={group.label} group={group} />)
        ) : (
          <TableBody ids={groups[0]?.ids} groupLabel={null} firstPageData={groups[0]?.systems} />
        )}
      </Scrollable>
    </Container>
  );
};
