import React, { useEffect } from 'react';
import { FormLinkField } from '@common/index';
import { useFormikContext } from 'formik';
import { isEqual } from 'lodash';
import { usePrevious } from '@react-hookz/web';
import { CommonFieldProps } from '../types';

interface Props extends CommonFieldProps {
  onFormClick: (form: any) => void;
  onCreateNewForm?: (formIdToReplace?: number) => void;
}

export const FormsField = ({ onUpdated, onFormClick, onCreateNewForm }: Props) => {
  const { values } = useFormikContext();

  const value = values.forms;
  const prevValue = usePrevious(value);

  useEffect(() => {
    if (prevValue !== undefined && !isEqual(value, prevValue)) {
      onUpdated(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, value, prevValue]);

  return (
    <FormLinkField
      name="forms"
      companyId={values.companyId}
      onFormClick={onFormClick}
      onCreateNewForm={onCreateNewForm}
    />
  );
};
