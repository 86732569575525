import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { LineString } from 'geojson';

export type Route = {
  geometry: LineString;
  type: 'planned' | 'actual';
  selected: boolean | null;
};

type Props = {
  routes: Route[];
};

export const RoutesLayer = ({ routes }: Props) => {
  return (
    <Source
      type="geojson"
      data={{
        type: 'FeatureCollection',
        features: routes.map((route) => ({
          type: 'Feature',
          geometry: route.geometry,
          properties: { type: route.type, selected: route.selected }
        }))
      }}
    >
      <Layer
        type="line"
        layout={{
          'line-cap': 'round',
          'line-join': 'round',
          'line-sort-key': [
            'case',
            ['==', ['get', 'selected'], true],
            3,
            ['==', ['get', 'type'], 'actual' as Route['type']],
            2,
            1
          ]
        }}
        paint={{
          'line-color': ['case', ['==', ['get', 'selected'], true], '#009688', '#778CA2'],
          'line-opacity': ['case', ['==', ['get', 'selected'], false], 0.5, 1],
          'line-dasharray': ['case', ['==', ['get', 'type'], 'planned' as Route['type']], [2, 2], [1]],
          'line-width': 3
        }}
      />
    </Source>
  );
};
