import React, { useEffect, useState } from 'react';
import { BarChart, Battery, Wifi } from 'react-feather';
import moment from 'moment';
import { FormRenderer } from '@features/DocumentManagement/Forms/FormRenderer';
import { useForm } from '@kit/components/Form';
import { FormBuilderElement, mapDndStructureToFormTemplateLayout } from '../helpers';
import { Container, Content, HeaderIcons, Notch, PhoneContainer, PhoneHeader, Time } from './styled';

interface Props {
  formName: string;
  configsById: { [key: string]: FormBuilderElement };
  layout: string[];
}

const CurrentTime = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return <Time>{moment(time).format('hh:mm')}</Time>;
};

export const Preview = ({ formName, layout, configsById }: Props) => {
  const formLayouts = mapDndStructureToFormTemplateLayout(layout, configsById);

  const {
    form: { control }
  } = useForm({
    defaultValues: {},
    onSubmit: () => Promise.resolve()
  });

  return (
    <Container>
      <PhoneContainer>
        <PhoneHeader>
          <CurrentTime />
          <Notch />
          <HeaderIcons>
            <BarChart size="16px" />
            <Wifi size="16px" />
            <Battery size="16px" />
          </HeaderIcons>
        </PhoneHeader>

        <Content>
          <FormRenderer isMobilePreview control={control} form={{ name: formName, formLayouts }} />
        </Content>
      </PhoneContainer>
    </Container>
  );
};
