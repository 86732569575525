import styled, { createGlobalStyle, css } from 'styled-components';
import { colors, device } from '@styles';

export const HideAppointmentTooltipGlobalStyle = createGlobalStyle`
  .dx-draggable-dragging.dx-draggable-clone .trayItem {
    rotate: -5deg;
  }

  .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item {
    width: 284px !important;
    padding: 0px !important;
    border: none !important;
    background-color: #fff !important;
    color: #1D1D35 !important;
  }

  .dx-popup-wrapper.dx-scheduler-appointment-tooltip-wrapper .dx-popup-content .dx-list-item .dx-list-item-content {
    padding: 0 12px !important;
  }
`;
export const Wrapper = styled.div`
  ${device.sm`
    height: calc(100vh - 48px);
  `}

  display: flex;
  overflow: hidden;
`;

export const Content = styled.div`
  background-color: #fff;
  padding-left: 16px;
  padding-right: 16px;
  width: 100%;
  min-width: 0;
`;

export const Main = styled.article`
  height: calc(100vh - 48px - 91px);
`;

export const TRAY_WIDTH = '360px';
export const TRAY_COLLAPSED_WIDTH = '40px';

export const TrayContainer = styled.div`
  width: 360px;
  min-width: 360px;
  flex: 0 0 360px;
  overflow: auto;
  padding-left: 24px;
  padding-right: 16px;
  transition: margin-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  border-right: 1px solid #dfdfe8;
  ${(props) =>
    props.isCollapsed
      ? css`
          margin-left: calc(${TRAY_COLLAPSED_WIDTH} - ${TRAY_WIDTH});
          padding-right: ${TRAY_COLLAPSED_WIDTH};
        `
      : ''};

  @media (max-width: 767px) {
    display: none;
  }
`;

export const CalendarContainer = styled.div`
  flex: 1;
  width: 100%;
`;

export const TaskContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${colors.white};
  ${(props) =>
    props.isFullScreen
      ? css`
          top: 0;
          left: 0;
          z-index: 102;
        `
      : css`
          width: 960px;
          height: calc(100vh - 48px);
          box-shadow: -16px -16px 200px 0px #1d1d3552;

          @media (max-width: 767px) {
            width: 100%;
            height: 100%;
          }
        `}
`;
