import styled from 'styled-components';

export const Circle = styled.div`
  background-color: rgb(77, 124, 254);
  cursor: pointer;
  border: 2px solid #fff;
  height: 0.8rem;
  width: 0.8rem;
  border-radius: 100%;
`;
