import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fcf2db;
  border-radius: 8px;
  padding: 16px;
  margin-top: 24px;
`;

export const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 18px;
  font-weight: 500;

  svg {
    flex-shrink: 0;
  }
`;

export const Description = styled.div`
  margin: 16px 0;
  font-size: 14px;
  font-weight: 400;
`;

export const PieContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 16px;
  margin-top: 16px;
  border: 1px solid #dfdfe8;
`;

export const PieTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const PieLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const LegendLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 400;
  gap: 8px;
`;

export const LegendLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
`;
