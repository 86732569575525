import styled from 'styled-components';
import { EditableFieldContainer } from '../styled';

export const AssigneesContainer = styled(EditableFieldContainer)`
  display: flex;
  gap: 24px;
  align-items: center;
  flex-wrap: wrap;
`;

export const UserAvatarAndName = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Placeholder = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #9c9caa;
`;
