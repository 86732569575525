import React, { useEffect, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { PriorityIcon } from '@common/PriorityIcon';
import { PrioritySelectField } from '@common/index';
import { PRIORITY_OPTIONS } from '@components/Scheduler/constants';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { Container } from './styled';
import { CommonFieldProps } from '../types';

export const PriorityField = ({ onUpdated }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);
  const [isOpen, setIsOpen] = useState(true);
  const context = useFormikContext();

  useEffect(() => {
    setIsFocused(isEdit);
    if (isEdit) {
      setIsOpen(true);
    }
  }, [isEdit, setIsFocused]);

  const priorityOption = useMemo(
    () => PRIORITY_OPTIONS.find(({ id }) => context.values.priority === id) ?? PRIORITY_OPTIONS[1],
    [context.values]
  );

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <PrioritySelectField
          name="priority"
          open={isOpen}
          onOpen={() => {
            setIsOpen(true);
          }}
          onClose={() => {
            setIsOpen(false);
            setIsEdit(false);
          }}
        />
      </div>
    );
  }

  return (
    <Container onClick={() => setIsEdit(true)}>
      <PriorityIcon priority={context.values.priority} size="16px" />
      <div>{priorityOption.title}</div>
    </Container>
  );
};
