import { XIcon } from '@kit/ui/icons/X';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { InputField } from '@kit/components/Form';
import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from 'react-beautiful-dnd';
import { DragHandleIcon } from '@kit/ui/icons/DragHandle';
import { AddOptionButton, ErrorText, IconButton, OptionRow, OptionsContainer, ScrollableOptions } from './styled';

interface Props {
  control: any; // TODO
  name?: string;
  error?: string;
}

export const Options = ({ control, name = 'options', error }: Props) => {
  const { fields, append, remove, move } = useFieldArray<any>({
    control,
    name,
    rules: {
      validate: (value) => {
        const filledOptions = value.filter((option: any) => option.value.trim());

        if (filledOptions.length === 0) {
          return 'At least one option is required';
        }

        return undefined;
      }
    }
  });

  const handleAdd = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const handleRemove = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  const handleDragEnd = useCallback<OnDragEndResponder>(
    (result) => {
      if (!result.destination) {
        return;
      }

      move(result.source.index, result.destination.index);
    },
    [move]
  );

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <OptionsContainer>
        <ScrollableOptions>
          <Droppable droppableId="options">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {fields.map((field, index) => (
                  <Draggable key={field.id} draggableId={field.id} index={index}>
                    {(provided, snapshot) => (
                      <OptionRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={provided.draggableProps.style}
                        isDragging={snapshot.isDragging}
                      >
                        <div {...provided.dragHandleProps}>
                          <DragHandleIcon size="16px" color="#9C9CAA" />
                        </div>
                        <InputField key={field.id} name={`${name}.${index}.value`} control={control} />
                        <IconButton onClick={handleRemove(index)}>
                          <XIcon size="16px" color="#9C9CAA" />
                        </IconButton>
                      </OptionRow>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ScrollableOptions>

        {error && <ErrorText>{error}</ErrorText>}

        <AddOptionButton withAdditionalMargin={fields.length === 0} onClick={handleAdd}>
          + Add option
        </AddOptionButton>
      </OptionsContainer>
    </DragDropContext>
  );
};
