import { IntegrationProvider } from '@generated/types/graphql';
import React, { useMemo } from 'react';
import { Square, Info } from 'react-feather';
import { Cell, Customized, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { formatCurrencyWithoutCents } from '@utils/utils';
import { PROVIDER_CONFIGS_MAP } from '../../constants';
import { Container, Title, Description, PieLegend, LegendLine, LegendLabel, PieContainer, PieTitle } from './styled';
import { PlaceholderPieChart } from '../../PlaceholderPieChart';

interface Props {
  billPerProvider: { [provider in IntegrationProvider]: number };
}

export const ExpectedBill = ({ billPerProvider }: Props) => {
  const totalBill = useMemo(
    () => Object.values(billPerProvider).reduce((acc, amount) => acc + amount, 0),
    [billPerProvider]
  );

  const points = useMemo(() => {
    return Object.entries(billPerProvider).map(([provider, amount]) => ({
      provider: provider as IntegrationProvider,
      amount
    }));
  }, [billPerProvider]);

  return (
    <Container>
      <Title>
        <Info size="16px" color="#828D9A" />
        Budget Overview
      </Title>
      <Description>These are expected bills for 30 days period. See details bellow.</Description>

      <PieContainer>
        <PieTitle>Expected bills</PieTitle>
        <ResponsiveContainer height={100}>
          {totalBill > 0 && (
            <PieChart width={100} height={100} data={points}>
              <Pie
                dataKey="amount"
                nameKey="provider"
                data={points}
                cy="50%"
                outerRadius="100%"
                innerRadius="80%"
                fill="#009688"
                startAngle={90}
                endAngle={-270}
              >
                {points.map((point, index) => (
                  <Cell key={`cell-${index}`} fill={PROVIDER_CONFIGS_MAP[point.provider].color} />
                ))}
              </Pie>
              <Customized
                // eslint-disable-next-line
                  component={() => (
                  <text>
                    <tspan x="50%" y="55%" textAnchor="middle" fontSize={16} fontWeight={500}>
                      {formatCurrencyWithoutCents(totalBill)}
                    </tspan>
                  </text>
                )}
              />
            </PieChart>
          )}

          {totalBill === 0 && <PlaceholderPieChart size={100} />}
        </ResponsiveContainer>

        <PieLegend>
          {points.map(({ provider, amount }) => (
            <LegendLine key={provider}>
              <LegendLabel>
                <Square
                  size="16px"
                  color={PROVIDER_CONFIGS_MAP[provider].color}
                  fill={PROVIDER_CONFIGS_MAP[provider].color}
                />

                {PROVIDER_CONFIGS_MAP[provider].name}
              </LegendLabel>

              {formatCurrencyWithoutCents(amount)}
            </LegendLine>
          ))}
        </PieLegend>
      </PieContainer>
    </Container>
  );
};
