import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { useToast } from '@hooks/useToast';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';
import { RoleDTO } from '@types';
import rolesApi from '@services/api/rolesAPI';

export const useUpdateRole = () => {
  const queryClient = useQueryClient();
  const { showSuccess, showError } = useToast();
  const companyId = useAppSelector(selectWorkspaceId);

  return useMutation<RoleDTO, Error, { id: number; dto: Partial<RoleDTO> }>(
    async ({ id, dto }) => {
      try {
        return (await rolesApi.update(companyId, id, dto)).data;
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onMutate({ id, dto }) {
        queryClient.cancelQueries([ReactQueryKey.WorkspaceRoles, companyId]);
        const previousRoles = queryClient.getQueryData<RoleDTO[]>([ReactQueryKey.WorkspaceRoles, companyId]);

        if (previousRoles) {
          queryClient.setQueryData<RoleDTO[]>(
            [ReactQueryKey.WorkspaceRoles, companyId],
            previousRoles.map((role) => (role.id === id ? { ...role, ...dto } : role))
          );
        }

        return { previousRoles };
      },
      onError: (error, _variables, context) => {
        queryClient.setQueryData([ReactQueryKey.WorkspaceRoles, companyId], context?.previousRoles);

        showError(error.message);
      },
      onSuccess: () => {
        showSuccess('Role updated successfully');
      }
    }
  );
};
