import React from 'react';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { SearchBar } from '@common/SearchBar';
import { useUpdateEffect } from '@react-hookz/web';
import { useClientFilterState } from '../useClientFilterState';

export const Search = () => {
  const { clientFilters, updateFilters } = useClientFilterState();
  const [, debouncedSearch, setSearch] = useDebouncedState(clientFilters.query, 300);

  useUpdateEffect(() => {
    updateFilters({
      query: debouncedSearch
    });
  }, [debouncedSearch, updateFilters]);

  return <SearchBar defaultValue={clientFilters.query} placeholder="Search..." onValueChange={setSearch} />;
};
