import React, { useCallback, useMemo, useState } from 'react';
import { Popover } from '@kit/ui/Popover';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { useBlueprints } from '@hooks/useBlueprints';
import { BlueprintDTO, PaginationResult, Property, RecordType } from '@types';
import { PropertyId } from '@enums';
import { isDropdown, isStage } from "@utils/properties";
import { SearchBar } from '@common/SearchBar';
import { useClientFilterState } from '../../useClientFilterState';
import { GroupTitle, List, Menu, MenuItem, Trigger, TriggerLabel, TriggerValue } from './styled';

export const GroupBy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');

  const { scopeToAllColumns } = useCompanyProperties();

  const {
    clientFilters: { groupBy },
    updateFilters
  } = useClientFilterState();

  const handleChange = useCallback(
    (value: string | number | null) => () => {
      updateFilters({
        groupBy: value
      });
    },
    [updateFilters]
  );

  const {
    fetchAll: { data: blueprints = {} as PaginationResult<BlueprintDTO> }
  } = useBlueprints();

  const groups = useMemo(() => {
    const result = [] as { title: string; items: (Property | BlueprintDTO)[] }[];

    const projectBlurprints = (blueprints.results || []).filter((blueprint) => blueprint.type === RecordType.PROJECT);

    const projectProperties = scopeToAllColumns[RecordType.PROJECT] || [];

    if (projectBlurprints.length) {
      result.push({
        title: 'Workflows',
        // usage at client/src/services/api/helpers.ts `generateSearchParams`
        items: projectBlurprints.map((blueprint) => ({ ...blueprint, id: `blueprint_${blueprint.id}` }))
      });
    }

    if (projectProperties.length) {
      result.push({
        title: 'Properties',
        items: projectProperties
          .filter(
            (property) =>
              (isDropdown(property) || [PropertyId.City, PropertyId.State].includes(property.id)) &&
              property.scope?.includes(RecordType.PROJECT) &&
              !isStage(property)
          )
          .map((property) => ({ ...property }))
      });
    }

    return result;
  }, [scopeToAllColumns, blueprints]);

  const filteredGroups = useMemo(() => {
    if (!search) {
      return groups;
    }

    return groups
      .map((group) => ({
        ...group,
        items: group.items.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
      }))
      .filter((group) => group.items.length);
  }, [groups, search]);

  const selectedValue = useMemo(() => {
    if (!groupBy) {
      return null;
    }

    if (groupBy.toString().startsWith('blueprint_')) {
      return blueprints.results?.find((blueprint) => `blueprint_${blueprint.id}` === groupBy);
    }

    return scopeToAllColumns[RecordType.PROJECT]?.find((property) => property.id === groupBy);
  }, [groupBy, blueprints, scopeToAllColumns]);

  return (
    <Popover
      content={
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          <List>
            {!search && <MenuItem onClick={handleChange(null)}>None</MenuItem>}

            {filteredGroups.map((group) => (
              <div key={group.title}>
                <GroupTitle>{group.title}</GroupTitle>
                {group.items.map((item) => (
                  <MenuItem key={item.id} onClick={handleChange(item.id)}>
                    {item.name}
                  </MenuItem>
                ))}
              </div>
            ))}
          </List>
        </Menu>
      }
      open={isOpen}
      onClose={() => setIsOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Group by:</TriggerLabel>
        <TriggerValue>{selectedValue?.name || 'None'}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
