import { colors } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => (props.isWhiteBackground ? colors.white : '#e8ecef')};
  border-radius: 12px;
  padding: 2px 8px;
  gap: 4px;
  font-weight: 500;
  font-size: 12px;

  svg {
    flex-shrink: 0;
  }
`;
