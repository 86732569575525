import { colors } from '@styles';
import styled from 'styled-components';

export const Breadcrumbs = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  font-size: 14px;
  gap: 4px;

  a,
  a:hover,
  a:active,
  a:visited,
  a:focus {
    color: ${colors.green};
    text-decoration: none;
  }
`;

export const Container = styled.div`
  padding: 16px 0;
  max-width: 700px;
`;

export const Header = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 8px;

  > *:first-child {
    flex: 1;
    max-width: 500px;
  }

  > *:last-child {
    margin-top: 28px;
  }
`;

export const ArrowContainer = styled.div`
  text-align: center;
`;

export const Table = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-radius: 8px;
  width: 100%;
  table-layout: fixed;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
  }

  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: #fff;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #98a9bc;
    height: 48px;
    text-align: left;
    padding: 8px 16px;
    border-bottom: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }
  }
  tr {
    /*
    cursor: pointer;

    
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }*/
  }

  td {
    overflow: hidden;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    height: 64px;
    font-size: 14px;
    font-weight: 400;
    word-break: break-word;

    &:last-child {
      border-right: 1px solid #dfdfe8;
      > * {
        margin-left: auto;
        justify-content: flex-end;
        text-align: right;
      }
    }

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }
  }

  td.no-break {
    white-space: nowrap;
  }

  svg {
    flex-shrink: 0;
  }

  td label span {
    transition:
      background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out;
  }
`;

export const Th = styled.th<{ width: string }>`
  width: ${({ width }) => width};
`;

export const ThreeLevelScopeBody = styled.tbody``;

export const Td = styled.td<{
  willBeChecked?: boolean;
  willBeUnchecked?: boolean;
  isChecked?: boolean;
  isDisabled?: boolean;
}>`
  &&& {
    label {
      span {
        ${({ willBeChecked, willBeUnchecked, isChecked, isDisabled }) => {
          if (isDisabled) {
            return ``;
          }

          if (willBeChecked) {
            return `
          background-color: ${colors.green}80 !important;
          border-color: ${colors.green}33 !important;
        `;
          }

          if (willBeUnchecked) {
            return `
          background-color: ${colors.green}33 !important;
          border-color: ${colors.green}33 !important;
        `;
          }

          return isChecked
            ? `
        background-color: ${colors.green} !important;
        border-color: ${colors.green} !important;
      `
            : `
        background-color: #fff !important;
        border-color: ##c8c8d3 !important;
        `;
        }};
      }
    }
  }
`;

export const PermissionTitle = styled.div<{ isNested?: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: ${({ isNested }) => (isNested ? '24px' : '0')};
  font-weight: ${({ isNested }) => (isNested ? '400' : '500')};
`;
