import React from 'react';
import { Badge } from '@kit/ui/Badge';
import { PageDescription, PageHeader, PageTitle } from './styled';

export const Header = () => (
  <PageHeader>
    <PageTitle>
      Systems
      <Badge bgColor="#DBE5FF" color="#4D7CFE">
        Beta
      </Badge>
    </PageTitle>
    <PageDescription>
      Manage various aspects of system configurations to ensure optimal performance and streamlined operations
    </PageDescription>
  </PageHeader>
);
