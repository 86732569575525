import {
  WorkOrderTemplateListItem,
  useWorkOrderTemplateList
} from '@hooks/templates/workOrders/useWorkOrderTemplateList';
import React, { useCallback, useMemo } from 'react';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Copy, Edit2 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { QueryParamsEnum, useQueryParam, useQueryParamMutation } from '@hooks/useQueryParam';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { useDeleteWorkOrderTemplate } from '@hooks/templates/workOrders/useDeleteWorkOrderTemplate';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Author, Table, TitleWithIcon } from './styled';

const TableRow = ({ item }: { item: WorkOrderTemplateListItem }) => {
  const { setParams } = useQueryParamMutation();
  const [, setSelectedWorkOrderTemplateId] = useQueryParam(QueryParamsEnum.WorkOrderTemplateId);

  const confirmDelete = useConfirmDeleteModal();
  const { mutateAsync: removeTemplate } = useDeleteWorkOrderTemplate();

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        icon: <Edit2 size="16px" color="#9C9CAA" />,
        title: 'Edit',
        onClick: () => {
          setSelectedWorkOrderTemplateId(item.id);
        }
      },
      {
        icon: <Copy size="16px" color="#9C9CAA" />,
        title: 'Duplicate',
        onClick: () => {
          setParams({
            [QueryParamsEnum.WorkOrderTemplateId]: 'new',
            [QueryParamsEnum.FromId]: item.id
          });
        }
      },
      {
        icon: <TrashIcon size="16px" color="#D54855" />,
        title: 'Delete',
        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this template?')) {
            await removeTemplate({ id: item.id });
          }
        }
      }
    ];
  }, [setSelectedWorkOrderTemplateId, setParams, item, confirmDelete, removeTemplate]);

  const handleRowClick = useCallback(() => {
    setSelectedWorkOrderTemplateId(item.id);
  }, [setSelectedWorkOrderTemplateId, item]);

  return (
    <tr onClick={handleRowClick}>
      <td width="50%">
        <TitleWithIcon>
          <WorkOrderTypeIcon size="16px" isField={item.isField} />
          {item.title}
        </TitleWithIcon>
      </td>
      <td>
        <Author>
          <UserAvatar user={item.createdByUser} size={20} />
          <div>
            {item.createdByUser.firstName} {item.createdByUser.lastName}
          </div>
        </Author>
      </td>
      <td
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};

interface Props {
  search?: string;
}

export const WorkOrders = ({ search = '' }: Props) => {
  const { data = [] } = useWorkOrderTemplateList();

  const filteredData = useMemo(() => {
    return data.filter((item) => {
      return item.title.toLowerCase().includes(search.toLowerCase());
    });
  }, [data, search]);

  return (
    <Table>
      <thead>
        <th>Name</th>
        <th>Created by</th>
        <th />
      </thead>
      <tbody>
        {filteredData.map((item) => (
          <TableRow key={item.id} item={item} />
        ))}
      </tbody>
    </Table>
  );
};
