import React, { useState, useCallback, useEffect } from 'react';
import { times, debounce } from 'lodash/fp';
import { Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { SearchBar } from '@common/SearchBar';
import {
  createUngroupedTablePagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TablePaginationProvider,
  TableRow
} from '@common/Table';
import { useCatalog, useCatalogItems, isProduct, isMasterItem, useCatalogItemsMutation } from '@hooks';
import { capitalize } from 'lodash';
import { Dictionary, CatalogItem } from '@generated/types/graphql';
import { Badge } from '@common/Badge';
import { defaultTheme } from '@themes';
import { Actions } from '@components/templates/Workspace/common/Actions';
import { DeepPartial } from 'redux';
import { makeConfirmAction } from '@utils';
import { useEffectOnce } from 'react-use';
import { CellValue } from '@components/Project/Financial/styled';

import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { CatalogItemModal } from './CatalogModal';
import {
  Content,
  SidebarCategoryTitle,
  SidebarCategoryItem,
  Sidebar,
  SidebarCategoryItemCount,
  SidebarCategoryItemSpan,
  Filters,
  FiltersItem,
  Wrapper,
  PageHeader
} from './styled';

const useUngroupedTablePagination = createUngroupedTablePagination();

export const Catalog: React.FC = () => {
  const paging = useUngroupedTablePagination();
  useEffectOnce(() => {
    paging.setPerPage(50);
  });

  const [searchValue, setSearchValue] = useState('');
  const [seeOnlyEnabled, setOnlyEnabled] = useState<boolean>(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchValueChange = useCallback(debounce(300, setSearchValue), []);

  const [selectedCatalog, setSelectedCatalog] = useState<Dictionary>();
  const [selectedItem, setSelectedItem] = useState<DeepPartial<CatalogItem>>();
  const { data: catalogs } = useCatalog();
  const { data: catalogsItems, isLoading } = useCatalogItems({
    paging,
    catalogId: selectedCatalog?.id,
    searchFilter: searchValue,
    seeOnlyEnabled
  });
  const { remove } = useCatalogItemsMutation();

  useEffect(() => {
    if (!selectedCatalog) {
      setSelectedCatalog(catalogs?.[0]?.catalogs[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [catalogs]);

  const columnCount = isProduct(selectedCatalog) ? 6 : 4;

  return (
    <div>
      <PageHeader />
      <Grid container>
        <Grid item md={3}>
          <Sidebar>
            {catalogs.map((catalog) => (
              <>
                <SidebarCategoryTitle>
                  {capitalize(catalog.parentCatalog)}s
                  <SidebarCategoryItemCount>
                    {catalog.catalogs.reduce(
                      (acc, subcatalog) => acc + subcatalog.catalogItemsByCategoryIdConnection.totalCount,
                      0
                    )}
                  </SidebarCategoryItemCount>
                </SidebarCategoryTitle>
                {catalog.catalogs.map((subCatalog) => (
                  <SidebarCategoryItem
                    isActive={subCatalog.id === selectedCatalog?.id}
                    onClick={() => setSelectedCatalog(subCatalog)}
                  >
                    <SidebarCategoryItemSpan>{subCatalog.value}</SidebarCategoryItemSpan>
                    <SidebarCategoryItemCount isActive={subCatalog.id === selectedCatalog?.id}>
                      {subCatalog.catalogItemsByCategoryIdConnection.totalCount}
                    </SidebarCategoryItemCount>
                  </SidebarCategoryItem>
                ))}
              </>
            ))}
          </Sidebar>
        </Grid>
        <Grid item md={9}>
          <TablePaginationProvider value={paging}>
            <Wrapper>
              <Button
                variant={ButtonVariant.Primary}
                onClick={() => setSelectedItem({ category: selectedCatalog })}
                data-testid="btn_NewCatalogItem"
              >
                <Plus size="16px" />
                {selectedCatalog?.value}
              </Button>
              <SearchBar onValueChange={handleSearchValueChange} placeholder="Search" />
              {false && ( // hiding it until we get seed/master items
                <Filters>
                  <FiltersItem key="enabled" onClick={() => setOnlyEnabled(!seeOnlyEnabled)} active={seeOnlyEnabled}>
                    Enabled {selectedCatalog?.value}
                  </FiltersItem>
                  <FiltersItem key="all" onClick={() => setOnlyEnabled(!seeOnlyEnabled)} active={!seeOnlyEnabled}>
                    All {selectedCatalog?.value}
                  </FiltersItem>
                </Filters>
              )}
            </Wrapper>
            <Content headerHeight={90}>
              <TableContainer>
                <Table style={{ tableLayout: 'fixed' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Name</TableCell>
                      {isProduct(selectedCatalog) && <TableCell align="center">Manufacturer</TableCell>}
                      {isProduct(selectedCatalog) && <TableCell align="center">SKU</TableCell>}
                      <TableCell align="center">Cost</TableCell>
                      <TableCell align="center">Price</TableCell>
                      <TableCell align="center" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading &&
                      times(
                        (index) => (
                          <TableRow key={index}>
                            {times(
                              () => (
                                <TableCell>
                                  <Skeleton animation="wave" height={38} />
                                </TableCell>
                              ),
                              columnCount
                            )}
                          </TableRow>
                        ),
                        10
                      )}
                    {!isLoading &&
                      catalogsItems.results.map((item) => {
                        return (
                          <TableRow key={item.id}>
                            <TableCell style={{ color: defaultTheme.colors.black }}>
                              <CellValue>{item.name}</CellValue>
                              {item.isCustom && <Badge color={defaultTheme.colors.yellow}>Custom</Badge>}
                            </TableCell>
                            {isProduct(selectedCatalog) && (
                              <TableCell>
                                <CellValue>{item.manufacturer}</CellValue>
                              </TableCell>
                            )}
                            {isProduct(selectedCatalog) && (
                              <TableCell>
                                <CellValue>{item.sku}</CellValue>
                              </TableCell>
                            )}
                            <TableCell>
                              <CellValue>{item.cost}</CellValue>
                            </TableCell>
                            <TableCell>
                              <CellValue>{item.price}</CellValue>
                            </TableCell>
                            <TableCell>
                              <Actions
                                linkText={`Enable ${item.name}`}
                                unlinkText={`Disable ${item.name}`}
                                name={item.name}
                                onEdit={
                                  isMasterItem(item)
                                    ? undefined
                                    : (e) => {
                                        e.stopPropagation();
                                        setSelectedItem(item);
                                      }
                                }
                                onRemove={
                                  !isMasterItem(item) && !item.masterItem
                                    ? (e) => {
                                        e.stopPropagation();
                                        makeConfirmAction(remove.mutate, { id: item.id })();
                                      }
                                    : undefined
                                }
                                onLink={
                                  !isMasterItem(item)
                                    ? undefined
                                    : (e) => {
                                        e.stopPropagation();
                                        setSelectedItem(item);
                                      }
                                }
                                onUnlink={
                                  !isMasterItem(item) && item.masterItem
                                    ? (e) => {
                                        e.stopPropagation();
                                        makeConfirmAction(remove.mutate, { id: item.id })();
                                      }
                                    : undefined
                                }
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableCell colspan={columnCount}>
                      <TablePagination total={catalogsItems.total} />
                    </TableCell>
                  </TableFooter>
                </Table>
              </TableContainer>
            </Content>
          </TablePaginationProvider>
        </Grid>
      </Grid>
      <CatalogItemModal
        entity={
          selectedItem
            ? {
                ...selectedItem,
                category: selectedCatalog
              }
            : undefined
        }
        onClose={() => setSelectedItem(undefined)}
      />
    </div>
  );
};
