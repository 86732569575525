import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

export const Scrollable = styled.div`
  overflow: auto;

  height: calc(100vh - 48px - 57px - 104px);
  ${scroll};
`;
