import React, { useCallback, useRef, useState } from 'react';
import { Checkbox } from '@kit/ui/Checkbox';
import { Layers } from 'react-feather';
import { useClickOutside } from '@react-hookz/web';
import { LayersMenu, MapLayersButton, MapLayersButtonContainer, Menu } from './styled';

interface Props {
  showAllRoutes: boolean;
  showUndispatchedWorkOrders: boolean;
  onShowAllRoutesChange: (value: boolean) => void;
  onShowUndispatchedWorkOrdersChange: (value: boolean) => void;
}

export const LayersButton = ({
  showAllRoutes,
  showUndispatchedWorkOrders,
  onShowAllRoutesChange,
  onShowUndispatchedWorkOrdersChange
}: Props) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const menuNodeRef = useRef<HTMLDivElement>(null);
  const containerNodeRef = useRef<HTMLDivElement>(null);

  useClickOutside(menuNodeRef, (e) => {
    if (containerNodeRef.current?.contains(e.target as Node)) {
      return;
    }

    setIsPopoverOpen(false);
  });

  const togglePopover = useCallback(() => {
    setIsPopoverOpen((prev) => !prev);
  }, []);

  return (
    <MapLayersButtonContainer ref={containerNodeRef}>
      <MapLayersButton onClick={togglePopover}>
        <Layers size="16px" color="#9c9caa" />
        Map layers
      </MapLayersButton>
      {isPopoverOpen && (
        <Menu ref={menuNodeRef}>
          <LayersMenu>
            <Checkbox isChecked={showAllRoutes} onChange={onShowAllRoutesChange} label="Show all routes" />
            <Checkbox
              isChecked={showUndispatchedWorkOrders}
              onChange={onShowUndispatchedWorkOrdersChange}
              label="Show undispatched Work Orders"
            />
          </LayersMenu>
        </Menu>
      )}
    </MapLayersButtonContainer>
  );
};
