import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Plus } from 'react-feather';
import { Fleet, MetricConfigs, useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { Integration, SyncFrequency } from '@generated/types/graphql';
import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { ProfileCreateDTO, SystemMetric } from '@services/api/systemProfilesAPI';
import { useCreateFleet } from '@hooks/workspace/systems/fleets/useCreateFleet';
import { useUpdateFleet } from '@hooks/workspace/systems/fleets/useUpdateFleet';
import { useDeleteFleet } from '@hooks/workspace/systems/fleets/useDeleteFleet';
import { PROVIDER_CONFIGS_MAP, SIDEBAR_BUDGET_PORTAL_NODE_ID } from '../constants';
import { List } from './styled';
import { FleetForm } from './FleetForm';
import { Panel, PanelDescription, PanelHeader, PanelTitle } from '../styled';
import { useExpectedBill } from '../useExpectedBill';
import { calculateHitsCost } from '../helpers';
import { ChangedExpectedBill } from './ChangedExpectedBill';

const makeNewFleet = (integrations: Integration[]): Fleet => ({
  id: 0,
  name: '',
  default: false,
  systemProfileConfigs: integrations.map((integration) => ({
    integration: {
      id: integration.id,
      provider: integration.provider
    },
    frequency: SyncFrequency.Day,
    metrics: Object.keys(PROVIDER_CONFIGS_MAP[integration.provider].metricsConfig).reduce((acc, metricStr) => {
      const metric = metricStr as SystemMetric;

      acc[metric] = {
        enabled: PROVIDER_CONFIGS_MAP[integration.provider].metricsConfig[metric].defaultEnabled,
        by: 'auto'
      };

      return acc;
    }, {} as MetricConfigs)
  }))
});

interface Props {
  fleets: Fleet[];
  installedIntegrations: Integration[];
  createFleet: (payload: ProfileCreateDTO) => Promise<void>;
  updateFleet: (payload: { id: number; dto: ProfileCreateDTO }) => Promise<void>;
  deleteFleet: (id: number) => Promise<void>;
  isSimulation?: boolean;
}

export const FleetsUi = ({
  fleets,
  installedIntegrations,
  createFleet,
  updateFleet,
  deleteFleet,
  isSimulation
}: Props) => {
  const [newFleet, setNewFleet] = useState<Fleet>(null);

  const [expanded, setExpanded] = useState<number | null>(null);

  const handleChange = useCallback((fleet: Fleet, isExpanded: boolean) => {
    setExpanded(isExpanded ? fleet.id : null);
  }, []);

  const handleAddFleet = useCallback(() => {
    setNewFleet((prev) => prev || makeNewFleet(installedIntegrations));
    setExpanded(0);
  }, [installedIntegrations]);

  const handleCancelCreation = useCallback(() => {
    setNewFleet(null);
    setExpanded(null);
  }, []);

  const currentExpectedBillByProvider = useExpectedBill(fleets);
  const { changedExpectedBillByProvider, onFleetChange } = useChangedExpectedBill(fleets);

  const isBillChanged =
    calculateHitsCost(changedExpectedBillByProvider) !== calculateHitsCost(currentExpectedBillByProvider);

  const updatedBillNode = document.getElementById(SIDEBAR_BUDGET_PORTAL_NODE_ID);

  return (
    <Panel>
      <PanelHeader>
        <div>
          <PanelTitle>Fleets</PanelTitle>
          <PanelDescription>Fleets are monitoring profiles.</PanelDescription>
        </div>

        <Button variant={ButtonVariant.Primary} onClick={handleAddFleet}>
          <Plus size="16px" />
          Fleet
        </Button>
      </PanelHeader>

      <List>
        {fleets.map((fleet) => (
          <FleetForm
            key={fleet.id}
            onCancelCreation={handleCancelCreation}
            fleet={fleet}
            isExpanded={fleet.id === expanded}
            onToggleExpand={handleChange}
            onFleetChange={onFleetChange}
            createFleet={createFleet}
            updateFleet={updateFleet}
            deleteFleet={deleteFleet}
            isSimulation={isSimulation}
          />
        ))}

        {newFleet && (
          <FleetForm
            fleet={newFleet}
            onCancelCreation={handleCancelCreation}
            isExpanded={newFleet.id === expanded}
            onToggleExpand={handleChange}
            createFleet={createFleet}
            updateFleet={updateFleet}
            deleteFleet={deleteFleet}
            isSimulation={isSimulation}
          />
        )}
      </List>

      {updatedBillNode &&
        createPortal(
          isBillChanged ? <ChangedExpectedBill billPerProvider={changedExpectedBillByProvider} /> : null,
          updatedBillNode
        )}
    </Panel>
  );
};

const useChangedExpectedBill = (existingFleets: Fleet[]) => {
  const [fleets, setFleets] = useState<Fleet[]>(existingFleets);
  useEffect(() => {
    setFleets(existingFleets);
  }, [existingFleets]);

  const handleFleetChange = useCallback((updatedFleet: Fleet) => {
    setFleets((prev) => {
      return prev.map((fleet) => {
        if (fleet.id === updatedFleet.id) {
          return updatedFleet;
        }

        return fleet;
      });
    });
  }, []);

  const expectedBillByProvider = useExpectedBill(fleets);

  return {
    changedExpectedBillByProvider: expectedBillByProvider,
    onFleetChange: handleFleetChange
  };
};

export const Fleets = () => {
  const { mutateAsync: createFleet } = useCreateFleet();
  const { mutateAsync: updateFleet } = useUpdateFleet();
  const { mutateAsync: deleteFleet } = useDeleteFleet();
  const { data: fleets = [] } = useFleetList();
  const { data: installedIntegrations } = useIntegrations();

  return (
    <FleetsUi
      fleets={fleets}
      installedIntegrations={installedIntegrations}
      createFleet={createFleet}
      updateFleet={updateFleet}
      deleteFleet={deleteFleet}
    />
  );
};
