import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { IntegrationStat, SystemsConnection } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/index';
import { selectWorkspaceId } from '@state/selectors';

export const useSystemsStats = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.SystemsSettings, 'useSystemsStats', companyId], async () => {
    try {
      const { integrationStats, byFleet, byIntegration, byFleetByIntegration } = await postGraphql<{
        integrationStats: IntegrationStat[] | null;
        byFleet: SystemsConnection;
        byIntegration: SystemsConnection;
        byFleetByIntegration: SystemsConnection;
      }>(
        gql`
          query SYSTEMS_STATS_QUERY($companyId: Int!) {
            integrationStats(filter: { integration: { companyId: { equalTo: $companyId } } }) {
              total
              monitored
              averageMonthlyNew
            }

            byFleet: systemsConnection(filter: { integration: { companyId: { equalTo: $companyId } } }) {
              groupedAggregates(groupBy: PROFILE_ID) {
                keys
                distinctCount {
                  id
                }
              }
            }

            byIntegration: systemsConnection(filter: { integration: { companyId: { equalTo: $companyId } } }) {
              groupedAggregates(groupBy: INTEGRATION_ID) {
                keys
                distinctCount {
                  id
                }
              }
            }

            byFleetByIntegration: systemsConnection(filter: { integration: { companyId: { equalTo: $companyId } } }) {
              groupedAggregates(groupBy: [PROFILE_ID, INTEGRATION_ID]) {
                keys
                distinctCount {
                  id
                }
              }
            }
          }
        `,
        { companyId }
      );

      const { total, monitored, averageMonthlyNew } = (integrationStats || []).reduce(
        (acc, stat) => {
          acc.total += parseInt(stat.total, 10);
          acc.monitored += parseInt(stat.monitored, 10);
          acc.averageMonthlyNew += parseFloat(stat.averageMonthlyNew);

          return acc;
        },
        {
          total: 0,
          monitored: 0,
          averageMonthlyNew: 0
        }
      );

      const byFleetId = byFleet.groupedAggregates.reduce(
        (acc, { keys, distinctCount }) => {
          acc[keys[0]] = parseInt(distinctCount.id, 10);

          return acc;
        },
        {} as Record<string, number>
      );

      const byIntegrationId = byIntegration.groupedAggregates.reduce(
        (acc, { keys, distinctCount }) => {
          acc[keys[0]] = parseInt(distinctCount.id, 10);

          return acc;
        },
        {} as Record<string, number>
      );

      const byFleetIdByIntegrationId = byFleetByIntegration.groupedAggregates.reduce(
        (acc, { keys, distinctCount }) => {
          const [fleetId, integrationId] = keys;

          acc[fleetId] = acc[fleetId] || {};
          acc[fleetId][integrationId] = parseInt(distinctCount.id, 10);

          return acc;
        },
        {} as Record<string, Record<string, number>>
      );

      return {
        total,
        monitored,
        averageMonthlyNew: Math.floor(averageMonthlyNew),
        byFleetId,
        byIntegrationId,
        byFleetIdByIntegrationId
      };
    } catch (e) {
      throw apiErrorHandler('Error fetching systems stats', e);
    }
  });
};
