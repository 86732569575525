import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  overflow: hidden;
`;

export const PhoneContainer = styled.div`
  max-width: 340px;
  margin: 0 auto;
  border: 3px solid #dfdfe8;
  border-radius: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
`;

export const PhoneHeader = styled.div`
  position: relative;
  display: flex;
  padding: 12px 20px;
  align-items: center;
  justify-content: space-between;
  color: #000;
`;

export const Time = styled.div`
  font-size: 17px;
  font-weight: 500;
`;

export const HeaderIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;

export const Notch = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 20px;
  border-radius: 0 0 8px 8px;
  background: #000;
`;

export const Content = styled.div`
  ${scroll};
  overflow: auto;

  /* Mobile app uses a bit different styles */
  &&& {
    .MuiInputBase-root {
      background: #f7f7fc;
    }

    .radio-group,
    .checkbox-group {
      gap: 16px;
    }

    .radio-group > *,
    .checkbox-group > * {
      box-shadow: 0px 4px 12px 0px #140f3312;
      border-radius: 8px;
      border: 1px solid #dfdfe8;
      padding: 8px;
    }
  }
`;
