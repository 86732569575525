import React, { useState } from 'react';
import { LngLat, Marker, useMap } from 'react-map-gl';
import { useClickTooltip } from '@kit/ui/Floats';
import { offset } from '@floating-ui/react';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { ManualPointPopup } from '@components/Scheduler/Dispatcher/Map/ManualPointPopup';
import { MapPin } from 'react-feather';
import { useUserGeoSettings } from '@hooks/geolocation/useUserGeoSettings';

export const ManualPointLayer = () => {
  const { current: map } = useMap();

  const [originalCursor, setOriginalCursor] = useState<string | null>(null);

  // set pointer cursor when hovering map with Ctrl / restore oritinal cursor (e.g. grab) when releasing Ctrl
  map.on('mousemove', (e: MapLayerMouseEvent) => {
    const current = e.target.getCanvas().style.cursor;

    if (e.originalEvent.ctrlKey || e.originalEvent.metaKey) {
      if (current !== 'pointer') {
        setOriginalCursor(current);
        e.target.getCanvas().style.cursor = 'pointer';
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (current === 'pointer') {
        e.target.getCanvas().style.cursor = originalCursor;
        setOriginalCursor(null);
      }
    }
  });

  const [point, setPoint] = useState<LngLat | null>(null);
  const [showPopup, setShowPopup] = useState(false);

  const popup = useClickTooltip({
    open: showPopup,
    onOpenChange: (open) => {
      setShowPopup(open);

      if (!open) {
        setPoint(null);
      }
    },
    placement: 'top',
    middleware: [offset(24)]
  });

  map.on('click', (e: MapLayerMouseEvent) => {
    if (!e.originalEvent.ctrlKey && !e.originalEvent.metaKey) {
      return;
    }

    setPoint(e.lngLat);
    setShowPopup(true);
  });

  const { data: geoSettings } = useUserGeoSettings();

  // disallow users under geo (e.g. workers) to set their location manually - they must use normal methods
  if (!geoSettings || geoSettings.some((setting) => setting.enabled)) {
    return null;
  }

  return (
    <>
      {point && showPopup && (
        <Marker longitude={point.lng} latitude={point.lat}>
          <div ref={popup.refs.setReference} {...popup.getReferenceProps()}>
            <MapPin size={13} color="#235dff" />
          </div>

          <div ref={popup.refs.setFloating} style={popup.floatingStyles} {...popup.getFloatingProps()}>
            <ManualPointPopup point={point} handleClose={() => setShowPopup(false)} />
          </div>
        </Marker>
      )}
    </>
  );
};
