/* eslint-disable react/function-component-definition */
/* eslint-disable @typescript-eslint/no-shadow */
import React, { useMemo, useState } from 'react';
import { uniqBy } from 'lodash';

import OneSchemaImporter from '@oneschema/react';
import { useAllCompaniesUsers, useBlueprints, useCompanyProperties } from '@hooks';
import { isStage } from '@utils/properties';
import { PropertyType, RecordType } from '@types';
import { Briefcase } from 'react-feather';
import { DollarIcon } from '@kit/ui/icons/Dollar';
import { Loader } from '@kit/ui/Loader';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useStartImport } from '@hooks/import/useStartImport';
import { useOneSchemaToken } from '@hooks/import/useOneSchemaToken';
import { useProjectStages } from '@hooks/useProjectStages';
import { Container, Header, LoaderWrapper, SelectType } from './styled';
import { FormValues, ImportSettings } from './ImportSettings';
import { History } from './History';
import { config } from '../../../config';

const PROPERTY_TYPE_TO_ONESCHEMA_TYPE = {
  [PropertyType.Date]: 'DATETIME_ISO',
  [PropertyType.DateTime]: 'DATETIME_ISO',
  [PropertyType.Numeric]: 'NUMBER',
  [PropertyType.Text]: 'TEXT',
  [PropertyType.Link]: 'URL',
  [PropertyType.File]: 'URL'
};

const PROPERTY_NAME_TO_ONESCHEMA_TYPE = {
  primaryEmail: 'EMAIL',
  primaryPhone: 'US_PHONE_NUMBER_EXT'
};

/* const ADDRESS_PROPERTY = {
  id: -3,
  type: 'TEXT',
  name: 'Site Address',
  mappedName: 'address'
}; */

type UiStep = 'selectType' | 'oneSchema' | 'importSettings';

const MAX_CHAR_LIMITS: Record<string, number> = {
  title: 500,
  description: 5000
};

const COLUMN_NAME_RENAMES_MAP: Record<string, string> = {
  'Primary email': 'Contact Email',
  'Primary phone': 'Contact Phone'
};

const ALLOWED_READONLY_COLUMNS = ['primaryEmail', 'primaryPhone'];

export const Import = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLaunching, setIsLaunching] = useState(false);

  const { mutateAsync: startImport } = useStartImport();
  const { data: oneSchemaJwt, isLoading: isOneSchemaJwtLoading } = useOneSchemaToken();

  const [step, setStep] = useState<UiStep>('selectType');

  const [importType, setImportType] = useState<'projects' | 'requests'>(null);

  const { scopeToAllColumns } = useCompanyProperties();
  const { data: users } = useAllCompaniesUsers();
  const { data: projectStages, isLoading: isProjectStagesLoading } = useProjectStages(RecordType.PROJECT);
  const { data: requestStages, isLoading: isRequestStagesLoading } = useProjectStages(RecordType.DEAL);
  const {
    fetchAll: { data: rawBlueprints, isLoading: isWorkflowsLoading }
  } = useBlueprints();

  const workflows = useMemo(
    () =>
      rawBlueprints?.results?.filter(
        (blueprint) => blueprint.type === (importType === 'projects' ? RecordType.PROJECT : RecordType.DEAL)
      ),
    [rawBlueprints, importType]
  );

  const [dataToImport, setDataToImport] = useState(null);

  const startImporing = async (settings: FormValues) => {
    await startImport({
      matchFoundStrategy: settings.useDeduplication ? settings.actionIfMatched.id : undefined,
      matchNotFoundStrategy: settings.useDeduplication ? settings.actionIfNotMatched.id : undefined,
      groupByColumnId: settings.groupBy?.id,
      matchByColumnId: settings.useDeduplication ? settings.deduplicationBy?.propertyId : undefined,
      type: importType === 'projects' ? 'PROJECT' : 'DEAL',
      importData: dataToImport
    });

    setIsOpen(false);
    setImportType(null);
    setStep('selectType');
  };

  const onLaunched = (_data) => {
    setIsLaunching(false);
  };
  // const confirm = useConfirmModal();
  const onSuccess = async (data) => {
    setDataToImport(data);
    // await confirm({ confirmText: JSON.stringify(data, null, 2) });

    setIsOpen(false);
    setStep('importSettings');
  };

  const onCancel = () => {
    setIsOpen(false);
    setImportType(null);
    setStep('selectType');
  };

  const onError = (error) => {
    // TODO handle error
    console.error(error);
  };

  const openImporter = (type: 'projects' | 'requests') => {
    setImportType(type);
    setIsLaunching(true);
    setIsOpen(true);
    setStep('oneSchema');
  };

  const picklistNormolize = (list: { value: string; color: string }[]) =>
    uniqBy(
      list
        .filter((val) => val?.value !== '' && val?.value !== null && val?.value !== undefined)
        .map((val) => ({ ...val, value: val?.value?.trim() })),
      'value'
    ).filter(Boolean);

  const templateOverrides = useMemo(() => {
    if (
      isWorkflowsLoading ||
      scopeToAllColumns.PROJECT?.length === 0 ||
      users?.length === 0 ||
      isRequestStagesLoading ||
      isProjectStagesLoading
    ) {
      return { columns_to_add: [] };
    }

    const nameCount: { [key: string]: number } = {};

    const columnsToAdd = [
      {
        key: 'blueprint_name',
        label: 'Workflow Name',
        data_type: 'PICKLIST',
        is_required: true,
        must_exist: true,

        validation_options: {
          picklist_options: picklistNormolize([
            {
              value: 'None',
              color: 'ColorSelectorGray100'
            },
            ...workflows.map((workflow) => ({
              value: workflow.name,
              color: 'ColorSelectorPurple100'
            }))
          ])
        }
      },
      {
        key: 'stage_name',
        label: 'Workflow Stage',
        data_type: 'PICKLIST',
        is_required: true,
        must_exist: true,

        validation_options: {
          picklist_options: picklistNormolize([
            {
              value: 'None',
              color: 'ColorSelectorGray100'
            },
            ...(importType === 'projects' ? projectStages : requestStages).map((stage) => ({
              value: stage.name,
              color: 'ColorSelectorPurple100'
            }))
          ])
        }
      },
      {
        key: 'custom_person',
        label: 'Custom Person',
        description: `Custom Person column. Use an email so our system can search for the worker's information automatically.`,
        validation_options: {
          picklist_options: picklistNormolize(
            users.map((user) => ({
              value: user.email,
              color: 'ColorSelectorPurple100'
            }))
          )
        },
        data_type: 'PICKLIST',
        is_custom: true
      },
      {
        key: 'contactName',
        label: 'Contact Name',
        data_type: 'TEXT',
        is_required: false,
        min_char_limit: 1,
        max_char_limit: 500
      },
      ...[
        ...scopeToAllColumns[importType === 'projects' ? RecordType.PROJECT : RecordType.DEAL].filter(
          (property) =>
            !property.virtual &&
            (!property.readonly || (property.readonly && ALLOWED_READONLY_COLUMNS.includes(property.mappedName))) &&
            !isStage(property)
        )
      ].map((property) => {
        const count = nameCount[property.name] || 0;
        nameCount[property.name] = count + 1;

        return {
          key: (property.id as number) > 0 ? `${property.mappedName}_${property.id}` : property.mappedName,
          label: count > 0 ? `${property.name} ${count}` : COLUMN_NAME_RENAMES_MAP[property.name] ?? property.name,
          data_type:
            PROPERTY_NAME_TO_ONESCHEMA_TYPE[property.mappedName] ??
            PROPERTY_TYPE_TO_ONESCHEMA_TYPE[property.type] ??
            'PICKLIST',
          is_required: ['address', 'title'].includes(property.mappedName),
          must_exist: ['address', 'title'].includes(property.mappedName),
          min_char_limit: property.mappedName === 'title' ? 1 : undefined,
          max_char_limit: MAX_CHAR_LIMITS[property.mappedName] ?? undefined,

          ...(property.type === PropertyType.Dropdown
            ? {
                validation_options: {
                  picklist_options: picklistNormolize(
                    property.additional?.values?.length
                      ? property.additional?.values.map((value) => ({
                          value,
                          color: 'ColorSelectorPurple100'
                        }))
                      : [
                          {
                            value: 'none',
                            color: 'ColorSelectorPurple100'
                          }
                        ]
                  )
                }
              }
            : {}),
          ...(property.type === PropertyType.Person
            ? {
                validation_options: {
                  picklist_options: picklistNormolize(
                    users.map((user) => ({
                      value: user.email,
                      color: 'ColorSelectorPurple100'
                    }))
                  )
                }
              }
            : {}),
          ...(property.multiple || property.type === PropertyType.File
            ? {
                delimiter: ','
              }
            : {})
        };
      })
    ];

    return {
      columns_to_add: columnsToAdd
      // validation_hooks_to_add: [
      //   {
      //     name: 'All field validation',
      //     url: `http://158.101.144.170:8800/email/validation?companyId=${companyId}`,
      //     // column_keys: columnsToAdd.map((column) => column.key)
      //     column_keys: ['title']
      //   }
      // ]
    };
  }, [
    isWorkflowsLoading,
    scopeToAllColumns,
    workflows,
    users,
    importType,
    isRequestStagesLoading,
    isProjectStagesLoading,
    projectStages,
    requestStages
  ]);

  const usedSpreadsheetColumns = useMemo(() => {
    if (!dataToImport) {
      return [];
    }

    return (
      dataToImport.columns?.map((column) => {
        const templateKey = column.template_column_key;

        const columnProperty = scopeToAllColumns[importType === 'projects' ? RecordType.PROJECT : RecordType.DEAL].find(
          (property) => property.mappedName === templateKey || `${property.mappedName}_${property.id}` === templateKey
        );

        return {
          id: templateKey,
          name: columnProperty?.name ?? 'Unknown',
          propertyId: columnProperty?.id
        };
      }) ?? []
    );
  }, [dataToImport, importType, scopeToAllColumns]);

  const isLoading = templateOverrides?.columns_to_add?.length === 0;

  return (
    <Container>
      <Header>
        <h1>Import</h1>
        <div>Easily add or update Requests and Projects</div>
      </Header>
      {step === 'selectType' && (
        <>
          <SelectType>
            <Button variant={ButtonVariant.Primary} onClick={() => openImporter('requests')}>
              <DollarIcon size="16px" />
              Import Requests
            </Button>
            <div>or</div>
            <Button variant={ButtonVariant.Primary} onClick={() => openImporter('projects')}>
              <Briefcase size="16px" />
              Import Projects
            </Button>
          </SelectType>

          <History />
        </>
      )}

      {importType && (isLoading || isLaunching) && (
        <LoaderWrapper>
          <Loader size={64} />
        </LoaderWrapper>
      )}

      {!isLoading && !isOneSchemaJwtLoading && (
        <OneSchemaImporter
          key={importType}
          /* managing state from your application */
          isOpen={isOpen}
          inline
          customizationKey="coperniq"
          onRequestClose={() => setIsOpen(false)}
          /* required config values */
          clientId={config.oneschemaClientId}
          userJwt={oneSchemaJwt}
          templateKey="project_import"
          templateOverrides={templateOverrides}
          /* optional config values */
          importConfig={{ type: 'local' }}
          devMode={false}
          className="oneschema-importer"
          customizationOverrides={{
            uploadPaneHeaderText: importType === 'projects' ? 'Import projects' : 'Import requests',
            uploaderHeaderText: 'Upload file'
          }}
          style={{
            border: 0,
            width: '100%',
            height: 'calc(100vh - 220px)'
          }}
          /* handling results */
          onLaunched={onLaunched}
          onSuccess={onSuccess}
          onCancel={onCancel}
          onError={onError}
          languageCode="en"
        />
      )}

      {step === 'importSettings' && (
        <ImportSettings
          spreadsheetColumns={usedSpreadsheetColumns}
          importType={importType}
          onStart={startImporing}
          onCancel={onCancel}
        />
      )}
    </Container>
  );
};
