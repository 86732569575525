import { PermissionLevel, PermissionMatrixConfig, PermissionMatrixScope, PermissionMatrixSubject } from './types';

export const PERMISSIONS_MATRIX_CONFIG: PermissionMatrixConfig = [
  {
    subject: PermissionMatrixSubject.Project,
    label: 'Records',
    tooltip: 'Clients, Requests, Projects',
    isThreeLevel: true,
    scopes: [
      {
        scope: PermissionMatrixScope.Own,
        label: 'My Records',
        tooltip: 'Records that I created or am a member of.',
        levels: [
          PermissionLevel.None,
          PermissionLevel.View,
          PermissionLevel.Create,
          PermissionLevel.Edit,
          PermissionLevel.Delete
        ]
      },
      {
        scope: PermissionMatrixScope.Team,
        label: 'Team Records',
        tooltip: 'Records that any of my teammates created or are members of. Team records include "My Records".',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.All,
        label: 'All Records',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Work,
    label: 'Work Orders & Reminders',
    isThreeLevel: true,
    scopes: [
      {
        scope: PermissionMatrixScope.Own,
        label: 'My Work Orders & Reminders',
        tooltip: 'Work Orders & reminders that I created or am an assignee of.',
        levels: [
          PermissionLevel.None,
          PermissionLevel.View,
          PermissionLevel.Create,
          PermissionLevel.Edit,
          PermissionLevel.Delete
        ]
      },
      {
        scope: PermissionMatrixScope.Team,
        label: 'Team Work Oders & Reminders',
        tooltip:
          'Work Orders & Reminders that any of my teammates created or are assignees of. Team Work Orders & Reminders include "My Work Orders & Reminders".',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.All,
        label: 'All Work Orders & Reminders',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Form,
    label: 'Forms',
    isThreeLevel: true,
    scopes: [
      {
        scope: PermissionMatrixScope.Own,
        label: 'My Forms',
        tooltip: 'Forms that I created or am an assignee of.',
        levels: [
          PermissionLevel.None,
          PermissionLevel.View,
          PermissionLevel.Create,
          PermissionLevel.Edit,
          PermissionLevel.Delete
        ]
      },
      {
        scope: PermissionMatrixScope.Team,
        label: 'Team Forms',
        tooltip: 'Forms that any of my teammates created or are assignees of. Team Forms include "My Forms".',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.All,
        label: 'All Forms',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Doc,
    label: 'Docs',
    isThreeLevel: true,

    scopes: [
      {
        scope: PermissionMatrixScope.Own,
        label: 'My Files',
        tooltip: 'Files that I uploaded.',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Create, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.Team,
        label: 'Team Files',
        tooltip: 'Files that any of my teammates uploaded. Team Files include "My Files".',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.All,
        label: 'All Files',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Team,
    label: 'Teams',
    isThreeLevel: true,
    scopes: [
      {
        scope: PermissionMatrixScope.Own,
        label: 'Own Teams',
        levels: [
          PermissionLevel.None,
          PermissionLevel.View,
          PermissionLevel.Create,
          PermissionLevel.Edit,
          PermissionLevel.Delete
        ]
      },
      {
        scope: PermissionMatrixScope.All,
        label: 'All Teams',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Workspace,
    label: 'Workspace',
    scopes: [
      {
        scope: PermissionMatrixScope.Settings,
        label: 'Workspace settings',
        levels: [PermissionLevel.None, PermissionLevel.Edit]
      },
      {
        scope: PermissionMatrixScope.Analytics,
        label: 'Analytics',
        levels: [
          PermissionLevel.None,
          PermissionLevel.View,
          PermissionLevel.Edit,
          PermissionLevel.Create,
          PermissionLevel.Delete
        ]
      },
      {
        scope: PermissionMatrixScope.Invite,
        label: 'Invite members',
        levels: [PermissionLevel.None, PermissionLevel.Edit]
      },
      {
        scope: PermissionMatrixScope.Import,
        label: 'Import Projects/Requests',
        levels: [PermissionLevel.None, PermissionLevel.Edit]
      },
      {
        scope: PermissionMatrixScope.SmartView,
        label: 'Smart Views',
        levels: [PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Create, PermissionLevel.Delete]
      },
      {
        scope: PermissionMatrixScope.Hub,
        label: 'Hubs',
        levels: [PermissionLevel.View, PermissionLevel.Edit, PermissionLevel.Create, PermissionLevel.Delete]
      }
    ]
  },
  {
    subject: PermissionMatrixSubject.Contract,
    label: 'Financials',
    scopes: [
      {
        scope: PermissionMatrixScope.Contract,
        label: 'Financials',
        levels: [PermissionLevel.None, PermissionLevel.View, PermissionLevel.Edit]
      }
    ]
  }
];
