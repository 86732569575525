import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 24px;
  padding-right: 24px;
`;

export const Scrollable = styled.div`
  overflow: auto;

  height: calc(100vh - 48px - 48px - 24px);
  ${scroll};
`;

export const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 200px;
`;

export const TableContainer = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-top: 1px solid #dfdfe8;
  width: 100%;
  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 60px;
    font-weight: 400;
    font-size: 14px;
    border-left: 1px solid #dfdfe8;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    position: relative;

    a {
      &:link,
      &:visited,
      &:hover,
      &:active,
      &:focus {
        color: #235dff;
        text-decoration: none;
      }
    }
  }

  tbody tr {
    cursor: pointer;

    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #f8fafb;
    }
  }

  tfoot {
    position: relative;
  }

  tfoot td {
    height: 38px;
    position: sticky;
    left: 0px;
    z-index: 1;

    :first-child {
      border-bottom-left-radius: 4px;
      z-index: 2;
    }

    :last-child {
      border-bottom-right-radius: 4px;
    }
  }
`;

export const LoadMore = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: ${colors.green};
  cursor: pointer;
`;

export const LoadMoreLoaderContainer = styled.div`
  position: absolute;
  left: 50%;
  top: 5px;
`;
