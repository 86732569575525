import React, { useCallback, useMemo } from 'react';
import { Form, FormValidationRules, InputField, SelectField, TextareaField, useForm } from '@kit/components/Form';
import { ProjectStageType, RecordType } from '@types';
import { useConfirmDeleteModal } from '@common/PromiseModal';
import { StageListItem } from '@hooks/workspace/stages/useStageList';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { ProjectStageBadge } from '@components/ProjectStages';
import { useProjectStageMutations } from '@hooks/useProjectStages';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { Body, Fields, Footer } from './styled';

const renderTypeOption = (option: { id: ProjectStageType; name: string }) => {
  return <ProjectStageBadge stage={{ type: option.id, name: option.name }} />;
};

type FormValues = {
  name: string;
  description: string;
  type: { id: ProjectStageType; name: string };
  yellowSla: number;
  redSla: number;
};

interface Props {
  initialValues?: StageListItem;
  onClose: () => void;
  recordType: RecordType.DEAL | RecordType.PROJECT;
  isDescriptionShown?: boolean;
}

export const StageForm = ({ initialValues, onClose, recordType, isDescriptionShown }: Props) => {
  const isNew = !initialValues;

  const confirmDelete = useConfirmDeleteModal();

  const companyId = useAppSelector(selectWorkspaceId);

  const {
    create: { mutateAsync: createStage },
    update: { mutateAsync: updateStage },
    delete: { mutateAsync: deleteStage }
  } = useProjectStageMutations(companyId);

  const typeOptions = useMemo(() => {
    if (recordType === RecordType.PROJECT) {
      return [
        ProjectStageType.initiation,
        ProjectStageType.production,
        ProjectStageType.complete,
        ProjectStageType.cancelled
      ].map((type) => ({ id: type, name: type }));
    }

    return [ProjectStageType.new, ProjectStageType.selling, ProjectStageType.won, ProjectStageType.lost].map(
      (type) => ({ id: type, name: type })
    );
  }, [recordType]);

  const postForm = async (values: FormValues) => {
    if (isNew) {
      await createStage({
        ...values,
        type: values.type.id,
        scope: recordType
      });
    } else {
      await updateStage({
        stageId: initialValues.id,
        dto: {
          ...values,
          type: values.type.id
        }
      });
    }

    onClose();
  };

  const handleDelete = useCallback(async () => {
    if (await confirmDelete('Are you sure you want to delete this Stage?')) {
      await deleteStage(initialValues.id);
      onClose();
    }
  }, [confirmDelete, deleteStage, initialValues, onClose]);

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: initialValues
      ? {
          name: initialValues.name,
          description: initialValues.description,
          yellowSla: initialValues.yellowSla,
          redSla: initialValues.redSla,
          type: typeOptions.find((type) => type.id === initialValues.type) || typeOptions[0]
        }
      : undefined
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      type: {
        isRequired: true
      },
      yellowSla: {
        min: 1
      },
      redSla: {
        min: 1
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <Fields>
          <InputField label="Name" name="name" control={control} />
          <SelectField
            label="Type"
            name="type"
            control={control}
            options={typeOptions}
            getOptionLabel={(option) => option.name}
            renderOption={renderTypeOption}
          />

          <InputField label="Yellow SLA" name="yellowSla" type="number" control={control} />

          <InputField label="Red SLA" name="redSla" type="number" control={control} />
        </Fields>

        {isDescriptionShown && (
          <TextareaField label="Description (visible only in Portal)" name="description" control={control} />
        )}
      </Body>

      <Footer>
        {!isNew && (
          <Button disabled={isSubmitting} variant={ButtonVariant.Danger} onClick={handleDelete}>
            Delete
          </Button>
        )}
        <Button disabled={isSubmitting} variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
