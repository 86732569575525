import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { ChevronDown } from 'react-feather';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { selectMui, dropdown } from '@styles';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';

const styles = selectMui({});
const useStyles = makeStyles(styles);

const Wrapper = styled.div`
  && {
    .MuiInputBase-root {
      width: 100%;
    }

    .MuiSelect-outlined.MuiSelect-outlined {
      text-transform: none;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  && {
    ${dropdown.item};
    text-transform: none;
    min-width: auto;
    width: calc(100% - 20px);
    margin-left: 10px;
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 7px;
  }
`;

type Props = {
  name?: string;
  component?: JSX.Element;
};

const options = [
  {
    id: 'office',
    name: (
      <Option>
        <WorkOrderTypeIcon isField={false} /> Office Work Order
      </Option>
    )
  },
  {
    id: 'field',
    name: (
      <Option>
        <WorkOrderTypeIcon isField /> Field Work Order
      </Option>
    )
  }
];

const TypeSelect: React.FC<Props> = (props: Props) => {
  const { name, component = Select, onOpen, onClose, open } = props;

  const classes = useStyles();

  return (
    <Wrapper>
      <Field
        classes={classes}
        component={component}
        name={name}
        variant="outlined"
        MenuProps={{
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right'
          }
        }}
        IconComponent={ChevronDown}
        data-testid="typeSelect"
        open={open}
        onOpen={onOpen}
        onClose={onClose}
      >
        {options.map((option) => (
          <StyledMenuItem value={option.id} key={option.id} data-testid="typeSelectOption">
            {option.name}
          </StyledMenuItem>
        ))}
      </Field>
    </Wrapper>
  );
};

export default TypeSelect;
