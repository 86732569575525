import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import fleetChartsAPI, { ChartDTO, Meter, TotalPower } from '@services/api/fleetChartsAPI';

export const useSystemsPeakPower = (systemsIds: number[]) =>
  useQuery({
    queryKey: [ReactQueryKey.System, 'useSystemsPeakPower', systemsIds],
    queryFn: async () => {
      try {
        const chartsDto: ChartDTO[] = [
          {
            metric: 'power',
            kind: 'total',
            meter: Meter.Production,
            period: '1 year'
          }
        ];

        const {
          data: [chart]
        } = await fleetChartsAPI.getSystemsCharts({ systems: systemsIds, charts: chartsDto });

        return (chart as TotalPower).value || 0;
      } catch (e) {
        throw apiErrorHandler('Error fetching system peak power', e);
      }
    },
    keepPreviousData: true,
    initialData: 0
  });
