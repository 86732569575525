import { PriorityIcon } from '@common/PriorityIcon';

import React, { useCallback } from 'react';
import { PrivilegedTask } from '@generated/types/graphql';
import { formatOrderDates } from '@components/Scheduler/helpers/formatWorkOrderDates';
import { WorkOrderTypeIcon } from '@common/WorkOrderTypeIcon';
import { WorkOrderStatus } from '@components/WorkOrder/WorkOrderStatus';
import moment from 'moment';
import { UserAvatar } from '@kit/components/UserAvatar';
import { Container, Header, Title, IconWrapper, Dates, SecondLine, SecondLineIcons } from './styled';
import { formatOrdinal } from '@utils/format';

interface Props {
  order: PrivilegedTask;
  onClick: (order: PrivilegedTask) => void;
}

export const WorkOrder = ({ order, onClick }: Props) => {
  const handleClick = useCallback(() => {
    onClick(order);
  }, [order, onClick]);

  const { assignee } = order;

  return (
    <Container className="trayItem" onClick={handleClick} borderColor={order.firstLabel?.color}>
      <Header>
        {assignee && <UserAvatar user={assignee} />}
        <Title title={(order.uid ? `#${order.uid} · ` : '') + order.title}>
          {order.uid ? `#${order.uid} · ` : ''}{order.title}
        </Title>

        <WorkOrderStatus status={order.taskStatus} />
      </Header>
      <SecondLine>
        <div>{[order.project?.title, order.project?.city, order.project?.state].filter(Boolean).join(' · ')}</div>

        <SecondLineIcons>
          <IconWrapper>
            <WorkOrderTypeIcon size="16px" isField={order.isField} />
          </IconWrapper>

          <IconWrapper>
            <PriorityIcon priority={order.priority} />
          </IconWrapper>
        </SecondLineIcons>
      </SecondLine>

      {!order.isField && (order.startDate || order.endDate) && <Dates>{formatOrderDates(order)}</Dates>}
      {order.isField &&
        order.taskVisitsByTaskIdConnection.nodes.map((visit, index) => (
          <Dates key={index} isCrossedOut={visit.isCompleted}>
            <div>{formatOrderDates(visit)}</div>
            <div>{+moment.duration(moment(visit.endDate).diff(visit.startDate)).asHours().toFixed(2)}hr</div>
            <div>{formatOrdinal(index + 1)} visit</div>
          </Dates>
        ))}
    </Container>
  );
};
