import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useRecordsGroups } from '@hooks/useRecords';
import { DealStatus, SearchFrom } from '@components/Project/ProjectView/types';
import { RecordType } from '@types';
import { useClientFilterState } from '../useClientFilterState';

const getIsActiveFilter = (isArchivedShown: boolean): DealStatus => {
  return isArchivedShown ? DealStatus.all : DealStatus.active;
};

export const useGroupedRecordIds = () => {
  const { clientFilters } = useClientFilterState();

  const companyId = useAppSelector(selectWorkspaceId);

  const { data, isLoading } = useRecordsGroups(companyId, RecordType.DEAL, {
    groupBy: clientFilters.groupBy || 0,
    orderBy: clientFilters.sortBy,
    status: clientFilters.status ? [clientFilters.status] : [],
    type: getIsActiveFilter(clientFilters.isArchivedShown),
    search: clientFilters.query,
    filters: clientFilters.filters,
    // seems to be not used:
    page: 1,
    perPage: clientFilters.perPage.value,
    from: SearchFrom.grid,
    groupFilter: ''
  });

  return {
    groupedIds: data,
    isLoading
  };
};
