import { colors, scroll } from '@styles';
import styled from 'styled-components';

export const Menu = styled.div`
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  width: 200px;
`;

export const List = styled.div`
  margin-top: 8px;
  max-height: 300px;
  overflow: auto;
  ${scroll};
`;

export const GroupTitle = styled.div`
  color: #98a9bc;
  font-size: 12px;
  font-weight: 500;
  margin: 8px;
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const Trigger = styled.div`
  padding: 8px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  color: #778ca2;
  background-color: ${colors.white};
  border: 1px solid #e8ecef;
  min-width: 152px;
  cursor: pointer;

  > *:last-child {
    margin-left: auto;
  }
`;

export const TriggerLabel = styled.div``;

export const TriggerValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.black};
`;
