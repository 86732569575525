import { Popover } from '@kit/ui/Popover';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { Select } from '@kit/ui/Select';
import { XIcon } from '@kit/ui/icons/X';
import { sortAsc as SortAsc, sortDesc as SortDesc } from '@assets/svg';
import { Sort, useClientFilterState } from '../../useClientFilterState';
import { AddButton, IconButton, Menu, OrderButton, SortByRow, Trigger, TriggerLabel, TriggerValue } from './styled';
import { SORT_OPTIONS, SortOption } from '@features/SystemPortfolio/constants';

const MAX_SORT_BY = 3;

export const SortBy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    clientFilters: { sortBy },
    updateFilters
  } = useClientFilterState();

  const unselectedOptions = useMemo(() =>
      SORT_OPTIONS.filter((available) => !sortBy.find(({ option }) => option.id === available.id))
    , [sortBy]);

  const replaceSort = useCallback((atIndex: number, sort: Sort | null) => updateFilters({
    sortBy: sortBy
      .map((existing, index) => index === atIndex ? sort : existing)
      .filter(Boolean)
  }), [sortBy, updateFilters]);

  const addSort = useCallback((sort: Sort) => updateFilters({
    sortBy: [...sortBy, sort]
  }), [sortBy, updateFilters]);

  return (
    <Popover
      content={
        <Menu>
          {sortBy.map(({ option, isDesc }, index) => (
            <SortByRow key={option.id}>
              <Select<SortOption>
                value={option}
                options={unselectedOptions}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(op, val) => op.id === val.id}
                isClearable={false}
                onChange={(_, value) => replaceSort(index, { option: value, isDesc: false })}
              />
              <OrderButton onClick={() => replaceSort(index, { option, isDesc: !isDesc })}>
                <img src={isDesc ? SortDesc : SortAsc} alt="" />
              </OrderButton>
              {sortBy.length > 1 && (
                <IconButton isHidden={index === 0} onClick={() => replaceSort(index, null)}>
                  <XIcon size="16px" />
                </IconButton>
              )}
            </SortByRow>
          ))}

          {sortBy.length < MAX_SORT_BY && unselectedOptions.length > 0 && (
            <AddButton onClick={() => addSort({ option: unselectedOptions[0], isDesc: false })}>
              + Add sort
            </AddButton>
          )}
        </Menu>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Sort by:</TriggerLabel>
        <TriggerValue>{sortBy?.[0].option.name}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
