import styled from 'styled-components';
import { Scheduler as DevextremeScheduler } from 'devextreme-react/scheduler';
import { colors, fonts, scroll } from '@styles';

export const Container = styled.div<{ width: string }>`
  transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  width: ${({ width }) => width};
`;

export const SchedulerWrapper = styled(DevextremeScheduler)`
  && {
    .dx-widget {
      font-family: ${fonts.primary};
    }

    .dx-scheduler-header {
      height: 40px;
    }

    .dx-toolbar-after .dx-toolbar-item:first-child {
      margin-right: 16px;
    }

    .today-button {
      color: #98a9bc;
      margin-right: 8px;
      position: absolute;
      left: 0;
    }

    .dx-toolbar-after {
      display: flex;
      align-items: center;
    }

    /*
    NO NEED IN DISPATCHER
    .dx-scheduler-view-switcher {
      position: absolute;
      right: 52px;
    }*/

    .dx-scheduler-navigator .dx-collection {
      margin-left: 74px;
    }

    .dx-scheduler-view-switcher .dx-buttongroup .dx-widget {
      background-color: #e8ecef;
      /*
      CHANGED IN DISPATCHER
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      */
      border-radius: 8px;
    }

    /* NOT USED IN DISPATCHER 
    .list-view-button {
      padding: 3px 16px;
      background-color: #E8ECEF;
      color: #778CA2;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border: none;
    }

    .list-view-button .dx-button-content {
      padding: 0;
    }*/

    .dx-scheduler-navigator .dx-button-has-icon .dx-icon {
      font-size: 16px;
      width: 16px;
      height: 16px;
      color: #98a9bc;
    }

    .dx-scheduler-header .dx-toolbar .dx-scheduler-navigator .dx-scheduler-navigator-caption {
      min-width: 168px;
    }

    .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
      padding-left: 0;
      padding-right: 0;
    }

    .dx-scheduler-navigator .dx-button-has-icon .dx-button-content {
      padding: 3px;
    }

    .dx-scheduler-navigator .dx-button-has-text .dx-button-content {
      padding: 3px 16px;
    }

    .dx-scheduler-header .dx-toolbar .dx-toolbar-item-content {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    .dx-scheduler-navigator .dx-button {
      border-color: #e8ecef;
    }

    .dx-widget.dx-toolbar .dx-button {
      font-size: 12px;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained {
      border: none;
      padding: 3px 16px;
      text-transform: capitalize;
    }

    .dx-scheduler-view-switcher .dx-button-mode-contained.dx-buttongroup-first-item.dx-button {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    /*
    NO NEED IN DISPATCHER
    .dx-scheduler-view-switcher .dx-button-mode-contained.dx-buttongroup-last-item.dx-button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }*/

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained .dx-button-content {
      padding: 0;
    }

    .dx-scheduler-view-switcher .dx-buttongroup-item.dx-button.dx-button-mode-contained:not(.dx-item-selected) {
      background-color: #e8ecef;
      color: #778ca2;
    }

    .dx-scheduler-view-switcher .list-view-button {
      background-color: #e8ecef !important;
      color: #778ca2 !important;
    }

    .dx-scheduler-view-switcher
      .dx-buttongroup-item.dx-button.dx-button-mode-contained.dx-button-normal.dx-item-selected {
      background-color: #1d1d35;
      color: #fff;
      border-radius: 8px;
    }

    .dx-scheduler-header {
      background-color: #fff;
      border: none;
    }

    .dx-scheduler-date-table-scrollable,
    .dx-scrollable-container {
      ${scroll};
    }

    /*.dx-scrollable-wrapper,
    .dx-scheduler-scrollable-fixed-content {
      height: auto;
    }*/

    .dx-scheduler-header-panel,
    .dx-scheduler-header-panel-empty-cell {
      margin-top: 0;
    }

    /*
    CHANGED IN DISPATCHER
    .dx-scheduler-header-panel-cell {
      text-transform: uppercase;
      color: #252631;
    }
    */

    /*
    CHANGED IN DISPATCHER
    .dx-scheduler-header-panel-empty-cell,
    .dx-scheduler-header-panel-cell {
      border-right-color: transparent;
      border-left-color: transparent;
    }
    */

    .dx-scheduler-header-row,
    .dx-scheduler-header-panel-empty-cell {
      /*
      CHANGED IN DISPATCHER
      background-color: #E8ECEF;
      */
    }

    /*
    CHANGED IN DISPATCHER
    .dx-scheduler-date-table-cell,
    .dx-scheduler-time-panel-cell {
      height: 24px;
    }*/

    .dx-scheduler-work-space-week .dx-scheduler-date-table-cell {
      border-top: none;
    }

    .dx-scheduler-time-panel-cell {
      padding-right: 0;
      text-align: center;
    }

    .dx-scheduler-all-day-title {
      background: #fff;
    }

    .dx-scheduler-time-panel-cell,
    .dx-scheduler-all-day-title {
      color: #98a9bc;
      font-size: 12px;
      font-weight: normal;
    }

    .dx-scheduler-time-panel-cell > div {
      margin-top: -9px;
    }

    .dx-scheduler-time-panel-cell::after {
      display: none;
    }

    .dx-scheduler-work-space {
      border: 1px solid #c8c8d3;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom: 0;
    }

    /*
    CHANGED IN DISPATCHER
    .dx-scheduler-date-table-cell {
      border-color: #F2F4F6 !important;
    }*/

    .dx-scheduler-work-space-week .dx-scheduler-header-panel-empty-cell,
    .dx-scheduler-time-panel {
      width: 50px !important;
    }

    .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
      color: ${colors.green};
    }

    .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell::before {
      display: none;
    }

    .dx-scheduler-work-space-day .dx-scheduler-date-time-indicator,
    .dx-scheduler-work-space-week .dx-scheduler-date-time-indicator,
    .dx-scheduler-work-space-work-week .dx-scheduler-date-time-indicator {
      margin-left: 50px;
    }

    .dx-scheduler-date-time-indicator {
      background-color: #ed4947;
    }

    .dx-scheduler-date-time-indicator::before,
    .dx-scheduler-time-panel-cell.dx-scheduler-time-panel-current-time-cell::before {
      display: none;
    }

    .dx-scheduler-work-space-month .dx-scheduler-date-table-cell .dx-scheduler-date-table-cell-text {
      color: #000;
      font-size: 12px;
      padding-right: 4px;
      line-height: 18px;
      margin-top: 2px;
    }

    .dx-scheduler-work-space-month .dx-scheduler-date-table-current-date .dx-scheduler-date-table-cell-text {
      color: ${colors.green};
      font-weight: normal;
      width: 18px;
      height: 18px;
      margin-left: auto;
      padding-right: 0;
      border-radius: 50%;
      background-color: #cceae7;
      text-align: center;
      margin-right: 4px;
    }

    .dx-scheduler-work-space-month .dx-scheduler-appointment-content {
      font-size: 12px;
      padding: 0;
    }

    .dx-scheduler-work-space-month .dx-scheduler-appointment.dx-scheduler-appointment {
      box-shadow: none;
      background-color: transparent;
      color: black;
    }

    .dx-scheduler-appointment.dx-scheduler-appointment {
      font-size: 12px;
      line-height: 14px;
      border: none;
    }

    .dx-scheduler-work-space-week .dx-scheduler-appointment.dx-scheduler-appointment,
    .dx-scheduler-timeline .dx-scheduler-appointment.dx-scheduler-appointment {
      box-shadow: 0px 4px 8px rgba(26, 3, 64, 0.08);
      background: #ffffff !important;
      color: black;
      border-radius: 4px;
    }

    .dx-scheduler-work-space-week .dx-scheduler-appointment-content,
    .dx-scheduler-timeline .dx-scheduler-appointment-content {
      padding: 0;
    }

    .dx-scheduler-appointment-collector {
      background-color: transparent !important;
      box-shadow: none !important;
    }

    .dx-scheduler-appointment-collector.dx-button.dx-state-hover::before {
      display: none;
    }

    /* DISPATCHER */
    .dx-scheduler-header-row:nth-child(2) {
      display: none;
    }

    .dx-scheduler-header-panel-cell {
      font-size: 8px;
      color: ${colors.black};
      height: 24px;
    }

    .dx-scheduler-timeline-week .dx-scheduler-date-table-cell {
      width: 80px;
    }

    .dx-scheduler-group-row .dx-scheduler-group-header {
      padding: 0 8px;
    }

    .dx-scheduler-timeline-week .dx-scheduler-date-table-cell,
    .dx-scheduler-timeline-week .dx-scheduler-group-header {
      min-height: 228px;
      max-height: 228px;
      height: 228px;
    }

    .dx-scheduler-timeline-week .dx-scheduler-date-table .dx-scheduler-date-table-row {
      max-height: 228px;
    }

    .dx-scheduler-timeline-day .dx-scheduler-date-table-cell,
    .dx-scheduler-timeline-day .dx-scheduler-group-header {
      min-height: 58px;
      height: 58px;
      max-height: 58px;
    }

    .dx-scheduler-timeline-day .dx-scheduler-date-table .dx-scheduler-date-table-row {
      max-height: 58px;
    }

    .dx-scheduler-timeline-day .dx-scheduler-date-table-cell {
      width: 80px;
    }

    .dx-scheduler-timeline-day .dx-scheduler-header-panel-cell > div {
      margin-left: -100%;
      background: #fff;
      padding: 3px 0;
    }
    .dx-scheduler-timeline-day .dx-scheduler-header-panel-cell {
      overflow: visible;

      &:first-child > div {
        display: none;
      }
    }

    .dx-scheduler-work-space-grouped.dx-scheduler-timeline .dx-scheduler-last-group-cell {
      border-left: 0.5px dashed #d2d9e1;
      border-right: 0.5px dashed #d2d9e1;
    }

    .dx-scheduler-date-table-cell {
      border-top-color: #e4e8ec;
    }

    .dx-scheduler-header-panel-empty-cell,
    .dx-scheduler-header-panel-cell {
      border-left: 0.5px dashed #d2d9e1;
      border-right: none;
    }

    .dx-scheduler-timeline.dx-scheduler-work-space-both-scrollbar .dx-scheduler-header-panel,
    .dx-scheduler-timeline.dx-scheduler-work-space-both-scrollbar .dx-scheduler-header-panel-empty-cell {
      border-bottom-color: #e4e8ec;
    }

    .dx-scheduler-timeline .dx-scheduler-header-panel-cell.dx-scheduler-header-panel-current-time-cell {
      border-bottom: none;
    }

    .dx-scheduler-group-flex-container .dx-scheduler-group-row:last-child .dx-scheduler-group-header {
      border-right: none;
      border-top-color: #e4e8ec;
      width: 209px;

      &:first-child {
        border-top-color: transparent;
      }
    }

    .dx-scheduler-timeline .dx-scheduler-appointment.dx-scheduler-appointment {
      max-height: 38px;
      /*min-width: 60px;*/
    }

    .dx-scheduler-timeline-day .dx-scheduler-appointment.dx-scheduler-appointment {
      margin-top: -13px;
    }

    .dx-scheduler-appointment-collector.dx-button.dx-button-default {
      pointer-events: none;
    }

    .dx-scheduler-timeline-day .dx-scheduler-appointment-collector.dx-button.dx-button-default {
      margin-top: -3px;
    }
  }
`;

export const OverflowLabel = styled.span`
  color: ${colors.green};
  font-size: 10px;
`;
