import { List, ListItem, ListItemSecondaryAction, Popover } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { Filter } from 'react-feather';
import { SearchBar } from '@common/SearchBar';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Checkbox } from '@kit/ui/Checkbox';
import { GroupContainer, GroupItemsList, GroupSelectedCount, Menu, Remove, SelectedCount, Summary } from './styled';

const Group = ({
  filter,
  onSelect
}: {
  filter: FilterGroup;
  onSelect: (fieldName: string, selected: number[]) => void;
}) => {
  const [search, setSearch] = useState('');

  const { title, options, selected, fieldName, isSearchbale } = filter;

  const isAllSelected = useMemo(
    () => options.length > 0 && options.every(({ id }) => selected.includes(id)),
    [selected, options]
  );

  const selectedCount = useMemo(() => options.filter(({ id }) => selected.includes(id)).length, [selected, options]);

  const handleToggle = (id: number) => () => {
    if (selected.includes(id)) {
      onSelect(
        fieldName,
        selected.filter((value) => value !== id)
      );
    } else {
      onSelect(fieldName, selected.concat([id]));
    }
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      onSelect(fieldName, []);
    } else {
      onSelect(
        fieldName,
        options.map(({ id }) => id)
      );
    }
  };

  const handleClear = useCallback(() => {
    onSelect(fieldName, []);
  }, [onSelect, fieldName]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    return options.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase().trim()));
  }, [options, search]);

  return (
    <GroupContainer>
      {isSearchbale && <SearchBar placeholder="Search..." value={search} onValueChange={setSearch} />}
      <List disablePadding>
        <ListItem role={undefined} dense disableGutters>
          <Checkbox
            isChecked={isAllSelected}
            onChange={handleToggleAll}
            label={`${title} (${
              filteredOptions.length === options.length ? options.length : `${filteredOptions.length}/${options.length}`
            })`}
          />

          {selectedCount > 0 && (
            <ListItemSecondaryAction>
              <GroupSelectedCount>
                {selectedCount}

                <Remove onClick={handleClear} />
              </GroupSelectedCount>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <GroupItemsList disablePadding>
        {filteredOptions.map((option) => {
          return (
            <ListItem style={{ paddingLeft: 16 }} key={option.id} role={undefined} dense disableGutters>
              <Checkbox
                isChecked={selected.includes(option.id)}
                onChange={handleToggle(option.id)}
                label={
                  <>
                    {option.icon}
                    {option.title}
                  </>
                }
              />
            </ListItem>
          );
        })}
      </GroupItemsList>
    </GroupContainer>
  );
};

interface FilterGroup {
  title: string;
  fieldName: string;
  selected: number[];
  isSearchbale?: boolean;
  options: { id: number; title: string }[];
}

interface Props {
  filterGroups: FilterGroup[];
  onSelect: (filter: { [key: string]: number[] }) => void;
}

export const MoreFilters = ({ filterGroups, onSelect }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [search, setSearch] = useState('');

  const filteredGroups = useMemo(() => {
    if (!search) {
      return filterGroups;
    }

    return filterGroups.map((group) => ({
      ...group,
      options: group.options.filter(({ title }) => title.toLowerCase().includes(search.toLowerCase().trim()))
    }));
  }, [filterGroups, search]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = useCallback(
    (fieldName: string, selected: number[]) => {
      onSelect({ [fieldName]: selected });
    },
    [onSelect]
  );

  const handleClear = useCallback(() => {
    onSelect(
      filterGroups.reduce(
        (acc, group) => {
          acc[group.fieldName] = [];

          return acc;
        },
        {} as { [key: string]: number[] }
      )
    );
  }, [onSelect, filterGroups]);

  const totalSelected = useMemo(
    () => filterGroups.reduce((acc, group) => acc + group.selected.length, 0),
    [filterGroups]
  );

  return (
    <>
      <Button aria-describedby="filter-more" variant={ButtonVariant.Flat} onClick={handleClick} isUpperCase={false}>
        <Filter size="16px" />
        More
        {totalSelected > 0 && <SelectedCount>({totalSelected})</SelectedCount>}
        ..
      </Button>
      <Popover
        id="filter-more"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          {filteredGroups.map((group) => (
            <Group key={group.title} filter={group} onSelect={handleSelect} />
          ))}

          <Summary>
            <div>Selected: {totalSelected}</div>
            <Button
              onClick={handleClear}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              disabled={totalSelected === 0}
            >
              Clear all
            </Button>
          </Summary>
        </Menu>
      </Popover>
    </>
  );
};
