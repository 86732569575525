import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, ButtonSize, ButtonVariant } from '@kit/ui/Button';
import { Input } from '@kit/ui/Input';
import { useAppSelector } from '@hooks/store';
import { selectCompanyById, selectWorkspaceId } from '@state/selectors';
import { useCopyToClipboard } from '@hooks/useCopyToClipboard';
import { useLocation } from '@reach/router';
import { useEffectOnceBy } from '@hooks/useEffectOnceBy';
import { usePortalNotifications } from '@hooks/workspace/notifications/usePortalNotifications';
import {
  Container,
  SideNav,
  SideNavItem,
  List,
  Title,
  Description,
  CopyContainer,
  PageHeader,
  PageTitle
} from './styled';
import { config } from '../../../config';
import { Reviews } from './Reviews';
import { Referral } from './Referral';
import { Notifications } from './Notifications';
import { makeTitleHref } from './helpers';

const SECTIONS = ['Share Portal', 'Notifications', 'Referral settings', 'Review settings'];

export const PortalSettings = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const [, copy] = useCopyToClipboard();
  const { isLoading: isNotificationLoading } = usePortalNotifications();

  const company = useAppSelector((state) => selectCompanyById(state, companyId));
  const portalUrl = `${config.portalUrl}/${company?.subdomainName ?? ''}`;
  const { hash } = useLocation();
  const [activeItem, setActiveItem] = useState<string | null>(hash ? hash.slice(1) : 'company-logo');
  const observer = useRef<IntersectionObserver>(null);

  const isJustClicked = useRef(false);
  const clickTimeout = useRef<NodeJS.Timeout>(null);

  useEffect(() => {
    observer.current = new IntersectionObserver(
      (entries) => {
        const visibleSection = entries.find((entry) => entry.isIntersecting)?.target;
        if (visibleSection && !isJustClicked.current) {
          setActiveItem(visibleSection.id);
        }
      },
      { threshold: 0.5 }
    );

    const sections = document.querySelectorAll('[data-section]');

    sections.forEach((section) => {
      observer.current.observe(section);
    });

    return () => {
      sections.forEach((section) => {
        observer.current.unobserve(section);
      });
    };
  }, []);

  useEffectOnceBy(
    () => {
      // scroll to the active section
      const activeSection = document.getElementById(activeItem);

      if (activeSection) {
        activeSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    () => Boolean(company) && !isNotificationLoading
  );

  const handleNavItemClick = useCallback((e) => {
    isJustClicked.current = true;

    if (clickTimeout.current) {
      clearTimeout(clickTimeout.current);
    }

    setActiveItem(e.target.href.split('#')[1]);

    clickTimeout.current = setTimeout(() => {
      isJustClicked.current = false;
    }, 500);
  }, []);

  if (!company) {
    return null;
  }

  return (
    <>
      <PageHeader>
        <PageTitle>Portal settings</PageTitle>
      </PageHeader>
      <Container>
        <List>
          <div data-section id={makeTitleHref('Share portal')}>
            <Title>Share Portal</Title>
            <Description>
              This is the URL of your Portal, add it to your website so Clients can easily find it.
            </Description>

            <CopyContainer>
              <Input disabled value={portalUrl} />
              <Button size={ButtonSize.Large} onClick={() => copy(portalUrl)} variant={ButtonVariant.Primary}>
                Copy url
              </Button>
            </CopyContainer>
          </div>

          <Notifications />

          <div data-section id={makeTitleHref('Referral settings')}>
            <Title>Referral settings</Title>
            <Referral />
          </div>

          <div data-section id={makeTitleHref('Review settings')}>
            <Title>Review settings</Title>
            <Reviews />
          </div>
        </List>
        <SideNav>
          {SECTIONS.map((section) => (
            <SideNavItem
              onClick={handleNavItemClick}
              isActive={makeTitleHref(section) === activeItem}
              key={section}
              href={`#${makeTitleHref(section)}`}
            >
              {section}
            </SideNavItem>
          ))}
        </SideNav>
      </Container>
    </>
  );
};
