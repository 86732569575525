import { List, ListItem, ListItemSecondaryAction, Popover } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { ChevronDown } from 'react-feather';
import { uniq } from 'lodash';
import { SearchBar } from '@common/SearchBar';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Checkbox } from '@kit/ui/Checkbox';
import {
  FilterButton,
  GroupContainer,
  GroupItemsList,
  GroupSelectedCount,
  Menu,
  Remove,
  SelectedCount,
  Summary
} from './styled';

const Group = ({
  title,
  options,
  icon: Icon,
  selected,
  onSelect,
  search
}: {
  search: string;
  title: string;
  options: { id: number | string; title: string }[];
  selected: number[];
  onSelect: (selected: number[]) => void;
  icon?: any;
}) => {
  const isAllSelected = useMemo(
    () => options.length > 0 && options.every(({ id }) => selected.includes(id)),
    [selected, options]
  );

  const selectedCount = useMemo(() => options.filter(({ id }) => selected.includes(id)).length, [selected, options]);

  const handleToggle = (id: number) => () => {
    if (selected.includes(id)) {
      onSelect(selected.filter((value) => value !== id));
    } else {
      onSelect(selected.concat([id]));
    }
  };

  const handleToggleAll = () => {
    if (isAllSelected) {
      onSelect(selected.filter((id) => !options.some((option) => option.id === id)));
    } else {
      onSelect(uniq(selected.concat(options.map(({ id }) => id))));
    }
  };

  const handleClear = useCallback(() => {
    onSelect(selected.filter((id) => !options.some((option) => option.id === id)));
  }, [onSelect, selected, options]);

  const filteredOptions = useMemo(() => {
    if (!search) {
      return options;
    }

    return options.filter((option) => option.title.toLowerCase().includes(search.toLowerCase().trim()));
  }, [options, search]);

  return (
    <GroupContainer>
      <List disablePadding>
        <ListItem role={undefined} dense disableGutters>
          <Checkbox
            isChecked={isAllSelected}
            onChange={handleToggleAll}
            label={
              <>
                {Icon && <Icon size="16px" color="#C8C8D3" />}
                {title} (
                {filteredOptions.length === options.length
                  ? options.length
                  : `${filteredOptions.length}/${options.length}`}
                )
              </>
            }
          />
          {selectedCount > 0 && (
            <ListItemSecondaryAction>
              <GroupSelectedCount>
                {selectedCount}

                <Remove onClick={handleClear} />
              </GroupSelectedCount>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </List>
      <GroupItemsList disablePadding>
        {filteredOptions.map((option) => {
          return (
            <ListItem style={{ paddingLeft: 16 }} key={option.id} role={undefined} dense disableGutters>
              <Checkbox
                isChecked={selected.includes(option.id)}
                onChange={handleToggle(option.id)}
                label={option.title}
              />
            </ListItem>
          );
        })}
      </GroupItemsList>
    </GroupContainer>
  );
};

interface Props {
  fieldName: string;
  label: string;
  groups: { title: string; options: { id: number | string; title: string }[] }[];
  selected: number[];
  onSelect: (filter: { [key: string]: number[] }) => void;
  showAllIfNoneSelected?: boolean;
}

export const FilterWithGroups = ({ fieldName, label, groups, selected, onSelect, showAllIfNoneSelected }: Props) => {
  const [search, setSearch] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleSelect = useCallback(
    (newSelected: number[]) => {
      onSelect({ [fieldName]: newSelected });
    },
    [fieldName, onSelect]
  );

  const handleClear = useCallback(() => {
    handleSelect([]);
  }, [handleSelect]);

  return (
    <>
      <FilterButton aria-describedby={`filter-${fieldName}`} variant="outlined" onClick={handleClick}>
        {label}
        {selected.length === 0 && showAllIfNoneSelected && <SelectedCount>All</SelectedCount>}
        {selected.length > 0 && <SelectedCount>({selected.length})</SelectedCount>}
        <ChevronDown size="20px" />
      </FilterButton>
      <Popover
        id={`filter-${fieldName}`}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Menu>
          <SearchBar value={search} placeholder="Search..." onValueChange={setSearch} />

          {groups.map((group) => (
            <Group
              search={search}
              icon={group.icon}
              key={group.title}
              title={group.title}
              options={group.options}
              selected={selected}
              onSelect={handleSelect}
            />
          ))}

          <Summary>
            <div>Selected: {selected.length}</div>
            <Button
              onClick={handleClear}
              isUpperCase={false}
              variant={ButtonVariant.Flat}
              disabled={selected.length === 0}
            >
              Clear all
            </Button>
          </Summary>
        </Menu>
      </Popover>
    </>
  );
};
