import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;

  display: flex;
  justify-content: flex-start;

  padding: 20px 10px;

  border-top: 1px solid #f2f4f6;
  border-radius: 0 0 4px 4px;

  background-color: #fff;

  button {
    margin: 0 10px;
  }
`;

interface IFooter {
  children?: JSX.Element | JSX.Element[];
}

const Footer: React.FC<IFooter> = (props: IFooter): JSX.Element => {
  const { children } = props;
  return <Wrapper>{children}</Wrapper>;
};

export default Footer;
