import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 8px;
  border: 1px solid #dfdfe8;
`;

export const Header = styled.div`
  padding: 16px;
  border-bottom: 1px solid #dfdfe8;
`;

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

export const Body = styled.div`
  padding: 16px;
  background-color: #fff;
  border-radius: 0 0 8px 8px;
`;
