import React, { useMemo } from 'react';
import { Flag } from 'react-feather';
import { useProjectStagesRest } from '@hooks/useProjectStages';
import { useRoutes } from '@hooks';
import { Control } from 'react-hook-form';
import { RadioGroupField } from '@kit/components/Form';
import { isPlainObject } from 'lodash';
import { SlaOption } from './styled';

interface Props {
  stageId: number;
  name: string;
  label: string;
  control: Control<any>;
}

export const SlaRadioField = ({ stageId, label, name, control }: Props) => {
  const { companyId } = useRoutes();

  const {
    fetchAll: { data: workspaceStages }
  } = useProjectStagesRest(companyId);

  const selectedStage = isPlainObject(stageId) ? stageId : workspaceStages?.find((stage) => stage.id === stageId);

  const redSla = selectedStage?.redSla;
  const yellowSla = selectedStage?.yellowSla;

  const options = useMemo(
    () =>
      [
        redSla !== null && {
          value: 'red',
          label: `${redSla}D`
        },
        yellowSla !== null && {
          value: 'yellow',
          label: `${yellowSla}D`
        }
      ].filter(Boolean),
    [redSla, yellowSla]
  );

  if (!workspaceStages || !selectedStage) {
    return null;
  }

  return (
    <RadioGroupField
      label={label}
      name={name}
      control={control}
      options={options}
      placeholder="SLA not specified for this stage"
      layout="row"
      renderOptionLabel={({ label, value }) => (
        <SlaOption type={value}>
          <Flag size="16px" />
          {label}
        </SlaOption>
      )}
    />
  );
};
