import React, { useMemo } from 'react';
import { useSystemsStats } from '@hooks/workspace/systems/useSystemsStats';
import { formatNumber } from '@utils/utils';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { Link } from '@reach/router';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Content, Left, Right, Label, Value, ContentContainer } from './styled';
import { Panel } from '../styled';
import { FLEET_COLORS } from '../constants';
import { Header } from './Header';
import { FleetsPie } from './FleetsPie';

export const SystemsOverview = () => {
  const { data } = useSystemsStats();
  const { data: fleets } = useFleetList();

  const points = useMemo(() => {
    if (!data || !fleets) {
      return [];
    }

    return fleets.map((fleet, index) => ({
      name: fleet.name,
      amount: data.byFleetId[fleet.id] || 0,
      color: FLEET_COLORS[index % FLEET_COLORS.length]
    }));
  }, [data, fleets]);

  if (!data || !fleets) {
    return null;
  }

  return (
    <Panel>
      <Header />

      <ContentContainer>
        <Content>
          <Left>
            <div>
              <Label>Monitored systems / Total </Label>
              <Value>
                {formatNumber(data.monitored)} / {formatNumber(data.total)}
              </Value>
            </div>
            <div>
              <Label>Average new Systems, per month</Label>
              <Value>{formatNumber(data.averageMonthlyNew)}</Value>
            </div>
            <div>
              <Link to="simulation">
                <Button variant={ButtonVariant.Primary}>Simulate monitored systems</Button>
              </Link>
            </div>
          </Left>
          <Right>
            <FleetsPie points={points} total={data.total} />
          </Right>
        </Content>
      </ContentContainer>
    </Panel>
  );
};
