import { InputField } from '@kit/components/Form';
import React from 'react';
import { FormProps } from './types';
import { Header, Title } from './styled';

export const Group = ({ control }: FormProps) => {
  return (
    <div>
      <Header>
        <Title>Group</Title>
      </Header>
      <InputField control={control} label="Title" name="name" />
    </div>
  );
};
