import React from 'react';
import { Container, Header, Title, Description, Body } from './styled';

interface Props {
  title: string;
  description: string;
  children: React.ReactNode;
}

export const Panel = ({ title, description, children }: Props) => {
  return (
    <Container>
      <Header>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </Header>
      <Body>{children}</Body>
    </Container>
  );
};
