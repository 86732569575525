import React, { useCallback, useMemo } from 'react';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { formatCurrencyWithoutCents } from '@utils/utils';
import { useSystemsBudget } from '@hooks/workspace/systems/useSystemsBudget';
import { Cell, Customized, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { Square } from 'react-feather';
import { IntegrationProvider, OverbudgetBehaviour } from '@generated/types/graphql';
import { useModal } from '@common/PromiseModal';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { PROVIDER_CONFIGS_MAP } from '../constants';
import { OVERBUDGET_BEHAVIOUR_TITLES_MAP } from './constants';
import { useExpectedBill } from '../useExpectedBill';
import {
  Card,
  CardTitle,
  Content,
  LegendLabel,
  LegendLine,
  OverbudgetDescription,
  PieContainer,
  PieLegend,
  Value
} from './styled';
import { Panel, PanelDescription, PanelHeader, PanelTitle } from '../styled';
import { EditBudget } from './EditBudget';
import { PlaceholderPieChart } from '../PlaceholderPieChart';

export const Budget = () => {
  const { data: budgetInfo } = useSystemsBudget();
  const { data: fleets } = useFleetList();

  const expectedBillByProvider = useExpectedBill(fleets);

  const { openModal } = useModal();

  const handleEdit = useCallback(() => {
    openModal(
      ({ onClose }) => (
        <EditBudget
          initialValues={{
            monthlyBudget: budgetInfo.budget.monthlyBudget,
            overbudgetBehaviour: budgetInfo.budget.overbudgetBehaviour,
            whitelistProfiles: budgetInfo.budget.whitelistProfiles
          }}
          onClose={onClose}
        />
      ),
      { title: 'Edit Budget' }
    );
  }, [openModal, budgetInfo]);

  const points = useMemo(() => {
    if (!budgetInfo || !expectedBillByProvider) {
      return [];
    }

    return Object.entries(expectedBillByProvider).map(([provider, amount]) => ({
      provider: provider as IntegrationProvider,
      amount
    }));
  }, [budgetInfo, expectedBillByProvider]);

  const expectedBillAmount = useMemo(() => {
    if (!expectedBillByProvider) {
      return 0;
    }

    return Object.values(expectedBillByProvider).reduce((acc, amount) => acc + amount, 0);
  }, [expectedBillByProvider]);

  if (!budgetInfo || !expectedBillByProvider) {
    return null;
  }

  return (
    <Panel>
      <PanelHeader>
        <div>
          <PanelTitle>Budget</PanelTitle>
          <PanelDescription>
            Configure your budget to efficiently manage and monitor systems using API calls to SolarAPIs.
          </PanelDescription>
        </div>

        <Button onClick={handleEdit} variant={ButtonVariant.Primary}>
          Edit budget
        </Button>
      </PanelHeader>

      <Content>
        <Card>
          <CardTitle>Your budget</CardTitle>
          <Value>
            <span>{formatCurrencyWithoutCents(budgetInfo.budget.monthlyBudget)}</span> per month
          </Value>
        </Card>
        <Card>
          <CardTitle>Expected bills next month</CardTitle>

          <PieContainer>
            <ResponsiveContainer>
              {expectedBillAmount > 0 && (
                <PieChart width={140} height={140} data={points}>
                  <Pie
                    dataKey="amount"
                    nameKey="provider"
                    data={points}
                    cy="50%"
                    outerRadius="100%"
                    innerRadius="80%"
                    fill="#009688"
                    startAngle={90}
                    endAngle={-270}
                  >
                    {points.map((point, index) => (
                      <Cell key={`cell-${index}`} fill={PROVIDER_CONFIGS_MAP[point.provider].color} />
                    ))}
                  </Pie>
                  <Customized
                    // eslint-disable-next-line
                  component={() => (
                      <text>
                        <tspan x="50%" y="55%" textAnchor="middle" fontSize={24} fontWeight={500}>
                          {formatCurrencyWithoutCents(expectedBillAmount)}
                        </tspan>
                      </text>
                    )}
                  />
                </PieChart>
              )}

              {expectedBillAmount === 0 && <PlaceholderPieChart />}
            </ResponsiveContainer>

            <PieLegend>
              {points.map(({ provider, amount }) => (
                <LegendLine key={provider}>
                  <LegendLabel>
                    <Square
                      size="16px"
                      color={PROVIDER_CONFIGS_MAP[provider].color}
                      fill={PROVIDER_CONFIGS_MAP[provider].color}
                    />

                    {PROVIDER_CONFIGS_MAP[provider].name}
                  </LegendLabel>

                  {formatCurrencyWithoutCents(amount)}
                </LegendLine>
              ))}
            </PieLegend>
          </PieContainer>
        </Card>
        <Card>
          <CardTitle>Overbudget behavior</CardTitle>

          <OverbudgetDescription>
            If budget usage reaches its limits ({formatCurrencyWithoutCents(budgetInfo.budget.monthlyBudget)}), then{' '}
            <span>
              {OVERBUDGET_BEHAVIOUR_TITLES_MAP[budgetInfo.budget.overbudgetBehaviour]}
              {budgetInfo.budget.overbudgetBehaviour === OverbudgetBehaviour.StopExceptWhitelist
                ? ` ${budgetInfo.budget.whitelistProfiles.map((fleet) => fleet.name).join(', ')}.`
                : '.'}
            </span>
          </OverbudgetDescription>
        </Card>
      </Content>
    </Panel>
  );
};
