import { Popover } from '@kit/ui/Popover';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronUpIcon } from '@kit/ui/icons/ChevronUp';
import { ChevronDownIcon } from '@kit/ui/icons/ChevronDown';
import { sortColumnAdapter } from '@adapters/ProjectAdapters/ProjectHeaderAdapter';
import { useCompanyProperties } from '@hooks/useCompanyProperties';
import { Property, RecordType } from '@types';
import { Select } from '@kit/ui/Select';
import { XIcon } from '@kit/ui/icons/X';
import { isDefault } from '@utils/properties';

import { sortAsc as SortAsc, sortDesc as SortDesc } from '@assets/svg';
import { isEqual } from 'lodash';
import { useClientFilterState } from '../../useClientFilterState';
import { Trigger, TriggerLabel, TriggerValue, Menu, SortByRow, AddButton, IconButton, OrderButton } from './styled';

type SortItem = {
  property: Property | null;
  isDesc: boolean;
};

const MAX_SORT_BY = 3;

export const SortBy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    clientFilters: { sortBy },
    updateFilters
  } = useClientFilterState();
  const [items, setItems] = useState<SortItem[] | null>(null);
  const { scopeToColumns } = useCompanyProperties();

  const properties = useMemo(() => sortColumnAdapter(scopeToColumns[RecordType.PROJECT] || []), [scopeToColumns]);

  useEffect(() => {
    if (scopeToColumns[RecordType.PROJECT].length) {
      const newItems = sortBy
        .map((sortByField) => ({
          property: properties.find((property) => property.id === sortByField.columnId),
          isDesc: sortByField.desc
        }))
        .filter((item) => Boolean(item.property));

      setItems((prev) => {
        if (!isEqual(newItems, prev)) {
          return newItems;
        }

        return prev;
      });
    }
  }, [scopeToColumns, properties, sortBy]);

  const handleFiltersChange = useCallback(
    (newItems: SortItem[]) => {
      const newSortBy = newItems
        .filter((item) => Boolean(item.property))
        .map((item) => ({
          columnId: item.property.id as number,
          desc: item.isDesc
        }));

      if (!isEqual(newSortBy, sortBy)) {
        updateFilters({
          sortBy: newSortBy
        });
      }
    },
    [updateFilters, sortBy]
  );

  const handleAdd = useCallback(() => {
    const newItems = [...items, { property: null, isDesc: true }];

    setItems(newItems);
    handleFiltersChange(newItems);
  }, [handleFiltersChange, items]);

  const handleChangeProperty = useCallback(
    (index: number) => (property: Property) => {
      const newItems = [...items];
      newItems[index] = { ...newItems[index], property };

      setItems(newItems);
      handleFiltersChange(newItems);
    },
    [handleFiltersChange, items]
  );

  const handleRemoveProperty = useCallback(
    (index: number) => () => {
      const newItems = [...items];
      newItems.splice(index, 1);

      setItems(newItems);
      handleFiltersChange(newItems);
    },
    [handleFiltersChange, items]
  );

  const togglePropertyOrder = useCallback(
    (index: number) => () => {
      const newItems = [...items];
      newItems[index] = { ...newItems[index], isDesc: !newItems[index].isDesc };

      setItems(newItems);
      handleFiltersChange(newItems);
    },
    [handleFiltersChange, items]
  );

  const getPropertiesGrouping = useCallback((property: Property) => {
    if (typeof property.id === 'number' && isDefault(property)) {
      return 'Standard';
    }

    return 'Custom';
  }, []);

  return (
    <Popover
      content={
        <Menu>
          {items?.map((item, index) => (
            <SortByRow key={item.property?.id || `new_${index}`}>
              <Select
                value={item.property}
                options={properties}
                getOptionLabel={(option) => option.name}
                getOptionSelected={(op, val) => op.id === val.id}
                groupBy={getPropertiesGrouping}
                isClearable={false}
                onChange={(_e, value) => handleChangeProperty(index)(value)}
              />
              <OrderButton onClick={togglePropertyOrder(index)}>
                <img src={item.isDesc ? SortDesc : SortAsc} alt="" />
              </OrderButton>
              {items.length > 1 && (
                <IconButton isHidden={index === 0} onClick={index === 0 ? undefined : handleRemoveProperty(index)}>
                  <XIcon size="16px" />
                </IconButton>
              )}
            </SortByRow>
          ))}

          {items?.length < MAX_SORT_BY && <AddButton onClick={handleAdd}>+ Add sort</AddButton>}
        </Menu>
      }
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
    >
      <Trigger onClick={() => setIsOpen(true)}>
        <TriggerLabel>Sort by:</TriggerLabel>
        <TriggerValue>{items?.[0].property.name}</TriggerValue>
        {isOpen ? <ChevronUpIcon size="16px" /> : <ChevronDownIcon size="16px" />}
      </Trigger>
    </Popover>
  );
};
