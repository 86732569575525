import { ContactsOrderBy } from '@generated/types/graphql';
import { QueryParamsEnum, useQueryParam } from '@hooks/useQueryParam';
import { useCallback } from 'react';
import { createGlobalState } from 'react-use';
import { PER_PAGE_OPTIONS, SortOption, SORT_OPTIONS } from '../constants';

export type OrderBy = {
  option: SortOption;
  isDesc: boolean;
};

export type ClientFilterState = {
  sortBy: OrderBy[];
  query: string;
  perPage: { label: string; value: number };
};

const clientFilterState = createGlobalState<ClientFilterState>({
  sortBy: [{ option: SORT_OPTIONS[0], isDesc: true }],
  query: '',
  perPage: PER_PAGE_OPTIONS[0]
});

export const mapClientSortToServerSort = (clientSort: OrderBy[]): ContactsOrderBy[] => {
  return clientSort.map((sort) => {
    const { id } = sort.option;

    switch (id) {
      case 'name':
        return sort.isDesc ? ContactsOrderBy.NameDesc : ContactsOrderBy.NameAsc;
      case 'createdAt':
        return sort.isDesc ? ContactsOrderBy.CreatedAtDesc : ContactsOrderBy.CreatedAtAsc;
      default:
        return ContactsOrderBy.CreatedAtDesc;
    }
  });
};

export const useClientFilterState = () => {
  const [clientFilters, setClientFilters] = clientFilterState();

  const [, setQueryParamSmartViewId] = useQueryParam(QueryParamsEnum.SmartViewId);

  const handleFiltersChange = useCallback(
    (newFilters: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...newFilters
      }));

      setQueryParamSmartViewId(undefined);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setClientFilters]
  );

  const resetFilters = useCallback(
    (values: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...values
      }));
    },
    [setClientFilters]
  );

  return {
    clientFilters,
    updateFilters: handleFiltersChange,
    resetFilters
  };
};
