import { ProjectStageBadge } from '@components/ProjectStages';
import { useProjectStagesRest } from '@hooks/useProjectStages';
import { SelectField } from '@kit/components/Form';
import { AutomationField, RecordType, WorkspaceStageFromAPI } from '@types';
import React, { useMemo } from 'react';
import { Control } from 'react-hook-form';
import { ANY_OPTION } from './constants';

interface Props {
  field: AutomationField;
  isDisabled?: boolean;
  control: Control<any>;
  recordType: RecordType;
}

export const RecordStagesField = ({ field, isDisabled, control, recordType }: Props) => {
  const {
    fetchAll: { data: recordStages, isFetched }
  } = useProjectStagesRest();

  const options = useMemo(() => {
    if (!recordStages) {
      return [];
    }

    return recordStages.filter((stage) => stage.scope === recordType);
  }, [recordStages, recordType]);

  if (!recordStages || !isFetched) {
    return null;
  }

  return (
    <SelectField
      name={field.key}
      disabled={isDisabled}
      label={field.label}
      control={control}
      options={!field.required ? [ANY_OPTION].concat(options) : options}
      getOptionLabel={(option: WorkspaceStageFromAPI) => option.name}
      getOptionValue={(option: WorkspaceStageFromAPI) => option.id}
      renderOption={(stage) => (stage.id === ANY_OPTION.id ? stage.name : <ProjectStageBadge stage={stage} />)}
    />
  );
};
