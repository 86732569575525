import React from 'react';
import { FakeFormFieldContainer, FieldLabel, Asterisk } from './styled';

interface Props {
  label?: React.ReactNode;
  children: React.ReactNode;
  isRequired?: boolean;
}

export const FakeFormField = ({ label, children, isRequired }: Props) => {
  return (
    <FakeFormFieldContainer>
      {label && (
        <FieldLabel>
          {label} {isRequired && <Asterisk>*</Asterisk>}
        </FieldLabel>
      )}
      <div>{children}</div>
    </FakeFormFieldContainer>
  );
};
