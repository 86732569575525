import styled, { css } from 'styled-components';

export const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 16px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfdfe8;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const Logo = styled.div`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  max-width: 220px;
  max-height: 42px;
  width: 100%;
  height: 100%;

  * {
    width: 100%;
    height: 100%;
  }
`;

export const Description = styled.div`
  max-width: 320px;
  color: #828d9a;
  font-size: 12px;
  font-weight: 400;
`;

export const HeaderRight = styled.div`
  margin-left: auto;
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const LimitTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;

  > *:first-child {
    font-size: 12px;
  }

  > *:last-child {
    font-size: 14px;
  }
`;

const progressStyles = css`
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
`;

export const LimitProgress = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-top: 8px;

  progress {
    ${progressStyles};
  }
  progress::-webkit-progress-bar {
    ${progressStyles};
  }
  progress::-webkit-progress-value {
    ${progressStyles};
    background: #71c4c4;
  }
  progress::-moz-progress-bar {
    ${progressStyles};
  }

  progress[value]::-webkit-progress-bar {
    background-color: #dfdfe8;
    background-image: #71c4c4;
    border-radius: 4px;
  }
`;
