import React from 'react';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { SearchBar } from '@common/SearchBar';
import { useUpdateEffect } from '@react-hookz/web';
import { useClientFilterState } from '../useClientFilterState';

export const Search = () => {
  const [, debouncedSearch, setSearch] = useDebouncedState('', 300);
  const { updateFilters } = useClientFilterState();

  useUpdateEffect(() => {
    updateFilters({
      query: debouncedSearch
    });
  }, [debouncedSearch, updateFilters]);

  return <SearchBar placeholder="Search..." onValueChange={setSearch} />;
};
