import { IntegrationProvider, IntegrationSystemLogGroupBy } from '@generated/types/graphql';
import { useHistoricalHits } from '@hooks/workspace/systems/useHistoricalHits';
import { DateTime, Interval } from 'luxon';
import { useMemo } from 'react';
import { Fleet } from '@hooks/workspace/systems/fleets/useFleetList';
import { SystemMetric } from '@services/api/systemProfilesAPI';
import { PROVIDER_CONFIGS_MAP } from '../constants';
import { DateRangeType } from './types';
import { calculateHitsCost } from '../helpers';

export type Point = {
  date: DateTime<true>;
  totalHits: number;
  totalCost: number;
  statsByFleetId?: Record<string, { cost: number; hits: number }>;
};

export const useApiUsage = ({
  period,
  dateRangeType,
  fleets,
  stats
}: {
  fleets: Fleet[];
  period: Interval<true>;
  dateRangeType: DateRangeType;
  stats: {
    total: number;
    averageMonthlyNew: number;
    byFleetId: Record<string, number>;
    byFleetIdByIntegrationId: { [fleetId: string]: { [integrationId: string]: number } };
  };
}) => {
  const { data } = useHistoricalHits({
    from: period.start.toJSDate(),
    to: period.end.toJSDate(),
    groupBy:
      dateRangeType === DateRangeType.Daily
        ? IntegrationSystemLogGroupBy.CreatedAtTruncatedToDay
        : IntegrationSystemLogGroupBy.CreatedAtTruncatedToMonth
  });

  const fleetsById = useMemo(() => {
    if (!fleets) {
      return null;
    }

    return fleets.reduce(
      (acc, fleet) => {
        acc[fleet.id] = fleet;

        return acc;
      },
      {} as Record<number, Fleet>
    );
  }, [fleets]);

  return useMemo(() => {
    if (!data || !stats || !fleetsById) {
      return [];
    }

    return makeUsage({ period, data, stats, fleetsById, dateRangeType });
  }, [data, period, stats, fleetsById, dateRangeType]);
};

const makeUsage = ({
  period,
  data,
  stats,
  fleetsById,
  dateRangeType
}: {
  period: Interval<true>;
  data: Record<string, { [key in IntegrationProvider]: number }>;
  stats: {
    total: number;
    averageMonthlyNew: number;
    byFleetId: Record<string, number>;
    byFleetIdByIntegrationId: { [fleetId: string]: { [integrationId: string]: number } };
  };
  fleetsById: Record<number, Fleet>;
  dateRangeType: DateRangeType;
}) => {
  return period.splitBy(dateRangeType === DateRangeType.Monthly ? { month: 1 } : { day: 1 }).reduce((acc, interval) => {
    const intervalStartDate = interval.start.toISODate();

    const now = DateTime.now();
    const current = now.startOf(dateRangeType === DateRangeType.Daily ? 'day' : 'month').toISODate();

    const intervalData = data[intervalStartDate];

    const totalHits = intervalData ? Object.values(intervalData).reduce((acc, hits) => acc + hits, 0) : 0;

    if (intervalStartDate === current) {
      if (dateRangeType === DateRangeType.Daily) {
        const predicted = getFutureStats(stats, 0, fleetsById, 1);

        acc.push({
          date: interval.start,
          totalHits: predicted.totalHits,
          totalCost: predicted.totalCost,
          statsByFleetId: predicted.statsByFleetId
        });

        return acc;
      }
      const { days } = now.startOf('day').diff(interval.start, 'days');

      acc.push({
        date: interval.start,
        totalHits,
        totalCost: intervalData ? calculateHitsCost(intervalData) : 0,
        statsByFleetId: getFutureStats(stats, 0, fleetsById, Math.max(1, days)).statsByFleetId
      });

      return acc;
    }

    if (interval.start > now) {
      const monthsFromNow = interval.start.diff(
        now.startOf(dateRangeType === DateRangeType.Daily ? 'day' : 'month'),
        'months'
      ).months;
      const futureStats = getFutureStats(stats, monthsFromNow, fleetsById, interval.length('days'));

      acc.push({
        date: interval.start,
        totalHits: futureStats.totalHits,
        totalCost: futureStats.totalCost,
        statsByFleetId: futureStats.statsByFleetId
      });

      return acc;
    }

    acc.push({
      date: interval.start,
      totalHits,
      totalCost: intervalData ? calculateHitsCost(intervalData) : 0
    });

    return acc;
  }, [] as Point[]);
};

const getFutureStats = (
  stats: {
    total: number;
    averageMonthlyNew: number;
    byFleetId: Record<string, number>;
    byFleetIdByIntegrationId: { [fleetId: string]: { [integrationId: string]: number } };
  },
  monthsFromNow: number,
  fleetsById: Record<string, Fleet>,
  amountOfPeriods: number
) => {
  const futureStats = {
    total: stats.total + monthsFromNow * stats.averageMonthlyNew,
    averageMonthlyNew: stats.averageMonthlyNew,
    byFleetId: Object.keys(stats.byFleetId).reduce(
      (acc, fleetId) => {
        const fleetRatio = stats.byFleetId[fleetId] / stats.total;
        acc[fleetId] = stats.byFleetId[fleetId] + stats.averageMonthlyNew * monthsFromNow * fleetRatio;

        return acc;
      },
      {} as Record<string, number>
    )
  };

  const statsByFleetId = Object.keys(stats.byFleetId).reduce(
    (acc, fleetId) => {
      const fleet = fleetsById[fleetId];
      const systemsWithFleet = futureStats.byFleetId[fleetId];

      const hitsByProvider: { [provider in IntegrationProvider]?: number } = {};

      fleet.systemProfileConfigs.forEach((config) => {
        const providerId = config.integration.id;
        const { provider } = config.integration;
        const providerConfig = PROVIDER_CONFIGS_MAP[provider];
        const providerRatio = (stats.byFleetIdByIntegrationId[fleetId]?.[providerId] ?? 0) / systemsWithFleet;

        const enabledMetrics = Object.keys(config.metrics).filter(
          (metric) => config.metrics[metric as SystemMetric].enabled
        ) as SystemMetric[];

        hitsByProvider[provider] =
          (hitsByProvider[provider] || 0) +
          systemsWithFleet * providerRatio * providerConfig.getHitsByEnabledMetrics(enabledMetrics) * amountOfPeriods;
      });

      acc[fleetId] = {
        hits: Object.values(hitsByProvider).reduce((acc, hits) => acc + hits, 0),
        cost: Object.entries(hitsByProvider).reduce((acc, [provider, hits]) => {
          const providerConfig = PROVIDER_CONFIGS_MAP[provider as IntegrationProvider];

          return acc + providerConfig.getHitsCost(hits);
        }, 0)
      };

      return acc;
    },
    {} as { [key: string]: { hits: number; cost: number } }
  );

  const totalHits = Object.values(statsByFleetId).reduce((acc, { hits }) => acc + hits, 0);
  const totalCost = Object.values(statsByFleetId).reduce((acc, { cost }) => acc + cost, 0);

  return {
    totalHits,
    totalCost,
    statsByFleetId
  };
};
