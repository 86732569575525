export enum PermissionMatrixSubject {
  Project = 'project',
  Work = 'task',
  Form = 'form',
  Doc = 'file',
  Team = 'team',
  Workspace = 'workspace',
  Contract = 'contract'
}

export enum PermissionMatrixScope {
  Own = 'own',
  Team = 'team',
  All = 'all',
  Settings = 'settings',
  Analytics = 'analytics',
  Invite = 'invite',
  Import = 'import',
  SmartView = 'smartview',
  Hub = 'smartviewFolder',
  Contract = 'contract'
}

export enum PermissionLevel {
  View = 'view',
  Create = 'create',
  Edit = 'edit',
  Delete = 'delete',
  None = 'none'
}

export type PermissionMatrixRowConfig = {
  scope: PermissionMatrixScope;
  levels: PermissionLevel[];
  label: string;
  tooltip?: string;
};

export type PermissionMatrixRowGroup = {
  label: string;
  tooltip?: string;
  subject: PermissionMatrixSubject;
  scopes: PermissionMatrixRowConfig[];
  isThreeLevel?: boolean;
};

export type PermissionMatrixConfig = PermissionMatrixRowGroup[];
