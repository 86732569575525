import { colors } from '@styles';
import styled from 'styled-components';

export const Menu = styled.div`
  padding: 14px;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  width: 152px;

  .MuiRadio-root {
    padding: 5px;
  }

  .MuiFormControlLabel-root {
    margin-right: 0;
  }

  .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
  }
`;

export const SwitchLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(props) => (props.isSelected ? colors.black : '#98A9BC')};
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Trigger = styled.div`
  padding: 8px;
  height: 32px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: #778ca2;
  background-color: ${colors.white};
  border: 1px solid #e8ecef;
  min-width: 152px;
  cursor: pointer;
  border-right: 0;

  > *:last-child {
    margin-left: auto;
  }
`;

export const OrderButton = styled.div`
  width: 32px;
  height: 32px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: ${colors.white};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8ecef;
  color: ${colors.green};
`;

export const TriggerLabel = styled.div``;

export const TriggerValue = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${colors.black};
`;
