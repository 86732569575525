import { ReactQueryKey } from '@enums';
import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { useIntegrationsProgress } from '@hooks/workspace/systems/integrations/useIntegrationsProgress';
import { useIntervalEffect } from '@react-hookz/web';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export const useInProgressIntegrations = () => {
  const { data: integrations, isLoading } = useIntegrations();

  const queryClient = useQueryClient();

  const { data: progress = [], refetch: fetchProgress } = useIntegrationsProgress(
    integrations?.map((integration) => integration.id) ?? [],
    { enabled: integrations?.length > 0 }
  );

  const isSomeIntegrationInProgress = Boolean(progress?.some((integration) => integration.inProgress));

  useIntervalEffect(() => fetchProgress(), isSomeIntegrationInProgress ? 1000 : undefined);

  useEffect(() => {
    if (!isSomeIntegrationInProgress) {
      queryClient.invalidateQueries([ReactQueryKey.SystemsSettings]);
    }
  }, [isSomeIntegrationInProgress, queryClient]);

  return {
    isIntegrationsLoading: isLoading || (integrations?.length > 0 && !progress),
    isSomeIntegrationInProgress,
    progress
  };
};
