import { useQuery, UseQueryOptions } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { axios } from '@services/api/base';
import { LineString } from 'geojson';
import { config } from '../../config';

export type PlannedRoutes = { [userId: number]: Route };

/**
 * https://docs.mapbox.com/api/navigation/directions
 */
export const usePlannedRoutes = (
  args: {
    points: { [userId: number]: { lat: number; lng: number }[] };
  },
  opts?: UseQueryOptions<PlannedRoutes>
) =>
  useQuery<PlannedRoutes>(
    [ReactQueryKey.Geolocation, 'usePlannedRoute', args],
    async () => {
      try {
        return (
          await Promise.all(
            Object.entries(args.points).map(async ([userId, points]) => {
              if (points.length < 2) {
                return [+userId, emptyRoute] as [number, Route];
              }

              const response = await axios.get<Directions>(
                `directions/v5/mapbox/driving/${points.map(({ lat, lng }) => `${lng},${lat}`).join(';')}`,
                {
                  baseURL: config.mapboxApiUrl,
                  params: {
                    geometries: 'geojson',
                    overview: 'full',
                    access_token: config.mapboxAccessToken
                  }
                }
              );

              if (response.data.code !== 'Ok') {
                throw new Error(
                  `Error "${response.data.code}" getting directions, status ${response.status} "${response.statusText}", message: "${response.data.message}"`
                );
              }

              return [+userId, response.data.routes[0]] as [number, Route];
            })
          )
        ).reduce(
          (acc, [userId, route]) => ({
            ...acc,
            [userId]: route
          }),
          {} as PlannedRoutes
        );
      } catch (e) {
        throw apiErrorHandler('Error fetching user route', e);
      }
    },
    {
      initialData: {},
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: 60 * 60 * 1000,
      initialDataUpdatedAt: 0,
      ...opts
    }
  );

type Directions = {
  // https://docs.mapbox.com/api/navigation/directions/#directions-api-errors
  code: 'Ok' | string;
  message?: string;
  routes: Route[];
};

// https://docs.mapbox.com/api/navigation/directions#route-object
export type Route = {
  geometry: LineString;
  legs: Leg[];
};

// https://docs.mapbox.com/api/navigation/directions/#route-leg-object
export type Leg = {
  // meters
  distance: number;
  // seconds
  duration: number;
};

const emptyRoute: Route = {
  geometry: { type: 'LineString', coordinates: [] },
  legs: [{ distance: 0, duration: 0 }]
};
