import { PrivilegedTask, Reminder, TaskVisit } from '@generated/types/graphql';
import { parseUtcDate } from '@utils/dates';

type TaskOrReminder = PrivilegedTask | Reminder;

export type FlattenedVisits = {
  title: string;
  isVisit: boolean;
  visitId: number;
  visitIndex: number;
  startDate: Date;
  endDate: Date;
  isCompleted: boolean;
  taskStartDate: Date;
  taskEndDate: Date;
};

const mapVisitToTask = (task: PrivilegedTask, visit: TaskVisit, index: number): PrivilegedTask & FlattenedVisits => ({
  ...task,
  isVisit: true,
  visitId: visit.id,
  visitIndex: index,
  startDate: parseUtcDate(visit.startDate),
  endDate: parseUtcDate(visit.endDate),
  isCompleted: visit.isCompleted,
  taskStartDate: task.startDate ? parseUtcDate(task.startDate) : null,
  taskEndDate: task.endDate ? parseUtcDate(task.endDate) : null
});

/**
 * @deprecated overcomplexifies data model instead of simplifying, don't repeat it
 */
export const flattenVisits = (tasks: TaskOrReminder[]) => {
  return tasks
    .map((task) => {
      if ('dueDate' in task || !task.isField) {
        return task;
      }

      if (!task.taskVisitsByTaskIdConnection.nodes.length) {
        return task;
      }

      return task.taskVisitsByTaskIdConnection.nodes.map((visit, index) => mapVisitToTask(task, visit, index));
    })
    .flat();
};
