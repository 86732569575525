import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 16px;

  height: calc(100vh - 48px);
  overflow: hidden;
  display: flex;
  flex-direction: column;

  h2 {
    font-size: 20px;
    font-weight: 500;
    margin: 16px 0;
  }
`;

export const Header = styled.div`
  h1 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 8px;
    margin-top: 0;
  }

  font-size: 14px;

  margin-bottom: 24px;
`;

export const SelectType = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  margin-bottom: 40px;
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 48px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 40px;
  margin-top: 24px;
`;

export const LastStep = styled.div`
  max-width: 800px;
  overflow: auto;
  ${scroll};
`;

export const Stepper = styled.div`
  margin: 24px 0;
  display: flex;
  gap: 100px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 16px;
    left: 32px;
    right: 32px;
    height: 2px;
    background-color: #dfdfe8;
    z-index: -1;
  }
`;

export const Step = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 4px;
  font-size: 12px;
  max-width: 70px;
  text-align: center;
  font-weight: 400;
  color: ${({ isActive = true }) => (isActive ? '#1D1D35' : '#828D9A')};
`;

export const StepCircle = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #009a47;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
`;

export const CurrentStepCircle = styled(StepCircle)`
  background-color: #cdf3df;
  color: #000;
`;

export const NotStartedStepCircle = styled(StepCircle)`
  background-color: #e4e8ec;
  color: #828d9a;
`;

export const LastStepForm = styled.div`
  background-color: #ffffff;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #dfdfe8;
`;

export const FormTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 24px;
`;

export const SettingsSection = styled.div<{ isEnabled: boolean }>`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  > *:first-child {
    width: 40px;
    min-width: 40px;
    text-align: center;
  }

  > *:last-child {
    flex: 1;
    opacity: ${({ isEnabled }) => (isEnabled ? 1 : 0.5)};
  }
`;

export const SettingsTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`;

export const SettingsDescription = styled.div`
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #dfdfe8;
  margin: 8px 0 24px;
`;

export const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
`;

export const OptionTitle = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
  padding-top: 4px;
`;

export const OptionDescription = styled.div`
  font-size: 12px;
  color: #828d9a;
  padding-bottom: 8px;
`;
