import React, { useMemo } from 'react';
import { useSystemsStatusTotal } from '@hooks/systems/useSystemsStatusTotal';
import { Cell, Customized, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { SystemStatus } from '@generated/types/graphql';
import { SYSTEM_STATUS_COLORS } from '@styles';
import { LegendLine, PieContainer, PieLegend, PieWidget, StatusLabel } from './styled';
import { capitalize } from 'lodash';
import { Square } from 'react-feather';
import { formatNumber } from '@utils';

type Props = {
  allIds: number[];
};

const statuses = [
  SystemStatus.Normal,
  SystemStatus.Warning,
  SystemStatus.Error
];

export const StatusesWidget = ({ allIds }: Props) => {
  const { data: total } = useSystemsStatusTotal({ ids: allIds });

  const cells = useMemo(() => statuses.map((status) => ({ status, amount: total[status] || 0 })),
    [total]);

  return (
    <PieWidget>
      <PieContainer>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={cells}
              dataKey={({ amount }: typeof cells[0]) => amount}
              nameKey={({ status }: typeof cells[0]) => status}
              innerRadius="80%"
              outerRadius="100%"
              startAngle={360}
              endAngle={0}
              isAnimationActive={false}
            >
              {cells.map(({ status }) => (
                <Cell
                  key={status}
                  stroke={SYSTEM_STATUS_COLORS[status].color}
                  fill={SYSTEM_STATUS_COLORS[status].color}
                />
              ))}
            </Pie>
            <Customized component={() => (
              <text>
                <tspan x="50%" y="50%" textAnchor="middle" fontSize={32} fontWeight={400}>
                  {formatNumber(allIds.length)}
                </tspan>
                <tspan x="50%" y="50%" textAnchor="middle" fontSize={16} fontWeight={400} fill="#828D9A" dy={24}>
                  Systems
                </tspan>
              </text>
            )} />
          </PieChart>
        </ResponsiveContainer>
      </PieContainer>
      <PieLegend>
        {cells.map(({ status, amount }) => (
          <LegendLine key={status}>
            <StatusLabel>
              <Square
                size={12}
                color={SYSTEM_STATUS_COLORS[status].color}
                fill={SYSTEM_STATUS_COLORS[status].color}
              />

              {capitalize(status)}
            </StatusLabel>

            {formatNumber(amount)}
          </LegendLine>
        ))}
      </PieLegend>
    </PieWidget>
  );
};