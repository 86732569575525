import { ReactQueryKey } from '@enums';
import workflowsApi from '@services/api/workflowsApi';
import { ActionsTriggersFromApi } from '@types';
import { apiErrorHandler } from '@utils/api';
import { useQuery } from 'react-query';

export const useAutomationTriggersAndActions = () => {
  return useQuery<ActionsTriggersFromApi>([ReactQueryKey.AutomationTriggersAndActions], async () => {
    try {
      const {
        data
      }: {
        data: ActionsTriggersFromApi;
      } = await workflowsApi.findActionsTriggers();

      return data;
    } catch (e) {
      throw apiErrorHandler('Error fetching triggers and actions', e);
    }
  });
};
