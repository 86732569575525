import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { SearchBar } from '@common/SearchBar';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useUpdateEffect } from '@react-hookz/web';
import { useAppSelector } from '@hooks/store';
import { isCustomerPortalEnabled } from '@state/selectors';
import { Switch } from '@kit/ui/Switch';
import { TabItem, Tabs } from '@kit/ui/Tabs';
import { Container } from './styled';
import { NotificationStatus, NotificationsTypeFilter, useClientFilterState } from '../useClientFilterState';
import { SortBy } from './SortBy';

const TABS: TabItem<NotificationsTypeFilter>[] = [
  { id: NotificationsTypeFilter.All, title: 'All ' },
  { id: NotificationsTypeFilter.Email, title: 'Emails' },
  { id: NotificationsTypeFilter.SMS, title: 'SMS' },
  { id: NotificationsTypeFilter.Mentions, title: 'Mentions' },
  { id: NotificationsTypeFilter.Reminders, title: 'Reminders' },
  { id: NotificationsTypeFilter.WorkOrders, title: 'Work Orders' }
];

export const Header = () => {
  const [tab, setTab] = useState(TABS[0]);
  const [, debouncedSearch, setSearch] = useDebouncedState('', 400);

  const isPortalEnabled = useAppSelector(isCustomerPortalEnabled);

  const { clientFilters, updateFilters } = useClientFilterState();

  const types = useMemo(() => {
    if (isPortalEnabled) {
      return [...TABS, { id: NotificationsTypeFilter.Portal, title: 'Portal' }];
    }

    return TABS;
  }, [isPortalEnabled]);

  useEffect(() => {
    updateFilters({
      query: debouncedSearch
    });
  }, [debouncedSearch, updateFilters]);

  const handleReadToggleChange = useCallback(
    (isChecked: boolean) => {
      updateFilters({ statuses: isChecked ? [] : [NotificationStatus.Unread] });
    },
    [updateFilters]
  );

  useUpdateEffect(() => {
    updateFilters({
      types: [tab.id]
    });
  }, [tab]);

  return (
    <Container>
      <SearchBar placeholder="Search..." onValueChange={setSearch} />
      <SortBy />
      <Switch
        isActive={clientFilters.statuses.includes(NotificationStatus.Read) || clientFilters.statuses.length === 0}
        label="Show read"
        onChange={handleReadToggleChange}
      />

      <Tabs selected={tab.id} tabs={types} onChange={setTab} />
    </Container>
  );
};
