import { ReactQueryKey } from '@enums';
import { PrivilegedTask, Reminder } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import { ReminderFetchOnly, useReminders } from '@hooks/useReminders';
import { selectWorkspaceId } from '@state/selectors';
import { useMemo } from 'react';
import { createGlobalState } from 'react-use';
import { RecordType } from '@types';
import { ClientFilterState, useClientFilterState } from '../useClientFilterState';
import { usePaginatedTasks } from '../useTasks';
import { flattenVisits } from '../flattenVisits';

// TODO add initial value to prevent network request
export const useFilterState = createGlobalState<any>({});

type TaskOrReminder = PrivilegedTask | Reminder;

const filterTasks = (tasks: TaskOrReminder[], filters: ClientFilterState) => {
  return tasks.filter((task) => {
    const isReminder = 'dueDate' in task;

    if (
      filters.query.length > 0 &&
      !task.project.title.toLowerCase().includes(filters.query) &&
      !task.title.toLowerCase().includes(filters.query) &&
      !task.project.address?.some((line) => line.toLowerCase().includes(filters.query)) &&
      !task.project.city?.toLowerCase().includes(filters.query) &&
      !task.project.state?.toLowerCase().includes(filters.query) &&
      !task.project.street?.toLowerCase().includes(filters.query) &&
      !task.project.zipcode?.toLowerCase().includes(filters.query)
    ) {
      return false;
    }

    if ((filters.labels.length > 0 || filters.priorities.length > 0 || filters.statuses.length > 0) && isReminder) {
      return false;
    }

    if (filters.reminderTypes.length > 0 && filters.templates.length === 0 && !isReminder) {
      return false;
    }

    if (filters.reminderTypes.length === 0 && filters.templates.length > 0 && isReminder) {
      return false;
    }

    if (filters.clientStatuses.length > 0 || filters.projectStatuses.length > 0 || filters.requestStatuses.length > 0) {
      if (
        task.project.type === RecordType.ACCOUNT &&
        !filters.clientStatuses.includes(task.project.accountStatus as AccountStatus | null)
      ) {
        return false;
      }

      if (task.project.type === RecordType.PROJECT && !filters.projectStatuses.includes(task.project.status)) {
        return false;
      }

      if (task.project.type === RecordType.DEAL && !filters.requestStatuses.includes(task.project.requestStatus)) {
        return false;
      }
    }

    if (filters.assignees.length > 0) {
      if (isReminder) {
        if (!filters.assignees.some((id) => (id === 'unassigned' && !task.assignee) || task.assignee.id === id)) {
          return false;
        }
      } else if (
        !filters.assignees.some(
          (id) =>
            (id === 'unassigned' && !task.assignee) ||
            task.assignee?.id === id ||
            task.assigneesByTaskId.find((assigneeByTaskId) => assigneeByTaskId.user?.id === id)
        )
      ) {
        return false;
      }
    }

    if (isReminder) {
      if (filters.reminderTypes.length === 0) {
        return true;
      }

      if (task.isAppointment && filters.reminderTypes.includes('appointment')) {
        return true;
      }

      return !task.isAppointment && filters.reminderTypes.includes(task.type);
    }

    if (filters.templates.length > 0 && !filters.templates.includes(task.templateTaskId)) {
      return false;
    }

    if (
      filters.labels.length > 0 &&
      !filters.labels.some((id) => task.privilegedTaskLabels.find((taskLabel) => taskLabel.label.id === id))
    ) {
      return false;
    }

    if (filters.priorities.length > 0 && !filters.priorities.includes(task.priority)) {
      return false;
    }

    if (filters.statuses.length > 0 && !filters.statuses.includes(task.taskStatus.id)) {
      return false;
    }

    return true;
  });
};

export const useSchedulerTasks = () => {
  const [filters] = useFilterState();
  const [clientFilters] = useClientFilterState();

  const companyId = useAppSelector(selectWorkspaceId);

  const { tasks, total } = usePaginatedTasks({
    queryKey: ReactQueryKey.TasksScheduler,
    perPage: 2000,
    isEnabled: Boolean(filters.dateRangeFilter),
    filters: {
      or: [
        {
          startDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
            lessThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          endDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
            lessThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          startDate: {
            lessThanOrEqualTo: filters.dateRangeFilter?.startDate
          },
          endDate: {
            greaterThanOrEqualTo: filters.dateRangeFilter?.endDate
          }
        },
        {
          taskVisitsByTaskId: {
            some: {
              startDate: {
                greaterThanOrEqualTo: filters.dateRangeFilter?.startDate,
                lessThanOrEqualTo: filters.dateRangeFilter?.endDate
              }
            }
          }
        }
      ]
    }
  });

  const { data: reminders = [] } = useReminders(companyId, undefined, ReminderFetchOnly.All);

  const filteredTasks = useMemo(() => {
    const filtered = filterTasks([...tasks, ...reminders], clientFilters);

    return {
      tasks: flattenVisits(filtered),
      visibleCount: filtered.length
    };
  }, [tasks, clientFilters, reminders]);

  return {
    tasks: filteredTasks.tasks,
    visibleCount: filteredTasks.visibleCount,
    total: total + reminders.length
  };
};
