import React from 'react';
import { colors } from '@styles';
import { Tooltip } from '@material-ui/core';
import { Settings, Link } from 'react-feather';
import { Wrapper, Edit, Remove, Copy, MarkDefault, Unlink } from './styled';

interface ActionProps {
  onEdit?: (e: React.MouseEvent) => void;
  onCopy?: (e: React.MouseEvent) => void;
  onRemove?: (e: React.MouseEvent) => void;
  onDefault?: (e: React.MouseEvent) => void;
  onSettings?: (e: React.MouseEvent) => void;
  onLink?: (e: React.MouseEvent) => void;
  onUnlink?: (e: React.MouseEvent) => void;
  linkText?: string;
  unlinkText?: string;
  name: string;
}

export const Actions: React.FC<ActionProps> = (props) => {
  const {
    onEdit,
    onRemove,
    onCopy,
    onDefault,
    onSettings,
    name,
    onLink,
    unlinkText = `Unlink ${name}`,
    onUnlink,
    linkText = `Link ${name}`
  } = props;

  return (
    <Wrapper>
      {onEdit && (
        <Tooltip title={`Edit ${name}`} placement="top" arrow>
          <Edit size={20} onClick={onEdit} />
        </Tooltip>
      )}
      {onSettings && (
        <Tooltip title="Template settings" placement="top" arrow>
          <Settings size={20} onClick={onSettings} />
        </Tooltip>
      )}
      {onCopy && (
        <Tooltip title={`Duplicate ${name}`} placement="top" arrow>
          <Copy size={20} onClick={onCopy} />
        </Tooltip>
      )}
      {onDefault && (
        <Tooltip title="Set as default payment method" placement="top" arrow>
          <MarkDefault size={20} onClick={onDefault} />
        </Tooltip>
      )}
      {onRemove && (
        <Tooltip title={`Remove ${name}`} placement="top" arrow>
          <Remove size={20} color={colors.red} onClick={onRemove} />
        </Tooltip>
      )}
      {onUnlink && (
        <Tooltip title={unlinkText} placement="top" arrow>
          <Unlink size={20} onClick={onUnlink} />
        </Tooltip>
      )}
      {onLink && (
        <Tooltip title={linkText} placement="top" arrow>
          <Link size={20} onClick={onLink} />
        </Tooltip>
      )}
    </Wrapper>
  );
};
