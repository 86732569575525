import {
  IntegrationProvider,
  IntegrationSystemLogFilter,
  IntegrationSystemLogGroupBy,
  IntegrationSystemLogsConnection
} from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { DeepPartial } from 'redux';
import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useIntegrations } from './integrations/useIntegrations';

type Params = {
  from: Date;
  to: Date;
  groupBy: IntegrationSystemLogGroupBy;
};

type Options = {
  isEnabled?: boolean;
};

export const useHistoricalHits = ({ from, to, groupBy }: Params, { isEnabled = true }: Options = {}) => {
  const companyId = useAppSelector(selectWorkspaceId);

  const { data: integrations } = useIntegrations();

  const integrationsById = useMemo(() => {
    if (!integrations) {
      return {};
    }

    return integrations.reduce(
      (acc, integration) => {
        acc[integration.id] = integration.provider;

        return acc;
      },
      {} as Record<number, IntegrationProvider>
    );
  }, [integrations]);

  const filter: DeepPartial<IntegrationSystemLogFilter> = {
    createdAt: {
      greaterThanOrEqualTo: from,
      lessThanOrEqualTo: to
    },
    integration: {
      companyId: {
        equalTo: companyId
      }
    }
  };

  const groupByWithIntegration = [groupBy, IntegrationSystemLogGroupBy.IntegrationId];

  return useQuery(
    [ReactQueryKey.SystemsSettings, ReactQueryKey.SystemsBreakdownHits, { companyId, from, to, integrationsById }],
    async () => {
      try {
        const { integrationSystemLogsConnection } = await postGraphql<{
          integrationSystemLogsConnection: IntegrationSystemLogsConnection;
        }>(
          gql`
            query HISTORICAL_HITS_QUERY(
              $filter: IntegrationSystemLogFilter!
              $groupBy: [IntegrationSystemLogGroupBy!]!
            ) {
              integrationSystemLogsConnection(filter: $filter) {
                groupedAggregates(groupBy: $groupBy) {
                  keys
                  distinctCount {
                    id
                  }
                }
              }
            }
          `,
          { filter, groupBy: groupByWithIntegration }
        );

        const result: Record<string, { [provider in IntegrationProvider]: number }> = {};

        integrationSystemLogsConnection.groupedAggregates.forEach(({ keys, distinctCount }) => {
          const [date, integrationId] = keys;
          const provider = integrationsById[integrationId];

          const isoDate = DateTime.fromISO(date).toISODate();

          result[isoDate] = {
            ...result[isoDate],
            [provider]: parseInt(distinctCount.id, 10)
          };
        });

        return result;
      } catch (e) {
        throw apiErrorHandler('Error fetching historical hits', e);
      }
    },
    { enabled: integrations.length > 0 && isEnabled }
  );
};
