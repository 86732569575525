import styled from 'styled-components';

export const EditableFieldContainer = styled.div`
  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    border-color: #e8ecef;
  }

  .ql-editor {
    padding-top: 0;
    padding-left: 0;
  }
`;
