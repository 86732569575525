import { useQuery } from 'react-query';
import projectApi from '@services/api/projectApi';
import { apiErrorHandler } from '@utils/api';
import { ReactQueryKey } from '@enums';

export const useOneSchemaToken = () => {
  return useQuery(
    [ReactQueryKey.OneSchemaToken],
    async () => {
      try {
        const { data } = await projectApi.getOneSchemaToken();

        return data.token;
      } catch (error) {
        throw apiErrorHandler('Error fetching OneSchema token', error);
      }
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, staleTime: Infinity }
  );
};
