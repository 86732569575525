import React, { useMemo } from 'react';
import { Link } from 'gatsby';
import { ChevronRightIcon } from '@kit/ui/icons/ChevronRight';
import { useAutomationList } from '@hooks/automations/useAutomationList';
import { Breadcrumbs, Container } from './styled';
import { Form } from './Form';

interface Props {
  automationId: string;
}

export const Page = ({ automationId }: Props) => {
  const isNew = automationId === 'new';
  const { data: allAutomations, isLoading } = useAutomationList();

  const automation = useMemo(() => {
    if (!allAutomations) {
      return null;
    }

    return allAutomations.find((automation) => automation.id === +automationId);
  }, [allAutomations, automationId]);

  if (!isNew && (isLoading || !automation)) {
    return null;
  }

  return (
    <Container>
      <Breadcrumbs>
        <Link to="../">Automations</Link>
        <ChevronRightIcon size="16px" color="#828D9A" />
        <div>{isNew ? 'New' : automation.name}</div>
      </Breadcrumbs>

      <Form initialValues={automation} />
    </Container>
  );
};
