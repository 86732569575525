import styled from 'styled-components';

export const SlaOption = styled.div<{ type: 'red' | 'yellow' }>`
  display: flex;
  align-items: center;
  border-radius: 4px;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 3px;
  gap: 4px;
  background-color: ${({ type }) => (type === 'red' ? '#D54855' : '#F1AA12')};
`;
