import styled from 'styled-components';

export const Table = styled.table`
  position: sticky;
  top: 32px;
  z-index: 3;
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  background-color: #f8fafb;
  width: 100%;

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 180px;
    min-width: 180px;
    max-width: 180px;
    height: 40px;
    text-align: left;
    padding-left: 16px;

    font-size: 12px;
    font-weight: 500;
    color: #828d9a;
    text-transform: uppercase;
  }
`;

export const SelectionAndPerPage = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 32px;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #f8fafb;
`;

export const PerPage = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #828d9a;
  font-size: 14px;
  font-weight: 400;

  &&& {
    .MuiAutocomplete-inputRoot {
      padding: 0;

      .MuiAutocomplete-input {
        min-width: 50px;
      }
    }
  }
`;
