import { FormValidationRules, useForm, Form, InputField, RadioGroupField } from '@kit/components/Form';
import React, { useMemo } from 'react';
import { WorkflowListItem } from '@hooks/workflows/useWorkflowList';
import { useCreateWorkflow } from '@hooks/workflows/useCreateWorkflow';
import { useUpdateWorkflow } from '@hooks/workflows/useUpdateWorkflow';
import { useCopyWorkflow } from '@hooks/workflows/useCopyWorkflow';
import { navigate } from 'gatsby';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Body, Footer } from './styled';

type FormValues = {
  name: string;
  type: string;
  description: string;
};

const TYPE_OPTIONS = [
  { value: 'PROJECT', label: 'Project' },
  { value: 'DEAL', label: 'Request' }
];

interface Props {
  sourceId?: number;
  initialValues?: WorkflowListItem;
  onClose: () => void;
}

export const WorkflowForm = ({ sourceId, initialValues, onClose }: Props) => {
  const isNew = !initialValues;
  const isCopy = Boolean(sourceId);

  const { mutateAsync: create } = useCreateWorkflow();
  const { mutateAsync: update } = useUpdateWorkflow();
  const { mutateAsync: copy } = useCopyWorkflow();

  const postForm = async (values: FormValues) => {
    const payload = {
      name: values.name,
      type: values.type,
      description: values.description
    };

    if (isCopy) {
      const createdId = await copy({ sourceId, ...payload });

      navigate(`./${createdId}`);
    } else if (isNew) {
      const createdId = await create(payload);

      navigate(`./${createdId}`);
    } else {
      await update({
        id: initialValues!.id,
        ...payload
      });
    }

    onClose();
  };

  const { form, handleSubmit } = useForm({
    onSubmit: postForm,
    mode: 'onBlur',
    defaultValues: initialValues ?? { type: 'PROJECT' }
  });

  const {
    formState: { isSubmitting },
    control
  } = form;

  const rules = useMemo<FormValidationRules<FormValues>>(
    () => ({
      name: {
        isRequired: true
      },
      type: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <Body>
        <InputField label="Name" name="name" control={control} />
        <InputField label="Description" name="description" control={control} />
        {isNew && <RadioGroupField label="Type" name="type" options={TYPE_OPTIONS} control={control} />}
      </Body>
      <Footer>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} variant={ButtonVariant.Primary} type="submit">
          {isNew || isCopy ? 'Create' : 'Update'}
        </Button>
      </Footer>
    </Form>
  );
};
