import React, { useCallback } from 'react';
import { selectCompanySettings } from '@state/selectors';
import { useDebouncedState } from '@hooks/useDebouncedState';
import { useAppSelector } from '@hooks/store';
import { useUpdateEffect } from '@react-hookz/web';
import { useModal } from '@common/PromiseModal';
import { useUpdateCompany } from '@hooks/useUpdateCompany';
import { SettingsBlock } from '../SettingsBlock';
import { EditForm } from './EditForm';
import { DEFAULT_SETTINGS } from './constants';

export const Referral = () => {
  const settings = useAppSelector(selectCompanySettings)?.referral ?? DEFAULT_SETTINGS;

  const [isEnabled, isEnabledDebounced, setIsEnabled] = useDebouncedState(settings.enabled, 300);

  const { openModal } = useModal();
  const { mutateAsync: updateCompany } = useUpdateCompany();

  useUpdateEffect(() => {
    updateCompany({
      settings: {
        referral: { ...settings, enabled: isEnabledDebounced }
      }
    });
  }, [isEnabledDebounced]);

  const handleEdit = useCallback(() => {
    openModal<void>(({ onClose }) => <EditForm settings={settings} onClose={onClose} />, {
      title: 'Edit “Client can submit Referrals”'
    });
  }, [openModal, settings]);

  return (
    <SettingsBlock
      title="Client can submit Referrals"
      description="Display Referral prompts in the Client Portal. Clients receive a custom link to share via email or social media. This link directs to a request form in the Portal. Each new client is tagged with the name of the referrer."
      isEnabled={isEnabled}
      onToggleEnabled={setIsEnabled}
      onEdit={handleEdit}
    />
  );
};
