import styled from 'styled-components';

export const Container = styled.div`
  padding: 9px 8px;
  background-color: #fff;
  margin-bottom: 12px;
  box-shadow: 0px 4px 8px 0px #1a034014;
  border-radius: 4px;
  font-size: 12px;
  border-left: 4px solid ${(props) => props.borderColor || '#fff'};
`;

export const Header = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 4px;

  .MuiAvatar-root {
    margin-right: 8px;
  }

  svg {
    flex-shrink: 0;
  }

  > *:last-child {
    margin-left: auto;
  }
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-top: 2px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SecondLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;

  > div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SecondLineIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const IconWrapper = styled.div`
  flex-shrink: 0;
  margin-top: 2px;
`;

export const Dates = styled.div`
  color: #98a9bc;
  margin-top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: ${(props) => (props.isCrossedOut ? 'line-through' : 'none')};

  > div:first-child {
    flex: 2;
    white-space: nowrap;
  }

  > div:not(:first-child) {
    flex: 1;
    text-align: right;
  }
`;
