import { AssigneesField } from '@common/index';
import { useFormikContext } from 'formik';
import React from 'react';
import { User } from 'react-feather';
import { UserAvatar } from '@kit/components/UserAvatar';
import { useInlineUpdateField } from '../useInlineUpdateField';
import { AssigneesContainer, Placeholder, UserAvatarAndName } from './styled';
import { CommonFieldProps } from '../types';

interface MemberProps {
  assignee: {
    id: number;
    firstName: string;
    lastName: string;
    avatarUrl: string;
  };
}

const Member = ({ assignee }: MemberProps) => {
  // if this is custom property, not actual user
  if (!('avatarUrl' in assignee)) {
    return <div>{assignee.name}</div>;
  }

  return (
    <UserAvatarAndName>
      <UserAvatar user={assignee} size={20} />
      <div>
        {assignee.firstName} {assignee.lastName}
      </div>
    </UserAvatarAndName>
  );
};

export const CollaboratorsField = ({ onUpdated }: CommonFieldProps) => {
  const { isEdit, setIsEdit, setIsFocused, nodeRef } = useInlineUpdateField(onUpdated);

  const context = useFormikContext();

  if (isEdit) {
    return (
      <div ref={nodeRef}>
        <AssigneesField
          name="assignees"
          // projectID={project?.id}
          // companyId={companyId}
          allowTeams
          allowUserProperties
          excludeIds={context.values.assignee ? [context.values.assignee.id] : []}
          membersOnly={false}
          onFocus={() => setIsFocused(true)}
          onBlur={() => {
            setIsFocused(false);
          }}
        />
      </div>
    );
  }

  return (
    <AssigneesContainer onClick={() => setIsEdit(true)}>
      {context.values.assignees.map((member) => (
        <Member key={member.id} assignee={member} />
      ))}

      {context.values.assignees.length === 0 && (
        <Placeholder>
          <User size="16px" /> No collaborators
        </Placeholder>
      )}
    </AssigneesContainer>
  );
};
