import { colors } from '@styles';
import styled from 'styled-components';

export const PageHeader = styled.div`
  padding: 24px 0;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Container = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const TableContainer = styled.div``;

export const Table = styled.table`
  background-color: #fff;
  border-spacing: 0;
  border-radius: 8px;
  width: 100%;

  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    background-color: #fff;
  }

  thead {
    position: sticky;
    top: -1px;
    z-index: 1;
    background-color: #fff;
  }

  th {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    font-weight: 400;
    color: #98a9bc;
    height: 48px;
    text-align: left;
    padding: 8px 16px;
    border-bottom: 1px solid #dfdfe8;
    border-top: 1px solid #dfdfe8;

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }

    &:last-child {
      border-right: 1px solid #dfdfe8;
    }
  }
  tr {
    cursor: pointer;

    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #f5f5f5;
    }
  }

  td {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 1px solid #dfdfe8;
    padding: 8px 16px;
    height: 64px;
    font-size: 14px;
    font-weight: 400;

    &:last-child {
      border-right: 1px solid #dfdfe8;
      > * {
        margin-left: auto;
        justify-content: flex-end;
        text-align: right;
      }
    }

    &:first-child {
      border-left: 1px solid #dfdfe8;
    }
  }
`;
export const Author = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const TitleWithIcon = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    flex-shrink: 0;
  }
`;

export const RightSliderPanel = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${colors.white};
  box-shadow: -16px -16px 200px 0px #1d1d3552;

  width: 650px;
  height: calc(100vh - 47px);

  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
  }
`;

const OuterButton = styled.div<{ isDisabled?: boolean }>`
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${(props) => (props.isDisabled ? 'not-allowed' : 'pointer')};

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const CloseButton = styled(OuterButton)`
  height: 40px;
  color: #9c9caa;
  transition: color 0.15s ease-in-out;

  &:hover {
    color: #1d1d35;
  }

  width: 40px;
`;

export const OuterButtons = styled.div`
  position: absolute;
  top: 24px;
  left: -40px;
  z-index: 5;
  gap: 8px;
  flex-direction: column;
  display: flex;
`;

export const LoaderWrapper = styled.div`
  margin: 40px auto;
`;
