import styled from 'styled-components';

export const PageHeader = styled.div`
  padding: 24px 0;
`;

export const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 8px;
`;

export const PageDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
`;

export const Container = styled.div`
  display: flex;
  gap: 24px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 775px;
  padding-bottom: 64px;
  flex: 1;
`;

export const SideNav = styled.div`
  width: 200px;
  position: sticky;
  right: 0;
  top: 0;
  height: fit-content;

  @media (min-width: 1400px) {
    width: 260px;
  }
`;

export const SideNavItem = styled.a<{ isActive: boolean }>`
  color: ${({ isActive }) => (isActive ? '#009688' : '#828D9A')};
  font-size: 14px;
  display: block;
  font-weight: 500;
  padding: 12px 0;
  cursor: pointer;
  transition:
    color 0.2s ease-in-out,
    border-right 0.2s ease-in-out;
  text-decoration: none;
  width: 150px;
  border-right: ${({ isActive }) => (isActive ? '2px solid #009688' : '1px solid #DFDFE8')};

  &:hover {
    color: ${({ isActive }) => (isActive ? '#009688' : '#000')};
  }
`;

export const Panel = styled.div`
  padding: 24px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dfdfe8;
`;

export const PanelHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;

  button {
    white-space: nowrap;
    flex-shrink: 0;
  }
`;

export const PanelTitle = styled.div`
  font-size: 18px;
  font-weight: 500;
`;

export const PanelDescription = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
  color: #828d9a;
`;

export const Section = styled.div<{ isDisabled?: boolean }>`
  ${({ isDisabled }) =>
    isDisabled &&
    ` 
 position: relative;

  &::after {
    content: 'Please, wait until systems integration initialization is completed.';
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
  `}
`;
