import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  border-radius: 4px;
  overflow: hidden;
`;

interface IBody {
  children?: JSX.Element | JSX.Element[];
  style?: React.CSSProperties;
}

const Body: React.FC<IBody> = (props: IBody): JSX.Element => {
  const {
    children,
    style
  } = props;

  return (
    <Wrapper style={style}>
      {children}
    </Wrapper>
  );
};

export default Body;
