import deleteReq from '@services/api/base/delete';
import { Urls } from '@services/api/base/axios';
import { BuyPhoneDto, UpdateCompanyPhoneDto } from '@services/api/types';
import post from '@services/api/base/post';
import put from '@services/api/base/put';

export default {
  buyPhone: (companyId: number, dto: BuyPhoneDto) =>
    post(`${Urls.project}/company-phone/`, dto, { companyId }),

  deletePhone: (companyId: number, companyPhoneId: number) =>
    deleteReq(`${Urls.project}/company-phone/${companyPhoneId}`, { companyId }),

  updatePhone: (companyId: number, companyPhoneId: number, dto: UpdateCompanyPhoneDto) =>
    put(`${Urls.project}/company-phone/${companyPhoneId}`, dto, { companyId })
};
