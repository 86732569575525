import styled, { css } from 'styled-components';
import { getLighterBgColor } from '@utils';
import { fonts } from '@styles';

export const Container = styled.div`
  padding: 6px 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  text-align: left;
  font-family: ${fonts.primary};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  ${(props) =>
    props.color
      ? css`
          background-color: ${props.color};
          color: #fff;

          &:hover {
            background-color: ${getLighterBgColor(props.color)};
          }
        `
      : ''}
`;

export const LabelCircle = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.color || '#fff'};
  border: ${(props) => (props.color ? 'none' : '1px solid black')};
  display: inline-block;
  margin-bottom: 1px;
  margin-right: 4px;
`;

export const Title = styled.span`
  font-weight: 500;
  text-decoration: ${(props) => (props.isCompleted ? 'line-through' : 'none')};
`;
