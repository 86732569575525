import React, { useMemo } from 'react';
import {
  CompanyNoficationSettingsTreeItem,
  useCompanyNotifications
} from '@hooks/workspace/notifications/useCompanyNotifications';
import { EntityType } from '@generated/types/graphql';
import { NotificationEntity } from '@features/Platform/Notifications';
import { useFleetList } from '@hooks/workspace/systems/fleets/useFleetList';
import { useSystemsStats } from '@hooks/workspace/systems/useSystemsStats';
import { useIntegrations } from '@hooks/workspace/systems/integrations/useIntegrations';
import { Container, List, Section, SideNav, SideNavItem } from './styled';
import { ProviderList } from './ProviderList';
import { SystemsOverview } from './SystemsOverview';
import { SystemsBreakdown } from './SystemsBreakdown';
import { Budget } from './Budget';
import { Fleets } from './Fleets';
import { Alerts } from './Alerts';
import { SIDEBAR_BUDGET_PORTAL_NODE_ID } from './constants';
import { Header } from './Header';
import { makeTitleHref, useNav } from './useNav';
import { useInProgressIntegrations } from './useInProgressIntegrations';

const SECTIONS = ['Systems overview', 'Systems breakdown', 'Budget', 'Fleets', 'Alerts', 'Notifications', 'Source'];

export const SystemsSettings = () => {
  const { activeItem, onNavItemClick } = useNav();
  const { data: notificationSettings } = useCompanyNotifications();
  const { data: fleets = [] } = useFleetList();
  const { data: stats } = useSystemsStats();

  const { data: integrations } = useIntegrations();

  const hasAnyInstalledIntegrations = integrations?.length > 0;

  const { isIntegrationsLoading, isSomeIntegrationInProgress, progress } = useInProgressIntegrations();

  const notifications = useMemo<CompanyNoficationSettingsTreeItem | null>(() => {
    const systemNotifications = notificationSettings?.find((entity) => entity.entityType === EntityType.Fleet);

    return systemNotifications ? { ...systemNotifications, title: 'Notifications' } : null;
  }, [notificationSettings]);

  if (isIntegrationsLoading) {
    return null;
  }

  return (
    <>
      <Header />

      <Container>
        <List>
          {hasAnyInstalledIntegrations && (
            <>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Systems overview')}>
                <SystemsOverview />
              </Section>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Systems breakdown')}>
                <SystemsBreakdown fleets={fleets} stats={stats} />
              </Section>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Budget')}>
                <Budget />
              </Section>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Fleets')}>
                <Fleets />
              </Section>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Alerts')}>
                <Alerts />
              </Section>
              <Section isDisabled={isSomeIntegrationInProgress} data-section id={makeTitleHref('Notifications')}>
                {notifications && <NotificationEntity entity={notifications} />}
              </Section>
            </>
          )}
          <div data-section id={makeTitleHref('Source')}>
            <ProviderList progress={progress} />
          </div>
        </List>
        <SideNav>
          {hasAnyInstalledIntegrations &&
            SECTIONS.map((section) => (
              <SideNavItem
                onClick={onNavItemClick}
                isActive={makeTitleHref(section) === activeItem}
                key={section}
                href={`#${makeTitleHref(section)}`}
              >
                {section}
              </SideNavItem>
            ))}

          <div id={SIDEBAR_BUDGET_PORTAL_NODE_ID} />
        </SideNav>
      </Container>
    </>
  );
};
