import { ReactQueryKey } from '@enums';
import { errorHandler } from '@services/api/helpers';
import { selectWorkspaceId } from '@state/selectors';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import blueprintAPI from '@services/api/blueprintAPI';
import { useToast } from '..';

type UpdateWorkflowPayload = {
  id: number;
  name: string;
  description: string;
};

export const useUpdateWorkflow = () => {
  const queryClient = useQueryClient();
  const { showSuccess } = useToast();
  const companyId = useSelector(selectWorkspaceId);

  return useMutation<void, Error, UpdateWorkflowPayload>(
    async (req) => {
      try {
        await blueprintAPI.update(companyId, req.id, req);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess('Workflow updated successfully');
        queryClient.invalidateQueries([ReactQueryKey.WorkflowList]);
      }
    }
  );
};
