import { updateAccountSettingAction } from '../../state/actions/settingsAction/accountSettingAction';
import { userService, getUserServicePUTURL } from '../service.config';
import { getConfig } from '../service.utils';
import { AccountSettingType } from '../../state/reducers/settings/accountReducer';
import { authenticationUpdateAction } from '../../state/actions/authentication/authenticationAction';
import { alertShow } from '../../state/actions/alert/alertAction';
import { getUserSettings, setUserSettings } from '@utils/utils';

export const fetchProfile = () => {
  return (dispatch: any) => {
    dispatch(updateAccountSettingAction({ isLoading: true }));
    fetch(`${userService}/profile`, getConfig('', 'GET'))
      .then((response) => response.json())
      .then((result) => {
        const { accessToken, refreshToken, ...user } = result;
        dispatch(
          updateAccountSettingAction({
            ...user,
            isLoading: false,
            settings: getUserSettings(user.id)
          })
        );
      })
      .catch((error) => console.log(error));
  };
};

export const updateProfile = (
  user: AccountSettingType,
  setSubmitting: (isSubmitting: boolean) => void
) => {
  return async (dispatch: any) => {
    delete user.password;
    try {
      const response = await fetch(
        `${userService}/profile`,
        getConfig(JSON.stringify(user), 'PUT')
      );
      const { status } = response;
      switch (status) {
        case 200:
          {
            const {
              accessToken,
              refreshToken,
              user: userResponse
            } = await response.json();
            setUserSettings(user.id, user.settings);

            dispatch(
              authenticationUpdateAction({
                userResponse,
                accessToken,
                refreshToken
              })
            );

            dispatch(fetchProfile());
          }
          break;
        case 400:
          {
            const responseData = await response.json();
            const { message = [] } = responseData;
            dispatch(
              alertShow(
                [Array.isArray(message) ? message.join('\n') : message],
                'error'
              )
            );
          }
          break;
        case 403:
          {
            const responseData = await response.text();
            dispatch(alertShow([responseData], 'error'));
          }
          break;
        default:
          dispatch(
            alertShow(['Failed to update profile, please try again.'], 'error')
          );
      }
    } catch (e) {
      console.error('couldnt save user', e);
      dispatch(
        alertShow(['Failed to update profile, please try again.'], 'error')
      );
    }
    setSubmitting(false);
  };
};

export const saveUserDataAsync = (user, companyId: number) => {
  return async () => {
    delete user.passwordConfirmation;
    delete user.isLoading;
    delete user.alert;
    delete user.isUploadingProfile;

    return new Promise((resolve, reject) => {
      fetch(
        getUserServicePUTURL(user.id, companyId),
        getConfig(JSON.stringify(user), 'PUT')
      )
        .then((response) => response.json())
        .then((result) => {
          resolve(true);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };
};
