import { CheckboxField, InputField, TextareaField } from '@kit/components/Form';
import React, { useCallback } from 'react';
import { useFieldArray } from 'react-hook-form';
import { XIcon } from '@kit/ui/icons/X';
import { Globe, Plus } from 'react-feather';
import { Tooltip } from '@material-ui/core';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { Header, HeaderRight, Title, Options, OptionLabel, RemoveOptionButton } from './styled';
import { FormProps } from './types';

interface Props extends FormProps {
  title?: string;
}

export const Select = ({ title = 'Single select', control, isProjectProperty, isReadOnlyProperty }: Props) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'options'
  });

  const handleAddOption = useCallback(() => {
    append({ value: '' });
  }, [append]);

  const handleRemoveOption = useCallback(
    (index: number) => () => {
      remove(index);
    },
    [remove]
  );

  return (
    <div>
      <Header>
        <Title>
          {title}{' '}
          {isProjectProperty && (
            <Tooltip title="Project property">
              <Globe size="16px" />
            </Tooltip>
          )}
        </Title>
        <HeaderRight>
          {isProjectProperty && (
            <CheckboxField isDisabled={isReadOnlyProperty} name="isEditable" control={control} label="Editable" />
          )}

          <CheckboxField name="isRequired" control={control} label="Required" />
        </HeaderRight>
      </Header>
      <InputField control={control} label="Title" name="name" />

      <TextareaField control={control} label="Add notes and instructions" name="notes" />

      {!isProjectProperty && (
        <>
          {fields.length > 0 && (
            <Options>
              {fields.map((field, index) => (
                <React.Fragment key={field.id}>
                  <OptionLabel>Option {index + 1}</OptionLabel>
                  <InputField name={`options.${index}.value`} control={control} />
                  <RemoveOptionButton onClick={handleRemoveOption(index)}>
                    <XIcon size="16px" />
                  </RemoveOptionButton>
                </React.Fragment>
              ))}
            </Options>
          )}

          <Button variant={ButtonVariant.Flat} onClick={handleAddOption}>
            <Plus size="16px" />
            Add option
          </Button>
        </>
      )}
    </div>
  );
};
