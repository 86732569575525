import { useMutation, useQueryClient } from 'react-query';
import { useAppSelector, useToast } from '@hooks';
import { errorHandler } from '@services/api/helpers';
import profilesAPI, { ProfileCreateDTO } from '@services/api/systemProfilesAPI';
import { ReactQueryKey } from '@enums';
import { selectWorkspaceId } from '@state/selectors';

export const useCreateFleet = () => {
  const queryClient = useQueryClient();
  const companyId = useAppSelector(selectWorkspaceId);
  const { showSuccess } = useToast();

  return useMutation<void, Error, ProfileCreateDTO>(
    async (dto) => {
      try {
        await profilesAPI.create(companyId, dto);
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onSuccess: () => {
        showSuccess(`Fleet created successfully`);
        queryClient.invalidateQueries([ReactQueryKey.SystemsSettings, ReactQueryKey.FleetsSettings]);
      }
    }
  );
};
