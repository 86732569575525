import styled from 'styled-components';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  withStyles
} from '@material-ui/core';
import { ModalFooter } from '@common/PromiseModal';

export const List = styled.div`
  margin-top: 16px;

  form {
    width: 100%;
  }
`;

export const Accordion = withStyles({
  root: {
    borderTop: '1px solid #DFDFE8',
    boxShadow: 'none',
    margin: '0 -24px',
    padding: '0 24px',
    '&:not(:last-child)': {
      borderBottom: 0
    },
    '&:before': {
      display: 'none'
    },
    '&.Mui-expanded': {
      margin: 'auto -24px'
    }
  }
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    minHeight: 56,
    overflow: 'hidden',
    alignItems: 'center',
    padding: 0,
    '&.Mui-expanded': {
      minHeight: 56
    }
  },

  content: {
    overflow: 'hidden',
    alignItems: 'center',
    gap: '16px',
    '&.Mui-expanded': {
      margin: '12px 0'
    }
  }
})(MuiAccordionSummary);

export const AccordionDetails = withStyles(() => ({
  root: {
    transition: 'background-color 0.15s ease-in-out',
    padding: '0 0 16px 0',
    '& > div': {
      width: '100%'
    }
  }
}))(MuiAccordionDetails);

export const FormActions = styled(ModalFooter)`
  padding: 16px 0 0;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Condition = styled.div`
  margin-bottom: 16px;
  display: flex;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;

  > * {
    margin-bottom: 0;
    white-space: nowrap;
  }

  > *:not(.field-container) {
    line-height: 40px;
  }

  .field-container:has(.metric) {
    width: 180px;
  }

  .field-container:has(.percent),
  .field-container:has(.days) {
    width: 60px;
  }
`;

export const LessMore = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #009688;
  cursor: pointer;
  width: 54px;
  font-weight: 500;
  height: 40px;
`;
