import styled from 'styled-components';
import { colors, device } from '@styles';

export const TwoColumnFields = styled.div`
  display: grid;
  grid-gap: 30px;

  ${device.sm`
    grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  `};
`;

export const FormBody = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

export const InnerForm = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  width: 100%;
  padding: 10px;
`;

export const Label = styled.label`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 5px;
  display: block;
`;

export const Required = styled.span`
  &:after {
    color: ${colors.red};
    content: '*';
  }
`;
