import { scroll } from '@styles';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 24px 16px;
  overflow: auto;
  ${scroll};
`;

export const Placeholder = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #828d9a;
`;
