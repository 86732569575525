import { useCallback } from 'react';
import { GroupOption, PER_PAGE_OPTIONS, SORT_OPTIONS, SortOption } from '../constants';
import { useSessionStorageValue } from '@react-hookz/web';
import { LocalStorageKey } from '@enums';

export type Sort = {
  option: SortOption;
  isDesc: boolean;
};

export type ClientFilterState = {
  sortBy: Sort[];
  query: string;
  groupBy: GroupOption | null;
  perPage: { label: string; value: number };
  monitored: boolean | null;
};

export const useClientFilterState = () => {
  const { value: clientFilters, set: setClientFilters } = useSessionStorageValue<ClientFilterState>(
    LocalStorageKey.systemsClientFilterState,
    {
      defaultValue: {
        sortBy: [{ option: SORT_OPTIONS[0], isDesc: false }],
        query: '',
        groupBy: null,
        perPage: PER_PAGE_OPTIONS[0],
        monitored: null
      }
    }
  );

  const handleFiltersChange = useCallback(
    (newFilters: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...newFilters
      }));
    },
    [setClientFilters]
  );

  const resetFilters = useCallback(
    (values: Partial<ClientFilterState>) => {
      setClientFilters((prev) => ({
        ...prev,
        ...values
      }));
    },
    [setClientFilters]
  );

  return {
    clientFilters,
    updateFilters: handleFiltersChange,
    resetFilters
  };
};
