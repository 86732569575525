import { ModalBody, ModalFooter } from '@common/PromiseModal';
import { useCreateRole } from '@hooks/workspace/roles/useCreateRole';
import { useRoleList } from '@hooks/workspace/roles/useRoleList';
import { Form, FormValidationRules, InputField, RadioGroupField, SelectField, useForm } from '@kit/components/Form';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { RoleDTO } from '@types';
import { normalizeRoleName } from '@utils/roles';
import React, { useCallback } from 'react';

interface FormValues {
  name: string;
  startOption: 'scratch' | 'copy';
  roleToCopy?: RoleDTO;
}

interface Props {
  initialValues?: FormValues;
  onClose: (newId: number | void) => void;
}

const START_OPTIONS = [
  { label: 'Start from scratch', value: 'scratch' },
  { label: 'Start from existing role', value: 'copy' }
];

const RULES: FormValidationRules<FormValues> = {
  name: {
    isRequired: true
  },
  roleToCopy: {
    isRequired: true
  }
};

export const CreateForm = ({ initialValues, onClose }: Props) => {
  const { mutateAsync: create } = useCreateRole();

  const { data: roles } = useRoleList();

  const { handleSubmit, form } = useForm<FormValues>({
    defaultValues: initialValues ?? {
      name: '',
      startOption: 'scratch'
    },
    onSubmit: async ({ name, startOption, roleToCopy }) => {
      if (startOption === 'scratch') {
        const newRole = await create({ name });
        onClose(newRole.id);
      } else {
        const newRole = await create({ name, settings: roleToCopy?.settings });
        onClose(newRole.id);
      }
    }
  });

  const handleCancel = useCallback(() => {
    onClose();
  }, [onClose]);

  const {
    control,
    formState: { isSubmitting },
    watch
  } = form;

  const startOption = watch('startOption');

  return (
    <Form rules={RULES} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField label="Name" name="name" control={control} />

        <RadioGroupField name="startOption" control={control} options={START_OPTIONS} />

        {startOption === 'copy' && (
          <SelectField
            label="Select template Role"
            name="roleToCopy"
            control={control}
            options={roles ?? []}
            getOptionLabel={(role) => normalizeRoleName(role.name)}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={handleCancel} variant={ButtonVariant.Secondary}>
          Cancel
        </Button>
        <Button disabled={isSubmitting} type="submit" variant={ButtonVariant.Primary}>
          Create
        </Button>
      </ModalFooter>
    </Form>
  );
};
