import { ReactQueryKey } from '@enums';
import { PrivilegedTask } from '@generated/types/graphql';
import { useAppSelector } from '@hooks/store';
import taskTemplateApi from '@services/api/taskTemplateApi';
import { selectWorkspaceId } from '@state/selectors';
import { apiErrorHandler } from '@utils/api';
import { useQuery } from 'react-query';

export const useWorkOrderTemplate = (id?: number) => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery<PrivilegedTask>(
    [ReactQueryKey.WorkOrderTemplateList, companyId, id],
    async () => {
      try {
        // TODO - use graphql
        const { data } = await taskTemplateApi.findOne(id, companyId);

        return data.results[0];
      } catch (e) {
        throw apiErrorHandler('Error fetching work order templates', e);
      }
    },
    { enabled: Boolean(id) }
  );
};
