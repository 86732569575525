import React from 'react';
import { mapCenter } from '@features/Platform/GeolocationSettings/const';
import { Layer, Source } from 'react-map-gl';
import circle from '@turf/circle';
import { Map } from '@kit/ui/Map';

type Props = {
  // feets
  radius: number;
};

export const RadiusMap = ({ radius }: Props) => (
  <Map
    initialViewState={{
      latitude: mapCenter[1],
      longitude: mapCenter[0],
      zoom: 13
    }}
    style={{
      border: '1px solid #D2D9E1',
      borderRadius: 8
    }}
  >
    <Source type="geojson" data={{ type: 'Point', coordinates: mapCenter }}>
      <Layer
        type="circle"
        paint={{
          'circle-color': '#009688',
          'circle-radius': 4,
          'circle-stroke-color': '#FFFFFF',
          'circle-stroke-width': 1
        }}
      />
    </Source>
    <Source type="geojson" data={circle(mapCenter, radius, { units: 'feet', steps: 128 })}>
      <Layer
        type="fill"
        paint={{
          'fill-color': 'rgba(0,150,136,0.33)'
        }}
      />
    </Source>
  </Map>
);
