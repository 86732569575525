import { mapSearchFilterToServerSearchFilter } from '@components/Scheduler/useClientFilterState';
import { useClientTrayFilterState } from '@components/Scheduler/useClientTrayFilterState';
import { usePaginatedTasks } from '@components/Scheduler/useTasks';
import { ReactQueryKey } from '@enums';
import { LngLatBounds } from 'mapbox-gl';

export const MAX_VISIBLE_UNDISPATCHED_WO_ON_MAP = 200;

interface Props {
  bounds: LngLatBounds | null;
  isEnabled: boolean;
}

const boundsToPolygon = (bounds: LngLatBounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  return [
    [sw.lng, sw.lat],
    [ne.lng, sw.lat],
    [ne.lng, ne.lat],
    [sw.lng, ne.lat],
    [sw.lng, sw.lat]
  ];
};

export const useUndispatchedTasks = ({ bounds, isEnabled }: Props) => {
  const [filters] = useClientTrayFilterState();

  return usePaginatedTasks({
    isEnabled: isEnabled && !!bounds,
    queryKey: ReactQueryKey.DispatcherMapUndispatchedTasks,
    filters: {
      project: {
        geoLocationGeography: {
          coveredBy: {
            type: 'Polygon',
            coordinates: bounds ? [boundsToPolygon(bounds)] : []
          }
        }
      },
      isCompleted: { equalTo: false },
      isArchived: { equalTo: false },
      status: filters.statuses.length > 0 ? { in: filters.statuses } : undefined,
      not: {
        assigneeExists: true,
        or: [{ startDate: { isNull: false } }, { endDate: { isNull: false } }, { taskVisitsByTaskIdExist: true }]
      },
      templateTaskId: filters.templates.length > 0 ? { in: filters.templates } : undefined,
      ...mapSearchFilterToServerSearchFilter(filters.query)
    },
    perPage: MAX_VISIBLE_UNDISPATCHED_WO_ON_MAP
  });
};
