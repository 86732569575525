import { colors } from '@styles';
import styled from 'styled-components';

export const DropZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  width: 100%;
  height: 80px;

  border-radius: 4px;
  background-color: white;
  border: 1px dashed #dfdfe8;

  > span,
  .ant-upload-drag-container,
  .ant-upload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const DropZoneContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
  font-size: 14px;
  color: ${colors.green};
`;

export const LogoContainer = styled.div`
  margin-top: 16px;
  position: relative;
  border-radius: 8px;
  padding: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: #dfdfe8;
  }

  svg {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out;
  }

  :hover {
    cursor: pointer;

    &:after {
      background-color: rgba(29, 29, 53, 0.5);
    }

    svg {
      opacity: 1;
      z-index: 1;
    }
  }
`;
export const CompanyLogoImg = styled.img`
  width: auto;
  max-width: 300px;
  height: 100px;
`;

export const IconInsideImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  translate: translate(-50%, -50%);
`;

export const LoaderContainer = styled.div`
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
