import { ModalBody } from '@common/PromiseModal';
import { colors, scroll } from '@styles';
import styled, { css } from 'styled-components';

export const Description = styled.div`
  margin: 16px 0;
  font-size: 14px;
`;

export const Body = styled(ModalBody)`
  width: 815px;
  max-width: 60vw;
  min-height: 450px;
`;

export const Prompt = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding: 24px;
  gap: 24px;
  display: flex;
  align-items: center;

  > *:first-child {
    min-width: 0;
    flex: 3;
  }
  > *:last-child {
    margin-top: 24px;
    flex: 2;
    min-width: 300px;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

export const MessageAndPreview = styled.div`
  display: flex;
  gap: 24px;

  > * {
    flex: 1;
  }

  .ql-editor {
    min-height: 200px;
    max-height: 200px;
    font-weight: 400;
    line-height: normal;
  }
`;

export const MessagePreview = styled.div`
  border-radius: 16px;
  border: 1px solid #d8d8d8;
  padding: 12px;
  background-color: #fff;
  height: 200px;
  font-size: 14px;
  white-space: pre-wrap;
  position: relative;
  word-break: break-word;

  > div {
    overflow: auto;
    ${scroll};
    display: flex;
    height: 100%;
  }

  a {
    color: #235dff;
    text-decoration: none;

    &:hover,
    &:visited,
    &:active,
    &:link,
    &:focus {
      color: #235dff;
    }
  }

  &:before {
    position: absolute;
    content: '';
    left: -1px;
    bottom: -8px;
    width: 10px;
    height: 36px;
    background-color: #fff;
    border-left: 1px solid #d8d8d8;
    border-bottom: 1px solid #d8d8d8;
    border-bottom-right-radius: 100%;
  }
`;

export const TitleInputWrapper = styled.div`
margin-left:-12px;
margin-top: -12px;

> div {
  margin-bottom: 8px;
}
&& {
.MuiOutlinedInput-input {
    font-size: 24px;
    line-height: 29px;

    font-weight: 500;
    padding: 9px 12px;
    padding-left: 13px;
    height: auto;
    letter-spacing normal;

    ::placeholder {
        font-size: 24px;
        color: #828D9A;
        font-weight: 500;
        font-style: normal;
    }
}
}
`;

export const EditableFieldContainer = styled.div<{ isDisabled: boolean; isPlaceholder?: boolean }>`
  font-size: 14px;
  font-weight: 400;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  line-height: 22px;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : '#000')};

  ${(props) =>
    !props.isDisabled &&
    css`
      cursor: pointer;
      transition:
        border-color 0.15s ease-in-out,
        background-color 0.15s ease-in-out;

      &:hover {
        border-color: #e8ecef;
        background-color: #fff;
      }
    `};
`;
export const EditIcon = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${colors.green};
`;

export const TitleView = styled(EditableFieldContainer)<{ isPlaceholder: boolean }>`
  max-width: 100%;
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  display: inline-flex;
  line-height: 29px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => (props.isPlaceholder ? '#828D9A' : '#000')};

  cursor: pointer;
  border: 1px solid transparent;
  padding: 8px 12px;
  border-radius: 4px;
  min-height: 40px;
  background-color: transparent;
  transition:
    border-color 0.15s ease-in-out,
    background-color 0.15s ease-in-out;

  &:hover {
    border-color: #dfdfe8;
    background-color: #fff;
  }

  padding-right: 24px;
  position: relative;

  > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${EditIcon} {
    top: 14px;
  }
`;

export const DescriptionWrapper = styled.div`
  margin-left: -12px;
  margin-top: -12px;

  > div {
    margin-bottom: 8px;
  }

  &&& {
    .MuiOutlinedInput-root {
      padding: 9px 12px;
      padding-left: 13px;
    }
  }
`;

export const DescriptionView = styled(EditableFieldContainer)`
  padding: 8px 12px;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.15008px;
  line-height: 18px;
  border: 1px solid transparent;
  white-space: pre-wrap;

  padding-right: 24px;
  position: relative;

  ${EditIcon} {
    top: 8px;
  }
`;

export const ReferralLink = styled.div`
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f7f7fc;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  font-size: 14px;

  > div {
    max-width: calc(100% - 32px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    color: #9c9caa;
  }
`;

export const ShareButtons = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
`;

export const ShareButton = styled.a`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background-color: #fff;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  transition: border-color 0.15s ease-in-out;

  &:hover {
    border-color: ${colors.green};
  }
`;
export const ChangeImageButton = styled.div`
  color: #fff;
  height: 32px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #fff;
  position: absolute;
  translate: translate(-50%, -50%);
  border-radius: 4px;
`;

export const RemoveImageButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  opacity: 0.72;
  background-color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 4px;
  top: 4px;
`;

export const PromptImageContainer = styled.div`
  img {
    width: auto;
    max-height: 180px;
    max-width: 100%;
  }

  width: 100%;
  min-height: 150px;
  position: relative;
  border-radius: 8px;
  padding: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ${ChangeImageButton},
  ${RemoveImageButton} {
    opacity: 0;
  }

  svg {
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 8px;
    transition: background-color 0.15s ease-in-out;
  }

  :hover {
    cursor: pointer;

    &:after {
      background-color: rgba(29, 29, 53, 0.75);
    }

    svg {
      opacity: 1;
      z-index: 1;
    }

    ${ChangeImageButton} {
      opacity: 1;
      z-index: 1;
    }

    ${RemoveImageButton} {
      opacity: 1;
      z-index: 1;
    }
  }
`;

export const FakeFormFieldContainer = styled.div`
  margin-bottom: 16px;
`;

export const FieldLabel = styled.label`
  margin-bottom: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #828d9a;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Asterisk = styled.span`
  color: #d54855;
`;

export const FieldsRow = styled.div`
  display: flex;
  gap: 16px;

  > * {
    flex: 1;
  }
`;
