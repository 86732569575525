import React, { useCallback } from 'react';
import { Switch } from '@kit/ui/Switch';
import { useClientFilterState } from '../useClientFilterState';

export const ArchivedToggle = () => {
  const {
    clientFilters: { isArchivedShown },
    updateFilters
  } = useClientFilterState();

  const handleChange = useCallback(
    (isChecked: boolean) => {
      updateFilters({ isArchivedShown: isChecked });
    },
    [updateFilters]
  );

  return <Switch isActive={isArchivedShown} onChange={handleChange} label="Show archived" />;
};
