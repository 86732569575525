import React, { useMemo } from 'react';
import { Form, FormValidationRules, InputField, useForm } from '@kit/components/Form';
import { SolarEdgeIntegrationDTO } from '@types';
import { Button, ButtonVariant } from '@kit/ui/Button';
import { useAppSelector, useAuth } from '@hooks';
import { selectWorkspaceId } from '@state/selectors';
import { IntegrationProvider } from '@generated/types/graphql';
import { useCreateIntegration } from '@hooks/workspace/systems/integrations';
import { ModalBody, ModalFooter } from '@common/PromiseModal';

type Props = {
  isMock: boolean;
  onClose: () => void;
};

export const SolarEdgeForm = ({ isMock, onClose }: Props) => {
  const {
    user: { userId }
  } = useAuth();
  const companyId = useAppSelector(selectWorkspaceId);

  const { mutateAsync: create } = useCreateIntegration(userId, companyId);

  const {
    form: {
      control,
      formState: { isSubmitting, isValid }
    },
    handleSubmit
  } = useForm({
    defaultValues: {
      provider: isMock ? IntegrationProvider.LunarEdge : IntegrationProvider.SolarEdge
    },
    onSubmit: async (values: SolarEdgeIntegrationDTO) => {
      await create(values);

      onClose();
    }
  });

  const rules = useMemo<FormValidationRules<SolarEdgeIntegrationDTO>>(
    () => ({
      provider: {
        isRequired: true
      },
      apiKey: {
        isRequired: true
      }
    }),
    []
  );

  return (
    <Form rules={rules} onSubmit={handleSubmit}>
      <ModalBody>
        <InputField control={control} name="apiKey" label="SolarEdge API key" type="password" />
      </ModalBody>

      <ModalFooter>
        <Button variant={ButtonVariant.Secondary} onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={isSubmitting || !isValid} variant={ButtonVariant.Primary} type="submit">
          Connect
        </Button>
      </ModalFooter>
    </Form>
  );
};
