import { errorHandler } from '@services/api/helpers';
import { useMutation } from 'react-query';
import projectApi from '@services/api/projectApi';
import download from 'downloadjs';

export const useDownloadFailedRows = () => {
  return useMutation<void, Error, number>(async (historyId) => {
    try {
      const { data } = await projectApi.downloadFailedRows(historyId);

      download(data, `failed-rows-${historyId}.csv`, 'text/csv');
    } catch (error) {
      throw errorHandler(error);
    }
  });
};
