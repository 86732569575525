import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border: 1px solid #dfdfe8;
  border-radius: 16px;
  background-color: #ffffff;
  max-width: 300px;
  width: 300px;
`;

export const Title = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #1d1d35;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
`;

export const DebugFrame = styled.fieldset`
  border: 1px solid #F1AA12;
  padding: 8px;
`;

export const DebugLabel = styled.legend`
  color: #F1AA12;
  font-size: 12px;
  font-weight: 400;
`;
