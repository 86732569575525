import React from 'react';
import { useClientFilterState } from '../useClientFilterState';
import { GroupedTable } from './GroupedTable';
import { PlainTable } from './PlainTable';
import { Container, Scrollable } from './styled';

export const Table = () => {
  const {
    clientFilters: { groupBy }
  } = useClientFilterState();

  return (
    <Container>
      <Scrollable>{groupBy ? <GroupedTable /> : <PlainTable />}</Scrollable>
    </Container>
  );
};
