import { ReactQueryKey } from '@enums';
import { useToast } from '@hooks/useToast';
import { errorHandler } from '@services/api/helpers';
import { useMutation, useQueryClient } from 'react-query';
import { selectWorkspaceId } from '@state/selectors';
import { useAppSelector } from '@hooks';
import { labelsApi, UpdateLabelPayload } from '@services/api/labelsApi';

export const useUpdateLabel = () => {
  const companyId = useAppSelector(selectWorkspaceId);
  const { showError, showSuccess } = useToast();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { id: number; dto: UpdateLabelPayload }>(
    async ({ id, dto }) => {
      try {
        await labelsApi.update(companyId, { id, dto });
      } catch (error) {
        throw errorHandler(error);
      }
    },
    {
      onError: (error) => {
        showError(error.message);
      },
      onSuccess: async () => {
        showSuccess('Label updated successfully');
        queryClient.invalidateQueries([ReactQueryKey.LabelList, companyId]);
      }
    }
  );
};
