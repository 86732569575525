import { SystemAlertConfig } from '@generated/types/graphql';
import { useQuery } from 'react-query';
import { ReactQueryKey } from '@enums';
import { apiErrorHandler } from '@utils';
import { postGraphql } from '@services/api/base/graphql';
import { gql } from 'graphql-request';
import { selectWorkspaceId } from '@state/selectors';
import { SystemAlertCondition } from '@services/api/systemAlertsAPI';
import { useAppSelector } from '../../..';

export type SystemAlertConfigListItem = Pick<SystemAlertConfig, 'id' | 'name' | 'showInPortal'> & {
  fleets: SystemAlertConfig['profiles'];
  condition: SystemAlertCondition;
};

export const useSystemAlertConfigList = () => {
  const companyId = useAppSelector(selectWorkspaceId);

  return useQuery([ReactQueryKey.SystemsSettings, ReactQueryKey.SystemsAlertsSettings, companyId], async () => {
    try {
      const { systemAlertConfigs } = await postGraphql<{ systemAlertConfigs: SystemAlertConfig[] }>(
        gql`
          query SYSTEM_ALERT_CONFIG_LIST_QUERY($companyId: Int!) {
            systemAlertConfigs(condition: { companyId: $companyId }) {
              id

              name
              profiles {
                id
                name
              }
              showInPortal
              condition
            }
          }
        `,
        { companyId }
      );

      return systemAlertConfigs.map((alert) => ({
        ...alert,
        fleets: alert.profiles
      })) as SystemAlertConfigListItem[];
    } catch (e) {
      throw apiErrorHandler('Error fetching alerts', e);
    }
  });
};
