import React from 'react';
import { Body } from '@components/templates/common';
import { Routable } from '@types';
import Blueprint from './Blueprint';
import { BlueprintContainer } from './styled';

interface Props extends Routable {
  workflowId: string;
}

export const WorkflowPage = ({ workflowId }: Props) => {
  if (!workflowId) {
    return null;
  }

  return (
    <BlueprintContainer>
      <Body style={{ overflow: 'revert', position: 'initial' }}>
        <Blueprint blueprintId={+workflowId} />
      </Body>
    </BlueprintContainer>
  );
};
