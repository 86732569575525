import { ReactQueryKey } from '@enums';
import workflowsApi from '@services/api/workflowsApi';
import { selectWorkspaceId } from '@state/selectors';
import { PaginationResult, AutomationFromApi } from '@types';
import { apiErrorHandler } from '@utils/api';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

export const useAutomationList = () => {
  const companyId = useSelector(selectWorkspaceId);

  return useQuery<AutomationFromApi[]>([ReactQueryKey.ProjectWorkflows], async () => {
    try {
      const {
        data
      }: {
        data: PaginationResult<AutomationFromApi>;
      } = await workflowsApi.find({
        sortCol: 'name',
        sortDesc: false,
        fetchAll: true,
        companyId
      });

      return data.results;
    } catch (e) {
      throw apiErrorHandler('Error fetching automations', e);
    }
  });
};
