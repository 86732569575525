import { StageListItem } from '@hooks/workspace/stages/useStageList';
import React, { useCallback, useMemo } from 'react';
import { useConfirmDeleteModal, useModal } from '@common/PromiseModal';
import { RecordType } from '@types';
import { ENTITY_NAME_BY_RECORD_TYPE } from '@features/ProjectPortfolio/Project/History/FeedItem/constants';
import { ProjectStageBadge } from '@components/ProjectStages';
import { ContextMenu, MenuItem } from '@kit/components/ContextMenu';
import { Edit3 } from 'react-feather';
import { TrashIcon } from '@kit/ui/icons/Trash';
import { useAppSelector } from '@hooks/store';
import { selectWorkspaceId } from '@state/selectors';
import { useProjectStageMutations } from '@hooks/useProjectStages';
import { RedSlaValue, YellowSlaValue } from './styled';
import { StageForm } from '../../Form';

interface Props {
  stage: StageListItem;
  recordType: RecordType;
  isDescriptionShown?: boolean;
}

export const Row = ({ stage, recordType, isDescriptionShown }: Props) => {
  const { openModal } = useModal();
  const confirmDelete = useConfirmDeleteModal();

  const companyId = useAppSelector(selectWorkspaceId);

  const {
    delete: { mutateAsync: deleteStage }
  } = useProjectStageMutations(companyId);

  const handleRowClick = useCallback(() => {
    openModal<void>(
      ({ onClose }) => (
        <StageForm
          isDescriptionShown={isDescriptionShown}
          recordType={recordType}
          initialValues={stage}
          onClose={onClose}
        />
      ),
      { title: `Edit ${ENTITY_NAME_BY_RECORD_TYPE[recordType]} Stage` }
    );
  }, [openModal, recordType, isDescriptionShown, stage]);

  const menuItems = useMemo<MenuItem[]>(() => {
    return [
      {
        title: 'Edit',
        icon: <Edit3 size="16px" color="#98A9BC" />,
        onClick: () => {
          openModal<void>(
            ({ onClose }) => (
              <StageForm
                isDescriptionShown={isDescriptionShown}
                recordType={recordType}
                initialValues={stage}
                onClose={onClose}
              />
            ),
            { title: `Edit ${ENTITY_NAME_BY_RECORD_TYPE[recordType]} Stage` }
          );
        }
      },
      {
        title: 'Delete',
        icon: <TrashIcon size="16px" color="#D54855" />,

        onClick: async () => {
          if (await confirmDelete('Are you sure you want to delete this Stage?')) {
            await deleteStage(stage.id);
          }
        }
      }
    ];
  }, [openModal, recordType, isDescriptionShown, stage, confirmDelete, deleteStage]);

  return (
    <tr onClick={handleRowClick}>
      <td width="40%">{stage.name}</td>
      <td width="60%">{isDescriptionShown && stage.description}</td>
      <td>{stage.yellowSla ? <YellowSlaValue>{stage.yellowSla}</YellowSlaValue> : '-'}</td>
      <td>{stage.redSla ? <RedSlaValue>{stage.redSla}</RedSlaValue> : '-'}</td>
      <td>
        <ProjectStageBadge stage={{ type: stage.type, name: stage.type }} />
      </td>
      <td
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <ContextMenu items={menuItems} />
      </td>
    </tr>
  );
};
